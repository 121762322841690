import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import APIcon from 'apex-web/lib/components/common/APIcon';

import './SendReceiveConfirmHeader.css';

const bemClasses = getBEMClasses('dcx-send-receive-confirm-header');

const SendReceiveConfirmHeader = (props, context) => {
  const {
    isSend,
    product: { ProductFullName, Product, ProductSymbol }
  } = props;
  const headerText = isSend
    ? `${context.t('Send')} ${Product || ProductSymbol} ${ProductFullName}`
    : `${Product || ProductSymbol} ${ProductFullName} ${context.t('Request')}`;
  return (
    <header className={bemClasses()}>
      <APIcon
        name={isSend ? 'sendWithBorder' : 'receiveWithBorder'}
        customClass={bemClasses('icon')}
      />
      <span className={bemClasses('title')}>{headerText}</span>
    </header>
  );
};

SendReceiveConfirmHeader.propTypes = {
  product: PropTypes.object.isRequired,
  isSend: PropTypes.bool.isRequired
};

SendReceiveConfirmHeader.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendReceiveConfirmHeader;
