import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import ProductIconContainer from 'apex-web/lib/components/common/ProductIcon/ProductIconContainer';
import ArrowDownIcon from '../../../images/icons/icon-arrow-down.svg';

import './WalletRow.css';
import { WalletRowNavItems } from './WalletRowNavItemsContainer';

const walletRowClasses = getBEMClasses('dcx-wallet-row');

const WalletRow = props => {
  const {
    iconFileName,
    ProductSymbol,
    ProductFullName,
    isCrypto,
    positionDetails,
    baseCurrency,
    isOpened,
    isWithdrawDisabled,
    ProductId,
    isMobile
  } = props;

  const baseCurrencyTitle = useMemo(() => {
    if (baseCurrency) {
      const convertedValue = rawAmount * baseCurrency.value;
      const formattedValue = formatNumberToLocale(
        convertedValue,
        baseCurrency.DecimalPlaces
      );
      return `(${baseCurrency.Product} ${formattedValue})`;
    } else {
      return '-';
    }
  }, [baseCurrency]);

  return isMobile ? (
    <>
      <div
        className={walletRowClasses('mobile', isOpened && 'open')}
        onClick={props.onRowClick}
      >
        <div className={walletRowClasses('icon-label')}>
          <div className={walletRowClasses('product-icon')}>
            <ProductIconContainer iconFileName={iconFileName} size={32} />
          </div>
          <div className={walletRowClasses('symbol-wrapper')}>
            <p className={walletRowClasses('column-title')}>
              {ProductFullName}
            </p>
            <p className={walletRowClasses('column-value')}>{ProductSymbol}</p>
          </div>
        </div>
        <img
          src={ArrowDownIcon}
          className={walletRowClasses(isOpened ? 'arrow-up' : 'arrow-down')}
        />
      </div>
      {isOpened && (
        <div className={walletRowClasses('mobile-details')}>
          <div className={walletRowClasses('column')}>
            <p className={walletRowClasses('column-value')}>Amount</p>
            <p className={walletRowClasses('column-title')}>
              {positionDetails?.Amount}
            </p>
          </div>
          <div className={walletRowClasses('column')}>
            <p className={walletRowClasses('column-value')}>Price</p>
            <p className={walletRowClasses('column-title')}>
              {baseCurrencyTitle}
            </p>
          </div>
          <div className={walletRowClasses('column')}>
            <p className={walletRowClasses('column-value')}>Available</p>
            <p className={walletRowClasses('column-title')}>
              {positionDetails?.AvailableBalance}
            </p>
          </div>
          <div className={walletRowClasses('column')}>
            <p className={walletRowClasses('column-value')}>Pending</p>
            <p className={walletRowClasses('column-title')}>
              {positionDetails?.PendingDeposits}
            </p>
          </div>
          <div className={walletRowClasses('activity-link-columns')}>
            <WalletRowNavItems
              isCrypto={isCrypto}
              isOpened={isOpened}
              isWithdrawDisabled={isWithdrawDisabled}
              product={{
                iconFileName,
                ProductSymbol,
                ProductFullName,
                ProductId
              }}
              isHideArrow
            />
          </div>
        </div>
      )}
    </>
  ) : (
    <div
      className={walletRowClasses('', isOpened && 'open')}
      onClick={props.onRowClick}
    >
      <div className={walletRowClasses('icon-label')}>
        <div className={walletRowClasses('product-icon')}>
          <ProductIconContainer iconFileName={iconFileName} size={32} />
        </div>
        <div className={walletRowClasses('symbol-wrapper')}>
          <p className={walletRowClasses('column-title')}>{ProductFullName}</p>
          <p className={walletRowClasses('column-value')}>{ProductSymbol}</p>
        </div>
      </div>
      <div className={walletRowClasses('column')}>
        <p className={walletRowClasses('column-title')}>
          {positionDetails?.Amount}
        </p>
        <p className={walletRowClasses('column-value')}>{baseCurrencyTitle}</p>
      </div>
      <div className={walletRowClasses('column')}>
        <p className={walletRowClasses('column-value')}>Available</p>
        <p className={walletRowClasses('column-title')}>
          {positionDetails?.AvailableBalance}
        </p>
      </div>
      <div className={walletRowClasses('column')}>
        <p className={walletRowClasses('column-value')}>Pending</p>
        <p className={walletRowClasses('column-title')}>
          {positionDetails?.PendingDeposits}
        </p>
      </div>
      <div className={walletRowClasses('activity-link-columns')}>
        <WalletRowNavItems
          isCrypto={isCrypto}
          isOpened={isOpened}
          isWithdrawDisabled={isWithdrawDisabled}
          product={{
            iconFileName,
            ProductSymbol,
            ProductFullName,
            ProductId
          }}
        />
      </div>
    </div>
  );
};

WalletRow.contextTypes = {
  t: PropTypes.func.isRequired
};

export default WalletRow;
