import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { getBEMClasses } from '../../../../helpers/cssClassesHelper';
import './DonutChart.css';

const baseClasses = getBEMClasses('portfolio-donut-chart');

const DonutChart = ({ assets, productManifest }) => {
  const chartLabels = assets.assetsWithPriceAndPercent.map(
    asset => asset.ProductSymbol
  );
  const chartColors = assets.assetsWithPriceAndPercent.map(
    asset => productManifest?.[asset.ProductSymbol]?.productColor || '#FF7300'
  );

  const chartHoldings = assets.assetsWithPriceAndPercent.map(asset =>
    parseFloat(asset.PercentOfTotalAmount)
  );

  const chartData = {
    options: {
      labels: chartLabels,
      colors: chartColors
    },
    series: chartHoldings
  };

  return (
    <div className={baseClasses('chart')}>
      <div className={baseClasses('chart-header')}>
        <h2>By asset</h2>
      </div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="donut"
        height={350}
      />

      <div className={baseClasses('total-value-wrapper')}>
        <p className={baseClasses('total-value-header')}>Total value</p>
        <p className={baseClasses('total-value-balance')}>
          ${assets.totalAvailableBalance}
        </p>
      </div>
    </div>
  );
};

export default DonutChart;
