import React, { useState } from 'react';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import ArrowDownIcon from '../../../images/icons/icon-arrow-down.svg';
import './Dropdown.css';

const Dropdown = props => {
  const {
    options,
    defaultValue = 'All',
    selectedOption,
    setSelectedOption,
    customClass,
    label
  } = props;
  const baseClasses = getBEMClasses('dropdown', customClass);
  const [showDropdown, setShowDropdown] = useState(false);
  const selectedOptionData = selectedOption
    ? options.find(item => item.value === selectedOption)
    : { title: defaultValue };
  return (
    <div className={baseClasses()}>
      {label && <div className={baseClasses('label')}>{label}</div>}
      <div
        className={baseClasses('selected-option')}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <div className={baseClasses('title-wrapper')}>
          {!!selectedOptionData?.icon && (
            <img
              src={selectedOptionData.icon}
              className={baseClasses('title-icon')}
            />
          )}
          <p className={baseClasses('title')}>{selectedOptionData.title}</p>
        </div>

        <img
          src={ArrowDownIcon}
          className={baseClasses('icon', showDropdown && 'open')}
        />
      </div>
      {showDropdown && (
        <div className={baseClasses('options-list')}>
          {options.map(item => (
            <p
              key={`dropdown_option_${item.value}`}
              className={baseClasses(
                'option',
                selectedOption === item.value && 'active'
              )}
              onClick={() => {
                setSelectedOption(item.value);
                setShowDropdown(false);
              }}
            >
              {item.title}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
