import { createSelector } from 'reselect';

export const filteredDepositsByProductSelector = createSelector(
  [state => state.deposit.depositsStatus, (state, props) => props],
  (deposits, options) => {
    if (deposits?.length) {
      if (options?.selectedCryptoProductId || options?.selectedFiatProductId) {
        return deposits
          .filter(
            deposit =>
              deposit.AssetId === options?.selectedCryptoProductId ||
              deposit.AssetId === options?.selectedFiatProductId
          )
          .sort(
            (a, b) =>
              new Date(b.CreatedTimestamp) - new Date(a.CreatedTimestamp)
          );
      } else {
        return deposits?.sort(
          (a, b) => new Date(b.CreatedTimestamp) - new Date(a.CreatedTimestamp)
        );
      }
    } else {
      return [];
    }
  }
);

export const filteredWithdrawalsByProductSelector = createSelector(
  [state => state.withdraw.withdrawTickets, (state, props) => props],
  (withdrawals, options) => {
    if (withdrawals?.length) {
      if (options?.selectedCryptoProductId || options?.selectedFiatProductId) {
        return withdrawals
          .filter(
            withdrawal =>
              withdrawal.AssetId === options?.selectedCryptoProductId ||
              withdrawal.AssetId === options?.selectedFiatProductId
          )
          .sort(
            (a, b) =>
              new Date(b.CreatedTimestamp) - new Date(a.CreatedTimestamp)
          );
      } else {
        return withdrawals.sort(
          (a, b) => new Date(b.CreatedTimestamp) - new Date(a.CreatedTimestamp)
        );
      }
    } else {
      return [];
    }
  }
);
