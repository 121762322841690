import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import APSelect from 'apex-web/lib/components/common/APSelect';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { templateFormRenderer } from 'apex-web/lib/helpers/formTemplateHelper';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import config from 'apex-web/lib/config';

import './SendAddress.css';

const baseClasses = getBEMClasses('dcx-send-address');

const SendAddressComponent = (props, context) => {
  const {
    selectWithdrawProduct,
    withdrawInfo,
    withdrawInfo: { template },
    product,
    product: { ProductId },
    selectWithdrawTemplate,
    WalletsDisabledMessage,
    verificationLevelConfig
  } = props;

  useEffect(() => {
    selectWithdrawProduct(ProductId);
  }, [ProductId]);

  const showTemplateFields = Object.keys(template || {}).length;
  const showTemplateTypeSelect =
    withdrawInfo.templateTypes.length > 1 &&
    !Object.keys(withdrawInfo.templateInfo).length &&
    !withdrawInfo.isLoading;
  const templateTypes = [
    { value: '', label: 'Select an option' },
    ...withdrawInfo.templateTypes.map(template => ({
      value: template.AccountProviderId,
      label: template[config.Withdraw.AccountProviderDisplayKey]
    }))
  ];

  const hasError = withdrawInfo.error || WalletsDisabledMessage;

  return (
    <div className={baseClasses('wrapper')}>
      {withdrawInfo.withdrawStatus.success ? (
        <div className={baseClasses('success')}>
          {context.t('Your withdraw ticket was created successfully.')}
        </div>
      ) : (
        <div className={baseClasses('form-body')}>
          {hasError ? (
            <div className={baseClasses('error')}>{hasError}</div>
          ) : (
            <React.Fragment>
              {withdrawInfo.isLoading && <Spinner />}

              {showTemplateTypeSelect && (
                <APSelect
                  name="TemplateType"
                  customClass={baseClasses()}
                  label={context.t(
                    'Select an option to continue the withdraw process'
                  )}
                  onSelect={value => selectWithdrawTemplate(ProductId, value)}
                  options={templateTypes}
                />
              )}

              {showTemplateFields > 0 && (
                <div className={baseClasses('section')}>
                  {templateFormRenderer(
                    template,
                    baseClasses(),
                    context,
                    product,
                    verificationLevelConfig
                  )}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

SendAddressComponent.defaultProps = {
  withdrawInfo: {
    templateInfo: {},
    template: {},
    isLoading: false,
    error: '',
    TemplateForm: {},
    withdrawStatus: {},
    getWithdrawFee: () => {}
  }
};

SendAddressComponent.propTypes = {
  getWithdrawFee: PropTypes.func,
  selectWithdrawProduct: PropTypes.func.isRequired,
  product: PropTypes.shape({
    ProductSymbol: PropTypes.string,
    ProductId: PropTypes.number
  }).isRequired,
  withdrawInfo: PropTypes.shape({
    templateInfo: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    TemplateForm: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    withdrawStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  })
};

SendAddressComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendAddressComponent;
