import { connect } from 'react-redux';
import TVChartComponent from './TVChartComponent';
import { changeTickersInterval } from 'apex-web/lib/redux/actions/tickersActions';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import config from 'apex-web/lib/config';

const mapStateToProps = state => {
  const selectedInstrument = selectedInstrumentSelector(state);
  const { decimalPlaces } = state.apexCore.product;

  const { theme } = config.global;
  const localConfig = JSON.parse(localStorage.getItem('apexTemplate')) || {};

  return {
    selectedInstrument,
    decimalPlaces,
    theme: localConfig.theme || theme
  };
};

const mapDispatchToProps = {
  changeTickersInterval
};

export default connect(mapStateToProps, mapDispatchToProps)(TVChartComponent);
