import React from 'react';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import SendReceive from '../../dcxComponents/SendReceive/SendReceiveContainer';
import DepositWithdraw from '../../dcxComponents/DepositWithdraw/DepositWithdrawContainer';
import './SendReceivePage.css';

const baseClasses = getBEMClasses('send-receive-page');

const SendReceivePage = props => {
  const historyState = props.history.location.state?.data;
  return (
    <>
      <div className={baseClasses('')}>
        <div className={baseClasses('content')}>
          <div className={baseClasses('header')}>
            <p className={baseClasses('title')}>Send and receive</p>
            <p className={baseClasses('sub-title')}>
              The safe place to send and receive crypto.
            </p>
          </div>
          {!!historyState?.product &&
            (historyState?.isCrypto ? (
              <SendReceive
                product={historyState?.product}
                isSend={historyState?.isSend}
              />
            ) : (
              <DepositWithdraw
                product={historyState?.product}
                isSend={historyState?.isSend}
              />
            ))}
        </div>
      </div>

      <div className={baseClasses('footer')}>
        <PageFooterLayout />
      </div>
    </>
  );
};

export default SendReceivePage;
