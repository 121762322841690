import React from 'react';
import PropTypes from 'prop-types';
import { required, biggerThanZero } from 'apex-web/lib/helpers/formValidations';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import APInput from 'apex-web/lib/components/common/APInput';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import APButton from 'apex-web/lib/components/common/APButton';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import config from 'apex-web/lib/config';

import './ManualDepositWorkflowComponent.css';

const baseClasses = getBEMClasses('manual-deposit');

const ManualDepositWorkflowComponent = (props, context) => {
  const {
    product: { DecimalPlaces, Product }
  } = props;
  const {
    Deposit,
    Deposit: { hideDepositBankInfo }
  } = config;
  const bankInfo = Deposit[Product] || {};

  return (
    <div className={baseClasses('container-with-footer')}>
      <div className={baseClasses('container')}>
        <div className={baseClasses('title')}>{context.t('Deposit Money')}</div>
        <div className={baseClasses('step-item')}>
          <span className={baseClasses('step')}>{context.t('Step 1:')}</span>
          {context.t('Create the deposit ticket.')}
        </div>
        <div className={baseClasses('step-item')}>
          <span className={baseClasses('step')}>{context.t('Step 2:')}</span>
          {context.t('Process deposit instructions on the deposit receipt.')}
        </div>
        <div className={baseClasses('frame')}>
          <APInput
            type="text"
            name="fullname"
            label={context.t('Full Name:')}
            customClass={baseClasses()}
            validate={[required]}
          />
          <APNumberInput
            type="text"
            name="amount"
            label={context.t('Amount:')}
            decimalPlaces={DecimalPlaces}
            customClass={baseClasses()}
            validate={[required, biggerThanZero]}
          />
          <APInput
            name="comments"
            label={context.t('Comments:')}
            customClass={baseClasses()}
            info={context.t(
              'The comment field is optional. Please use it for special instructions.'
            )}
          />
        </div>
        {!hideDepositBankInfo && (
          <React.Fragment>
            <div className={baseClasses('bank-info-label')}>
              {context.t('Bank Account to Wire the Funds')}
            </div>
            <div className={baseClasses('bank-info')}>
              <APLabelWithText
                label={context.t('Bank Name:')}
                text={bankInfo.bankName}
                customClass={baseClasses()}
              />
              <APLabelWithText
                label={context.t('Wire Number:')}
                text={bankInfo.bankWireNumber}
                customClass={baseClasses()}
              />
              <APLabelWithText
                label={context.t('Account Number:')}
                text={bankInfo.bankAccountNumber}
                customClass={baseClasses()}
              />
            </div>
          </React.Fragment>
        )}
      </div>
      <div className={baseClasses('footer')}>
        <APButton
          customClass={baseClasses()}
          type="submit"
          data-test="Place Deposit Ticket"
          disabled={props.submitting}
          styleName="additive"
          onClick={props.onSubmit}
        >
          {props.submitting
            ? context.t('Submitting...')
            : context.t('Place Deposit Ticket')}
        </APButton>
      </div>
    </div>
  );
};

ManualDepositWorkflowComponent.defaultProps = {
  onSubmit: () => {},
  submitting: false
};

ManualDepositWorkflowComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

ManualDepositWorkflowComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ManualDepositWorkflowComponent;
