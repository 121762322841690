import React from 'react';
import SimplePaginationContainer from 'apex-web/lib/components/common/Pagination/SimplePaginationContainer';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import Row from './components/Row/Row';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import APIcon from 'apex-web/lib/components/common/APIcon';
import './MobileOrderHistoryList.css';

const baseClasses = getBEMClasses('mobile-order-history');

const getMinimumOf = (data, min) => {
  const emptyRows = min - data.length;
  return min && emptyRows > 0
    ? [...data, ...new Array(Math.min(min || emptyRows, emptyRows))]
    : data;
};

const MobileOrderHistoryList = props => {
  const {
    componentName,
    rows,
    rowProps,
    columns,
    rowsContent,
    pageSize,
    onRequestPrev,
    onRequestNext,
    fetching,
    empty
  } = props;
  const totalPages = Math.ceil(rows.length / pageSize || rows.length);

  const renderList = data => {
    return data
      .filter(item => !!item)
      .map((item, index) => (
        <Row
          key={`mobile-order-history-row__${index}`}
          row={item}
          rowsContent={rowsContent}
          columns={columns}
          rowProps={rowProps}
        />
      ));
  };

  if (fetching) {
    return <Spinner text="Loading ..." />;
  }

  if (rows.length === 0) {
    return (
      <div className={baseClasses('empty-row')}>
        <APIcon name="stop" customClass={baseClasses('empty-icon')} />
        {empty}
      </div>
    );
  }

  return (
    <SimplePaginationContainer
      totalPages={totalPages}
      containerClassName={baseClasses('pagination-container')}
      content={() => {
        return renderList(getMinimumOf(rows, pageSize));
      }}
      componentName={componentName}
      customClass={baseClasses()}
      showOnlyActivePage={true}
      onRequestPrev={onRequestPrev}
      onRequestNext={onRequestNext}
    />
  );
};

export default MobileOrderHistoryList;
