import React from 'react';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './Input.css';

const Input = props => {
  const { type = 'text', customClass, label, variant, ...rest } = props;
  const baseClasses = getBEMClasses('transferInput', customClass);

  const inputElement = (
    <input type={type} className={baseClasses('input')} {...rest} />
  );

  return (
    <div className={baseClasses('row')}>
      {label && <div className={baseClasses('label')}>{label}</div>}
      {variant === 'dollarSign' ? (
        <div className={baseClasses('wrapper')}>{inputElement}</div>
      ) : (
        inputElement
      )}
    </div>
  );
};

export default Input;
