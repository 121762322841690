import apex from 'apex-web/lib/apex';
import subDays from 'date-fns/sub_days';
import subWeeks from 'date-fns/sub_weeks';
import subMonth from 'date-fns/sub_months';
import subYear from 'date-fns/sub_years';
import getTime from 'date-fns/get_time';

export const RECEIVE_ACCOUNT_TRANSACTION = 'RECEIVE_ACCOUNT_TRANSACTION';
export const REQUEST_ACCOUNT_TRANSACTION = 'REQUEST_ACCOUNT_TRANSACTION';

export const receiveAccountTransactions = payload => ({
  type: RECEIVE_ACCOUNT_TRANSACTION,
  payload
});

export const requestAccountTransactions = () => ({
  type: REQUEST_ACCOUNT_TRANSACTION
});

const getAccountTransactions = period => async (dispatch, getState) => {
  dispatch(requestAccountTransactions());
  const { AccountId, OMSId } = getState().user?.userInfo;
  const { toDate, fromDate } = getTimePeriod(period);
  const result = await apex.connection.RPCPromise('GetAccountTransactions', {
    AccountId,
    OMSId: OMSId,
    StartTimestamp: fromDate,
    EndTimeStamp: toDate
  });

  dispatch(
    receiveAccountTransactions({ transaction: JSON.parse(result.o), period })
  );
};

const getTimePeriod = period => {
  const toDate = getTime(new Date());
  if (period === 'day') {
    return {
      toDate,
      fromDate: getTime(subDays(new Date(), 1))
    };
  } else if (period === 'week') {
    return {
      toDate,
      fromDate: getTime(subWeeks(new Date(), 1))
    };
  } else if (period === 'month') {
    return {
      toDate,
      fromDate: getTime(subMonth(new Date(), 1))
    };
  } else if (period === 'year') {
    return {
      toDate,
      fromDate: getTime(subYear(new Date(), 1))
    };
  } else {
    return { toDate: undefined, fromDate: undefined };
  }
};

export { getAccountTransactions };
