import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { buyValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

import './ConfirmOrderModalComponent.css';

const bemClasses = getBEMClasses('confirm-order-modal');

const ConfirmOrderModalComponent = (props, context) => {
  const {
    formObj,
    isOpen,
    close,
    confirmReport,
    feeSymbol,
    selectedInstrument: {
      QuantityIncrement,
      PriceIncrement,
      Product1Symbol,
      Product2Symbol
    },
    price,
    orderTotal,
    isMarginActive
  } = props;
  const isBuySide = formObj.values.side === buyValue;

  const items = [
    {
      label: context.t('Order Type:'),
      text: formObj.values.orderType
    },
    formObj.values.value && formObj.values.side === '1'
      ? null
      : {
          label: context.t('Quantity'),
          text:
            formObj.values.value && formObj.values.side === '0'
              ? `${formatNumberToLocale(
                  formObj.values.value,
                  convertIncrementToIntDecimalPlaces(PriceIncrement)
                )} ${Product2Symbol}`
              : formObj.values.value && formObj.values.side === '1'
                ? `${formatNumberToLocale(
                    formObj.values.value,
                    convertIncrementToIntDecimalPlaces(QuantityIncrement)
                  )} ${Product1Symbol}`
                : `${formatNumberToLocale(
                    formObj.values.quantity,
                    convertIncrementToIntDecimalPlaces(QuantityIncrement)
                  )} ${Product1Symbol}`
        },
    {
      label: context.t('Price'),
      text: `${formatNumberToLocale(
        price,
        convertIncrementToIntDecimalPlaces(PriceIncrement)
      )} ${Product2Symbol}`
    },
    {
      label: context.t('Fee'),
      text: `${formatNumberToLocale(
        formObj.values.fee.OrderFee,
        feeSymbol
      )} ${feeSymbol}`
    },
    formObj.values.value && formObj.values.side === '0'
      ? null
      : {
          label: context.t('Value'),
          text: `${formatNumberToLocale(
            orderTotal,
            convertIncrementToIntDecimalPlaces(PriceIncrement)
          )} ${Product2Symbol}`
        }
  ];

  if (isMarginActive) {
    items.splice(1, 0, {
      label: context.t('Leverage'),
      text: formObj.values.fee.Leverage
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      title={
        isMarginActive
          ? context.t('Confirm Margin Order')
          : context.t('Confirm Order')
      }
      close={close}
      footer={{
        buttonText: isBuySide
          ? context.t('Confirm Buy Order')
          : context.t('Confirm Sell Order'),
        buttonStyle: isBuySide ? 'additive' : 'subtractive',
        onClick: () => confirmReport(formObj.values)
      }}
      customClass={bemClasses(
        formObj.values.side === buyValue ? 'bought' : 'sold'
      )}
    >
      <div className={bemClasses()}>
        {items
          .filter(i => i)
          .map(item => {
            return (
              <APLabelWithText
                key={item.label}
                label={item.label}
                text={item.text}
                customClass={bemClasses()}
              />
            );
          })}
      </div>
    </Modal>
  );
};

ConfirmOrderModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ConfirmOrderModalComponent;
