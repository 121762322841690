import React from 'react';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import ArrowDownIcon from '../../../images/icons/icon-arrow-down.svg';
import './WalletRowNavItems.css';

const baseClasses = getBEMClasses('dcx-wallet-row-nav-items');

const WalletRowNavItemsComponent = ({
  isCrypto,
  isOpened,
  isWithdrawDisabled,
  product,
  history,
  isHideArrow
}) => {
  const onSendReceiveClick = isSend => {
    if (isSend && isWithdrawDisabled) {
      return;
    } else {
      history.push('/send-receive', { data: { product, isSend, isCrypto } });
    }
  };

  const onDepositWithdrawClick = isSend => {
    if (!isSend && isWithdrawDisabled) {
      return;
    }
    history.push('/send-receive', { data: { product, isSend, isCrypto } });
  };
  return (
    <div className={baseClasses()}>
      <div className={baseClasses('buttons')}>
        {isCrypto ? (
          <>
            <div
              className={baseClasses(
                'button',
                isWithdrawDisabled ? 'disabled' : ''
              )}
              onClick={e => {
                e.stopPropagation();
                onSendReceiveClick(true);
              }}
            >
              <p className={baseClasses('button-title')}>Send</p>
            </div>
            <div
              className={baseClasses('button')}
              onClick={e => {
                e.stopPropagation();
                onSendReceiveClick(false);
              }}
            >
              <p className={baseClasses('button-title')}>Receive</p>
            </div>
          </>
        ) : (
          <>
            <div
              className={baseClasses('button', 'deposit')}
              // onClick={e => {
              //   e.stopPropagation();
              //   onDepositWithdrawClick(true);
              // }}
              onClick={e => {
                e.stopPropagation();
                window.location.href = '/transfer';
              }}
            >
              <p className={baseClasses('button-title')}>Deposit</p>
            </div>
            <div
              className={baseClasses(
                'button',
                isWithdrawDisabled ? 'withdraw-disabled' : 'withdraw'
              )}
              onClick={e => {
                e.stopPropagation();
                onDepositWithdrawClick(false);
              }}
            >
              <p className={baseClasses('button-title')}>Withdraw</p>
            </div>
          </>
        )}
      </div>
      {!isHideArrow && (
        <img
          src={ArrowDownIcon}
          className={baseClasses(isOpened ? 'arrow-up' : 'arrow-down')}
        />
      )}
    </div>
  );
};

export { WalletRowNavItemsComponent };
