import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import SendReceiveConfirmHeaderComponent from './SendReceiveConfirmHeaderComponent';

import './SendReceiveConfirmModal.css';

const bemClasses = getBEMClasses('dcx-send-receive-confirm-modal');

const SendReceiveConfirmModal = (props, context) => {
  const {
    isOpen,
    close,
    product,
    isSend,
    values,
    onConfirm,
    useExternalAddress,
    fee,
    TicketAmount,
    hideFees
  } = props;

  const addressLabel = context.t('External Address');
  const sendLabel = context.t('Recipient’s Email');
  const receiveLabel = context.t('Requestee’s Email');

  const totalAmount = TicketAmount + fee;

  return (
    <Modal
      isOpen={isOpen}
      title={
        <SendReceiveConfirmHeaderComponent isSend={isSend} product={product} />
      }
      close={close}
      footer={{
        buttonText: context.t('Confirm'),
        buttonStyle: 'additive',
        onClick: onConfirm
      }}
      customClass={bemClasses()}
    >
      <header className={bemClasses('title')}>
        {context.t('Confirmation')}
      </header>
      <section className={bemClasses('details')}>
        {!useExternalAddress && (
          <APLabelWithText
            label={isSend ? sendLabel : receiveLabel}
            text={values.ReceiverUsername}
            customClass={bemClasses()}
          />
        )}

        <APLabelWithText
          label={`${product.Product || product.ProductSymbol} ${context.t(
            'Amount'
          )}`}
          text={`${formatNumberToLocale(
            TicketAmount,
            product.Product || product.ProductSymbol
          )} ${product.Product || product.ProductSymbol}`}
          customClass={bemClasses()}
        />

        {useExternalAddress && (
          <React.Fragment>
            {!hideFees && (
              <React.Fragment>
                <APLabelWithText
                  label={context.t('Miner Fees')}
                  text={`${formatNumberToLocale(
                    fee,
                    product.Product || product.ProductSymbol
                  )} ${product.Product || product.ProductSymbol}`}
                  customClass={bemClasses()}
                />
                <APLabelWithText
                  label={`${
                    product.Product || product.ProductSymbol
                  } ${context.t('Total Amount')}`}
                  text={`${formatNumberToLocale(
                    totalAmount,
                    product.Product || product.ProductSymbol
                  )} ${product.Product || product.ProductSymbol}`}
                  customClass={bemClasses()}
                />
              </React.Fragment>
            )}
            <APLabelWithText
              label={addressLabel}
              text={values.ExternalAddress}
              customClass={bemClasses()}
            />
          </React.Fragment>
        )}
        {!useExternalAddress && (
          <APLabelWithText
            label={context.t('Note')}
            text={values.Notes}
            customClass={bemClasses()}
          />
        )}

        <APLabelWithText
          label={context.t('Time')}
          text={formatDateTime(new Date())}
          customClass={bemClasses()}
        />
      </section>
    </Modal>
  );
};

SendReceiveConfirmModal.propTypes = {
  close: PropTypes.func.isRequired,
  isSend: PropTypes.bool.isRequired,
  fee: PropTypes.number.isRequired,
  TicketAmount: PropTypes.number,
  useExternalAddress: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired
};

SendReceiveConfirmModal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendReceiveConfirmModal;
