import React from 'react';
import PropTypes from 'prop-types';
import ContentWithHeaderLayoutTemplate from 'apex-web/lib/components/common/LayoutTemplates/ContentWithHeaderLayoutTemplate/ContentWithHeaderLayoutTemplate';
import ThemeSettingsContainer from './ThemeForm/ThemeSettingsContainer';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

const profileAndSecurityWidgetClasses = getBEMClasses([
  'profile-and-security-widget'
]);

const ThemeSettingsCardComponent = (props, context) => {
  const { themeOptions } = props;

  const getLayoutHeader = (header, classes) => {
    return (
      <div className={classes('header')}>
        <div className={classes('header-text')}>{header}</div>
      </div>
    );
  };

  return (
    <ContentWithHeaderLayoutTemplate
      header={getLayoutHeader(
        context.t('Theme'),
        profileAndSecurityWidgetClasses
      )}
      customClass="profile-and-security-widget"
    >
      <ThemeSettingsContainer themeOptions={themeOptions} />
    </ContentWithHeaderLayoutTemplate>
  );
};

ThemeSettingsCardComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ThemeSettingsCardComponent;
