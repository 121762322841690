import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { setActivePage } from 'apex-web/lib/redux/actions/paginationActions';
import {
  getTransfers,
  getIncomingTransfers
} from 'apex-web/lib/redux/actions/transferActions';
import {
  getTransferRequestsReceived,
  getTransferRequestsRequested
} from 'apex-web/lib/redux/actions/transferRequestActions';

import CustomTransfersFiltersComponent from '../CustomTransfersFiltersComponent';

const mapStateToProps = state => {
  const { OMSId, AccountId } = state.user.userInfo;

  return {
    OMSId,
    AccountId
  };
};

const mapDispatchToProps = dispatch => ({
  getTransfers: (OMSId, accountId) => {
    dispatch(getTransfers(accountId));
    dispatch(getIncomingTransfers(OMSId, accountId));
    dispatch(getTransferRequestsReceived(accountId, OMSId));
    dispatch(getTransferRequestsRequested(accountId, OMSId));
  },
  resetActivePage: () =>
    dispatch(
      setActivePage({ componentName: 'TransfersComponent', activePage: 0 })
    )
});

const TransfersFiltersForm = reduxForm({
  form: 'transfers-filters-form',
  initialValues: {
    transfersFilters: 'sentTransfers'
  }
})(CustomTransfersFiltersComponent);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransfersFiltersForm);
