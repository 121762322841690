import React, { useState, useEffect, useRef } from 'react';
import './CustomDropDown.css'; // Import the CSS file for component styles
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  Pagination
} from 'react-instantsearch-hooks-web';

const searchClient = algoliasearch(
  '912DK4PF2X',
  '428aeae5128abcb750132b0f2358e68c'
);

function isValidURL(url) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

function Hit({ hit }) {
  const newHostname = 'dcxmarkets.com';
  const currentUrl = isValidURL(hit.permalink) ? new URL(hit.permalink) : null;
  const pathname = currentUrl ? currentUrl.pathname : '';
  const search = currentUrl ? currentUrl.search : '';

  const newUrl =
    currentUrl && pathname
      ? `https://${newHostname}${pathname}${search}`
      : `https://${newHostname}`;

  return (
    <article className="hitArticle">
      <a href={newUrl} target="_blank">
        <h2>
          <Highlight attribute="post_title" hit={hit} />
        </h2>
        {/* <p dangerouslySetInnerHTML={{ __html: hit.post_excerpt }} /> */}
      </a>
    </article>
  );
}

export const CustomDropDown = () => {
  const handleButtonClick = () => {
    // Check if the zE function (provided by Zoho SalesIQ) is available
    if (typeof window.zE !== 'undefined') {
      // Show the Zoho SalesIQ widget using the zE.show() method
      window.zE(function() {
        window.zE.show();
      });
    } else {
      console.error('Zoho SalesIQ library failed to load.');
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [searchQuery, setSearchQuery] = useState(''); // Add this state

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="wp_posts_currency-guide">
      <div
        ref={dropdownRef}
        className={`custom-dropdown ${isOpen ? 'open' : ''}`}>
        <svg
          onClick={handleToggleDropdown}
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M31.0417 12.9167H8.95834C8.78473 12.9167 8.62848 12.8646 8.48959 12.7604C8.38543 12.6215 8.33334 12.4653 8.33334 12.2917V10.625C8.33334 10.4514 8.38543 10.3125 8.48959 10.2083C8.62848 10.0694 8.78473 10 8.95834 10H31.0417C31.2153 10 31.3542 10.0694 31.4583 10.2083C31.5972 10.3125 31.6667 10.4514 31.6667 10.625V12.2917C31.6667 12.4653 31.5972 12.6215 31.4583 12.7604C31.3542 12.8646 31.2153 12.9167 31.0417 12.9167ZM31.0417 21.25H8.95834C8.78473 21.25 8.62848 21.1979 8.48959 21.0938C8.38543 20.9549 8.33334 20.7986 8.33334 20.625V18.9583C8.33334 18.7847 8.38543 18.6458 8.48959 18.5417C8.62848 18.4028 8.78473 18.3333 8.95834 18.3333H31.0417C31.2153 18.3333 31.3542 18.4028 31.4583 18.5417C31.5972 18.6458 31.6667 18.7847 31.6667 18.9583V20.625C31.6667 20.7986 31.5972 20.9549 31.4583 21.0938C31.3542 21.1979 31.2153 21.25 31.0417 21.25ZM31.0417 29.5833H8.95834C8.78473 29.5833 8.62848 29.5312 8.48959 29.4271C8.38543 29.2882 8.33334 29.1319 8.33334 28.9583V27.2917C8.33334 27.1181 8.38543 26.9792 8.48959 26.875C8.62848 26.7361 8.78473 26.6667 8.95834 26.6667H31.0417C31.2153 26.6667 31.3542 26.7361 31.4583 26.875C31.5972 26.9792 31.6667 27.1181 31.6667 27.2917V28.9583C31.6667 29.1319 31.5972 29.2882 31.4583 29.4271C31.3542 29.5312 31.2153 29.5833 31.0417 29.5833Z"
            fill="#282828"
          />{' '}
        </svg>
        <div className="dropdown-menu">
          <a onClick={() => handleButtonClick}>Live chat</a>

          {/* <a href="https://chat.zendesk.com/"></a> */}
          <a href="https://dcxmarkets.com/help/crypto-coach" target="_blank">
            Crypto coach
          </a>
          <a href="#" onClick={handleOpenModal}>
            Search
          </a>
        </div>
        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={handleCloseModal}>
                &times;
              </span>
              {/* <input
                type="search"
                placeholder="Search"
                className="modal-search"
              /> */}

              <SearchBox className="modal-search" onChange={setSearchQuery} />

              {/* Only show results if searchQuery is not empty */}
              <>
                <Hits hitComponent={Hit} />
                {/* <Pagination /> */}
              </>
            </div>
          </div>
        )}
      </div>
    </InstantSearch>
  );
};
