import { connect } from 'react-redux';
import get from 'lodash/get';
import WithdrawStatusComponent from './WithdrawStatusComponent';
import { filteredWithdrawalsByProductSelector } from '../../../redux/selectors/depositWithdrawalSelectors';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { decimalsByProductId } from 'apex-web/lib/redux/selectors/productsSelectors';
import {
  cancelWithdrawTicket,
  getWithdrawTickets
} from 'apex-web/lib/redux/actions/withdrawActions';

const mapStateToProps = (state, ownProps) => {
  const withdrawTickets = filteredWithdrawalsByProductSelector(state, {
    selectedCryptoProductId: ownProps?.selectedCryptoProductId,
    selectedFiatProductId: ownProps?.selectedFiatProductId
  });
  const instruments = instrumentSelectorInstruments(state);
  const decimalPlaces = decimalsByProductId(state);
  const { activeRequests } = state.apexCore.orderHistory;
  const fetching = activeRequests > 0;
  const withdrawTicketsStartIndex = state.withdraw.withdrawTicketsStartIndex;

  return {
    data: withdrawTickets,
    instruments,
    fetching,
    decimalPlaces,
    selectedAccountId: get(state, 'user.selectedAccountId', 0),
    OMSId: get(state, 'user.userInfo.OMSId', 1),
    withdrawTicketsStartIndex
  };
};

const mapDispatchToProps = {
  cancelWithdrawTicket,
  getWithdrawTickets
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithdrawStatusComponent);
