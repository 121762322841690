import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import APTable from 'apex-web/lib/components/common/APTable';
import CancelAPButton from 'apex-web/lib/components/common/CancelAPButton';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { getOrderPriceToDisplay } from 'apex-web/lib/helpers/orderHistoryHelper';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import MobileOrderHistoryList from '../MobileOrderHistoryList/MobileOrderHistoryList';

const baseClass = getBEMClasses('order-history-table');

const OpenOrdersComponent = (props, context) => {
  const {
    data,
    instruments,
    cancelOrders,
    getOpenOrders,
    selectedAccountId,
    OMSId,
    config,
    fetching,
    openOrdersStartIndex,
    isMobile
  } = props;
  const { maxLines, usePagination } = config;

  useEffect(() => {
    if (selectedAccountId) {
      getOpenOrders(selectedAccountId, OMSId, maxLines, 0);
    }
  }, [selectedAccountId]);

  const columns = useMemo(
    () => [
      {
        header: context.t('Pair'),
        dataTest: 'Pair',
        cell: (row, { selectedInstrument }) =>
          selectedInstrument ? selectedInstrument.Symbol : ''
      },
      {
        header: context.t('Side'),
        dataTest: 'Side',
        cell: row => (
          <div className={baseClass(row.Side === 'Buy' ? 'buy' : 'sell')}>
            {context.t(row.Side)}
          </div>
        )
      },
      {
        header: context.t('Type'),
        dataTest: 'Type',
        cell: row =>
          row.IsQuote ? context.t('Quote') : context.t(row.OrderType)
      },
      {
        header: context.t('Size'),
        dataTest: 'Size',
        cell: (row, { selectedInstrument }) => (
          <div className={baseClass('size')}>
            {row.quantity &&
              formatNumberToLocale(
                row.quantity,
                convertIncrementToIntDecimalPlaces(
                  selectedInstrument.QuantityIncrement
                )
              )}
          </div>
        )
      },
      {
        header: context.t('Price'),
        dataTest: 'Price',
        cell: (row, { selectedInstrument }) => (
          <div className={baseClass('price')}>
            {getOrderPriceToDisplay(
              row,
              convertIncrementToIntDecimalPlaces(
                selectedInstrument.PriceIncrement
              )
            )}
          </div>
        )
      },
      {
        header: context.t('Date/Time'),
        dataTest: 'Date/Time',
        cell: row => formatDateTime(row.ReceiveTime)
      },
      {
        header: context.t('Status'),
        dataTest: 'Status',
        cell: row => context.t(row.OrderState)
      },
      {
        header: context.t('Action'),
        dataTest: 'Action',
        cell: row => (
          <CancelAPButton
            text={context.t('Cancel')}
            customClass={baseClass()}
            dataTest="Cancel"
            onClick={() => {
              cancelOrders([row.OrderId]);
            }}
          />
        )
      }
    ],
    [instruments?.length, cancelOrders]
  );

  const mobileRows = useMemo(
    () => [
      {
        cell: (row, { selectedInstrument }) =>
          selectedInstrument ? selectedInstrument.Symbol : ''
      },
      {
        cell: (row, { selectedInstrument }) => {
          return getOrderPriceToDisplay(
            row,
            convertIncrementToIntDecimalPlaces(
              selectedInstrument.PriceIncrement
            )
          );
        }
      }
    ],
    [instruments?.length]
  );

  const rowProps = row => ({
    'data-tip': true,
    'data-for': `orderInfo-${row.OrderId}`,
    selectedInstrument:
      instruments.find(item => item.InstrumentId === row.Instrument) || {}
  });

  return isMobile ? (
    <MobileOrderHistoryList
      rows={data}
      rowProps={rowProps}
      columns={columns}
      rowsContent={mobileRows}
      fetching={fetching}
      pageSize={maxLines}
      empty={context.t('No data is available')}
      onRequestPrev={() =>
        getOpenOrders(
          selectedAccountId,
          OMSId,
          maxLines,
          openOrdersStartIndex - maxLines
        )
      }
      onRequestNext={() =>
        getOpenOrders(
          selectedAccountId,
          OMSId,
          maxLines,
          openOrdersStartIndex + maxLines
        )
      }
      componentName={'OpenOrdersComponent'}
    />
  ) : (
    <APTable
      {...{
        columns,
        rows: data,
        totalPages: data.length,
        baseClass,
        fetching,
        rowProps,
        pageSize: maxLines,
        usePagination,
        alwaysShowPagination: true,
        useSimplePagination: true,
        empty: context.t('No data is available'),
        componentName: 'OpenOrdersComponent',
        onRequestPrev: () => {
          getOpenOrders(
            selectedAccountId,
            OMSId,
            maxLines,
            openOrdersStartIndex - maxLines
          );
        },
        onRequestNext: () => {
          getOpenOrders(
            selectedAccountId,
            OMSId,
            maxLines,
            openOrdersStartIndex + maxLines
          );
        }
      }}
    />
  );
};

/*
 this.context.t('Open Orders');
 */
OpenOrdersComponent.title = 'Open Orders';

OpenOrdersComponent.propTypes = {
  data: PropTypes.array.isRequired,
  instruments: PropTypes.array.isRequired,
  cancelOrders: PropTypes.func.isRequired
};

OpenOrdersComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default OpenOrdersComponent;
