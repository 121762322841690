import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import { product } from 'apex-web/lib/propTypes/sendReceiveComponent';
import ReceiveFormContainer from 'apex-web/lib/components/SendReceiveSidePane/ReceiveForm/ReceiveFormContainer';
import ReceiveAddressContainer from 'apex-web/lib/components/SendReceiveSidePane/ReceiveAddress/ReceiveAddressContainer';
import 'apex-web/lib/components/SendReceiveSidePane/Receive/Receive.css';

const bemClasses = getBEMClasses('receive');

class ReceiveComponent extends React.Component {
  render() {
    const {
      product,
      useExternalAddress,
      toggleUseExternalAddress,
      onChange,
      onSubmit,
      disableDeposit,
      verificationOnClick,
      allowTransfer
    } = this.props;
    return (
      <section className={bemClasses()}>
        <header className={bemClasses('source')}>
          {/* {allowTransfer && (
            <div className={bemClasses('source-item-with-border')}>
              <div
                className={bemClasses('source-item', {
                  active: !useExternalAddress
                })}
                onClick={() => {
                  useExternalAddress && toggleUseExternalAddress();
                }}>
                {this.context.t('Friend')}
              </div>
            </div>
          )} */}
          <div
            className={bemClasses('source-item', {
              active: useExternalAddress
            })}
            onClick={() => {
              !useExternalAddress && toggleUseExternalAddress();
            }}>
            {this.context.t('External Wallet')}
          </div>
        </header>
        <section className={bemClasses('body')}>
          {disableDeposit ? (
            <VerificationRequiredContainer
              disabled={disableDeposit}
              onClick={verificationOnClick}
            />
          ) : useExternalAddress ? (
            <ReceiveAddressContainer product={product} />
          ) : (
            <ReceiveFormContainer
              product={product}
              onChange={onChange}
              onSubmit={onSubmit}
            />
          )}
        </section>
      </section>
    );
  }
}

ReceiveComponent.propTypes = {
  product,
  useExternalAddress: PropTypes.bool.isRequired,
  toggleUseExternalAddress: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  allowTransfer: PropTypes.bool.isRequired,
  VerificationRequiredComponent: PropTypes.element,
  disableDeposit: PropTypes.bool,
  verificationOnClick: PropTypes.func
};

ReceiveComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReceiveComponent;
