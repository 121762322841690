import { connect } from 'react-redux';
import get from 'lodash/get';
import InstrumentTableComponent from './InstrumentTableComponent';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { selectInstrument } from 'apex-web/lib/redux/actions/instrumentActions';
import { openModal } from 'apex-web/lib/redux/actions/modalActions';

const mapStateToProps = (state, ownProps) => {
  const isFromAssetsPage = !!ownProps?.isFromAssetsPage;
  const selectedInstrumentFromState = selectedInstrumentSelector(state);
  const selectedInstrumentIdFromState = get(
    selectedInstrumentFromState,
    'InstrumentId',
    ''
  );
  const selectedInstrument = ownProps?.selectedInstrument
    ? ownProps?.selectedInstrument
    : selectedInstrumentFromState;

  const { level1 } = state.apexCore ? state.apexCore : {};
  const selectedInstrumentId = get(selectedInstrument, 'InstrumentId', '');

  return {
    level1: level1[selectedInstrumentId],
    selectedInstrument,
    isSelected:
      isFromAssetsPage &&
      selectedInstrumentId === selectedInstrumentIdFromState,
    isFromAssetsPage
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectInstrument: instrument => dispatch(selectInstrument(instrument)),
  openBuySellModal: side => {
    dispatch(
      openModal('BUY_SELL_MODAL', {
        selectedInstrument: ownProps?.selectedInstrument,
        side
      })
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstrumentTableComponent);
