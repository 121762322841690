import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import './DepositTransfer.css';
import CashIcon from '../../../images/icons/icon-cash.svg';
import BanxaIcon from '../../../images/icons/icon-banxa.svg';
import PayIdIcon from '../../../images/icons/icon-pay-id.svg';
import Dropdown from '../../common/Dropdown/Dropdown';
import DirectDepositForm from './DirectDepositForm/DirectDepositForm';
import PayIdForm from './PayIdForm/PayIdForm';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';

import { getMonoovaAcctDetails } from '../../../dcxApi';
import Banxa from './BanxaForm/Banxa';

const baseClasses = getBEMClasses('deposit-transfer');

const DEPOSIT_TYPES = [
  { title: 'Direct deposit', value: 'directDeposit', icon: CashIcon },
  { title: 'PayID', value: 'payId', icon: PayIdIcon },
  { title: 'Banxa', value: 'banxa', icon: BanxaIcon }
];

const DEPOSIT_INFO = {
  payId: {
    time: 'Instant',
    fee: 'Free',
    logo: PayIdIcon,
    dailyLimit: 'Refer to your bank, daily limits may vary.'
  },
  banxa: {
    time: 'Instant',
    fee: '2% fee',
    logo: BanxaIcon,
    dailyLimit: 'Refer to your bank, daily limits may vary.'
  },
  directDeposit: {
    time: '1-2 business days',
    fee: 'Free',
    logo: CashIcon,
    dailyLimit: 'Refer to your bank, daily limits may vary.'
  }
};

const DepositTransfer = ({ user }) => {
  const [selectedDeposit, setSelectedDeposit] = useState(
    DEPOSIT_TYPES[0].value
  ); // Default selected deposit type
  const selectDepositLabel = DEPOSIT_TYPES.find(
    item => item.value === selectedDeposit
  ).title;
  const [copyMessage, setCopyMessage] = useState('');
  const [isDepositDataLoading, setIsDepositDataLoading] = useState(false);

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text);
    setCopyMessage('Copied to clipboard');

    setTimeout(() => {
      setCopyMessage('');
    }, 4000);
  };

  const handleSelectDepositType = depositType => {
    setSelectedDeposit(depositType);
    setCopyMessage('');
  };

  const [depositData, setDepositData] = useState(null);

  useEffect(() => {
    if (user.selectedAccountId) {
      getMonoovaAcctDetails(user.selectedAccountId)
        .then(data => {
          if (data) {
            setDepositData(data.data);
          } else {
            console.error('data is undefined or null');
          }
        })
        .catch(error => {
          console.error('Error fetching account details:', error);
        });
    }
  }, [user.selectedAccountId]);

  return (
    <div className={baseClasses('')}>
      {/* <div>
        <Input type="text" label="Deposit amount" />
      </div> */}
      <div>
        <Dropdown
          label={'Method'}
          options={DEPOSIT_TYPES}
          selectedOption={selectedDeposit}
          setSelectedOption={handleSelectDepositType}
          customClass={baseClasses('dropdown')}
        />
      </div>
      {(selectedDeposit === 'directDeposit' || selectedDeposit === 'payId') &&
        isDepositDataLoading && (
          <Spinner customClass={baseClasses('spinner')} />
        )}
      {selectedDeposit === 'directDeposit' && (
        <>
          <DirectDepositForm
            depositInfo={DEPOSIT_INFO[selectedDeposit]}
            depositData={depositData}
            copyToClipboard={copyToClipboard}
          />
        </>
      )}

      {selectedDeposit === 'payId' && depositData && (
        <PayIdForm
          depositInfo={DEPOSIT_INFO[selectedDeposit]}
          depositData={depositData}
          copyToClipboard={copyToClipboard}
        />
      )}

      {/* {selectedDeposit === 'banxa' && <BanxaForm user={user} />} */}
      {selectedDeposit === 'banxa' && <Banxa user={user} />}

      {/* <div className={baseClasses('note')}>
        Please note, your daily deposit limit is: $10,000.
      </div> */}
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(DepositTransfer);
