import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'redux-form';
import { Link } from 'react-router-dom';
import path from 'apex-web/lib/helpers/path';
import APInput from 'apex-web/lib/components/common/APInput';
import APIcon from 'apex-web/lib/components/common/APIcon';
import APButton from 'apex-web/lib/components/common/APButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import APRadio from 'apex-web/lib/components/common/APRadio/APRadio';
import { required, email } from 'apex-web/lib/helpers/formValidations';
import config from 'apex-web/lib/config';
import { styleNames } from 'apex-web/lib/propTypes/commonComponent';

import 'apex-web/lib/styles/components/common/StandaloneForm.css';
// import "./ProblemLoggingInFormComponent.css";
import '../CustomLoginFormComponent/customForm.css';

const baseClasses = getBEMClasses('standalone-form');
const forgotPasswordFormClasses = getBEMClasses('forgot-password-form');

export class CustomForgotPasswordFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { resetPassword: true };
  }

  toggleFormType = () =>
    this.setState({ resetPassword: !this.state.resetPassword });

  sendEmail = values => {
    const { sendResetPasswordEmail, resendVerificationEmail } = this.props;

    if (this.state.resetPassword) {
      return sendResetPasswordEmail(values.Email);
    }

    return resendVerificationEmail(values);
  };

  render() {
    const { context } = this;
    const { handleSubmit, submitting, pristine, invalid } = this.props;
    const { useEmailAsUsername } = config.SignupForm;
    const { showOptions } = config.ProblemLoggingIn;

    let validate = [required];
    let label = context.t('Username');

    if (useEmailAsUsername || !this.state.resetPassword) {
      validate.push(email);
      label = context.t('Email');
    }

    return (
      <div className="dcxCustomForm">
        <div className="header">
          <svg
            width="60"
            height="23"
            viewBox="0 0 60 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4924_119280)">
              <path
                d="M59.9999 22.3771C57.7199 20.0881 55.9559 15.0361 51.7949 14.1211L50.4209 16.2181C53.7719 20.4451 57.7949 22.9051 60.0029 22.3771H59.9999Z"
                fill="#FF7300"
              />
              <path
                d="M40.011 5.86419C42.291 8.15319 43.758 12.5422 47.919 13.8502C48.813 14.1322 51.099 14.1202 51.099 14.1202C47.541 8.63319 42.561 5.25219 40.014 5.86419H40.011Z"
                fill="#FF7300"
              />
              <path
                d="M40.011 22.3772C42.291 20.0882 43.758 15.6992 47.919 14.3912C48.813 14.1092 51.099 14.1212 51.099 14.1212C47.541 19.6082 42.561 22.9892 40.014 22.3772H40.011Z"
                fill="#FF7300"
              />
              <path
                d="M59.9999 5.86381C57.7199 8.15281 55.9559 13.2048 51.7949 14.1198L50.4209 12.0228C53.7719 7.79581 57.7949 5.33581 60.0029 5.86381H59.9999Z"
                fill="#FF7300"
              />
              <path
                d="M29.1151 5.86328C28.1731 5.86328 27.2161 6.02528 26.2801 6.36428C23.1481 7.49828 20.9671 10.5253 20.8621 13.8553C20.7151 18.5293 24.4741 22.3783 29.1151 22.3783C29.4841 22.3783 29.8561 22.3543 30.2341 22.3033C32.2831 22.0333 34.1341 20.9293 35.4931 19.3693L36.4861 18.2263L35.1751 17.0863C34.8451 16.7983 34.4401 16.6573 34.0351 16.6573C33.5491 16.6573 33.0661 16.8583 32.7241 17.2543C31.8151 18.2983 30.4981 18.8983 29.1151 18.8983C28.4821 18.8983 27.8311 18.7723 27.2011 18.5053C25.7941 17.9113 24.7441 16.6183 24.4381 15.1213C23.8111 12.0493 26.1541 9.34028 29.1151 9.34028C29.4331 9.34028 29.7571 9.37028 30.0871 9.43628C31.1281 9.64328 32.0551 10.2343 32.7601 11.0263C33.0781 11.3833 33.5221 11.5633 33.9661 11.5633C34.3501 11.5633 34.7341 11.4283 35.0431 11.1523L36.4291 9.91328L35.2681 8.61728C33.7021 6.86828 31.4581 5.86628 29.1151 5.86628"
                fill="#FF7300"
              />
              <path
                d="M14.9131 0C12.9931 0 11.4361 1.557 11.4361 3.477V6.504C10.4371 6.087 9.35712 5.865 8.25612 5.865C6.99612 5.865 5.70612 6.153 4.47612 6.771C2.22912 7.902 0.60612 10.074 0.14712 12.549C-0.82488 17.79 3.19212 22.38 8.25912 22.38C10.6561 22.38 12.9361 21.336 14.5051 19.521C14.7841 19.197 14.9131 18.774 14.9131 18.348V0ZM8.25612 18.903C5.61912 18.903 3.47412 16.758 3.47412 14.121C3.47412 11.484 5.61912 9.342 8.25612 9.342C9.43212 9.342 10.5661 9.777 11.4361 10.554V17.691C10.5661 18.468 9.43512 18.903 8.25612 18.903Z"
                fill="#FF7300"
              />
            </g>
            <defs>
              <clipPath id="clip0_4924_119280">
                <rect width="60" height="22.449" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className="custom-form--header">
            {context.t('Problems Logging In?')}
          </div>
          <Link to={path('/')} className={baseClasses('close-icon')}>
            <APIcon name="close" classModifiers="big" />
          </Link>

          <Link to={path('/')} className={baseClasses('close-icon')}>
            <APIcon name="close" classModifiers="big" />
          </Link>
        </div>
        <Form onSubmit={handleSubmit(this.sendEmail)}>
          {showOptions ? (
            <React.Fragment>
              <div
                className={`${baseClasses(
                  'information-text'
                )} ${forgotPasswordFormClasses('information-text')}`}>
                {context.t(
                  'Select your issue type and provide us with your {label} below. We will follow up with a solution shortly.',
                  { label: label.toLowerCase() }
                )}
              </div>
              <section className={baseClasses('radio-list')}>
                <APRadio
                  name="type"
                  checked={this.state.resetPassword}
                  onChange={this.toggleFormType}
                  customClass={forgotPasswordFormClasses()}
                  label={context.t(
                    'I’m an existing user and I forgot my password.'
                  )}
                  id="problem-login-radio1"
                />
                <APRadio
                  name="type"
                  checked={!this.state.resetPassword}
                  onChange={this.toggleFormType}
                  customClass={forgotPasswordFormClasses()}
                  label={context.t(
                    'I’m a new user and I haven’t received my verification email.'
                  )}
                  id="problem-login-radio2"
                />
              </section>
            </React.Fragment>
          ) : (
            <div
              className={`${baseClasses(
                'information-text'
              )} ${forgotPasswordFormClasses('information-text')}`}>
              {context.t(
                'Provide us with your {label} below. We will follow up with reset instructions.',
                { label: label.toLowerCase() }
              )}
            </div>
          )}
          <APInput
            type="text"
            name="Email"
            customClass="custom-login--input"
            label={label}
            validate={validate}
          />
          <APButton
            type="submit"
            disabled={submitting || pristine || invalid}
            customClass="custom-login--input"
            styleName={styleNames.additive}>
            {submitting ? context.t('Processing...') : context.t('Send Email')}
          </APButton>
        </Form>

        <div className="option-links-section">
          <Link
            to={path('/login')}
            className={forgotPasswordFormClasses('link')}>
            {context.t('Log In')}
          </Link>
          {config.SignupForm.active && (
            <React.Fragment>
              <Link
                to={path('/signup')}
                className={forgotPasswordFormClasses('link')}>
                {context.t('Sign Up')}
              </Link>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
CustomForgotPasswordFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

CustomForgotPasswordFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CustomForgotPasswordFormComponent;
