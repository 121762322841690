import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import PageFooterLayout from '../layouts/PageFooterLayout/PageFooterLayout';
import MarginBalancesLayout from 'apex-web/lib/layouts/MarginBalancesLayout/MarginBalancesLayoutContainer';
import DigitalAssetsPage from 'apex-web/lib/components/DigitalAssets/DigitalAssetsPageContainer';

const layoutClasses = getBEMClasses('custom-page');

const DigitalAssetsCustom = (props, context) => {
  return (
    <React.Fragment>
      <div className={layoutClasses('accent-bar')}> </div>
      <div className={layoutClasses('container')} />
      <div className={layoutClasses('footer')}>
        <DigitalAssetsPage />
      </div>
      <PageFooterLayout />
    </React.Fragment>
  );
};

DigitalAssetsCustom.contextTypes = {
  t: PropTypes.func.isRequired
};

export default withAuthentication(DigitalAssetsCustom);
