import React, { useMemo, useEffect, useState } from 'react';
import * as level1DataProp from 'apex-web/lib/propTypes/level1Data';

import { productPositionAssetsSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { calculateRolling24HrPxChangeValue } from 'apex-web/lib/helpers/level1Helper';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import {
  formatPercentageValue,
  formatNumberToLocale
} from 'apex-web/lib/helpers/numberFormatter';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import TradingTabs from '../../dcxComponents/TradingTabs';
import './MarketsPage.css';
import MarketDataTable from '../../dcxComponents/MarketDataTable/MarketDataTable';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import { getDcxAssets } from '../../dcxApi';
import { useDcxData } from '../../dcxContext';

const baseClasses = getBEMClasses('markets-page');

const MarketsPage = props => {
  // const { level1, instruments, products } = props;

  // const rolling24HrPxChangeValue = calculateRolling24HrPxChangeValue(
  //   LastTradedPx,
  //   Rolling24HrPxChange
  // );

  const { assets } = useDcxData();

  console.log('assets: ', assets);

  const [data, setData] = useState([]);

  useEffect(() => {
    getDcxAssets().then(fetchedData => {
      const { data } = fetchedData;
      setData(data);
      console.log(data); // Logs fetched data
    });
  }, []);

  useEffect(() => {
    console.log('mdc, ', data); // Logs updated data after state change
  }, [data]);

  const marketDataCards = assets?.map(instrument => {
    const { instrumentId } = instrument;
    // const level1Data = level1[instrumentId];

    // const product1 = products.find(x => x.ProductId === Product1);
    // const product2 = products.find(x => x.ProductId === Product2);
    const price = instrument.price;
    const change = instrument?.change24hr;

    return (
      <div key={instrumentId}>
        <MarketDataTable
          instrument={instrument}
          product1={instrument?.productFullName1}
          product2={instrument?.productFullName2}
          price={price}
          change24hr={change}
          volume={instrument?.volume24hr}
          sessionHigh={instrument?.sessionHigh}
          sessionLow={instrument?.sessionLow}
          bid={instrument?.bestBid}
          ask={instrument?.bestOffer}
        />
      </div>
    );
  });

  return (
    <>
      <div className={baseClasses()}>
        <TradingTabs />
        <div className={baseClasses('container')}>
          <h1>Market Data</h1>
          <div className={baseClasses('cards-container')}>
            {marketDataCards}
          </div>
        </div>
      </div>

      <div className={baseClasses('footer')}>
        <PageFooterLayout />
      </div>
    </>
  );
};

// const mapStateToProps = state => {
//   const { level1 } = state.apexCore;
//   const instruments = instrumentSelectorInstruments(state);

//   const products = productPositionAssetsSelector(state);

//   return { level1, instruments, products };
// };

export default MarketsPage;
