import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import {
  getFinalBalanceWithdrawValue,
  getAvailableBalance
} from 'apex-web/lib/helpers/withdrawHelper';
import {
  FiatWithdrawConfirmationModal,
  FiatWithdrawFormContainer,
  FiatWithdrawDetailsComponent
} from './FiatWithdraw';
import {
  FiatDepositFormContainer,
  FiatDepositModalContainer,
  FiatDepositDetailsComponent
} from './FiatDeposit';
import DepositWithdrawHeaderComponent from './DepositWithdrawHeader/DepositWithdrawHeaderComponent';
import DepositWithdrawTabsComponent from './DepositWithdrawTabs/DepositWithdrawTabsComponent';
import './DepositWithdrawComponent.css';

const baseClasses = getBEMClasses('dcx-deposit-withdraw');

const DepositWithdrawComponent = (props, context) => {
  const {
    position,
    fee,
    AccountProviderId,
    product,
    TicketAmount,
    showDetails,
    productConfig,
    withdrawDetailsInfo,
    isDepositDisabled,
    isWithdrawDisabled,
    isHideDepositWithdrawTabs
  } = props;
  const [isSend, setIsSend] = useState(props.isSend);
  const [data, setData] = useState({});
  const [totalValue, setTotalValue] = useState(0);

  const onTabChange = () => {
    setIsSend(!isSend);
    setData({});
  };

  const onUpdate = updatedData => {
    getWithdrawFee(updatedData);
    setTotal(updatedData);
  };

  const setTotal = updatedData => {
    const amount = parseFloat(updatedData?.Amount || data?.Amount);
    const balance = getAvailableBalance(position.Amount, position.Hold);
    let total = 0;

    if (amount) {
      total = getFinalBalanceWithdrawValue(balance, amount, fee);
    }

    setTotalValue(total);
  };

  const getWithdrawFee = updatedData => {
    if (!isNaN(updatedData?.Amount)) {
      props.getWithdrawFee(
        product?.ProductId,
        updatedData?.Amount,
        AccountProviderId
      );
    }
  };

  const onDataUpdate = updatedData => {
    setData(data);
    if (!isSend && updatedData.Amount !== data?.Amount) {
      onUpdate(updatedData);
    }
  };

  const renderInstructions = modificator => {
    return (
      <section className={baseClasses('side-details', modificator)}>
        <header className={baseClasses('instructions-header', modificator)}>
          {context.t('Instructions')}
        </header>
        <section className={baseClasses('address-instructions', modificator)}>
          <div>{context.t(`Step 1: Create the deposit ticket.`)}</div>
          <div>
            {context.t(
              `Step 2: Process deposit instructions on the deposit receipt.`
            )}
          </div>
        </section>
      </section>
    );
  };

  const balance = getAvailableBalance(position.Amount, position.Hold);

  return (
    <div className={baseClasses()}>
      <section className={baseClasses('main')}>
        <DepositWithdrawHeaderComponent product={product} />
        <section className={baseClasses('main-form')}>
          {/* {!isHideDepositWithdrawTabs && (
            <DepositWithdrawTabsComponent
              isSend={isSend}
              toggleTab={onTabChange}
              product={product}
              balance={balance}
              isDepositDisabled={isDepositDisabled}
              isWithdrawDisabled={isWithdrawDisabled}
            />
          )} */}

          {isSend ? (
            <FiatDepositFormContainer
              product={product}
              onChange={onDataUpdate}
            />
          ) : (
            <FiatWithdrawFormContainer
              product={product}
              balance={balance}
              fee={fee}
              total={totalValue}
              onChange={onDataUpdate}
              showDetails={showDetails}
            />
          )}
        </section>
        {isSend && !showDetails ? renderInstructions('no-details') : null}
      </section>

      {showDetails && (
        <section className={baseClasses('side')}>
          {isSend ? (
            <FiatDepositDetailsComponent info={productConfig.instructions} />
          ) : (
            <FiatWithdrawDetailsComponent
              TicketAmount={TicketAmount}
              data={data}
              fee={fee}
              product={product}
              balance={balance}
              detailsInfo={withdrawDetailsInfo}
            />
          )}
        </section>
      )}

      <FiatDepositModalContainer />
      <FiatWithdrawConfirmationModal TicketAmount={TicketAmount} />
    </div>
  );
};

DepositWithdrawComponent.defaultProps = {
  showDetails: true,
  onSidePaneOpen: () => {},
  isSend: true
};

DepositWithdrawComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DepositWithdrawComponent;
