import React, { useState } from 'react';
import ArrowDownIcon from '../../../../../images/icons/icon-arrow-down.svg';
import './Row.css';
import { getBEMClasses } from '../../../../../helpers/cssClassesHelper';

const baseClass = getBEMClasses('mobile-order-history-row');

const Row = props => {
  const { row, rowsContent, columns, rowProps = () => {} } = props;
  const [isExpand, setIsExpand] = useState(false);

  return (
    <div>
      <div className={baseClass()} onClick={() => setIsExpand(!isExpand)}>
        <div className={baseClass('header')}>
          {rowsContent.map((item, index) => {
            return (
              <p
                key={`mobile-order-history-row-header_${index}`}
                className={baseClass('value')}
              >
                {item.cell(row, rowProps(row))}
              </p>
            );
          })}
        </div>
        <img
          src={ArrowDownIcon}
          className={baseClass('icon', isExpand && 'expand')}
        />
      </div>
      {isExpand && (
        <div className={baseClass('expand')}>
          {columns.map((item, index) => (
            <div
              key={`mobile-order-history-row-content_${index}`}
              className={baseClass('expand-row')}
            >
              <div className={baseClass('expand-row-title')}>{item.header}</div>
              <div className={baseClass('expand-row-value')}>
                {item.cell(row, rowProps(row))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Row;
