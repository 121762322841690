import React from 'react';
import PropTypes from 'prop-types';
import APButton from 'apex-web/lib/components/common/APButton';

export const HyphenWithdrawWorkflowComponent = (
  { productId, templateType, template = {}, getBankInfoForm },
  context
) =>
  !Object.keys(template).includes('BankAccountNumber') ? (
    <APButton
      onClick={() => getBankInfoForm(productId, templateType)}
      styleName="additive"
    >
      {context.t('Edit banking information')}
    </APButton>
  ) : null;

HyphenWithdrawWorkflowComponent.propTypes = {
  productId: PropTypes.number.isRequired,
  templateType: PropTypes.string.isRequired,
  template: PropTypes.object.isRequired,
  getBankInfoForm: PropTypes.func.isRequired
};

HyphenWithdrawWorkflowComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default HyphenWithdrawWorkflowComponent;
