import { connect } from 'react-redux';
import WithdrawWorkflowsComponent from './WithdrawWorkflowsComponent';
import config from 'apex-web/lib/config';

const mapStateToProps = state => ({
  templateType: config.Withdraw.Template || state.withdraw.templateType,
  loading: state.withdraw.isLoading
});

export default connect(mapStateToProps)(WithdrawWorkflowsComponent);
