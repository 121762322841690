import axios from 'axios';
import React, { useEffect, useState } from 'react';

const LineChart = ({ coinId }) => {
  const [chartData, setChartData] = useState([]);
  const [lineColor, setLineColor] = useState('#C1E5E1'); // Initial color set to green

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://pro-api.coingecko.com/api/v3/coins/${coinId}/market_chart?x_cg_pro_api_key=CG-mowbUNKjJk1Htv8p6SNke9x8&vs_currency=aud&days=1`
          );

          if (response.data && response.data.prices) {
            const chartData = response.data.prices.map(data => data[1]);
            setChartData(chartData);

            const firstPrice = chartData[0];
            const lastPrice = chartData[chartData.length - 1];
            if (firstPrice > lastPrice) {
              setLineColor('#D02F44'); // Change color to red if the price goes down
            } else if (firstPrice < lastPrice) {
              setLineColor('#3EC393');
              // setLineColor("#00AAD2") // Change color to green if the price goes up
            } else {
              setLineColor('#3EC393'); // Change color to orange if the price remains the same
            }
          }
        } catch (error) {
          console.error('Error fetching chart data:', error);
        }
      };

      fetchData();
    },
    [coinId]
  );

  const svgWidth = 350;
  const svgHeight = 60;
  const maxDataValue = Math.max(...chartData);
  const minDataValue = Math.min(...chartData);
  const linePoints = chartData
    .map(
      (data, index) =>
        `${(index / (chartData.length - 1)) * svgWidth},${(1 -
          (data - minDataValue) / (maxDataValue - minDataValue)) *
          svgHeight}`
    )
    .join(' ');

  return (
    <svg width={svgWidth} height={svgHeight}>
      <polyline
        points={linePoints}
        fill="none"
        stroke={lineColor} // Set the stroke color dynamically
        strokeWidth="2"
      />
    </svg>
  );
};

export default LineChart;
