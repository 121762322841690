const INSTRUMENT_TABLE_ITEMS = {
  symbol: 'symbol',
  lastPrice: 'lastPrice',
  dailyPercentageChange: 'dailyPercentageChange',
  bid: 'bid',
  ask: 'ask',
  dailyVolume: 'dailyVolume',
  dailyMin: 'dailyMin',
  dailyMax: 'dailyMax'
};

export default INSTRUMENT_TABLE_ITEMS;
