import React from 'react';
import PropTypes from 'prop-types';
import { templateFormRenderer } from 'apex-web/lib/helpers/formTemplateHelper';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import APButton from 'apex-web/lib/components/common/APButton';
import { required } from 'apex-web/lib/helpers/formValidations';

const DepositTemplateFormComponent = (
  { template, submitting, baseClasses, product },
  context
) => {
  if (Object.keys(template).length) {
    const viewOnlyForm = Object.values(template).every(val => !!val);

    return (
      <div className={baseClasses('fields')}>
        {viewOnlyForm && (
          <p>
            {context.t(
              'Please use the following information to make a deposit:'
            )}
          </p>
        )}

        {!template.amount && !template.Amount ? (
          <APNumberInput
            name="Amount"
            customClass={baseClasses()}
            labelInInput={product.Product}
            label={context.t('Amount')}
            decimalPlaces={product.DecimalPlaces}
            validate={[required]}
          />
        ) : null}

        {templateFormRenderer(template, baseClasses(), context, product)}
        {!viewOnlyForm && (
          <div className="form-group">
            <APButton
              type="submit"
              disabled={submitting}
              customClass={baseClasses()}
              styleName="additive"
            >
              {submitting
                ? context.t('Submitting...')
                : context.t('Place Deposit Ticket')}
            </APButton>
          </div>
        )}
      </div>
    );
  }
  return <div />;
};

DepositTemplateFormComponent.defaultProps = {
  product: PropTypes.object,
  template: {},
  submitting: false,
  baseClasses: () => {}
};

DepositTemplateFormComponent.propTypes = {
  template: PropTypes.object,
  submitting: PropTypes.bool,
  baseClasses: PropTypes.func
};

DepositTemplateFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DepositTemplateFormComponent;
