import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import APIcon from "apex-web/lib/components/components/common/APIcon";
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
import path from '../helpers/path';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import StandAloneLayout from '../layouts/StandAloneLayout/StandAloneLayout';

import LoginFormContainer from 'apex-web/lib/components/Login/LoginFormContainer';

import LoginWrapper from '../components/LoginWrapper';

import CustomLoginContainer from '../components/CustomLoginContainer';

import '../styles/components/common/StandaloneForm.css';
import './LoginPage.css';

const baseClasses = getBEMClasses('standalone-form');
const loginPageClasses = getBEMClasses('login-page');

export const LoginFormComponent = (props, context) => {
  return (
    <StandAloneLayout>
      <div className="dcx-login-form">
        {/* <div
        className={
          `${baseClasses("container")} ${loginPageClasses("container")}` +
          " loginForm"
        }
      >
        <div
          className={`${baseClasses("header")} ${loginPageClasses("header")}`}
        >
        <div
            className={`${baseClasses("header-text")} ${loginPageClasses(
              "header-text"
            )}`}
          >
            {context.t("Log In to {n}", {
              n: process.env.REACT_APP_SITE_NAME,
            })}
          </div>
        <Link to={path("/")} className={baseClasses("close-icon")}>
            <APIcon name="close" classModifiers="big" />
          </Link>
      </div> */}

        {/* <LoginFormContainer /> */}
        <CustomLoginContainer />
      </div>

      {/* <div className={`${baseClasses("footer")} ${loginPageClasses("lalal")}`}>
        <Link
          to={path("/signup")}
          className={loginPageClasses("link")}
          disabled
        >
          {context.t("Sign Up")}
        </Link>
        <div className={baseClasses("footer-separator")} />
        <Link
          to={path("/problem-logging-in")}
          className={loginPageClasses("link")}
          disabled
        >
          {context.t("Problem Logging In?")}
        </Link>
      </div> */}
    </StandAloneLayout>
  );
};

LoginFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  LoginFormComponent,
  process.env.REACT_APP_DEFAULT_PATH
);
