import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import FiatWithdrawFormComponent from './FiatWithdrawFormComponent';
import { closeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import {
  fetchProductWithdrawTemplates,
  fetchProductWithdrawTemplate,
  createWithdrawTicket,
  getWithdrawFee
} from 'apex-web/lib/redux/actions/withdrawActions';
import {
  openModal,
  MODAL_TYPES
} from 'apex-web/lib/redux/actions/modalActions';
import { selectPosition } from 'apex-web/lib/redux/actions/positionActions';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import config from 'apex-web/lib/config';

const mapStateToProps = (state, ownProps) => {
  const { disableWithdraw } = kycVerificationLevelsSelector(state);
  return {
    disableWithdraw,
    fee: state.withdraw.withdrawFee,
    withdrawInfo: state.withdraw,
    products: state.apexCore.product,
    allowTransfer: ownProps?.allowTransfer ?? config.global.allowTransfer
  };
};

const mapDispatchToProps = dispatch => ({
  selectPosition: productId => dispatch(selectPosition(productId)),
  verificationOnClick: () => dispatch(closeSidePane()),
  confirmWithdraw: payload => dispatch(createWithdrawTicket(payload)),
  selectWithdrawProduct: productId =>
    dispatch(fetchProductWithdrawTemplates(productId)),
  selectWithdrawTemplate: (ProductId, AccountProviderId) =>
    dispatch(
      fetchProductWithdrawTemplate(ProductId, parseInt(AccountProviderId))
    ),
  getWithdrawFee: (productId, amount, AccountProviderId) =>
    dispatch(getWithdrawFee(productId, amount, AccountProviderId))
});

const FiatWithdrawForm = reduxForm({
  form: 'createFiatWithdraw',
  onSubmit: (payload, dispatch, { fee, withdrawInfo: { template } }) => {
    return dispatch(
      openModal(MODAL_TYPES.CONFIRM_FIAT_WITHDRAW, {
        payload,
        fee,
        template,
        TicketAmount: payload.Amount
      })
    );
  }
})(FiatWithdrawFormComponent);

export default connect(mapStateToProps, mapDispatchToProps)(FiatWithdrawForm);
