import { connect } from 'react-redux';
import { get } from 'lodash';
import SendReceiveComponent from './SendReceiveComponent';
import {
  createWithdrawTicket,
  getWithdrawFee
} from 'apex-web/lib/redux/actions/withdrawActions';
import {
  requestTransferFunds,
  transferFunds,
  getTransfers
} from 'apex-web/lib/redux/actions/transferActions';
import { getTransferRequestsRequested } from 'apex-web/lib/redux/actions/transferRequestActions';
import { positionByProductIdSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import config from 'apex-web/lib/config';
import { fetchAccountWithdrawInfos } from 'apex-web/lib/redux/actions/eotcWithdrawActions';
import { selectPositionAndSave } from 'apex-web/lib/redux/actions/positionActions';

const mapStateToProps = (state, ownProps) => {
  const productId = get(ownProps, 'product.ProductId', null);
  const productSymbol = get(ownProps, 'product.ProductSymbol', null);
  const position = positionByProductIdSelector(productId)(state);
  const decimalPlaces = state.apexCore.product.decimalPlaces[productSymbol];
  const product = { ...ownProps.product, decimalPlaces };
  const { allowTransfer, hideFees } = config.global;

  const { withdrawFee: fee, template, AccountProviderId } = state.withdraw;

  const { selectedAccountId } = state.user;
  const TicketAmount = get(state, ['withdraw', 'TicketAmount'], 0);

  const verificationLevelConfig =
    get(state, ['user', 'verificationLevelConfigs', 'Products'], []).find(
      i => i.ProductId === productId
    ) || {};

  return {
    position,
    fee,
    template,
    product,
    allowTransfer,
    hideFees,
    selectedAccountId,
    TicketAmount,
    AccountProviderId,
    verificationLevelConfig
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  receiveFunds: ({ selectedAccountId, ...rest }) =>
    dispatch(requestTransferFunds(rest, ownProps.name)).then(() =>
      dispatch(getTransferRequestsRequested(selectedAccountId))
    ),
  sendFunds: ({ selectedAccountId, ...rest }) =>
    dispatch(transferFunds(rest, ownProps.name)).then(() =>
      dispatch(getTransfers(selectedAccountId))
    ),
  sendToAddress: payload =>
    dispatch(createWithdrawTicket(payload, ownProps.name)),
  getWithdrawFee: (productId, amount, AccountProviderId) =>
    dispatch(getWithdrawFee(productId, amount, AccountProviderId)),
  fetchAccountWithdrawInfos: () => dispatch(fetchAccountWithdrawInfos()),
  selectPositionAndSave: productId => dispatch(selectPositionAndSave(productId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendReceiveComponent);
