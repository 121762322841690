import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import APSelect from 'apex-web/lib/components/common/APSelect';
import APButton from 'apex-web/lib/components/common/APButton';
import ContentWithFooterLayoutTemplate from 'apex-web/lib/components/common/LayoutTemplates/ContentWithFooterLayoutTemplate/ContentWithFooterLayoutTemplate';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
// import { chartEnums } from './ChartEnums';

import './ThemeFormComponent.css';

const baseClasses = getBEMClasses('theme-form');

const ThemeFormEditable = (props, context) => {
  const { formValues, change, themeOptions, goToNextStep } = props;

  useEffect(() => {
    if (!formValues['theme']) {
      const { value } = themeOptions[0];
      change('theme', value);
    }

    // if (!formValues['chart']) {
    //   const { value } = this.getChartOptions()[0];
    //   change('theme', value);
    // }
    if (!formValues['chart']) {
      change('theme', 'TradingView');
    }
  }, []);

  const getThemeOptions = () =>
    themeOptions.map(i => ({
      ...i,
      label: context.t(i.label)
    }));

  // const getChartOptions = () =>
  //   Object.keys(chartEnums).map(key => ({
  //     value: key,
  //     label: context.t(chartEnums[key])
  //   }));

  const footer = (
    <APButton customClass={baseClasses()} onClick={goToNextStep}>
      {context.t('Next')}
    </APButton>
  );

  return (
    <div className={baseClasses()}>
      <ContentWithFooterLayoutTemplate
        footer={footer}
        customClass="retail-region-editable-cwf-lt"
      >
        <div className={baseClasses('input-wrapper')}>
          <APSelect
            name="theme"
            label={context.t('Color Theme')}
            customClass={baseClasses()}
            options={getThemeOptions()}
          />
        </div>
        {/* <div className={baseClasses('input-wrapper')}>
            <APSelect
              name="chart"
              label={context.t('Trading Layout Chart')}
              customClass={baseClasses()}
              options={getChartOptions()}
            />
          </div> */}
      </ContentWithFooterLayoutTemplate>
    </div>
  );
};

ThemeFormEditable.propTypes = {
  goToNextStep: PropTypes.func.isRequired
};

ThemeFormEditable.contextTypes = {
  t: PropTypes.func.isRequired
};

ThemeFormEditable.defaultProps = {
  themeOptions: [
    { value: 'Dark', label: 'Dark' },
    { value: 'Light', label: 'Light' }
  ]
};

export default ThemeFormEditable;
