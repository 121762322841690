import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './DepositWithdrawTransfer.css';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import DepositFiatIcon from '../../images/icons/icon-deposit-fiat.svg';
import WithdrawFiatIcon from '../../images/icons/icon-withdraw-fiat.svg';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import WithdrawTransfer from './WithdrawTranfer/WithdrawTransfer';
import DepositTranfer from './DepositTransfer/DepositTranfer';

const baseClasses = getBEMClasses('deposit-withdraw-transfer');

const DepositWithdrawTransfer = () => {
  const [activeTab, setActiveTab] = useState('deposit'); // State to track active tab

  const handleActiveTab = tabName => {
    setActiveTab(tabName); // Function to set active tab
  };

  return (
    <div className={baseClasses()}>
      <div className={baseClasses('tabs')}>
        <div
          className={baseClasses('tab', activeTab === 'deposit' && 'active')}
          onClick={() => handleActiveTab('deposit')} // Activate 'deposit' tab
        >
          <img
            src={DepositFiatIcon}
            className={baseClasses(
              'deposit-icon',
              activeTab === 'deposit' && 'active'
            )}
          />
          Deposit AUD
        </div>
        <div
          className={baseClasses('tab', activeTab === 'withdraw' && 'active')}
          onClick={() => handleActiveTab('withdraw')} // Activate 'withdraw' tab
        >
          <img
            src={WithdrawFiatIcon}
            className={baseClasses(
              'deposit-icon',
              activeTab === 'withdraw' && 'active'
            )}
          />
          Withdraw AUD
        </div>
      </div>
      {/* Content based on the active tab */}
      <div className={baseClasses('tab-content')}>
        {activeTab === 'deposit' && (
          <div className={baseClasses('deposit')}>
            <DepositTranfer />
          </div>
        )}
        {activeTab === 'withdraw' && (
          <div className={baseClasses('withdraw')}>
            <WithdrawTransfer />
          </div>
        )}
      </div>
    </div>
  );
};

DepositWithdrawTransfer.contextTypes = {
  t: PropTypes.func.isRequired
};

export default withAuthentication(DepositWithdrawTransfer);
