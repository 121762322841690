import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ReceiveFormComponent from './ReceiveFormComponent';

const mapStateToProps = state => {
  const userEmail = state.user.userInfo.Email;
  return {
    userEmail
  };
};

const ReceiveForm = reduxForm({
  form: 'ReceiveForm',
  onSubmit: () => {}
})(ReceiveFormComponent);

export default connect(mapStateToProps, null)(ReceiveForm);
