import { createSelector } from 'reselect';
import { currency } from 'apex-web/lib/helpers/numberFormatter';

const trailingOrdersSelectorFactory = ordersSelector =>
  createSelector(ordersSelector, orders => {
    return orders.map(order => {
      if (
        order.OrderType === 'TrailingStopLimit' ||
        order.OrderType === 'TrailingStopMarket'
      ) {
        return {
          ...order,
          trailingPrice: 'TRL'
        };
      }

      return order;
    });
  });

const accountTradesCurrencySelector = createSelector(
  [
    state => state.apexCore.orderHistory.accountTrades,
    state => state.apexCore.instrument.instruments,
    state => state.apexCore.product.products
  ],
  (trades, instruments, products) => {
    return trades.map(trade => {
      const instrument = instruments.find(
        item => item.InstrumentId === trade.InstrumentId
      );
      if (!instrument) return {};
      const { Product1Symbol, Product2Symbol } = instrument;
      const quantity = currency(trade.Quantity, Product1Symbol);
      const price = currency(trade.Price, Product2Symbol);
      const symbol = trade.Side === 'Buy' ? Product1Symbol : Product2Symbol;
      const value = currency(trade.Value, symbol);
      const feeProduct = products.find(p => p.ProductId === trade.FeeProductId);
      const fee = currency(trade.Fee, feeProduct ? feeProduct.Product : symbol);

      return {
        ...trade,
        Quantity: quantity,
        Price: price,
        Value: value,
        Fee: fee
      };
    });
  }
);

const openOrdersCurrencySelector = createSelector(
  [
    state => state.apexCore.orderHistory.openOrders,
    state => state.apexCore.instrument.instruments,
    state => state.apexCore.orderHistory.tradeReports
  ],
  convertOrderNumbersToCurrency
);

export const openOrdersSelector = createSelector(
  openOrdersCurrencySelector,
  orders => {
    return orders
      .filter(
        order =>
          order.OrderState !== 'FullyExecuted' && order.OrderState === 'Working'
      )
      .sort((a, b) => b.ReceiveTime - a.ReceiveTime);
  }
);

export const openOrdersSelectorFactory = () =>
  createSelector(
    [
      trailingOrdersSelectorFactory(openOrdersSelector),
      state => state.apexCore.instrument.instruments,
      (state, props) => props
    ],
    (orders, instruments, options) => {
      if (options?.selectedCryptoProductId || options?.selectedFiatProductId) {
        return filterOrdersByProductId(
          orders,
          options?.selectedCryptoProductId,
          options?.selectedFiatProductId,
          instruments
        );
      } else {
        return orders;
      }
    }
  );

const tradeReportsCurrecnySelector = createSelector(
  [
    state => state.apexCore.orderHistory.tradeReports,
    state => state.apexCore.instrument.instruments
  ],
  (tradeReports, instruments) => {
    const trades = tradeReports.filter(order => order.OrderState !== 'Working');
    trades.map(order => {
      const instrument = instruments.find(
        item => item.InstrumentId === order.InstrumentId || order.Instrument
      );
      const { Product1Symbol, Product2Symbol } = instrument;
      const quantity = currency(order.Quantity, Product1Symbol);
      const price = currency(order.Price, Product2Symbol);
      const symbol = order.Side === 'Buy' ? Product1Symbol : Product2Symbol;
      const fee = currency(order.Fee, symbol);

      return {
        ...order,
        Quantity: quantity,
        Price: price,
        Fee: fee
      };
    });

    return trades;
  }
);

const tradeReportsSelector = createSelector(
  [tradeReportsCurrecnySelector, accountTradesCurrencySelector],
  (tradeReports, accountTrades) => {
    const blockTrades = accountTrades.filter(trade => trade.IsBlockTrade);

    return tradeReports
      .concat(blockTrades)
      .map(order => ({
        ...order,
        SortingTime: order.ReceiveTimeTicks
          ? order.ReceiveTimeTicks
          : order.TradeTime
      }))
      .sort((a, b) => {
        if (a.SortingTime < b.SortingTime) return 1;
        if (a.SortingTime > b.SortingTime) return -1;
        return 0;
      });
  }
);

export const tradeReportsSelectorFactory = () =>
  createSelector(
    [
      trailingOrdersSelectorFactory(tradeReportsSelector),
      state => state.apexCore.instrument.instruments,
      (state, props) => props
    ],
    (orders, instruments, options) => {
      if (options?.selectedCryptoProductId || options?.selectedFiatProductId) {
        return filterOrdersByProductId(
          orders,
          options?.selectedCryptoProductId,
          options?.selectedFiatProductId,
          instruments
        );
      } else {
        return orders;
      }
    }
  );

const inactiveOrdersCurrencySelector = createSelector(
  [
    state => state.apexCore.orderHistory.inactiveOrders,
    state => state.apexCore.instrument.instruments
  ],
  convertOrderNumbersToCurrency
);

const inactiveOrdersSelector = createSelector(
  inactiveOrdersCurrencySelector,
  orders => {
    return orders
      .filter(
        order =>
          order.OrderState !== 'FullyExecuted' && order.OrderState !== 'Working'
      )
      .sort((a, b) => b.ReceiveTime - a.ReceiveTime);
  }
);

export const inactiveOrdersSelectorFactory = () =>
  createSelector(
    [
      trailingOrdersSelectorFactory(inactiveOrdersSelector),
      state => state.apexCore.instrument.instruments,
      (state, props) => props
    ],
    (orders, instruments, options) => {
      if (options?.selectedCryptoProductId || options?.selectedFiatProductId) {
        return filterOrdersByProductId(
          orders,
          options?.selectedCryptoProductId,
          options?.selectedFiatProductId,
          instruments
        );
      } else {
        return orders;
      }
    }
  );

const filledOrdersSelector = createSelector(
  accountTradesCurrencySelector,
  trades =>
    trades
      .filter(trade => !trade.IsBlockTrade)
      .sort((a, b) => {
        if (a.TradeTime < b.TradeTime) return 1;
        if (a.TradeTime > b.TradeTime) return -1;
        return 0;
      })
);

export const filledOrdersSelectorFactory = () =>
  createSelector(
    [
      trailingOrdersSelectorFactory(filledOrdersSelector),
      state => state.apexCore.instrument.instruments,
      (state, props) => props
    ],
    (orders, instruments, options) => {
      if (options?.selectedCryptoProductId || options?.selectedFiatProductId) {
        return filterOrdersByProductId(
          orders,
          options?.selectedCryptoProductId,
          options?.selectedFiatProductId,
          instruments
        );
      } else {
        return orders;
      }
    }
  );

function convertOrderNumbersToCurrency(orders, instruments, tradeReports = []) {
  const allOrders = [...orders, ...tradeReports];
  return allOrders
    .filter(order => !!order)
    .map(order => {
      const instrument = instruments.find(
        item => item.InstrumentId === order.Instrument
      );

      if (!instrument) {
        return order;
      }

      const { Product1Symbol, Product2Symbol } = instrument;
      const quantity = currency(order.Quantity, Product1Symbol);
      const origQuantity = currency(order.OrigQuantity, Product1Symbol);
      const price = currency(order.Price, Product2Symbol);

      return {
        ...order,
        quantity,
        OrigQuantity: origQuantity,
        Price: price
      };
    });
}

function filterOrdersByProductId(
  orders,
  cryptoProductId,
  fiatProductId,
  instruments
) {
  const ordersWithProductIds = orders.map(order => {
    const instrument = instruments.find(
      item =>
        item.InstrumentId === order.Instrument ||
        item.InstrumentId === order.InstrumentId
    );

    return {
      ...order,
      product1Id: instrument ? instrument.Product1 : null,
      product2Id: instrument ? instrument.Product2 : null
    };
  });

  return ordersWithProductIds.filter(
    order =>
      order.product1Id === cryptoProductId || order.product2Id === fiatProductId
  );
}
