import { connect } from 'react-redux';
import DepositTemplateFormComponent from './DepositTemplateFormComponent';
import { depositProductSelector } from 'apex-web/lib/redux/selectors/depositWithdrawalSelectors';

const mapStateToProps = (state, { submitting, baseClasses }) => ({
  template: state.deposit.template,
  product: depositProductSelector(state),
  submitting,
  baseClasses
});

export default connect(mapStateToProps)(DepositTemplateFormComponent);
