import React from 'react';
import PropTypes from 'prop-types';
import DetailsComponent from '../../SendReceive/DetailsComponent/DetailsComponent';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

const FiatWithdrawDetailsComponent = (props, context) => {
  const {
    data: { Amount },
    product: { ProductSymbol },
    balance,
    fee,
    detailsInfo,
    TicketAmount
  } = props;

  const { getItem } = DetailsComponent;
  const currentBalance = balance
    ? `${formatNumberToLocale(balance, ProductSymbol)} ${ProductSymbol}`
    : '-';
  const amount = TicketAmount
    ? `${formatNumberToLocale(TicketAmount, ProductSymbol)} ${ProductSymbol}`
    : '-';

  /**
   * TicketAmount = `GetWithdrawFee`.TicketAmount.
   *  If (Gateway.`DeductWithdrawFee` = true), fee is already deducted.
   */
  const remainingBalance =
    balance && Amount
      ? `${formatNumberToLocale(
          balance - (TicketAmount + fee),
          ProductSymbol
        )} ${ProductSymbol}`
      : '-';
  const items = [
    getItem(
      context.t('Your current {ProductSymbol} Balance', {
        ProductSymbol
      }),
      currentBalance
    ),
    getItem(context.t('Amount to Withdraw'), amount),
    {
      label: context.t('Fee'),
      text: fee
        ? `${formatNumberToLocale(fee, ProductSymbol)} ${ProductSymbol}`
        : '-'
    },
    getItem(context.t('Remaining Balance'), remainingBalance)
  ];

  const info = detailsInfo.map(item => {
    if (item.useLink) {
      return (
        <a href={item.linkAddress}>
          {context.t('{text}', { text: item.text })}
        </a>
      );
    } else {
      return context.t('{text}', { text: item.text });
    }
  });

  return (
    <DetailsComponent
      title={context.t('Balances')}
      items={items}
      info={info}
      classModifiers={'withdraw'}
    />
  );
};

FiatWithdrawDetailsComponent.propTypes = {
  FullName: PropTypes.string,
  Amount: PropTypes.number,
  BankAccountName: PropTypes.string,
  BankAccountNumber: PropTypes.string,
  BankAddress: PropTypes.string,
  Comment: PropTypes.string
};

FiatWithdrawDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatWithdrawDetailsComponent;
