import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import SendFormComponent from './SendFormComponent';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { closeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';

const mapStateToProps = state => {
  const { disableWithdraw } = kycVerificationLevelsSelector(state);
  const userEmail = state.user.userInfo.Email;
  const withdrawError = !!state.withdraw.error;
  return { disableWithdraw, userEmail, withdrawError };
};

const mapDispatchToProps = dispatch => ({
  verificationOnClick: () => dispatch(closeSidePane())
});

const SendForm = reduxForm({
  form: 'SendForm',
  onSubmit: () => {}
})(SendFormComponent);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendForm);
