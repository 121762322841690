import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ThemeConfirmComponent from './ThemeConfirmComponent';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import ThemeFormComponent from './ThemeFormComponent';
import APStepsComponent from 'apex-web/lib/components/common/APStepsComponent';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import APButton from 'apex-web/lib/components/common/APButton';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import { chartEnums } from './ChartEnums';

import './ThemeSettingsComponent.css';

const themeClasses = getBEMClasses('theme-settings');

const ThemeSettingsComponent = (props, context) => {
  const { formValues, change } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const submitForm = () => {
    const storedValues = JSON.parse(localStorage.getItem('apexTemplate')) || {};

    formValues.theme = formValues.theme.toLowerCase();

    localStorage.setItem(
      'apexTemplate',
      JSON.stringify({ ...storedValues, ...formValues })
    );

    toggleModal();
    document.location.reload(true);
  };

  const getChildProps = () => ({
    doSubmit: submitForm,
    ...props
  });

  return (
    <div className={themeClasses('main')}>
      <div className={themeClasses('body')}>
        <div className={themeClasses('row')}>
          <APLabelWithText
            label={context.t('Color Theme')}
            text={formValues.theme}
            customClass={themeClasses()}
          />
        </div>
        <div className={themeClasses('row')}>
          <APLabelWithText
            label={context.t('Chart')}
            text={context.t(chartEnums[formValues.chart])}
            customClass={themeClasses()}
          />
        </div>

        <div className={themeClasses('button-container')}>
          <APButton customClass={themeClasses()} onClick={toggleModal}>
            {context.t('Edit')}
          </APButton>
        </div>
        {isOpenModal && (
          <Modal
            isOpen={isOpenModal}
            title={context.t('Edit Theme')}
            onCancel={() => {
              const localTheme = JSON.parse(
                localStorage.getItem('apexTemplate')
              ).theme;
              if (formValues.theme !== localTheme) {
                change(
                  'theme',
                  localTheme.charAt(0).toUpperCase() + localTheme.slice(1)
                );
              }
              toggleModal();
            }}
            close={toggleModal}
            customClass={themeClasses('ap-modal')}
          >
            <APStepsComponent
              childComponents={[ThemeFormComponent, ThemeConfirmComponent]}
              {...getChildProps()}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

ThemeSettingsComponent.propTypes = {
  formValues: PropTypes.object.isRequired
};

ThemeSettingsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ThemeSettingsComponent;
