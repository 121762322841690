import React from 'react';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import PageFooterLayout from '../PageFooterLayout/PageFooterLayout';
import './PageLayout.css';

const baseClasses = getBEMClasses('page-layout');

const PageLayout = props => {
  const { title, subTitle, children } = props;
  return (
    <>
      <div className={baseClasses('')}>
        <div className={baseClasses('content')}>
          <div className={baseClasses('header')}>
            <p className={baseClasses('title')}>{title}</p>
            <p className={baseClasses('sub-title')}>{subTitle}</p>
          </div>
          {children}
        </div>
      </div>
      <div className={baseClasses('footer')}>
        <PageFooterLayout />
      </div>
    </>
  );
};

export default PageLayout;
