import { connect } from 'react-redux';
import ManualDepositWorkflowComponent from './ManualDepositWorkflowComponent';
import { depositProductSelector } from 'apex-web/lib/redux/selectors/depositWithdrawalSelectors';

const mapStateToProps = (state, { onSubmit, submitting }) => ({
  product: depositProductSelector(state),
  onSubmit,
  submitting
});

export default connect(mapStateToProps)(ManualDepositWorkflowComponent);
