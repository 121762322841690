import { connect } from 'react-redux';
import get from 'lodash/get';
import FilledOrdersComponent from './FilledOrdersComponent';
import { filledOrdersSelectorFactory } from '../../../redux/selectors/orderHistorySelectors';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import {
  cancelOrders,
  getAccountTrades
} from 'apex-web/lib/redux/actions/orderHistoryActions';

const makeMapStateToProps = () => {
  const filledOrdersSelector = filledOrdersSelectorFactory();

  return (state, ownProps) => {
    const filledOrders = filledOrdersSelector(state, {
      selectedCryptoProductId: ownProps?.selectedCryptoProductId,
      selectedFiatProductId: ownProps?.selectedFiatProductId
    });
    const instruments = instrumentSelectorInstruments(state);
    const { activeRequests, accountTradesStartIndex } =
      state.apexCore.orderHistory;
    const fetching = activeRequests > 0;

    return {
      data: filledOrders,
      instruments,
      fetching,
      selectedAccountId: get(state, 'user.selectedAccountId', 0),
      OMSId: get(state, 'user.userInfo.OMSId', 1),
      accountTradesStartIndex
    };
  };
};

const mapDispatchToProps = {
  cancelOrders,
  getAccountTrades
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(FilledOrdersComponent);
