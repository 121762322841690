import React, { useState } from 'react';
import { FiatWithdrawConfirmationModal } from '../../DepositWithdraw/FiatWithdraw';
import { FiatWithdrawFormContainer } from '../../DepositWithdraw/FiatWithdraw';
import {
  getFinalBalanceWithdrawValue,
  getAvailableBalance
} from 'apex-web/lib/helpers/withdrawHelper';
import { getWithdrawFee } from 'apex-web/lib/redux/actions/withdrawActions';
import { connect } from 'react-redux';

const WithdrawTransfer = props => {
  const { audProduct, fee, audPosition, accountProviderId } = props;
  const { Amount, Hold } = audPosition || {};

  const [data, setData] = useState({});
  const [totalValue, setTotalValue] = useState(0);

  const onUpdate = updatedData => {
    getWithdrawFee(updatedData);
    setTotal(updatedData);
  };

  const setTotal = updatedData => {
    const amount = parseFloat(updatedData?.Amount || data?.Amount);
    const balance = getAvailableBalance(audPosition.Amount, audPosition.Hold);
    let total = 0;

    if (amount) {
      total = getFinalBalanceWithdrawValue(balance, amount, fee);
    }

    setTotalValue(total);
  };

  const getWithdrawFee = updatedData => {
    if (!isNaN(updatedData?.Amount)) {
      props.getWithdrawFee(
        audProduct?.ProductId,
        updatedData?.Amount,
        accountProviderId
      );
    }
  };

  const onDataUpdate = updatedData => {
    setData(data);

    if (updatedData.Amount !== data?.Amount) {
      onUpdate(updatedData);
    }
  };

  const balance = getAvailableBalance(Amount, Hold);
  return (
    <>
      <FiatWithdrawFormContainer
        product={audProduct}
        balance={balance}
        fee={fee}
        total={totalValue}
        onChange={onDataUpdate}
        showDetails={true}
        allowTransfer={false}
      />
      <FiatWithdrawConfirmationModal
        product={{ ...audProduct, ...audPosition }}
      />
    </>
  );
};

const mapStateToProps = ({ product, position, withdraw, user }) => {
  const { selectedAccountId } = user;
  const accountPostions = position?.positions?.[selectedAccountId];
  const audPosition = accountPostions
    ? Object.values(accountPostions).find(item => item.ProductSymbol === 'AUD')
    : {};
  const audProduct =
    product.products.find(item => item.Product === 'AUD') || {};
  const { withdrawFee: fee, AccountProviderId } = withdraw;

  return { audProduct, audPosition, fee, accountProviderId: AccountProviderId };
};

const mapDispatchToProps = dispatch => ({
  getWithdrawFee: (productId, amount, accountProviderId) =>
    dispatch(getWithdrawFee(productId, amount, accountProviderId))
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawTransfer);
