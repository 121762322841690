import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import './Details.css';

const bemClasses = getBEMClasses('dcx-send-receive-details');

const DetailsComponent = (props, context) => {
  const { title, items, info, infoHeader, classModifiers, link } = props;
  const isHtmlString = typeof info === 'string';

  return (
    <React.Fragment>
      <header className={bemClasses('header', classModifiers)}>{title}</header>
      <section className={bemClasses('body')}>
        {items &&
          items.map(({ label, text }) => (
            <APLabelWithText
              key={label}
              label={label}
              text={text}
              customClass={bemClasses()}
            />
          ))}
      </section>
      <section className={bemClasses('information', classModifiers)}>
        {infoHeader && (
          <div className={bemClasses('information-header')}>{infoHeader}</div>
        )}
        {info &&
          (isHtmlString ? (
            <div dangerouslySetInnerHTML={{ __html: info }} />
          ) : (
            info.map((item, index) => (
              <div
                className={bemClasses('information-text', classModifiers)}
                key={index}
              >
                {item}
              </div>
            ))
          ))}
        {link && <a href={link.url}> {context.t(link.text)} </a>}
      </section>
    </React.Fragment>
  );
};

DetailsComponent.defaultProps = {
  link: {
    text: '',
    url: ''
  }
};

DetailsComponent.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  info: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  infoHeader: PropTypes.string,
  link: PropTypes.object
};

DetailsComponent.getItem = (label, text) => ({ label, text });

DetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DetailsComponent;
