import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import { product } from 'apex-web/lib/propTypes/sendReceiveComponent';
import ReceiveFormContainer from '../ReceiveForm/ReceiveFormContainer';
import ReceiveAddressContainer from '../ReceiveAddress/ReceiveAddressContainer';
import './Receive.css';
import config from 'apex-web/lib/config';

const bemClasses = getBEMClasses('dcx-receive');

const ReceiveComponent = (props, context) => {
  const {
    product,
    useExternalAddress,
    toggleUseExternalAddress,
    onChange,
    onSubmit,
    disableDeposit,
    showDepositLimitWarningMessage,
    verificationOnClick,
    allowTransfer
  } = props;

  return (
    <section className={bemClasses()}>
      <header className={bemClasses('source')}>
        {allowTransfer && (
          <div className={bemClasses('source-item-with-border')}>
            <div
              className={bemClasses('source-item', {
                active: !useExternalAddress
              })}
              onClick={() => {
                useExternalAddress && toggleUseExternalAddress();
              }}
            >
              {context.t('Friend')}
            </div>
          </div>
        )}
        <div
          className={bemClasses('source-item', {
            active: useExternalAddress
          })}
          onClick={() => {
            !useExternalAddress && toggleUseExternalAddress();
          }}
        >
          {context.t('External Wallet')}
        </div>
      </header>
      <section className={bemClasses('body')}>
        {disableDeposit ? (
          <VerificationRequiredContainer
            disabled={disableDeposit}
            onClick={verificationOnClick}
          />
        ) : showDepositLimitWarningMessage ? (
          <div>{config.Deposit.verificationLevelDepositsDisabledMessage}</div>
        ) : useExternalAddress ? (
          <ReceiveAddressContainer product={product} />
        ) : (
          <ReceiveFormContainer
            product={product}
            onChange={onChange}
            onSubmit={onSubmit}
          />
        )}
      </section>
    </section>
  );
};

ReceiveComponent.propTypes = {
  product,
  useExternalAddress: PropTypes.bool.isRequired,
  toggleUseExternalAddress: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  allowTransfer: PropTypes.bool.isRequired,
  VerificationRequiredComponent: PropTypes.element,
  disableDeposit: PropTypes.bool,
  showDepositLimitWarningMessage: PropTypes.bool,
  verificationOnClick: PropTypes.func
};

ReceiveComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReceiveComponent;
