import { connect } from 'react-redux';
import TrustpayWithdrawWorkflowComponent from './TrustpayWithdrawWorkflowComponent';

const mapStateToProps = state => ({
  url:
    state.withdraw.withdrawWorkflowInfo &&
    state.withdraw.withdrawWorkflowInfo.URL
});

export default connect(mapStateToProps)(TrustpayWithdrawWorkflowComponent);
