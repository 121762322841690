import React, { useState, useMemo } from 'react';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import InstrumentTable from '../../dcxComponents/InstrumentTable/InstrumentTableContainer';
import './AssetsPage.css';
import BuySellModalContainer from '../../dcxComponents/BuySellModal/BuySellModalContainer';
import SearchInput from '../../dcxComponents/common/SearchInput/SearchInput';
import TradingTabs from '../../dcxComponents/TradingTabs';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import ArrowDownIcon from '../../images/icons/icon-arrow-down.svg';
import { sortAssets, SORT_OPTIONS } from './helpers/sortAssets';
import { useDcxData } from '../../dcxContext';

const baseClasses = getBEMClasses('assets-page');

const getArrowDirection = (sortField, sortValue) => {
  if (sortField === sortValue.sortField) {
    return sortValue.direction;
  } else {
    return 'asc';
  }
};

const ASSETS_COLUMNS = [
  { title: 'Assets', sortOption: SORT_OPTIONS.name },
  { title: 'Price', sortOption: SORT_OPTIONS.price },
  { title: 'Last 24 hrs', sortOption: SORT_OPTIONS.lastDayChange },
  { title: 'Market cap', sortOption: SORT_OPTIONS.marketCup },
  { title: 'Performance', sortOption: SORT_OPTIONS.performance }
];

const AssetsPage = () => {
  const { assets, instruments } = useDcxData();
  const [searchValue, setSearchValue] = useState('');
  const [sortValue, setSortValue] = useState({
    sortField: SORT_OPTIONS.name,
    direction: 'asc'
  });

  // Integrate `data` into the filtering and sorting logic
  const filteredAssets = useMemo(() => {
    const searchValueInLowerCase = searchValue.toLowerCase();
    let result = searchValue
      ? assets.filter(
          item =>
            item.productFullName1
              .toLowerCase()
              .includes(searchValueInLowerCase) ||
            item.productFullName2
              .toLowerCase()
              .includes(searchValueInLowerCase) ||
            item.productSymbol1
              .toLowerCase()
              .includes(searchValueInLowerCase) ||
            item.productSymbol2.toLowerCase().includes(searchValueInLowerCase)
        )
      : assets;
    // Sort the result based on the sort direction
    result.sort((a, b) => {
      return sortAssets(
        a,
        b,
        sortValue.sortField,
        sortValue.direction === 'asc'
      );
    });

    return result;
  }, [assets, searchValue, sortValue]);

  const setSort = sortField => {
    setSortValue(prevSortValue => {
      if (prevSortValue.sortField === sortField) {
        return {
          sortField,
          direction: prevSortValue.direction === 'asc' ? 'des' : 'asc'
        };
      } else {
        return { sortField, direction: 'des' };
      }
    });
  };

  return (
    <>
      <div className={baseClasses()}>
        <TradingTabs />

        <div className={baseClasses('assets-list')}>
          <div className={baseClasses('header')}>
            <div className={baseClasses('header-content')}>
              <h1 className="title">Buy/Sell</h1>
              <p>The easy place to buy, sell and trade crypto</p>
            </div>
            <div className={baseClasses('search')}>
              <SearchInput value={searchValue} setValue={setSearchValue} />
            </div>
          </div>

          <div className={baseClasses('assets-header')}>
            {ASSETS_COLUMNS.map(item => (
              <div
                key={item.title}
                className={baseClasses('assets-header-item')}
                onClick={() => setSort(item.sortOption)}
              >
                <span>{item.title}</span>
                <img
                  src={ArrowDownIcon}
                  className={baseClasses(
                    'assets-header-item-arrow',
                    getArrowDirection(item.sortOption, sortValue)
                  )}
                />
              </div>
            ))}

            <div className={baseClasses('assets-header-item smaller')}></div>
          </div>

          {filteredAssets.map(item => (
            <InstrumentTable
              key={item.instrumentSymbol}
              title={item.productFullName1}
              symbol={item.productSymbol1}
              selectedInstrument={instruments.find(
                instrument => instrument.InstrumentId === item.instrumentId
              )}
              price={item.price}
              change24hr={item.change24hr}
              marketCap={item.volume24hr}
              isFromAssetsPage
              price24hr={item.price24hr}
            />
          ))}
        </div>
        <BuySellModalContainer />
      </div>
      <div className={baseClasses('footer')}>
        <PageFooterLayout />
      </div>
    </>
  );
};

export default AssetsPage;
