import React, { useState, useMemo } from 'react';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import config from 'apex-web/lib/config';
import './ReportingPage.css';
import OpenOrdersContainer from '../../dcxComponents/OrderHistoryComponents/OpenOrders';
import FilledOrdersContainer from '../../dcxComponents/OrderHistoryComponents/FilledOrders';
import InactiveOrdersContainer from '../../dcxComponents/OrderHistoryComponents/InactiveOrders';
import TradeReportsContainer from '../../dcxComponents/OrderHistoryComponents/TradeReports';
import DepositsStatusContainer from '../../dcxComponents/OrderHistoryComponents/DepositsStatus';
import WithdrawStatusContainer from '../../dcxComponents/OrderHistoryComponents/WithdrawStatus';
import PageLayout from '../../layouts/PageLayout/PageLayout';
import Dropdown from '../../dcxComponents/common/Dropdown/Dropdown';
import { connect } from 'react-redux';
import resize from 'apex-web/lib/hocs/resize';

const baseClasses = getBEMClasses('reporting-page');

const TABS = {
  openOrders: { title: 'Open orders', value: 'openOrders' },
  filledOrders: { title: 'Filled orders', value: 'filledOrders' },
  inactiveOrders: { title: 'Inactive orders', value: 'inactiveOrders' },
  tradeReports: { title: 'Trade reports', value: 'tradeReports' },
  depositStatus: { title: 'Deposit status', value: 'depositStatus' },
  withdrawStatus: { title: 'Withdraw status', value: 'withdrawStatus' }
};

const ReportingPage = ({ cryptoProducts, fiatProducts, width }) => {
  const {
    OrderHistoryData: { usePagination },
    TradingLayout: { maxLines }
  } = config;
  const orderHistoryConfig = {
    usePagination,
    maxLines
  };
  const isMobile = width <= 1025;
  const [activeTab, setActiveTab] = useState(TABS.openOrders.value);
  const [selectedCryptoProductId, setSelectedCryptoProductId] = useState('All');
  const [selectedFiatProductId, setSelectedFiatProductId] = useState('All');

  const cryptoProductsOptions = useMemo(
    () => [
      { title: 'All coins', value: 'All' },
      ...cryptoProducts.map(item => ({
        title: item.Product,
        value: item.ProductId
      }))
    ],
    [cryptoProducts.length]
  );

  const fiatProductsOptions = useMemo(
    () => [
      { title: 'All fiat', value: 'All' },
      ...fiatProducts.map(item => ({
        title: item.Product,
        value: item.ProductId
      }))
    ],
    [fiatProducts.length]
  );

  const cryptoProductId =
    selectedCryptoProductId === 'All' ? undefined : selectedCryptoProductId;
  const fiatProductId =
    selectedFiatProductId === 'All' ? undefined : selectedFiatProductId;

  return (
    <PageLayout title="My orders" subTitle="All your orders in one spot">
      <div className={baseClasses('tabs-with-actions')}>
        {isMobile ? (
          <Dropdown
            defaultValue={
              Object.values(TABS).find(item => item.value === activeTab)?.title
            }
            selectedOption={
              Object.values(TABS).find(item => item.value === activeTab)?.value
            }
            setSelectedOption={tab => setActiveTab(tab)}
            options={Object.values(TABS)}
            customClass={baseClasses('type-selector')}
          />
        ) : (
          <div className={baseClasses('tabs')}>
            {Object.values(TABS).map(item => (
              <div
                key={item.value}
                className={baseClasses(
                  'tab',
                  activeTab === item.value && 'active'
                )}
                onClick={() => setActiveTab(item.value)}
              >
                {item.title}
              </div>
            ))}
          </div>
        )}

        <div className={baseClasses('selectors')}>
          <Dropdown
            defaultValue={'All coins'}
            selectedOption={selectedCryptoProductId}
            setSelectedOption={id => setSelectedCryptoProductId(id)}
            options={cryptoProductsOptions}
            customClass={baseClasses('coin-selector')}
          />
          <Dropdown
            defaultValue={'All fiat'}
            selectedOption={selectedFiatProductId}
            setSelectedOption={id => setSelectedFiatProductId(id)}
            options={fiatProductsOptions}
            customClass={baseClasses('coin-selector')}
          />
        </div>
      </div>
      <div className={baseClasses('table')}>
        {activeTab === TABS.openOrders.value && (
          <OpenOrdersContainer
            config={orderHistoryConfig}
            selectedCryptoProductId={cryptoProductId}
            selectedFiatProductId={fiatProductId}
            isMobile={isMobile}
          />
        )}
        {activeTab === TABS.filledOrders.value && (
          <FilledOrdersContainer
            config={orderHistoryConfig}
            selectedCryptoProductId={cryptoProductId}
            selectedFiatProductId={fiatProductId}
            isMobile={isMobile}
          />
        )}
        {activeTab === TABS.inactiveOrders.value && (
          <InactiveOrdersContainer
            config={orderHistoryConfig}
            selectedCryptoProductId={cryptoProductId}
            selectedFiatProductId={fiatProductId}
            isMobile={isMobile}
          />
        )}
        {activeTab === TABS.tradeReports.value && (
          <TradeReportsContainer
            config={orderHistoryConfig}
            selectedCryptoProductId={cryptoProductId}
            selectedFiatProductId={fiatProductId}
            isMobile={isMobile}
          />
        )}
        {activeTab === TABS.depositStatus.value && (
          <DepositsStatusContainer
            config={orderHistoryConfig}
            selectedCryptoProductId={cryptoProductId}
            selectedFiatProductId={fiatProductId}
            isMobile={isMobile}
          />
        )}
        {activeTab === TABS.withdrawStatus.value && (
          <WithdrawStatusContainer
            config={orderHistoryConfig}
            selectedCryptoProductId={cryptoProductId}
            selectedFiatProductId={fiatProductId}
            isMobile={isMobile}
          />
        )}
      </div>
    </PageLayout>
  );
};

const mapStateToProps = state => {
  const {
    product: { products }
  } = state.apexCore;
  const cryptoProducts = products.filter(
    product => product.ProductType === 'CryptoCurrency'
  );
  const fiatProducts = products.filter(
    product => product.ProductType === 'NationalCurrency'
  );
  return { cryptoProducts, fiatProducts };
};

export default connect(mapStateToProps)(resize(ReportingPage));
