import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import APSegmentedButton from 'apex-web/lib/components/common/APSegmentedButton';
// import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import APSelect from 'apex-web/lib/components/common/APSelect';
import BuySellTabs from './BuySellTabs';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { biggerThanZero } from 'apex-web/lib/helpers/formValidations';
// import {
//   getOrderLeverage,
//   getOrderBorrowingPower
// } from 'apex-web/lib/helpers/orderHelper.js';
// import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { styleNames } from 'apex-web/lib/propTypes/commonComponent';
import {
  orderTypes,
  sellValue,
  buyValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { instrumentPropType } from 'apex-web/lib/propTypes/instrumentPropTypes';
import ConfirmOrderModalContainer from './ConfirmOrderModal';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import PaymentsImg from './assets/payments.png';

import './OrderEntryComponent.css';
import './OrderEntryWithPercentButtonsComponent.css';

const baseClass = 'dcx-order-entry';
const baseClasses = getBEMClasses(baseClass);

const OrderEntryWithPercentButtonsComponent = (props, context) => {
  const {
    orderEntryForm,
    // marketPrice,
    selectedInstrument,
    handleSubmit,
    submitting,
    fetching,
    invalid,
    disableTrading,
    VerificationRequiredComponent,
    // hideFees,
    // orderTotal,
    // orderNet,
    // orderFee,
    // isMarginActive,
    // marginProducts,
    title,
    reduxFormChange,
    positions,
    level1,
    lastTradePrice,
    side
  } = props;

  useEffect(() => {
    if (side) {
      reduxFormChange('side', side);
    }
  }, [side]);

  const buy = orderEntryForm.values.side === buyValue;
  const sell = orderEntryForm.values.side === sellValue;

  const useValue =
    orderEntryForm.values.orderType === orderTypes.market.displayName &&
    orderEntryForm.values.orderQuantity === selectedInstrument.Product2Symbol;

  const onMaxClicked = e => {
    try {
      const percent = parseFloat(e.target.value);
      const product1Position = positions[selectedInstrument.Product1];
      const product2Position = positions[selectedInstrument.Product2];

      if (orderEntryForm.values.orderType === orderTypes.market.displayName) {
        if (
          orderEntryForm.values.orderQuantity ===
            selectedInstrument.Product1Symbol &&
          buy
        ) {
          const position = positions[selectedInstrument.Product2];
          const availablePosition = position.Amount - position.Hold;
          const availableAmount = availablePosition / level1.BestOffer;

          const amount = (availableAmount * percent).toFixed(
            convertIncrementToIntDecimalPlaces(
              selectedInstrument.QuantityIncrement
            )
          );

          reduxFormChange('quantity', amount);
          return;
        }

        if (
          orderEntryForm.values.orderQuantity ===
            selectedInstrument.Product2Symbol &&
          buy
        ) {
          const position = positions[selectedInstrument.Product2];
          const availablePosition = position.Amount - position.Hold;

          const amount = (availablePosition * percent).toFixed(
            convertIncrementToIntDecimalPlaces(
              selectedInstrument.PriceIncrement
            )
          );

          reduxFormChange('value', amount);
          return;
        }

        if (
          orderEntryForm.values.orderQuantity ===
            selectedInstrument.Product1Symbol &&
          sell
        ) {
          const position = positions[selectedInstrument.Product1];
          const availablePosition = position.Amount - position.Hold;

          const amount = (availablePosition * percent).toFixed(
            convertIncrementToIntDecimalPlaces(
              orderEntryForm.values.orderQuantity ===
                selectedInstrument.Product2Symbol
                ? selectedInstrument.PriceIncrement
                : selectedInstrument.QuantityIncrement
            )
          );

          reduxFormChange('quantity', amount);
          return;
        }

        if (
          orderEntryForm.values.orderQuantity ===
            selectedInstrument.Product2Symbol &&
          sell
        ) {
          const position = positions[selectedInstrument.Product1];
          const availablePosition = position.Amount - position.Hold;
          const availableAmount = availablePosition * level1.BestBid;

          const amount = (availableAmount * percent).toFixed(
            convertIncrementToIntDecimalPlaces(
              orderEntryForm.values.orderQuantity ===
                selectedInstrument.Product2Symbol
                ? selectedInstrument.PriceIncrement
                : selectedInstrument.QuantityIncrement
            )
          );

          reduxFormChange('quantity', amount);
          return;
        }
      }

      if (orderEntryForm.values.orderType === orderTypes.limit.displayName) {
        const baseCurrencyPosition = buy ? product2Position : product1Position;
        const availablePosition =
          baseCurrencyPosition.Amount - baseCurrencyPosition.Hold;

        const marketPrice = buy ? level1.BestOffer : level1.BestBid;

        let quantity;
        if (buy) {
          quantity = (availablePosition * percent) / marketPrice;
        } else {
          quantity = availablePosition * percent;
        }

        if (
          orderEntryForm.values.limitPrice &&
          orderEntryForm.values.limitPrice > 0
        ) {
          reduxFormChange('limitPrice', orderEntryForm.values.limitPrice);

          let quant;
          if (buy) {
            quant =
              (availablePosition * percent) / orderEntryForm.values.limitPrice;
          } else {
            quant = quantity;
          }

          reduxFormChange(
            'quantity',
            quant.toFixed(
              convertIncrementToIntDecimalPlaces(
                selectedInstrument.QuantityIncrement
              )
            )
          );
        } else {
          reduxFormChange('limitPrice', marketPrice);
          reduxFormChange(
            'quantity',
            quantity.toFixed(
              convertIncrementToIntDecimalPlaces(
                selectedInstrument.QuantityIncrement
              )
            )
          );
        }
      }

      if (
        orderEntryForm.values.orderType === orderTypes.stopMarket.displayName
      ) {
        const baseCurrencyPosition = buy ? product2Position : product1Position;
        const availablePosition =
          baseCurrencyPosition.Amount - baseCurrencyPosition.Hold;

        const marketPrice = buy ? level1.BestOffer : level1.BestBid;
        let quantity;
        if (buy) {
          quantity = (availablePosition * percent) / marketPrice;
        } else {
          quantity = availablePosition * percent;
        }

        if (
          orderEntryForm.values.stopPrice &&
          orderEntryForm.values.stopPrice > 0
        ) {
          reduxFormChange('stopPrice', orderEntryForm.values.stopPrice);
          let quant;
          if (buy) {
            quant =
              (availablePosition * percent) / orderEntryForm.values.stopPrice;
          } else {
            quant = quantity;
          }

          reduxFormChange(
            'quantity',
            quant.toFixed(
              convertIncrementToIntDecimalPlaces(
                selectedInstrument.QuantityIncrement
              )
            )
          );
        } else {
          reduxFormChange('stopPrice', marketPrice);
          reduxFormChange(
            'quantity',
            quantity.toFixed(
              convertIncrementToIntDecimalPlaces(
                selectedInstrument.QuantityIncrement
              )
            )
          );
        }
      }
    } catch (e) {
      //
    }
  };

  const onBaseCurrencyChange = () => {
    if (orderEntryForm.values.value) {
      reduxFormChange('value', 0);
    } else {
      reduxFormChange('quantity', 0);
    }
  };

  const resetState = () => {
    props.change('limitPrice', '');
    props.change('stopPrice', '');
    props.untouch('limitPrice');
    props.untouch('stopPrice');
  };

  return (
    <div className={baseClasses()}>
      {title || null}
      <form onSubmit={handleSubmit} className={baseClasses('form')}>
        <BuySellTabs
          baseClass={baseClass}
          styleName={sell ? styleNames.subtractive : styleNames.additive}
        />

        <div className={baseClasses('body')}>
          <div className={baseClasses('market-price')}>
            {selectedInstrument?.Product1FullName}:{' '}
            {selectedInstrument?.Product2Symbol} {lastTradePrice}
          </div>
          <div className={baseClasses('order-type-wrapper')}>
            <APSegmentedButton
              name="orderType"
              label={context.t('Order Type')}
              items={[
                {
                  value: orderTypes.market.displayName,
                  text: context.t('Market'),
                  dataTest: 'Market Order Type',
                  title: context.t('Buy or sell at the best available price'),
                  onClick: resetState
                },
                {
                  value: orderTypes.limit.displayName,
                  text: context.t('Limit'),
                  dataTest: 'Limit Order Type',
                  title: context.t(
                    'Buy or sell at the specified price or better'
                  ),
                  onClick: resetState
                },
                {
                  value: orderTypes.stopMarket.displayName,
                  text: context.t('Stop'),
                  dataTest: 'Stop Order Type',
                  title: context.t(
                    'Buy or sell once the price reaches a specified Stop Price'
                  ),
                  onClick: resetState
                }
              ]}
              customClass={baseClass}
              styleName={buy ? styleNames.additive : styleNames.subtractive}
            />
          </div>

          {orderEntryForm.values.orderType === orderTypes.market.displayName ? (
            <div className={baseClasses('input-wrapper')} key="orderQuantity">
              <APSegmentedButton
                name="orderQuantity"
                items={[
                  {
                    value: selectedInstrument.Product1Symbol,
                    text: selectedInstrument.Product1Symbol,
                    onClick: onBaseCurrencyChange
                  },
                  {
                    value: selectedInstrument.Product2Symbol,
                    text: selectedInstrument.Product2Symbol,
                    onClick: onBaseCurrencyChange
                  }
                ]}
                customClass={`${baseClass}-percent`}
                styleName={buy ? styleNames.additive : styleNames.subtractive}
              />
            </div>
          ) : null}

          {useValue ? (
            <APNumberInput
              type="text"
              name="value"
              placeholder="0"
              customClass={baseClass}
              label={buy ? context.t('Buy Amount') : context.t('Sell Amount')}
              labelInInput={
                selectedInstrument && buy
                  ? selectedInstrument.Product2Symbol
                  : orderEntryForm.values.orderQuantity
              }
              validate={[biggerThanZero]}
              decimalPlaces={
                selectedInstrument && buy
                  ? convertIncrementToIntDecimalPlaces(
                      selectedInstrument.PriceIncrement
                    )
                  : convertIncrementToIntDecimalPlaces(
                      selectedInstrument.QuantityIncrement
                    )
              }
              step={
                buy
                  ? selectedInstrument.PriceIncrement
                  : selectedInstrument.QuantityIncrement
              }
              onMaxClick={onMaxClicked}
            />
          ) : (
            <APNumberInput
              type="text"
              name="quantity"
              placeholder="0"
              customClass={baseClass}
              label={buy ? context.t('Buy Amount') : context.t('Sell Amount')}
              labelInInput={
                selectedInstrument && selectedInstrument.Product1Symbol
              }
              validate={[biggerThanZero]}
              decimalPlaces={
                selectedInstrument &&
                convertIncrementToIntDecimalPlaces(
                  selectedInstrument.QuantityIncrement
                )
              }
              step={selectedInstrument.QuantityIncrement}
              onMaxClick={onMaxClicked}
              min={0}
            />
          )}
          {orderEntryForm.values.orderType === orderTypes.limit.displayName && (
            <React.Fragment>
              <APNumberInput
                type="text"
                name="limitPrice"
                placeholder="0"
                customClass={baseClass}
                label={context.t('Limit Price')}
                labelInInput={
                  selectedInstrument && selectedInstrument.Product2Symbol
                }
                validate={[biggerThanZero]}
                decimalPlaces={
                  selectedInstrument &&
                  convertIncrementToIntDecimalPlaces(
                    selectedInstrument.PriceIncrement
                  )
                }
                step={selectedInstrument.PriceIncrement}
                min={0}
              />
              <APSelect
                name="timeInForce"
                label={context.t('Time in Force')}
                customClass={baseClasses()}
                options={[
                  { value: 1, label: context.t('Good Til Canceled') },
                  { value: 3, label: context.t('Immediate or Cancel') },
                  { value: 4, label: context.t('Fill or Kill') }
                ]}
                showTriangles={true}
              />
            </React.Fragment>
          )}

          {orderEntryForm.values.orderType ===
            orderTypes.stopMarket.displayName && (
            <APNumberInput
              type="text"
              name="stopPrice"
              placeholder="0"
              customClass={baseClass}
              label={context.t('Stop Price')}
              labelInInput={
                selectedInstrument ? selectedInstrument.Product2Symbol : ''
              }
              validate={[biggerThanZero]}
              decimalPlaces={
                selectedInstrument &&
                convertIncrementToIntDecimalPlaces(
                  selectedInstrument.PriceIncrement
                )
              }
              step={selectedInstrument.PriceIncrement}
              min={0}
            />
          )}

          {/* <div className={baseClasses('section')}>
            {isMarginActive && (
              <React.Fragment>
                <APLabelWithText
                  name="borrowingPower"
                  label={context.t('Borrowing Power')}
                  text={`${
                    buy
                      ? selectedInstrument.Product2Symbol
                      : selectedInstrument.Product1Symbol
                  } ${formatNumberToLocale(
                    getOrderBorrowingPower(
                      orderEntryForm,
                      selectedInstrument,
                      marginProducts
                    ),
                    convertIncrementToIntDecimalPlaces(
                      buy
                        ? selectedInstrument.PriceIncrement
                        : selectedInstrument.QuantityIncrement
                    )
                  )}`}
                  customClass={baseClass}
                />

                <APLabelWithText
                  name="leverage"
                  label={context.t('Leverage')}
                  text={getOrderLeverage(orderEntryForm)}
                  customClass={baseClass}
                />
              </React.Fragment>
            )}

            <APLabelWithText
              name="marketPrice"
              label={context.t('Market Price')}
              text={`${selectedInstrument.Product2Symbol} ${marketPrice}`}
              customClass={baseClass}
            />

            {!hideFees && (
              <APLabelWithText
                label={context.t('Fees')}
                customClass={baseClass}
                text={orderFee}
              />
            )}

            <APLabelWithText
              label={context.t('Order Total')}
              text={orderTotal}
              customClass={baseClass}
            />

            {!hideFees && (
              <APLabelWithText
                label={context.t('Net')}
                text={orderNet}
                customClass={baseClass}
              />
            )}
          </div> */}
          {/* <div className={baseClasses('section')}>
            <p className={baseClasses('payments-label')}>Payment method</p>
            <img src={PaymentsImg} className={baseClasses('payments-img')} />
          </div> */}
          <div className={baseClasses('section')}>
            <button
              type="submit"
              disabled={submitting || fetching || invalid || disableTrading}
              className={baseClasses('submit-btn')}
            >
              {submitting
                ? context.t('Processing...')
                : context.t(
                    `${buy ? 'Buy' : 'Sell'} ${selectedInstrument.Product1FullName}`
                  )}
            </button>
            {VerificationRequiredComponent}
          </div>
        </div>
      </form>
      <ConfirmOrderModalContainer />
    </div>
  );
};

OrderEntryWithPercentButtonsComponent.defaultProps = {
  submitting: false,
  isMarginActive: false
};

OrderEntryWithPercentButtonsComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  allowBlockTrading: PropTypes.bool,
  openAdvancedOrderSidePane: PropTypes.func.isRequired,
  openReportBlockTradeSidePane: PropTypes.func.isRequired,
  selectedInstrument: instrumentPropType,
  orderEntryForm: PropTypes.object.isRequired,
  marketPrice: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
  fetching: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  disableTrading: PropTypes.bool.isRequired,
  VerificationRequiredComponent: PropTypes.element,
  ConfirmLimitOrderModalContainer: PropTypes.element
};

OrderEntryWithPercentButtonsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default OrderEntryWithPercentButtonsComponent;
