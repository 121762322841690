import React from 'react';
import PropTypes from 'prop-types';
import {
  getTotalRender,
  getAvailableBalance
} from 'apex-web/lib/helpers/withdrawHelper';
import { product } from 'apex-web/lib/propTypes/sendReceiveComponent';
import SendReceiveHeaderComponent from 'apex-web/lib/components/SendReceiveSidePane/SendReceiveHeader/SendReceiveHeaderComponent';
import SendReceiveFormTabsComponent from 'apex-web/lib/components/SendReceiveSidePane/SendReceiveFormTabs/SendReceiveFormTabsComponent';
// import SendFormContainer from 'apex-web/lib/components/SendReceiveSidePane/SendForm/SendFormContainer';
// import ReceiveContainer from 'apex-web/lib/components/SendReceiveSidePane/Receive/ReceiveContainer';
import SendReceiveDetailsComponent from 'apex-web/lib/components/SendReceiveSidePane/SendReceiveDetails/SendReceiveDetailsComponent';
import SendReceiveConfirmModal from 'apex-web/lib/components/SendReceiveSidePane/SendReceiveConfirmModal/SendReceiveConfirmModalComponent';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import SendFormContainer from '../CustomTransferForm/SendFormContainer';
import ReceiveContainer from '../CustomTransferForm/ReceiveContainer';

import './SendReceiveSidePane.css';

const sendReceiveClasses = getBEMClasses('send-receive');

class CustomSendReceiveSidePane extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSend: props.isSend,
      useExternalAddress: true,
      data: {},
      openConfirmModal: false,
      fee: null
    };
  }

  componentDidMount() {
    this.props.onSidePaneOpen({
      customClass: this.props.showDetails
        ? 'retail-sidepane-with-details'
        : 'retail-sidepane',
      hideHeader: true
    });

    this.props.selectPositionAndSave(this.props.position.ProductId);

    if (this.props.verificationLevelConfig.RequireWhitelistedAddress) {
      this.props.fetchAccountWithdrawInfos();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.fee !== prevState.fee) {
      return { fee: nextProps.fee };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.fee !== this.state.fee) {
      this.setTotal();
    }
  }

  toggleUseExternalAddress = () => {
    this.setState({ useExternalAddress: !this.state.useExternalAddress });
  };

  onUpdate = () => {
    this.getWithdrawFee();
    this.setTotal();
  };

  setTotal = () => {
    const { position } = this.props;
    const {
      data: { Amount },
      fee
    } = this.state;
    const balance = getAvailableBalance(position.Amount, position.Hold);
    const amount = parseFloat(Amount);
    let total = 0;

    if (amount) {
      total = getTotalRender(balance, amount, fee);
    }

    this.setState({ total });
  };

  getWithdrawFee = () => {
    const {
      AccountProviderId,
      product: { ProductId },
      getWithdrawFee
    } = this.props;
    const {
      data: { Amount }
    } = this.state;

    if (!isNaN(Amount)) {
      getWithdrawFee(ProductId, Amount, AccountProviderId);
    }
  };

  onDataUpdate = data => {
    const {
      data: { Amount },
      isSend
    } = this.state;
    this.setState({ data }, () => {
      if (isSend && data.Amount !== Amount) {
        this.onUpdate();
      }
    });
  };

  openConfirmModal = data => this.setState({ openConfirmModal: true, data });

  closeConfirmModal = () => this.setState({ openConfirmModal: false });

  onTabChange = () => {
    this.setState({
      isSend: !this.state.isSend,
      data: {},
      useExternalAddress: true
    });
  };

  submitData = () => {
    const {
      receiveFunds,
      sendFunds,
      sendToAddress,
      product: { ProductId },
      template,
      template: { TemplateType },
      selectedAccountId
    } = this.props;
    const {
      isSend,
      useExternalAddress,
      data,
      data: { Notes, ReceiverUsername, Amount }
    } = this.state;

    const payload = {
      Notes: Notes || '',
      ReceiverUsername,
      Amount,
      ProductId,
      selectedAccountId
    };

    const addressPayload = {
      ProductId,
      Amount,
      TemplateType,
      TemplateForm: {
        ...template, // Get all fields
        ...data // Overwrite those with values
      }
    };

    if (isSend) {
      if (useExternalAddress) {
        sendToAddress(addressPayload);
      } else {
        sendFunds(payload);
      }
    } else {
      receiveFunds(payload);
    }
    this.closeConfirmModal();
  };

  render() {
    const {
      product,
      position: { Amount, Hold },
      fee,
      showDetails,
      allowTransfer,
      hideFees,
      TicketAmount,
      verificationLevelConfig
    } = this.props;
    const {
      isSend,
      useExternalAddress,
      data,
      openConfirmModal,
      total
    } = this.state;
    const balance = getAvailableBalance(Amount, Hold);

    return (
      <div className={sendReceiveClasses()}>
        <section className={sendReceiveClasses('main')}>
          <SendReceiveHeaderComponent product={product} />
          <section className={sendReceiveClasses('main-form')}>
            G thang
            <SendReceiveFormTabsComponent
              isSend={isSend}
              toggleTab={this.onTabChange}
            />
            {isSend ? (
              <SendFormContainer
                product={product}
                useExternalAddress={useExternalAddress}
                balance={balance}
                fee={fee}
                total={total}
                showDetails={showDetails}
                toggleUseExternalAddress={this.toggleUseExternalAddress}
                onChange={this.onDataUpdate}
                onSubmit={this.openConfirmModal}
                allowTransfer={allowTransfer}
                verificationLevelConfig={verificationLevelConfig}
              />
            ) : (
              <ReceiveContainer
                product={product}
                useExternalAddress={useExternalAddress}
                toggleUseExternalAddress={this.toggleUseExternalAddress}
                onChange={this.onDataUpdate}
                onSubmit={this.openConfirmModal}
                allowTransfer={allowTransfer}
              />
            )}
          </section>
        </section>

        {showDetails && (
          <section className={sendReceiveClasses('side')}>
            <section
              className={sendReceiveClasses(
                isSend ? 'send-side-details' : 'side-details'
              )}>
              <SendReceiveDetailsComponent
                isSend={isSend}
                useExternalAddress={useExternalAddress}
                details={data}
                balance={balance}
                total={total}
                product={product}
                TicketAmount={TicketAmount}
                fee={fee}
              />
            </section>
          </section>
        )}
        <SendReceiveConfirmModal
          isOpen={openConfirmModal}
          useExternalAddress={useExternalAddress}
          close={this.closeConfirmModal}
          product={product}
          isSend={isSend}
          values={data}
          total={total}
          fee={fee}
          onConfirm={this.submitData}
          hideFees={hideFees}
        />
      </div>
    );
  }
}

CustomSendReceiveSidePane.defaultProps = {
  showDetails: true,
  onSidePaneOpen: () => {},
  isSend: true
};

CustomSendReceiveSidePane.propTypes = {
  allowTransfer: PropTypes.bool.isRequired,
  openRetailSendSidePane: PropTypes.func,
  openRetailReceiveSidePane: PropTypes.func,
  product,
  position: PropTypes.shape({
    AccountId: PropTypes.number,
    Amount: PropTypes.number,
    Hold: PropTypes.number,
    OMSId: PropTypes.number,
    PendingDeposits: PropTypes.number,
    PendingWithdraws: PropTypes.number,
    ProductId: PropTypes.number,
    ProductSymbol: PropTypes.string,
    TotalDayDeposits: PropTypes.number,
    TotalDayWithdraws: PropTypes.number,
    TotalMonthWithdraws: PropTypes.number,
    TotalYearWithdraws: PropTypes.number
  })
};

CustomSendReceiveSidePane.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CustomSendReceiveSidePane;
