import TransfersFiltersComponent from './TransfersFiltersComponent';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { setActivePage } from 'apex-web/lib/redux/actions/paginationActions';

const mapDispatchToProps = dispatch => ({
  resetActivePage: () =>
    dispatch(
      setActivePage({ componentName: 'TransfersComponent', activePage: 0 })
    )
});

const TransfersFiltersForm = reduxForm({
  form: 'transfers-filters-form',
  initialValues: {
    transfersFilters: 'sentTransfers'
  }
})(TransfersFiltersComponent);

export default connect(null, mapDispatchToProps)(TransfersFiltersForm);
