import React, { useEffect, useState } from 'react';
import './NewsITB.css';
import MarketData from './marketData';

const Dcx40Tab = () => {
  const masterAssetList = [
    'algorand',
    'apecoin',
    'avalanche-2',
    'binancecoin',
    'bitcoin',
    'bitcoin-cash',
    'cardano',
    'chainlink',
    'chiliz',
    'cosmos',
    'decentraland',
    'dogecoin',
    'elrond-erd-2',
    'eos',
    'ethereum',
    'ethereum-classic',
    'fantom',
    'filecoin',
    'flow',
    'internet-computer',
    'iota',
    'litecoin',
    'neo',
    'network',
    'pancakeswap',
    'polkadot',
    'matic-network',
    'quant',
    'ravencoin',
    'ripple',
    'shiba-inu',
    'solana',
    'stellar',
    'tether',
    'tezos',
    'the-sandbox',
    'tron',
    'uniswap'
  ];

  const [coinData, setCoinData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const idsParam = masterAssetList.join(',');
        const apiUrl = `https://pro-api.coingecko.com/api/v3/coins/markets?x_cg_pro_api_key=CG-mowbUNKjJk1Htv8p6SNke9x8&vs_currency=aud&ids=${idsParam}`;

        const response = await fetch(apiUrl);

        if (response.ok) {
          const data = await response.json();
          setCoinData(data);

          setIsLoading(false);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.error('error now: ', error);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="gainers-losers">
      <h2 className="dcx40Header">dcx40</h2>
      <ul>
        {coinData.map((coin, index) => (
          <li key={index}>
            <img src={coin.image} alt={coin.name} />
            <span>{coin.name}</span>
            <br />
            <span>
              {coin.current_price >= 0.01
                ? coin.current_price.toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                : coin.current_price.toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                    minimumFractionDigits: 10,
                    maximumFractionDigits: 10
                  })}
            </span>{' '}
            {/* Add more coin data as needed */}
          </li>
        ))}
      </ul>
    </div>
  );
};

const GainersLosersTab = ({ type }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `https://pro-api.coingecko.com/api/v3/coins/top_gainers_losers?x_cg_pro_api_key=CG-mowbUNKjJk1Htv8p6SNke9x8&vs_currency=aud&type=${type}`
          );

          if (response.ok) {
            const result = await response.json();
            const dataList =
              type === 'gainers' ? result.top_gainers : result.top_losers;
            setData(dataList);
            setIsLoading(false);
          } else {
            throw new Error('Request failed');
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    },
    [type]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="gainers-losers">
      <ul>
        {data.map((coin, index) => (
          <li key={index}>
            <img src={coin.image} alt={coin.name} />
            <span>{coin.name}</span>
            <br />
            <span>{`${coin.aud_24h_change.toFixed(2)}%`}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const NewsITB = () => {
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState('gainers'); // Added tab state and default to "gainers"
  const [selectedToken, setSelectedToken] = useState('BTC'); // Default token ID is "ETH"

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const year = yesterday.getFullYear();
        const month = String(yesterday.getMonth() + 1).padStart(2, '0');
        const day = String(yesterday.getDate()).padStart(2, '0');

        const dateString = `${year}-${month}-${day}`;
        const response = await fetch(
          `https://api.intotheblock.com/1/social/news?since=${dateString}`,
          {
            headers: {
              'x-api-key': 'VUtPdl9xJQLdpQmVUm1E6b2WBSjZxu047M2amfy9'
            }
          }
        );

        if (response.ok) {
          const data = await response.json();
          setNews(data);
          setIsLoading(false);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleTokenChange = event => {
    setSelectedToken(event.target.value);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="newsItbContainer newsItbContainer--full">
        <h2>Crypto news</h2>

        {news?.news?.map((article, index) => {
          return (
            <div key={index}>
              <ul>
                {article.topics.map((topic, index) => (
                  <li key={index}>
                    {/* {topic.context.map((context, contextIndex) => (
                      <div key={contextIndex}>
                        <a target="_blank" href={context.url}>
                          {context.title}
                        </a>
                      </div>
                    ))} */}

                    <div key={'1'}>
                      <a target="_blank" href={topic.context[0].url}>
                        {topic.context[0].title}
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>

      <div className="dashpageContainer all:unset">
        <MarketData />
      </div>
    </>
  );
};

export default NewsITB;
