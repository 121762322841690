import React from 'react';
import PropTypes from 'prop-types';
import APButton from 'apex-web/lib/components/common/APButton';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import ContentWithFooterLayoutTemplate from 'apex-web/lib/components/common/LayoutTemplates/ContentWithFooterLayoutTemplate/ContentWithFooterLayoutTemplate';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { chartEnums } from './ChartEnums';

import './ThemeConfirmComponent.css';

const baseClasses = getBEMClasses('theme-confirm');

const ThemeConfirm = (props, context) => {
  const { doSubmit, goToStep, formValues } = props;

  const update = () => {
    doSubmit();
    goToStep(0);
  };

  const footer = (
    <APButton customClass={baseClasses()} onClick={update} type="button">
      {context.t('Update')}
    </APButton>
  );

  return (
    <div className={baseClasses()}>
      <ContentWithFooterLayoutTemplate
        footer={footer}
        customClass={`${baseClasses()}-cwf-lt`}
      >
        <React.Fragment>
          <div className={baseClasses('confirm-block')}>
            {context.t('Confirm')}
          </div>

          <APLabelWithText
            label={context.t('Color Theme')}
            text={context.t(formValues.theme)}
            customClass={baseClasses()}
          />

          <APLabelWithText
            label={context.t('Chart')}
            text={context.t(chartEnums[formValues.chart])}
            customClass={baseClasses()}
          />
        </React.Fragment>
      </ContentWithFooterLayoutTemplate>
    </div>
  );
};

ThemeConfirm.propTypes = {
  formValues: PropTypes.object.isRequired
};

ThemeConfirm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ThemeConfirm;
