import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import { connect } from 'react-redux';
import { productIcons } from '../utils/Icons';
import { LoaderSvg } from '../utils/Loader';
import TradingTabs from '../dcxComponents/TradingTabs';

import { createUserAcct } from '../dcxApi';

import axios from 'axios';

import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import {
  BuySellContainer,
  BuySellMakeTransactionContainer,
  BuySellBalancesContainer,
  BuySellOrderOverviewContainer
} from 'apex-web/lib/components/Retail/RetailBuySell';
import 'apex-web/lib/layouts/Responsive/EotcBuySellLayout/EotcBuySellDesktopLayout.css';

import PageFooterLayout from '../layouts/PageFooterLayout/PageFooterLayout';

import './BuySellPage.css';

const classes = getBEMClasses('eotc-buy-sell-layout');

const formatMarketCap = value => {
  if (typeof value !== 'number') {
    return '-';
  }

  if (value >= 1e12) {
    // Convert to trillions with two decimal places
    return (value / 1e12).toFixed(2) + 'T';
  } else if (value >= 1e9) {
    // Convert to billions with two decimal places
    return (value / 1e9).toFixed(2) + 'B';
  } else if (value >= 1e6) {
    // Convert to millions with two decimal places
    return (value / 1e6).toFixed(2) + 'M';
  } else {
    // No conversion needed for values below a million
    return value.toFixed(2);
  }
};

const formatVolume = value => {
  if (typeof value === 'number') {
    // Round the value to the nearest dollar
    return Math.round(value).toLocaleString();
  } else {
    return '-';
  }
};

const formatChange24hr = value => {
  if (typeof value === 'number') {
    const formattedValue = value.toFixed(1); // Format to one decimal place
    return value >= 0 ? (
      <span style={{ color: 'green' }}>{formattedValue}%</span>
    ) : (
      <span style={{ color: 'red' }}>{formattedValue}%</span>
    );
  } else {
    return '-';
  }
};

const CoinTable = ({ products, positions, level1, dispatch }) => {
  const [coinData, setCoinData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchCoinData = async () => {
      const coinDataArray = [];
      const coinGeckoIds = [];

      // Build an array of coinGeckoIds for all products
      for (const product of products) {
        if (product.ProductFullName === 'AUD') {
          continue;
        }

        const coingeckoId = getCoinGeckoIdFromProduct(product);
        if (coingeckoId) {
          coinGeckoIds.push(coingeckoId);
        }
      }

      // Make a single request to fetch data for all coinGeckoIds
      try {
        const response = await axios.get(
          `https://pro-api.coingecko.com/api/v3/coins/markets?x_cg_pro_api_key=CG-mowbUNKjJk1Htv8p6SNke9x8&ids=${coinGeckoIds.join(
            ','
          )}&vs_currency=aud&order=market_cap_desc&per_page=${
            coinGeckoIds.length
          }&page=1&sparkline=false`
        );

        // Process the response and populate coinDataArray
        for (const coinData of response.data) {
          const marketCap = coinData.market_cap;
          const volume = coinData.total_volume;
          const change24hr = coinData.price_change_percentage_24h;

          // Find the corresponding product based on coinGeckoId
          const product = products.find(
            p => getCoinGeckoIdFromProduct(p) === coinData.id
          );

          coinDataArray.push({ product, marketCap, volume, change24hr });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      setCoinData(coinDataArray);
      setLoading(false);
    };

    fetchCoinData();
  }, [products]);

  const getCoinGeckoIdFromProduct = product => {
    // Map product to CoinGecko's coin ID using switch case
    switch (product.Product) {
      case 'BTC':
        return 'bitcoin';
      case 'ETH':
        return 'ethereum';
      case 'USDC':
        return 'usd-coin';
      case 'BNB':
        return 'binancecoin';
      case 'XRP':
        return 'ripple';
      case 'ADA':
        return 'cardano';
      case 'SOL':
        return 'solana';
      case 'DOT':
        return 'polkadot';
      case 'AVAX':
        return 'avalanche-2';
      case 'TRX':
        return 'tron';
      case 'SHIB':
        return 'shiba-inu';
      case 'Matic':
        return 'matic-network';
      case 'LTC':
        return 'litecoin';
      case 'NEAR':
        return 'near';
      case 'UNI':
        return 'uniswap';
      case 'BCH':
        return 'bitcoin-cash';
      case 'LINK':
        return 'chainlink';
      case 'XLM':
        return 'stellar';
      case 'ATOM':
        return 'cosmos';
      case 'ALGO':
        return 'algorand';
      case 'ETC':
        return 'ethereum-classic';
      case 'APE':
        return 'apecoin';
      case 'MANA':
        return 'decentraland';
      case 'SAND':
        return 'the-sandbox';
      case 'XTZ':
        return 'tezos';
      case 'MKR':
        return 'maker';
      case 'EOS':
        return 'eos';
      case 'AXS':
        return 'axie-infinity';
      case 'AAVE':
        return 'aave';
      case 'FTM':
        return 'fantom';
      case 'GRT':
        return 'the-graph';
      default:
        return null;
    }
  };

  const handleScrollToSelect = index => {
    const selectElement = document.querySelector(
      '.form-control.ap-select__select.buy-sell__select'
    );

    const formElement = document.querySelector(
      '.eotc-buy-sell-layout__make-transaction-col-1'
    );

    if (selectElement) {
      formElement.scrollIntoView();

      // Update the selected option relative to the row index + 1
      selectElement.value = index.toString();

      // Trigger a change event if needed
      const event = new Event('change', { bubbles: true });
      selectElement.dispatchEvent(event);
    }
  };

  const handleBuyClick = index => {
    handleScrollToSelect(index);

    dispatch({
      type: '@@redux-form/CHANGE',
      meta: {
        form: 'buySell',
        field: 'limitPrice'
      },
      payload: ''
    });

    dispatch({
      type: '@@redux-form/UNTOUCH',
      meta: {
        form: 'buySell',
        fields: ['limitPrice']
      }
    });

    dispatch({
      type: '@@redux-form/CHANGE',
      meta: {
        form: 'buySell',
        field: 'fee'
      },
      payload: '0'
    });

    dispatch({
      type: '@@redux-form/UNTOUCH',
      meta: {
        form: 'buySell',
        fields: ['fee']
      }
    });

    dispatch({
      type: '@@redux-form/CHANGE',
      meta: {
        form: 'buySell',
        field: 'responseMessage'
      },
      payload: ''
    });

    dispatch({
      type: '@@redux-form/CHANGE',
      meta: {
        form: 'buySell',
        field: 'side'
      },
      payload: '0'
    });
  };

  const handleSellClick = index => {
    handleScrollToSelect(index);

    dispatch({
      type: '@@redux-form/CHANGE',
      meta: {
        form: 'buySell',
        field: 'limitPrice'
      },
      payload: ''
    });

    dispatch({
      type: '@@redux-form/UNTOUCH',
      meta: {
        form: 'buySell',
        fields: ['limitPrice']
      }
    });

    dispatch({
      type: '@@redux-form/CHANGE',
      meta: {
        form: 'buySell',
        field: 'fee'
      },
      payload: '0'
    });

    dispatch({
      type: '@@redux-form/UNTOUCH',
      meta: {
        form: 'buySell',
        fields: ['fee']
      }
    });

    dispatch({
      type: '@@redux-form/CHANGE',
      meta: {
        form: 'buySell',
        field: 'responseMessage'
      },
      payload: ''
    });

    dispatch({
      type: '@@redux-form/CHANGE',
      meta: {
        form: 'buySell',
        field: 'side'
      },
      payload: '1'
    });
  };

  return (
    <div className="buysellCoinTable">
      {/* <table>
        <thead>
          <tr>
            <th>Coin</th>
            <th>Bid</th>
            <th>Ask</th>
            <th>Market Cap</th>
            <th>Volume</th>
            <th>Change 24hr</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => {
            if (product.ProductFullName === "AUD") {
              return null;
            }

            const position = positions[product.ProductId] || {};
            const level = level1[product.ProductId - 1] || {};

            const coinInfo = coinData.find(
              (data) => data.product.ProductId === product.ProductId
            );

            const iconData = productIcons[product.Product];
            const productIcon = iconData
              ? `/local/product-icons/${iconData.iconFileName}`
              : "";

            return (
              <tr key={product.ProductId}>
                <td>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {productIcon && (
                      <img
                        style={{
                          width: "28px",
                          height: "28px",
                          marginRight: "10px",
                        }}
                        src={productIcon}
                        alt={product.Product}
                      />
                    )}
                    {product.Product}
                  </div>
                </td>
                <td>${level.BestBid || "-"}</td>
                <td>${level.BestOffer || "-"}</td>

                <td>${coinInfo ? formatMarketCap(coinInfo.marketCap) : "-"}</td>
                <td>${coinInfo ? formatVolume(coinInfo.volume) : "-"}</td>
                <td>
                  {coinInfo ? formatChange24hr(coinInfo.change24hr) : "-"}
                </td>
                <td>
                  <button onClick={() => handleBuyClick(index)}>Buy</button>
                  <button
                    className="sellButtonBuySell"
                    onClick={() => handleSellClick(index)}
                  >
                    Sell
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> */}
    </div>
  );
};

const BuySellPage = ({ product, positions, level1 }, context) => {
  return (
    <React.Fragment>
      {process.env.COINGECKO_KEY}

      <div className={classes('accent-bar')} />
      <div className={classes('container')}>
        <ConnectedCoinTable
          products={product.products}
          positions={positions}
          level1={level1}
        />
      </div>

      <div className={classes('container')}>
        <div className={classes('col', ['lg'])}>
          <div
            className={classes(
              'make-transaction-container container-with-shadow'
            )}
          >
            <div className={classes('make-transaction-col-1 ')}>
              <BuySellMakeTransactionContainer>
                <BuySellContainer />
              </BuySellMakeTransactionContainer>
            </div>

            <div className={classes('make-transaction-col-2')}>
              <div className={classes('order-overview-container')}>
                <BuySellOrderOverviewContainer displayPricePerUnit />
              </div>

              <hr className={classes('right-panel-divider')} />

              <div className={classes('balances-container ')}>
                <div className={classes('balances-title')}>
                  {context.t('Balances')}
                </div>
                <BuySellBalancesContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes('footer')}>
        <PageFooterLayout />
      </div>
    </React.Fragment>
  );
};

BuySellPage.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ product, position, level1 }) => {
  return { product, positions: position.positions, level1 };
};

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

const ConnectedCoinTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(CoinTable);

export default withAuthentication(connect(mapStateToProps)(BuySellPage));
