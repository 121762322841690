import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import APTable from 'apex-web/lib/components/common/APTable';
// import APButton from 'apex-web/lib/components/common/APButton';
// import { styleNames } from 'apex-web/lib/propTypes/commonComponent';
// import { formatDate, formatTime } from 'apex-web/lib/helpers/dateHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

import './TransferComponents.css';

const baseClasses = getBEMClasses('dcx-transfers');

const TransfersComponent = (props, context) => {
  const getAmountRow = ({ Amount, product }) =>
    getMultilineCell(
      formatNumberToLocale(Amount, product.DecimalPlaces),
      product.ProductFullName
    );

  // const getDateRow = ({ CreatedTimestamp }) =>
  //   getMultilineCell(
  //     formatDate(CreatedTimestamp),
  //     formatTime(CreatedTimestamp)
  //   );

  const getMultilineCell = (primarytext, secondaryText) => (
    <div className={baseClasses('multiline-col')}>
      <div className={baseClasses('primary-text')}>{primarytext}</div>
      <div className={baseClasses('secondary-text')}>{secondaryText}</div>
    </div>
  );

  const getCell = field => row => (
    <div className={baseClasses('primary-text')}>{row[field]}</div>
  );

  // const getActionButtons = row => {
  //   if (row.Status === 'TransferCompleted' || row.Status === 'PayerRejected') {
  //     return null;
  //   }

  //   return (
  //     <div>
  //       <APButton
  //         styleName={styleNames.additive}
  //         onClick={() => allowRequest(row)}
  //       >
  //         {context.t('Approve')}
  //       </APButton>
  //       &nbsp;
  //       <APButton
  //         styleName={styleNames.subtractive}
  //         onClick={() => rejectRequest(row)}
  //       >
  //         {context.t('Reject')}
  //       </APButton>
  //     </div>
  //   );
  // };

  const getTransfersColumns = () => [
    {
      header: context.t('Action'),
      classModifier: 'transfers-action',
      cell: row => {
        const { selectedFilter } = props;
        const isSent = selectedFilter === 'sentTransfers';
        const sent = `${context.t('Sent')} ${row.product.ProductFullName}`;
        const received = `${context.t('Received')} ${
          row.product.ProductFullName
        }`;
        const from = `${context.t('From')} ${row.SenderUserName}`;
        const to = `${context.t('To')} ${row.ReceiverUserName}`;

        return isSent
          ? getMultilineCell(sent, to)
          : getMultilineCell(received, from);
      }
    },
    {
      header: context.t('Amount'),
      cell: getAmountRow,
      classModifier: 'amount'
    },
    {
      header: context.t('Note'),
      cell: getCell('Notes')
    }
  ];

  // const allowRequest = req => {
  //   props.approveTransferRequest(req.RequestCode);
  // };

  // const rejectRequest = req => {
  //   props.rejectTransferRequest(req.RequestCode);
  // };

  // const getRequestsSentColumns = () => [
  //   {
  //     header: context.t('Recipient'),
  //     classModifier: 'recipient',
  //     cell: row =>
  //       getMultilineCell(
  //         `${context.t('You requested to Receive')} ${
  //           row.product.ProductFullName
  //         }`,
  //         `${context.t('To')} ${row.PayerUsername}`
  //       )
  //   },
  //   {
  //     header: context.t('Amount'),
  //     classModifier: 'amount',
  //     cell: getAmountRow
  //   },
  //   {
  //     header: context.t('Time'),
  //     classModifier: 'time',
  //     cell: getDateRow
  //   },
  //   {
  //     header: context.t('Note'),
  //     classModifier: 'note',
  //     cell: getCell('Notes')
  //   },
  //   {
  //     header: context.t('Status'),
  //     classModifier: 'status',
  //     cell: getCell('Status')
  //   }
  // ];

  // const getRequestsReceivedColumns = () => [
  //   {
  //     header: context.t('Sent By'),
  //     classModifier: 'sent-by',
  //     cell: row =>
  //       getMultilineCell(
  //         `${context.t('Request for you to send')} ${
  //           row.product.ProductFullName
  //         }`,
  //         `${context.t('From')} ${row.RequestorUsername}`
  //       )
  //   },
  //   {
  //     header: context.t('Amount'),
  //     classModifier: 'amount',
  //     cell: getAmountRow
  //   },
  //   {
  //     header: context.t('Time'),
  //     classModifier: 'time',
  //     cell: getDateRow
  //   },
  //   {
  //     header: context.t('Note'),
  //     classModifier: 'note',
  //     cell: getCell('Notes')
  //   },
  //   {
  //     header: context.t('Status'),
  //     classModifier: 'status',
  //     cell: getCell('Status')
  //   },
  //   {
  //     header: context.t('Action'),
  //     cell: getActionButtons
  //   }
  // ];

  const getColumns = selectedFilter => {
    const columnsMethods = {
      sentTransfers: getTransfersColumns,
      receivedTransfers: getTransfersColumns
      // sentRequests: getRequestsSentColumns,
      // receivedRequests: getRequestsReceivedColumns
    };

    return columnsMethods[selectedFilter]();
  };

  const { usePagination, selectedFilter } = props;
  const columns = getColumns(selectedFilter);
  const items = props[selectedFilter];

  return (
    <div className={baseClasses('wrapper')}>
      <APTable
        {...{
          componentName: 'TransfersComponent',
          columns,
          pageSize: 7,
          minRow: 7,
          rows: items,
          baseClass: baseClasses,
          headerOutside: true,
          usePagination,
          empty: context.t('No data is available')
        }}
      />
    </div>
  );
};

TransfersComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default TransfersComponent;
