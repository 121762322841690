import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { getBEMClasses } from '../../../../helpers/cssClassesHelper';
import './LineChart.css';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import BigNumber from 'bignumber.js';

const baseClasses = getBEMClasses('portfolio-line-chart');

const PERIODS = [
  { title: '1D', value: 'day' },
  { title: '1W', value: 'week' },
  { title: '1M', value: 'month' },
  { title: '1Y', value: 'year' },
  { title: 'ALL', value: 'all' }
];

const LineChart = ({ accountTransactions, period, setPeriod }) => {
  const { transactionsInBaseCurrency, baseCurrencyDecimalPlace } =
    accountTransactions;
  const chartData = transactionsInBaseCurrency.map(item =>
    item.amountInBaseCurrency.toFixed(2)
  );
  const totalValue = transactionsInBaseCurrency?.length
    ? formatNumberToLocale(
        transactionsInBaseCurrency[transactionsInBaseCurrency.length - 1]
          ?.amountInBaseCurrency,
        baseCurrencyDecimalPlace
      )
    : 0;
  const lastValue = BigNumber(
    transactionsInBaseCurrency?.length
      ? transactionsInBaseCurrency[transactionsInBaseCurrency.length - 1]
          ?.amountInBaseCurrency || 0
      : 0
  );
  const firstValue = BigNumber(
    transactionsInBaseCurrency?.length
      ? transactionsInBaseCurrency[0]?.amountInBaseCurrency || 0
      : 0
  );

  const totalPercent =
    firstValue.isEqualTo(BigNumber(0)) && lastValue.isEqualTo(BigNumber(0))
      ? BigNumber(0)
      : lastValue
          .minus(firstValue)
          .div(firstValue.plus(lastValue).div(2))
          .times(100);
  const isMinusPercent = totalPercent.lt(BigNumber(0));

  const timeChart = {
    series: [
      {
        name: 'Value',
        data: chartData
      }
    ],

    options: {
      chart: {
        height: 350,
        type: 'area',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 1,
          gradientToColors: ['#EBFAFC'],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        },
        colors: ['#C5EEF5']
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2,
        colors: ['#00AAD2']
      },
      grid: {
        row: {
          colors: ['transparent'],
          opacity: 0.5
        }
      },
      yaxis: {
        labels: {
          show: false
        },
        tickAmount: 20
      },
      xaxis: {
        labels: {
          show: false
        }
      }
    }
  };
  return (
    <div id="chart" className={baseClasses('')}>
      <div className={baseClasses('total-wrapper')}>
        <p className={baseClasses('total-title')}>Total value</p>
        <p className={baseClasses('total-value')}>${totalValue}</p>
        <div className={baseClasses('total-percent-wrapper')}>
          <p
            className={baseClasses(
              'total-percent-arrow',
              isMinusPercent && 'minus'
            )}
          >
            ↑
          </p>
          <p className={baseClasses('total-percent')}>
            {!isMinusPercent ? '+' : ''}
            {totalPercent.toFixed(2)}%
          </p>
        </div>
      </div>
      <ReactApexChart
        options={timeChart.options}
        series={timeChart.series}
        height={350}
        type="area"
      />
      <div className={baseClasses('periods')}>
        {PERIODS.map(item => (
          <div
            key={item.title}
            className={baseClasses(
              'period',
              period === item.value && 'selected'
            )}
            onClick={() => setPeriod(item.value)}
          >
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LineChart;
