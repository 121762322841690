import React from 'react';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import CreateApiKeyContainer from 'apex-web/lib/components/ApiKeysSidePaneComponents/CreateApiKey/CreateApiKeyContainer';
import NewApiKeyModalContainer from 'apex-web/lib/components/ApiKeysSidePaneComponents/NewApiKey/NewApiKeyModalContainer';
import './ApiKeysModal.css';

const ApiKeysModalComponent = props => {
  const { isOpen, close } = props;
  return (
    <Modal isOpen={isOpen} close={close} customClass="api-keys-modal">
      <CreateApiKeyContainer />
      <NewApiKeyModalContainer />
    </Modal>
  );
};

export default ApiKeysModalComponent;
