import React from 'react';
import PropTypes from 'prop-types';
import PaddedDecimal from 'apex-web/lib/components/common/PaddedDecimal/PaddedDecimal';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

const orderBookClasses = getBEMClasses('dcx-orderbook');
const flexTable = getBEMClasses('flex-table');
const flexTableColumn = flexTable('column');

const Level2DataTableComponent = props => {
  const { onPriceClick, onQuantityClick, level2Data, classModifier, fetching } =
    props;

  const rowClassName = `${flexTable('row')} ${orderBookClasses(
    'order-row',
    classModifier
  )}`;
  const priceClassName = `${flexTableColumn} ${orderBookClasses(
    'table-price',
    classModifier
  )}`;
  const quantityClassName = `${flexTableColumn} ${orderBookClasses(
    'table-qty',
    classModifier
  )}`;

  if (fetching) {
    return (
      <Spinner customClass={orderBookClasses} classModifiers={classModifier} />
    );
  } else {
    return level2Data.map((data, index) => (
      <div key={data.Price} className={rowClassName}>
        <div
          data-price={data.Price}
          onClick={onPriceClick}
          className={priceClassName}
        >
          <PaddedDecimal
            bemMod={classModifier}
            value={data.Price}
            decimals={props.priceDecimalPlaces}
          />
        </div>
        <div
          data-quantity={data.Quantity}
          data-index={index}
          onClick={onQuantityClick}
          className={quantityClassName}
        >
          <PaddedDecimal
            value={data.Quantity}
            decimals={props.quantityDecimalPlaces}
          />
        </div>
      </div>
    ));
  }
};

Level2DataTableComponent.propTypes = {
  onPriceClick: PropTypes.func.isRequired,
  onQuantityClick: PropTypes.func.isRequired,
  level2Data: PropTypes.array.isRequired,
  classModifier: PropTypes.string,
  fetching: PropTypes.bool
};

export default Level2DataTableComponent;
