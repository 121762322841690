import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  getTotalRender,
  getAvailableBalance
} from 'apex-web/lib/helpers/withdrawHelper';
import { product } from 'apex-web/lib/propTypes/sendReceiveComponent';
import SendReceiveHeaderComponent from './SendReceiveHeader/SendReceiveHeaderComponent';
import SendReceiveFormTabsComponent from './SendReceiveFormTabs/SendReceiveFormTabsComponent';
import SendFormContainer from './SendForm/SendFormContainer';
import ReceiveContainer from './Receive/ReceiveContainer';
import SendReceiveDetailsComponent from './SendReceiveDetails/SendReceiveDetailsComponent';
import SendReceiveConfirmModal from './SendReceiveConfirmModal/SendReceiveConfirmModalComponent';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

import './SendReceive.css';

const sendReceiveClasses = getBEMClasses('dcx-send-receive');

const SendReceive = props => {
  const {
    selectPositionAndSave,
    verificationLevelConfig,
    fetchAccountWithdrawInfos,
    product,
    product: { ProductId },
    position,
    position: { Amount, Hold },
    fee,
    showDetails,
    allowTransfer,
    hideFees,
    TicketAmount,
    receiveFunds,
    sendFunds,
    sendToAddress,
    template,
    template: { TemplateType },
    selectedAccountId,
    AccountProviderId
  } = props;
  const [isSend, setIsSend] = useState(props.isSend);
  const [useExternalAddress, setUseExternalAddress] = useState(true);
  const [data, setData] = useState({});
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [total, setTotalValue] = useState(0);

  useEffect(() => {
    selectPositionAndSave(position.ProductId);

    if (verificationLevelConfig.RequireWhitelistedAddress) {
      fetchAccountWithdrawInfos();
    }
  }, [position, verificationLevelConfig]);

  useEffect(() => {
    if (fee) {
      setTotal();
    }
  }, [fee]);

  const toggleUseExternalAddress = () => {
    setUseExternalAddress(!useExternalAddress);
  };

  const onUpdate = updatedData => {
    getWithdrawFee(updatedData);
    setTotal(updatedData);
  };

  const setTotal = updatedData => {
    const balance = getAvailableBalance(position.Amount, position.Hold);
    const amount = parseFloat(updatedData?.Amount || data?.Amount);
    let total = 0;

    if (amount) {
      total = getTotalRender(balance, amount, fee);
    }

    setTotalValue(total);
  };

  const getWithdrawFee = updatedData => {
    if (!isNaN(updatedData?.Amount)) {
      props.getWithdrawFee(ProductId, updatedData?.Amount, AccountProviderId);
    }
  };

  const onDataUpdate = updatedData => {
    setData(updatedData);

    if (isSend) {
      onUpdate(updatedData);
    }
  };

  const openConfirmModal = data => {
    setIsConfirmModalOpen(true);
    setData(data);
  };

  const closeConfirmModal = () => setIsConfirmModalOpen(false);

  const onTabChange = () => {
    setIsSend(!isSend);
    setData({});
    setUseExternalAddress(true);
  };

  const submitData = () => {
    const { Notes, ReceiverUsername, Amount } = data;

    const payload = {
      Notes: Notes || '',
      ReceiverUsername,
      Amount,
      ProductId,
      selectedAccountId
    };

    const addressPayload = {
      ProductId,
      Amount,
      TemplateType,
      TemplateForm: {
        ...template, // Get all fields
        ...data // Overwrite those with values
      }
    };

    if (isSend) {
      if (useExternalAddress) {
        sendToAddress(addressPayload);
      } else {
        sendFunds(payload);
      }
    } else {
      receiveFunds(payload);
    }
    closeConfirmModal();
  };

  const balance = getAvailableBalance(Amount, Hold);

  return (
    <div className={sendReceiveClasses()}>
      <section className={sendReceiveClasses('main')}>
        <SendReceiveHeaderComponent product={product} />
        <section className={sendReceiveClasses('main-form')}>
          <SendReceiveFormTabsComponent
            isSend={isSend}
            toggleTab={onTabChange}
          />
          {isSend ? (
            <SendFormContainer
              product={product}
              useExternalAddress={useExternalAddress}
              balance={balance}
              fee={fee}
              total={total}
              showDetails={showDetails}
              toggleUseExternalAddress={toggleUseExternalAddress}
              onChange={onDataUpdate}
              onSubmit={openConfirmModal}
              allowTransfer={allowTransfer}
              verificationLevelConfig={verificationLevelConfig}
            />
          ) : (
            <ReceiveContainer
              product={product}
              useExternalAddress={useExternalAddress}
              toggleUseExternalAddress={toggleUseExternalAddress}
              onChange={onDataUpdate}
              onSubmit={openConfirmModal}
              allowTransfer={allowTransfer}
            />
          )}
        </section>
      </section>

      {showDetails && (
        <section className={sendReceiveClasses('side')}>
          <section
            className={sendReceiveClasses(
              isSend ? 'send-side-details' : 'side-details'
            )}
          >
            <SendReceiveDetailsComponent
              isSend={isSend}
              useExternalAddress={useExternalAddress}
              details={data}
              balance={balance}
              product={product}
              TicketAmount={TicketAmount}
              fee={fee}
            />
          </section>
        </section>
      )}
      <SendReceiveConfirmModal
        isOpen={isConfirmModalOpen}
        useExternalAddress={useExternalAddress}
        close={closeConfirmModal}
        product={product}
        isSend={isSend}
        values={data}
        fee={fee}
        onConfirm={submitData}
        hideFees={hideFees}
        TicketAmount={TicketAmount || data?.Amount}
      />
    </div>
  );
};

SendReceive.defaultProps = {
  showDetails: true,
  onSidePaneOpen: () => {},
  isSend: true
};

SendReceive.propTypes = {
  allowTransfer: PropTypes.bool.isRequired,
  openRetailSendSidePane: PropTypes.func,
  openRetailReceiveSidePane: PropTypes.func,
  product,
  position: PropTypes.shape({
    AccountId: PropTypes.number,
    Amount: PropTypes.number,
    Hold: PropTypes.number,
    OMSId: PropTypes.number,
    PendingDeposits: PropTypes.number,
    PendingWithdraws: PropTypes.number,
    ProductId: PropTypes.number,
    ProductSymbol: PropTypes.string,
    TotalDayDeposits: PropTypes.number,
    TotalDayWithdraws: PropTypes.number,
    TotalMonthWithdraws: PropTypes.number,
    TotalYearWithdraws: PropTypes.number
  })
};

export default SendReceive;
