export const productIcons = {
  BTC: { iconFileName: 'btc-48px.svg', productColor: '#EA973D' },
  BTC_TEST: { iconFileName: 'btc-48px.svg', productColor: '#EA973D' },
  USD: { iconFileName: 'usd-48px.svg', productColor: '#8ADB44' },
  ETH: { iconFileName: 'eth-48px.svg', productColor: '#677FE3' },
  LTC: { iconFileName: 'ltc-48px.svg', productColor: '#CAC6C6' },
  BCC: { iconFileName: 'bcc-48px.svg', productColor: '#EA973E' },
  XRP: { iconFileName: 'xrp-48px.svg', productColor: '#4BA8DF' },
  USDT: { iconFileName: 'usdt-48px.svg', productColor: '#4F9E7D' },
  XMR: { iconFileName: 'xmr-48px.svg', productColor: '#ED6F2E' },
  ZEC: { iconFileName: 'zec-48px.svg', productColor: '#E3B458' },
  DASH: { iconFileName: 'dash-48px.svg', productColor: '#3974B7' },
  OMG: { iconFileName: 'omg-48px.svg', productColor: '#2A55E7' },
  DOGE: { iconFileName: 'doge-48px.svg', productColor: '#BEA64A' },
  EUR: { iconFileName: 'eur-48px.svg', productColor: '#408EF1' },
  EOS: { iconFileName: 'eos-48px.svg', productColor: '#000000' },
  REP: { iconFileName: 'rep-48px.svg', productColor: '#592E50' },
  CVC: { iconFileName: 'cvc-48px.svg', productColor: '#5EAD4E' },
  BCH: { iconFileName: 'bch-48px.svg', productColor: '#8dc252' },
  XLM: { iconFileName: 'xlm-48px.svg', productColor: '#408EF1' },
  CAD: { iconFileName: 'cad-48px.svg', productColor: '#8ADB44' },
  JPY: { iconFileName: 'jpy-48px.svg', productColor: '#8ADB44' },
  ALGO: { iconFileName: 'algo.svg', productColor: '#8ADB44' },
  APE: { iconFileName: 'ape.svg', productColor: '#8ADB44' },
  APT: { iconFileName: 'apt.svg', productColor: '#8ADB44' },
  AVAX: { iconFileName: 'avax.svg', productColor: '#8ADB44' },
  BNB: { iconFileName: 'bnb.svg', productColor: '#8ADB44' },
  ADA: { iconFileName: 'ada.svg', productColor: '#8ADB44' },
  LINK: { iconFileName: 'link.svg', productColor: '#8ADB44' },
  CHZ: { iconFileName: 'chz.svg', productColor: '#8ADB44' },
  ATOM: { iconFileName: 'atom.svg', productColor: '#8ADB44' },
  MANA: { iconFileName: 'mana.svg', productColor: '#8ADB44' },
  ETC: { iconFileName: 'etc.svg', productColor: '#8ADB44' },
  FTM: { iconFileName: 'ftm.svg', productColor: '#8ADB44' },
  FIL: { iconFileName: 'fil.svg', productColor: '#8ADB44' },
  FLOW: { iconFileName: 'flow.svg', productColor: '#8ADB44' },
  ICP: { iconFileName: 'icp.svg', productColor: '#8ADB44' },
  DAO: { iconFileName: 'dao.svg', productColor: '#8ADB44' },
  MIOTA: { iconFileName: 'miota.svg', productColor: '#8ADB44' },
  NEAR: { iconFileName: 'near.svg', productColor: '#8ADB44' },
  NEO: { iconFileName: 'neo.svg', productColor: '#8ADB44' },
  CAKE: { iconFileName: 'cake.svg', productColor: '#8ADB44' },
  DOT: { iconFileName: 'dot.svg', productColor: '#8ADB44' },
  Matic: { iconFileName: 'matic.svg', productColor: '#8ADB44' },
  QNT: { iconFileName: 'qnt.svg', productColor: '#8ADB44' },
  RVN: { iconFileName: 'rvn.svg', productColor: '#8ADB44' },
  SHIB: { iconFileName: 'shib.svg', productColor: '#8ADB44' },
  SOL: { iconFileName: 'sol.svg', productColor: '#8ADB44' },
  XTZ: { iconFileName: 'xtz.svg', productColor: '#8ADB44' },
  SAND: { iconFileName: 'sand.svg', productColor: '#8ADB44' },
  TRX: { iconFileName: 'trx.svg', productColor: '#8ADB44' },
  UNI: { iconFileName: 'uni.svg', productColor: '#8ADB44' },
  USDC: { iconFileName: 'usdc.svg', productColor: '#8ADB44' },
  MKR: { iconFileName: 'marker.svg', productColor: '#8ADB44' },
  AXS: { iconFileName: 'axs.svg', productColor: '#8ADB44' },
  AAVE: { iconFileName: 'aave.svg', productColor: '#8ADB44' },
  GRT: { iconFileName: 'grt.svg', productColor: '#8ADB44' }
};
