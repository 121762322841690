import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import * as level1DataProp from 'apex-web/lib/propTypes/level1Data';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { calculateRolling24HrPxChangeValue } from 'apex-web/lib/helpers/level1Helper';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

import './MarketDataTable.css';

const marketDataItemClasses = getBEMClasses('market-data-table');

const formatToAud = value => {
  return formatNumberToLocale(value, 'AUD');
};

const formatPercentageValue = value => {
  return `${parseFloat(value).toFixed(2)}%`;
};

const MarketDataTable = (props, context) => {
  const {
    product1,
    product2,
    instrument,
    price,
    change24hr,
    volume,
    coin,
    sessionHigh,
    sessionLow,
    bid,
    ask
  } = props;

  console.log('Product 1:', product1);

  return (
    <div className={marketDataItemClasses()}>
      <div className={marketDataItemClasses('')}>
        {product1 && product2 && (
          <div className={marketDataItemClasses('product')}>
            {/* {`${product1.ProductFullName} / ${product2.ProductFullName}`} */}
            {/* {`${product1.ProductSymbol}/${product2.ProductSymbol}`} */}
            <div>
              <span>{`${product1}`}</span>
            </div>
            <div>
              <span>{`${product2}`}</span>
            </div>
          </div>
        )}
      </div>
      <div className={marketDataItemClasses('row')}>
        <div className={marketDataItemClasses('price-data')}>
          <p className={marketDataItemClasses('key')}>Last price</p>
          <p className={marketDataItemClasses('value')}>
            ${formatToAud(price)}
          </p>
        </div>
      </div>
      <div className={marketDataItemClasses('row')}>
        <div className={marketDataItemClasses('price-data')}>
          <p className={marketDataItemClasses('key')}> Change 24 hours</p>

          <div className={marketDataItemClasses('groupedKey')}>
            {/* <p className={marketDataItemClasses('value')}>
              {}
            </p> */}
            <p
              className={`${marketDataItemClasses('value')} ${
                change24hr < 0 ? 'text-neg' : 'text-pos'
              }`}
            >
              {formatPercentageValue(change24hr)}
            </p>
          </div>
        </div>
      </div>

      <div className={marketDataItemClasses('row')}>
        <div className={marketDataItemClasses('price-data')}>
          <p className={marketDataItemClasses('key')}>Volume</p>
          <p className={marketDataItemClasses('value')}>
            ${formatToAud(volume)}
          </p>
        </div>
      </div>
      <div className={marketDataItemClasses('row')}>
        <div className={marketDataItemClasses('price-data')}>
          <p className={marketDataItemClasses('key')}>High</p>
          <p className={marketDataItemClasses('value')}>
            ${formatToAud(sessionHigh)}
          </p>
        </div>

        <div className={marketDataItemClasses('price-data')}>
          <p className={marketDataItemClasses('key')}>Low</p>
          <p className={marketDataItemClasses('value')}>
            ${formatToAud(sessionLow)}
          </p>
        </div>
      </div>
      <div className={marketDataItemClasses('row')}>
        <div
          className={marketDataItemClasses('price-data')}
          classModifiers="bid"
        >
          <p className={marketDataItemClasses('key')}>Bid</p>
          <p className={marketDataItemClasses('value')}>${formatToAud(bid)}</p>
        </div>

        <div
          className={marketDataItemClasses('price-data')}
          classModifiers="bid"
        >
          <p className={marketDataItemClasses('key')}>Ask</p>
          <p className={marketDataItemClasses('value')}>${formatToAud(ask)}</p>
        </div>
      </div>
      {/* {coin && (
        <div className={marketDataItemClasses('row')}>
          <div className={marketDataItemClasses('activity')}>
            View Activity &#8594;
          </div>
          <div className={marketDataItemClasses('icons')}>
            <div className={marketDataItemClasses('icon-container')}>
              <APIcon name="buy" customClass={marketDataItemClasses('icon')} />
            </div>
            <div className={marketDataItemClasses('icon-container')}>
              <APIcon name="sell" customClass={marketDataItemClasses('icon')} />
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

MarketDataTable.PropTypes = {
  level1Data: level1DataProp.type.isRequired,
  product1: PropTypes.object.isRequired,
  product2: PropTypes.object.isRequired,
  coin: PropTypes.bool
};

MarketDataTable.contextTypes = {
  t: PropTypes.func.isRequired
};

export default MarketDataTable;
