import React from 'react';
import PropTypes from 'prop-types';
import { productIcons } from '../../../utils/Icons';

const InstrumentTableItem = props => {
  const {
    value,
    label,
    logo,
    classModifiers,
    baseClasses,
    isFromAssetsPage,
    index
  } = props;

  const getIconFileName = logo => {
    const productData = productIcons[logo];
    return productData ? productData.iconFileName : null;
  };

  const iconFileName = getIconFileName(logo);

  return (
    <div className={baseClasses('row', classModifiers)}>
      {iconFileName && (
        <img
          className={baseClasses('logo')}
          src={`/local/product-icons/${iconFileName}`}
        />
      )}

      <div
        className={baseClasses('content', iconFileName ? ['with-logo'] : [])}
      >
        {!isFromAssetsPage && (
          <span className={baseClasses('title')}>{label}</span>
        )}

        {isFromAssetsPage && index === 0 && (
          <span className={baseClasses('title')}>{label}</span>
        )}

        <span className={baseClasses('value', classModifiers)}>{value}</span>
      </div>
    </div>
  );
};

InstrumentTableItem.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  logo: PropTypes.string,
  baseClasses: PropTypes.func.isRequired,
  classModifiers: PropTypes.arrayOf(PropTypes.string)
};

InstrumentTableItem.defaultProps = {
  classModifiers: [],
  logo: null
};

export default InstrumentTableItem;
