import React from 'react';
import PropTypes from 'prop-types';
import DetailsComponent from '../DetailsComponent/DetailsComponent';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

const SendReceiveDetailsComponent = (props, context) => {
  const {
    isSend,
    useExternalAddress,
    balance,
    details: { Amount },
    product: { ProductSymbol },
    TicketAmount,
    fee
  } = props;
  const { getItem } = DetailsComponent;
  const title = isSend ? context.t('Send Details') : context.t('Instructions');
  const amountText = context.t('Sent amount');

  const remainingBalance = formatNumberToLocale(
    balance - (useExternalAddress ? TicketAmount + fee : Amount),
    ProductSymbol
  );
  const classModifiers = isSend ? 'top' : '';

  let items = [];
  let info = [];
  let infoHeader = '';

  if (isSend) {
    items.push(
      getItem(
        context.t('Current balance', {
          ProductSymbol
        }),
        balance
          ? `${formatNumberToLocale(balance, ProductSymbol)} ${ProductSymbol}`
          : '-'
      )
    );
    items.push(
      getItem(
        amountText,
        Amount
          ? `${formatNumberToLocale(
              useExternalAddress ? TicketAmount : Amount,
              ProductSymbol
            )} ${ProductSymbol}`
          : '-'
      )
    );
    items.push(
      getItem(
        context.t('Fee'),
        useExternalAddress
          ? `${formatNumberToLocale(fee, ProductSymbol)} ${ProductSymbol}`
          : '-'
      )
    );
    items.push(
      getItem(
        context.t(`Remaining Balance`),
        Amount && balance ? `${remainingBalance} ${ProductSymbol}` : '-'
      )
    );
  }

  if (isSend && !useExternalAddress) {
    infoHeader = context.t('Note');
    info.push(
      context.t(
        'If the sender is not a registered user of Retail, they will receive an email invitation to create an account and claim their funds.'
      )
    );
  }

  if (!isSend && !useExternalAddress) {
    info.push(context.t('Use this form to request funds via email.'));
    info.push(
      context.t(
        'Note - if the requestee is not a registered user of Retail, they will receive an email invitation to create an account.'
      )
    );
  }
  if (!isSend && useExternalAddress) {
    info.push(
      context.t(
        'Depositing cryptocurrency into your wallet is safe and easy. The address below can always be used to deposit cryptocurrency into your account.'
      )
    );
    info.push(
      context.t(
        'Your account will automatically update after the cryptocurrency network confirms your transaction. The confirmation may take up to 1 hour'
      )
    );
  }

  return (
    <DetailsComponent
      title={title}
      items={items}
      info={info}
      infoHeader={infoHeader}
      classModifiers={classModifiers}
    />
  );
};

SendReceiveDetailsComponent.propTypes = {
  isSend: PropTypes.bool,
  useExternalAddress: PropTypes.bool,
  details: PropTypes.object,
  fee: PropTypes.number,
  TicketAmount: PropTypes.number
};

SendReceiveDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendReceiveDetailsComponent;
