import { connect } from 'react-redux';
import ConfirmOrderModalComponent from './ConfirmOrderModalComponent';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import { orderFormTypes } from 'apex-web/lib/constants/sendOrder/orderFormTypes';
import { placeOrderWithChecks } from 'apex-web/lib/redux/actions/orderEntryActions';
import { getOrderPrice } from 'apex-web/lib/helpers/orderHelper';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { orderTotalSelector } from 'apex-web/lib/redux/selectors/orderTotalSelector';
import { isMarginActiveSelector } from 'apex-web/lib/redux/selectors/marginSelectors';
import { marketPriceOfSelectedInstrumentSelector } from 'apex-web/lib/redux/selectors/buySellSelectors';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { change, reset } from 'redux-form';

const ORDER_ENTRY_FORM_ID = 'orderEntry';

const mapStateToProps = state => {
  const {
    form,
    apexCore: { product }
  } = state;
  const formObj = form[ORDER_ENTRY_FORM_ID] || { values: { fee: {} } };

  const productId =
    formObj.values.fee.ProductId || formObj.values.fee.FeeProductId;
  const feeSymbol = productId
    ? product.products.find(item => item.ProductId === productId).Product
    : '';
  const marketPrice = marketPriceOfSelectedInstrumentSelector(state, {
    side: formObj.values.side,
    formatToLocale: false
  });

  const price = getOrderPrice(formObj, marketPrice);
  const selectedInstrument = selectedInstrumentSelector(state);
  const orderTotal = orderTotalSelector(state, { form: formObj.values });

  return {
    formObj,
    feeSymbol,
    price,
    selectedInstrument,
    isMarginActive: isMarginActiveSelector(state),
    orderTotal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    confirmReport: payload =>
      dispatch(placeOrderWithChecks(orderFormTypes.default, payload)).then(
        res => {
          if (res.result === false) {
            const text =
              res.errormsg && res.detail
                ? `${res.errormsg}: ${res.detail}`
                : res.errormsg || res.detail;
            dispatch(
              showSnack({
                id: 'placeOrderError',
                text,
                type: 'warning'
              })
            );
          } else {
            if (res && res.type === 'OPEN_MODAL') {
              // The warning modal for limit price was opened.
            } else {
              // Clear form values.
              // dispatch(reset(ORDER_ENTRY_FORM_ID));
              dispatch(change(ORDER_ENTRY_FORM_ID, 'side', payload.side));
              dispatch(
                change(
                  ORDER_ENTRY_FORM_ID,
                  'orderQuantity',
                  payload.orderQuantity
                )
              );
              dispatch(
                change(ORDER_ENTRY_FORM_ID, 'orderType', payload.orderType)
              );
            }
          }
        }
      )
  };
};

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmOrderModalComponent);

export default modalDecorator({
  name: MODAL_TYPES.CONFIRM_ORDER_REPORT
})(container);
