import { RECEIVE_INSTRUMENTS } from 'apex-web/lib/redux/actions/instrumentActions';
import { getAllTickersData } from '../actions/tickersActions';

export const instrumentsMiddleware = store => next => action => {
  if (action.type === RECEIVE_INSTRUMENTS) {
    store.dispatch(getAllTickersData());
  }

  return next(action);
};

export default instrumentsMiddleware;
