import {
  submittingWithdraw,
  receiveWithdrawWorkflowInfo,
  receiveWithdrawTicket,
  getWithdrawTickets
} from 'apex-web/lib/redux/actions/withdrawActions';
import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { formatErrorText } from 'apex-web/lib/helpers/errorTextFormatter';

export const createWithdrawTicket = ({
  ProductId,
  amount,
  iconFileName,
  ProductSymbol,
  ProductFullName,
  AccountProviderId,
  ...info
}) => async (dispatch, getState) => {
  dispatch(submittingWithdraw);
  const { OMSId } = getState().user.userInfo;
  const AccountId = getState().user.selectedAccountId;
  const templateForm = info.TemplateForm || info;
  const accountProviderId =
    AccountProviderId || getState().withdraw.AccountProviderId;
  const templateTypeObj = getState().withdraw.templateTypes.find(
    template => template.AccountProviderId === parseInt(accountProviderId)
  );
  // Since undefined is not serializable we default every prop to an empty string to avoid missing fields
  Object.keys(templateForm).forEach(
    prop => (templateForm[prop] = templateForm[prop] || '')
  );

  const payload = {
    OMSId,
    AccountId,
    ProductId: +ProductId,
    Amount: +info.Amount,
    TemplateForm: JSON.stringify(templateForm),
    TemplateType:
      templateTypeObj.TemplateName || getState().withdraw.template.TemplateType,
    AccountProviderId: +templateTypeObj.AccountProviderId
  };

  const withdrawStatus = await dispatch(
    callAPI('CreateWithdrawTicket', payload)
  );

  if (withdrawStatus.result === false) {
    return dispatch(
      showSnack({
        id: 'withdrawRejectSnack',
        text: formatErrorText(withdrawStatus),
        type: 'warning'
      })
    );
  }

  try {
    let workflowDetail = JSON.parse(withdrawStatus.detail);

    if (
      typeof workflowDetail === 'object' &&
      workflowDetail.ReplyObject &&
      workflowDetail.ReplyObject !== '0'
    ) {
      return dispatch(receiveWithdrawWorkflowInfo(workflowDetail));
    }
  } catch (e) {
    // do nothing
  }

  dispatch(
    showSnack({
      id: 'withdrawSuccessSnack',
      // context.t("Your withdraw has been successfully added, please check email for instructions.")
      text:
        'Your withdraw has been successfully added, please check email for instructions.',
      type: 'success'
    })
  );

  dispatch(receiveWithdrawTicket(withdrawStatus));
  return dispatch(getWithdrawTickets(AccountId, OMSId));
};
