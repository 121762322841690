import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

import './PortfolioPage.css';

import { selectAssetsBalanceInBaseCurrencyWithPredicate } from '../../redux/selectors/level1Selectors';
import PageLayout from '../../layouts/PageLayout/PageLayout';
import { getAccountTransactions } from '../../redux/actions/accountTransactions';
import LineChart from './components/LineChart/LineChart';
import { selectAccountTransactionsInBaseCurrency } from '../../redux/selectors/accountTransactionsSelectors';
import DonutChart from './components/DonutChart/DonutChart';
import AssetsTable from './components/AssetsTable/AssetsTable';

const baseClasses = getBEMClasses('portfolio-page');

const PortfolioPage = ({
  assets,
  dispatch,
  accountId,
  transactionsPeriod,
  accountTransactions,
  productManifest,
  user
}) => {
  const [period, setPeriod] = useState(transactionsPeriod || 'day');

  useEffect(() => {
    if (accountId) {
      dispatch(getAccountTransactions(period));
    }
  }, [accountId, period]);

  return (
    <PageLayout
      title="Portfolio"
      subTitle="All your crypto stuff in one place."
    >
      {user.verificationLevelConfigs.Level >= 1 &&
      assets.assetsWithPriceAndPercent &&
      assets.assetsWithPriceAndPercent.length !== 0 ? (
        <>
          <div className={baseClasses('top-row')}>
            <div className={baseClasses('graph')}>
              <div className={baseClasses('graph-header')}>
                <h2>Portfolio value</h2>
              </div>

              <LineChart
                accountTransactions={accountTransactions}
                period={period}
                setPeriod={setPeriod}
              />
            </div>

            <DonutChart assets={assets} productManifest={productManifest} />
          </div>

          <AssetsTable assets={assets} />
        </>
      ) : (
        <>
          <div className={baseClasses('no-assets')}>
            {user.verificationLevelConfigs.Level >= 1 ? (
              <>
                <p>Start trading today to see all your crypto in one place!</p>
                <a className={baseClasses('button')} href="/buy-sell">
                  Buy now
                </a>
              </>
            ) : (
              <>
                <p>
                  Please verify your account to start trading and see all your
                  crypto in one place!
                </p>
                <a
                  className={baseClasses('button')}
                  href="/settings/verification-level"
                >
                  Verify now
                </a>
              </>
            )}
          </div>
        </>
      )}
    </PageLayout>
  );
};

PortfolioPage.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    productManifest: { manifest }
  } = state;
  const predicate = asset => asset.Amount > 0;
  const assets = selectAssetsBalanceInBaseCurrencyWithPredicate(state, {
    predicate
  });
  const { AccountId } = state.user.userInfo;
  const { transactionsPeriod } = state.accountTransactions;
  const accountTransactions = selectAccountTransactionsInBaseCurrency(state);

  const user = state.user;

  return {
    assets,
    accountId: AccountId,
    transactionsPeriod,
    accountTransactions,
    productManifest: manifest,
    user
  };
};

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioPage);
