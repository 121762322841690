import { connect } from 'react-redux';
import {
  fetchingSelector,
  unfundedPositionsSelector,
  fundedPositionsSelector
} from 'apex-web/lib/redux/selectors/productPositionSelectors';
import { selectPositionAndSave } from 'apex-web/lib/redux/actions/positionActions';
import WalletRowListComponent from './WalletRowListComponent';
import {
  getTransfers,
  getIncomingTransfers
} from 'apex-web/lib/redux/actions/transferActions';
import {
  getTransferRequestsReceived,
  getTransferRequestsRequested
} from 'apex-web/lib/redux/actions/transferRequestActions';

const mapStateToProps = state => {
  const { OMSId, AccountId } = state.user.userInfo;
  return {
    fetching: fetchingSelector(state),
    products: [
      ...fundedPositionsSelector(state),
      ...unfundedPositionsSelector(state)
    ],
    OMSId,
    AccountId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectPositionAndSave: productId => {
      dispatch(selectPositionAndSave(productId));
    },
    getTransfers: (OMSId, accountId) => {
      dispatch(getTransfers(accountId));
      dispatch(getIncomingTransfers(OMSId, accountId));
      dispatch(getTransferRequestsReceived(accountId, OMSId));
      dispatch(getTransferRequestsRequested(accountId, OMSId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletRowListComponent);
