import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  fetchResetPassword,
  clearResetPasswordError
} from 'apex-web/lib/redux/actions/resetPasswordActions';
import { getQueryParam } from 'apex-web/lib/helpers/queryParamsHelper';
import { reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import {
  validatePassword,
  matchingPassword
} from 'apex-web/lib/helpers/formValidations';
import { preprocessErrorTextForReduxForm } from 'apex-web/lib/helpers/errorTextFormatter';

import CustomResetPasswordFormComponent from '../CustomResetPasswordFormComponent';

const d1 = getQueryParam('d1');
const userid = getQueryParam('UserId');
const verifycode = getQueryParam('verifycode');

const CustomResetPasswordForm = reduxForm({
  form: 'resetPassword',
  asyncValidate: async (values, dispatch, fieldProps, blurredField) => {
    if (blurredField === 'matchingPassword') {
      const invalid = matchingPassword(values.matchingPassword, values);
      if (invalid) {
        throw {
          matchingPassword: invalid
        };
      }
      return undefined;
    }
  },
  asyncBlurFields: ['matchingPassword'],
  onSubmit: (payload, dispatch) => {
    const errorMessage = validatePassword(payload.password);
    if (errorMessage) {
      throw new SubmissionError({
        matchingPassword: errorMessage
      });
    }

    const res = dispatch(
      fetchResetPassword({
        verifycode,
        userid,
        d1,
        formValues: payload
      })
    );

    if (res.error && res.message) {
      console.log(preprocessErrorTextForReduxForm(res.message, payload));

      throw new SubmissionError(
        preprocessErrorTextForReduxForm(res.message, payload)
      );
    }
  }
})(CustomResetPasswordFormComponent);

const selector = formValueSelector('resetPassword');

const mapStateToProps = state => {
  const {
    apiProcessing: { processingResetPassword },
    resetPassword: { success, message, error: fetchError }
  } = state;
  return {
    passwordValue: selector(state, 'password'),
    processingResetPassword,
    message,
    fetchError,
    success
  };
};

const mapDispatchToProps = {
  clearResetPasswordError
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomResetPasswordForm);
