import { connect } from 'react-redux';
import { fetchProductWithdrawTemplate } from 'apex-web/lib/redux/actions/withdrawActions';
import HyphenWithdrawWorkflowComponent from './HyphenWithdrawWorkflowComponent';

const mapStateToProps = state => ({
  template: state.withdraw.template,
  productId: state.withdraw.product,
  templateType: state.withdraw.templateType
});

const mapDispatchToProps = dispatch => ({
  getBankInfoForm: (productId, templateType) =>
    dispatch(
      fetchProductWithdrawTemplate(productId, templateType, {
        UpdateBankData: true
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HyphenWithdrawWorkflowComponent);
