import get from 'lodash/get';

export const SORT_OPTIONS = {
  name: 'productFullName1',
  price: 'price',
  lastDayChange: 'change24hr',
  marketCup: 'volume24hr',
  performance: 'Rolling24HrPxChangePercent'
};

export const sortAssets = (a, b, sortOption, isAscending = true) => {
  const aParam =
    sortOption === 'Rolling24HrPxChangePercent'
      ? calculateRollingChangePercent(a.price24hr)
      : get(a, sortOption);

  const bParam =
    sortOption === 'Rolling24HrPxChangePercent'
      ? calculateRollingChangePercent(b.price24hr)
      : get(b, sortOption);

  let result;
  if (aParam === undefined && bParam === undefined) {
    result = 0;
  } else if (isNaN(aParam) || isNaN(bParam)) {
    result = aParam !== bParam ? (aParam > bParam ? 1 : -1) : 0;
  } else {
    result = Number(aParam) - Number(bParam);
  }
  return result === 0 || isAscending ? result : -result;
};

// Helper function to calculate the 24-hour percentage change
const calculateRollingChangePercent = price24hrArray => {
  if (!price24hrArray || price24hrArray.length === 0) return 0;

  const firstPrice = price24hrArray[0];
  const latestPrice = price24hrArray[price24hrArray.length - 1];

  // Calculate percentage change
  const percentageChange = ((latestPrice - firstPrice) / firstPrice) * 100;
  return percentageChange;
};
