import React from 'react';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import OrderEntryWithPercentButtonsContainer from '../OrderEntryComponents/OrderEntryWithPercentButtonsContainer';
import './BuySellModal.css';

const BuySellModalComponent = props => {
  const { isOpen, close, selectedInstrument, side } = props;
  return (
    <Modal isOpen={isOpen} close={close} customClass="buy-sell-modal">
      <OrderEntryWithPercentButtonsContainer
        selectedInstrument={selectedInstrument}
        side={side}
      />
    </Modal>
  );
};

export default BuySellModalComponent;
