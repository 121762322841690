/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import ScriptLoader from 'apex-web/lib/helpers/scriptLoader';

const baseClasses = getBEMClasses('deposit-form');

const TrustpayDepositWorkflowComponent = ({ url }) => (
  <div className={baseClasses('trustpay-form')}>
    <form
      action={location.href}
      className="paymentWidgets"
      data-brands="VISA MASTER AMEX"
    />
    <ScriptLoader url={url} />
  </div>
);

TrustpayDepositWorkflowComponent.defaultProps = {
  url: '',
  title: ''
};

TrustpayDepositWorkflowComponent.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string
};

export default TrustpayDepositWorkflowComponent;
