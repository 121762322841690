import { connect } from 'react-redux';
import ScheduledReportsTableComponent from './ScheduledReportsTableComponent';
import { cancelReport } from 'apex-web/lib/redux/actions/reportActions';
import { cancelableScheduledReportsSelector } from 'apex-web/lib/redux/selectors/reportSelectors';

const mapStateToProps = state => {
  const reports = cancelableScheduledReportsSelector(state);
  const { isLoading } = state.report;

  return {
    reports,
    isLoading
  };
};

const mapDispathToProps = {
  cancelReport
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(ScheduledReportsTableComponent);
