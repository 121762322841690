import React, { useEffect, useState } from 'react';
import LineChart from './LineChart';
import MarketSwitcher from './MarketSwitcher';

export function formatPrice(price) {
  return price < 0.01
    ? price.toLocaleString(undefined, {
        minimumFractionDigits: 10,
        maximumFractionDigits: 10
      })
    : price.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
}

export function formatVolumePrice(price) {
  return price.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
}

const Dcx40Tab = () => {
  const masterAssetList = [
    'algorand',
    'apecoin',
    'avalanche-2',
    'binancecoin',
    'bitcoin',
    'bitcoin-cash',
    'cardano',
    'chainlink',
    'chiliz',
    'cosmos',
    'decentraland',
    'dogecoin',
    'elrond-erd-2',
    'eos',
    'ethereum',
    'ethereum-classic',
    'fantom',
    'filecoin',
    'flow',
    'internet-computer',
    'iota',
    'litecoin',
    'neo',
    'network',
    'pancakeswap',
    'polkadot',
    'matic-network',
    'quant',
    'ravencoin',
    'ripple',
    'shiba-inu',
    'solana',
    'stellar',
    'tether',
    'tezos',
    'the-sandbox',
    'tron',
    'uniswap'
  ];

  const [coinData, setCoinData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isSortedAscendingName, setIsSortedAscendingName] = useState(true);
  const [isSortedAscendingPrice, setIsSortedAscendingPrice] = useState(true);
  const [isSortedAscending24hrs, setIsSortedAscending24hrs] = useState(true);

  const sortByPrice = () => {
    const sortedData = [...cryptos].sort(
      (a, b) =>
        isSortedAscendingPrice
          ? a.current_price - b.current_price
          : b.current_price - a.current_price
    );
    setCryptos(sortedData);
    setIsSortedAscendingPrice(!isSortedAscendingPrice);
  };

  const [percentageGapToggle, setPercentageGapToggle] = useState(false);

  const sortByPercentageGap = () => {
    const sortedData = [...cryptos].sort(
      (a, b) =>
        isSortedAscending24hrs
          ? a.price_change_percentage_24h - b.price_change_percentage_24h
          : b.price_change_percentage_24h - a.price_change_percentage_24h
    );
    setCryptos(sortedData);
    setIsSortedAscending24hrs(!isSortedAscending24hrs);
  };

  const sortByName = () => {
    const sortedData = [...cryptos].sort((a, b) => {
      const nameA = a.name || '';
      const nameB = b.name || '';
      if (isSortedAscendingName) {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

    setCryptos(sortedData);
    setIsSortedAscendingName(!isSortedAscendingName);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const idsParam = masterAssetList.join(',');
        const apiUrl = `https://pro-api.coingecko.com/api/v3/coins/markets?x_cg_pro_api_key=CG-mowbUNKjJk1Htv8p6SNke9x8&vs_currency=aud&ids=${idsParam}`;

        const response = await fetch(apiUrl);

        if (response.ok) {
          const data = await response.json();
          setCoinData(data);

          setIsLoading(false);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.error('error now: ', error);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-wash">
      <div className="mx-auto pt-[60px] lg:max-w-screen-xl overflow-x-scroll px-4 lg:px-0">
        <div className="flex flex-wrap items-center text-center justify-center mb-10 w-[1400px] lg:w-auto">
          <div className="w-full mb-10">
            <div className="flex flex-row items-center justify-between">
              <div
                className="text-left basis-[calc(30%-10px)] cursor-pointer border-b-[1px] border-custom-sea h-[60px] flex items-center"
                onClick={() => sortByName()}>
                <p className="mb-0 text-[#787878] text-[16px]">Coin</p>
                <div
                  className={`${
                    isSortedAscendingName ? '' : 'rotate-180'
                  } ml-5`}>
                  {/* <ArrowDown /> */}
                </div>
              </div>
              <div
                className="text-left basis-[calc(15%-10px)] border-b-[1px] border-custom-sea h-[60px] flex items-center cursor-pointer"
                onClick={() => sortByPrice()}>
                <p className="mb-0 text-[#787878] text-[16px]">Price (AUD)</p>
                <div
                  className={`${
                    isSortedAscendingPrice ? '' : 'rotate-180'
                  } ml-5`}>
                  {/* <ArrowDown /> */}
                </div>
              </div>
              <div
                className="text-left basis-[calc(15%-10px)] cursor-pointer border-b-[1px] border-custom-sea h-[60px] flex items-center"
                onClick={() => sortByPercentageGap()}>
                <p className="mb-0 text-[#787878] text-[16px]">24hr move %</p>
                <div
                  className={`${
                    isSortedAscending24hrs ? '' : 'rotate-180'
                  } ml-5`}>
                  {/* <ArrowDown /> */}
                </div>
              </div>
              <div className="text-left basis-[calc(32%-0px)] border-b-[1px] border-custom-sea h-[60px] flex items-center">
                <p className="mb-0 text-[#787878] text-[16px]">Chart</p>
              </div>
              {/* <div className="text-left basis-[calc(10%-10px)] border-b-[1px] border-custom-sea h-[60px] flex items-center" /> */}
            </div>
          </div>

          {coinData.map((crypto, index) => {
            return (
              <div className="w-full mb-5" key={index}>
                <div className="flex flex-row items-center justify-between">
                  <div className="text-left basis-[calc(30%-10px)] border-b-[1px] border-custom-sea h-[70px] flex items-center">
                    <img className="w-6 mr-3 mb-0" src={crypto.image} />
                    <p className="text-[16px] text-charcoal font-[400] mb-0">
                      {crypto.name}
                      <span className="text-[#787878] ml-2">
                        {'('}
                        {crypto.symbol.toUpperCase()}
                        {')'}
                      </span>
                    </p>
                  </div>
                  <div className="text-left border-b-[1px] border-custom-sea h-[70px] basis-[calc(15%-10px)] flex items-center">
                    <p className="text-[16px] text-charcoal font-[400] mb-0">
                      ${formatPrice(crypto.current_price)}
                    </p>
                  </div>
                  <div className="text-left border-b-[1px] border-custom-sea h-[70px] basis-[calc(15%-10px)] flex items-center">
                    <p className="text-[16px] font-[400] mb-0 text-sea">
                      {crypto.price_change_percentage_24h > 0 ? '+' : null}
                      {crypto.price_change_percentage_24h.toFixed(2)}%
                    </p>
                  </div>
                  <div className="border-b-[1px] border-custom-sunset h-[70px] basis-[calc(30%-10px)]  ">
                    <div className="w-5/6">
                      <LineChart coinId={crypto.id} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const MarketCap = () => {
  const [coinData, setCoinData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `https://pro-api.coingecko.com/api/v3/coins/markets?x_cg_pro_api_key=CG-mowbUNKjJk1Htv8p6SNke9x8&vs_currency=AUD&order=market_cap_desc&per_page=40&sparkline=true&locale=en`;

        const response = await fetch(apiUrl);

        if (response.ok) {
          const data = await response.json();
          setCoinData(data);

          setIsLoading(false);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.error('error now: ', error);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-wash">
      <div className="mx-auto pt-[60px] lg:max-w-screen-xl overflow-x-scroll px-4 lg:px-0">
        <div className="flex flex-wrap items-center text-center justify-center mb-10 w-[1400px] lg:w-auto">
          <div className="w-full mb-10">
            <div className="flex flex-row items-center justify-between">
              <div className="text-left basis-[calc(30%-10px)]  border-b-[1px] border-custom-sea h-[60px] flex items-center">
                <p className="mb-0 text-[#787878] text-[16px]">Coin</p>
              </div>
              <div className="text-left basis-[calc(15%-10px)] border-b-[1px] border-custom-sea h-[60px] flex items-center ">
                <p className="mb-0 text-[#787878] text-[16px]">Market Cap</p>
              </div>
              <div className="text-left basis-[calc(15%-10px)]  border-b-[1px] border-custom-sea h-[60px] flex items-center">
                <p className="mb-0 text-[#787878] text-[16px]">Price (AUD)</p>
              </div>
              <div className="text-left basis-[calc(30%-10px)] border-b-[1px] border-custom-sea h-[60px] flex items-center">
                <p className="mb-0 text-[#787878] text-[16px]">Chart</p>
              </div>
              <div className="text-left basis-[calc(10%-10px)] border-b-[1px] border-custom-sea h-[60px] flex items-center">
                <p className="mb-0 text-[#787878] text-[16px]">24hr move %</p>
              </div>
            </div>
          </div>

          {coinData.map((crypto, index) => {
            return (
              <div className="w-full mb-5" key={index}>
                <div className="flex flex-row items-center justify-between">
                  <div className="text-left basis-[calc(30%-10px)] border-b-[1px] border-custom-sea h-[70px] flex items-center">
                    <img className="w-6 mr-3 mb-0" src={crypto.image} />
                    <p className="text-[16px] text-charcoal font-[400] mb-0">
                      {crypto.name}
                      <span className="text-[#787878] ml-2">
                        {'('}
                        {crypto.symbol.toUpperCase()}
                        {')'}
                      </span>
                    </p>
                  </div>
                  <div className="text-left border-b-[1px] border-custom-sea h-[70px] basis-[calc(15%-10px)] flex items-center">
                    <p className="text-[16px] text-charcoal font-[400] mb-0">
                      ${crypto.market_cap.toLocaleString()}
                    </p>
                  </div>
                  <div className="text-left border-b-[1px] border-custom-sea h-[70px] basis-[calc(15%-10px)] flex items-center">
                    <p className="text-[16px] font-[400] mb-0 text-sea">
                      ${formatPrice(crypto.current_price)}
                    </p>
                  </div>
                  <div className="border-b-[1px] border-custom-sunset h-[70px] basis-[calc(30%-10px)]  ">
                    <div className="w-5/6">
                      <LineChart coinId={crypto.id} />
                    </div>
                  </div>
                  <div className="text-left border-b-[1px] border-custom-sea h-[70px] basis-[calc(10%-10px)] flex items-center">
                    <p className="text-[16px] font-[400] mb-0 text-sea">
                      {crypto.market_cap_change_percentage_24h > 0 ? '+' : null}
                      {crypto.market_cap_change_percentage_24h.toFixed(2)}%
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Gainers = () => {
  const [coinData, setCoinData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `https://pro-api.coingecko.com/api/v3/coins/top_gainers_losers?x_cg_pro_api_key=CG-mowbUNKjJk1Htv8p6SNke9x8&vs_currency=AUD`;

        const response = await fetch(apiUrl);

        if (response.ok) {
          const data = await response.json();
          setCoinData(data);

          setIsLoading(false);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.error('error now: ', error);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-wash">
      <div className="mx-auto pt-[60px] lg:max-w-screen-xl overflow-x-scroll px-4 lg:px-0">
        <div className="flex flex-wrap items-center text-center justify-center mb-10 w-[1400px] lg:w-auto">
          <div className="w-full mb-10">
            <div className="flex flex-row items-center justify-between">
              <div className="text-left basis-[calc(30%-10px)]  border-b-[1px] border-custom-sea h-[60px] flex items-center">
                <p className="mb-0 text-[#787878] text-[16px]">Coin</p>
              </div>
              <div className="text-left basis-[calc(15%-10px)] border-b-[1px] border-custom-sea h-[60px] flex items-center ">
                <p className="mb-0 text-[#787878] text-[16px]">Price (AUD)</p>
              </div>
              <div className="text-left basis-[calc(15%-10px)]  border-b-[1px] border-custom-sea h-[60px] flex items-center">
                <p className="mb-0 text-[#787878] text-[16px]">
                  24hr Volume (AUD)
                </p>
              </div>
              <div className="text-left basis-[calc(30%-10px)] border-b-[1px] border-custom-sea h-[60px] flex items-center">
                <p className="mb-0 text-[#787878] text-[16px]">Chart</p>
              </div>
              <div className="text-left basis-[calc(10%-10px)] border-b-[1px] border-custom-sea h-[60px] flex items-center">
                <p className="mb-0 text-[#787878] text-[16px]">24hr move %</p>
              </div>
            </div>
          </div>

          {coinData.top_gainers?.map((crypto, index) => {
            return (
              <div className="w-full mb-5" key={index}>
                <div className="flex flex-row items-center justify-between">
                  <div className="text-left basis-[calc(30%-10px)] border-b-[1px] border-custom-sea h-[70px] flex items-center">
                    <img className="w-6 mr-3 mb-0" src={crypto.image} />
                    <p className="text-[16px] text-charcoal font-[400] mb-0">
                      {crypto.name}
                      <span className="text-[#787878] ml-2">
                        {'('}
                        {crypto.symbol.toUpperCase()}
                        {')'}
                      </span>
                    </p>
                  </div>
                  <div className="text-left border-b-[1px] border-custom-sea h-[70px] basis-[calc(15%-10px)] flex items-center">
                    <p className="text-[16px] text-charcoal font-[400] mb-0">
                      ${formatPrice(crypto.aud)}
                    </p>
                  </div>
                  <div className="text-left border-b-[1px] border-custom-sea h-[70px] basis-[calc(15%-10px)] flex items-center">
                    <p className="text-[16px] font-[400] mb-0 text-sea">
                      ${formatVolumePrice(crypto.aud_24h_vol)}
                    </p>
                  </div>
                  <div className="border-b-[1px] border-custom-sunset h-[70px] basis-[calc(30%-10px)]  ">
                    <div className="w-5/6">
                      <LineChart coinId={crypto.id} />
                    </div>
                  </div>
                  <div className="text-left border-b-[1px] border-custom-sea h-[70px] basis-[calc(10%-10px)] flex items-center">
                    <p className="text-[16px] font-[400] mb-0 text-sea">
                      +{crypto.aud_24h_change.toFixed(2)}%
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Losers = () => {
  const [coinData, setCoinData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `https://pro-api.coingecko.com/api/v3/coins/top_gainers_losers?x_cg_pro_api_key=CG-mowbUNKjJk1Htv8p6SNke9x8&vs_currency=AUD`;

        const response = await fetch(apiUrl);

        if (response.ok) {
          const data = await response.json();
          setCoinData(data);

          setIsLoading(false);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.error('error now: ', error);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-wash">
      <div className="mx-auto pt-[60px] lg:max-w-screen-xl overflow-x-scroll px-4 lg:px-0">
        <div className="flex flex-wrap items-center text-center justify-center mb-10 w-[1400px] lg:w-auto">
          <div className="w-full mb-10">
            <div className="flex flex-row items-center justify-between">
              <div className="text-left basis-[calc(30%-10px)]  border-b-[1px] border-custom-sea h-[60px] flex items-center">
                <p className="mb-0 text-[#787878] text-[16px]">Coin</p>
              </div>
              <div className="text-left basis-[calc(15%-10px)] border-b-[1px] border-custom-sea h-[60px] flex items-center ">
                <p className="mb-0 text-[#787878] text-[16px]">Price (AUD)</p>
              </div>
              <div className="text-left basis-[calc(15%-10px)]  border-b-[1px] border-custom-sea h-[60px] flex items-center">
                <p className="mb-0 text-[#787878] text-[16px]">
                  24hr Volume (AUD)
                </p>
              </div>
              <div className="text-left basis-[calc(30%-10px)] border-b-[1px] border-custom-sea h-[60px] flex items-center">
                <p className="mb-0 text-[#787878] text-[16px]">Chart</p>
              </div>
              <div className="text-left basis-[calc(10%-10px)] border-b-[1px] border-custom-sea h-[60px] flex items-center">
                <p className="mb-0 text-[#787878] text-[16px]">24hr move %</p>
              </div>
            </div>
          </div>

          {coinData.top_losers?.map((crypto, index) => {
            return (
              <div className="w-full mb-5" key={index}>
                <div className="flex flex-row items-center justify-between">
                  <div className="text-left basis-[calc(30%-10px)] border-b-[1px] border-custom-sea h-[70px] flex items-center">
                    <img className="w-6 mr-3 mb-0" src={crypto.image} />
                    <p className="text-[16px] text-charcoal font-[400] mb-0">
                      {crypto.name}
                      <span className="text-[#787878] ml-2">
                        {'('}
                        {crypto.symbol.toUpperCase()}
                        {')'}
                      </span>
                    </p>
                  </div>
                  <div className="text-left border-b-[1px] border-custom-sea h-[70px] basis-[calc(15%-10px)] flex items-center">
                    <p className="text-[16px] text-charcoal font-[400] mb-0">
                      ${formatPrice(crypto.aud)}
                    </p>
                  </div>
                  <div className="text-left border-b-[1px] border-custom-sea h-[70px] basis-[calc(15%-10px)] flex items-center">
                    <p className="text-[16px] font-[400] mb-0 text-sea">
                      ${formatVolumePrice(crypto.aud_24h_vol)}
                    </p>
                  </div>
                  <div className="border-b-[1px] border-custom-sunset h-[70px] basis-[calc(30%-10px)]  ">
                    <div className="w-5/6">
                      <LineChart coinId={crypto.id} />
                    </div>
                  </div>
                  <div className="text-left border-b-[1px] border-custom-sea h-[70px] basis-[calc(10%-10px)] flex items-center">
                    <p className="text-[16px] font-[400] mb-0 text-sea">
                      +{crypto.aud_24h_change.toFixed(2)}%
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const MarketData = () => {
  const [view, setView] = useState('top40'); // 'top40', 'marketCap', 'gainers', 'losers'

  return (
    <div className="marketdata-tab">
      <MarketSwitcher view={view} setView={setView} />
      {view === 'top40' && <Dcx40Tab />}

      {view === 'marketCap' && <MarketCap />}
      {view === 'gainers' && <Gainers />}
      {view === 'losers' && <Losers />}
    </div>
  );
};

export default MarketData;
