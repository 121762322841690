import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './SendReceiveFormTabs.css';

const bemClasses = getBEMClasses('dcx-send-receive-tabs');

const SendReceiveTabs = (props, context) => {
  const { isSend, toggleTab } = props;
  return (
    <header className={bemClasses()}>
      <div
        className={bemClasses('tab', { selected: isSend })}
        onClick={() => !isSend && toggleTab()}
      >
        <span className={bemClasses('tab-text')}>{context.t('Send')}</span>
      </div>
      <div
        className={bemClasses('tab', { selected: !isSend })}
        onClick={() => isSend && toggleTab()}
      >
        <span className={bemClasses('tab-text')}>{context.t('Receive')}</span>
      </div>
    </header>
  );
};

SendReceiveTabs.propTypes = {
  isSend: PropTypes.bool.isRequired,
  toggleTab: PropTypes.func.isRequired
};

SendReceiveTabs.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendReceiveTabs;
