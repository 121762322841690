import React, { useState, useEffect, useRef } from 'react';
import UserSummaryContainer from '../../components/UserSummary/UserSummaryContainer';
import PageHeaderNavComponent from '../../components/PageHeader/PageHeaderNavComponent';
import { CustomDropDown } from './CustomDropDown';
import { displayName } from 'qrcode.react';
import VerificationButton from './VerificationButton';

import './CustomDropDown.css'; // Import the CSS file for component styles
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  Pagination
} from 'react-instantsearch-hooks-web';

const searchClient = algoliasearch(
  '912DK4PF2X',
  '428aeae5128abcb750132b0f2358e68c'
);

function isValidURL(url) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

function Hit({ hit }) {
  const newHostname = 'dcxmarkets.com';
  const currentUrl = isValidURL(hit.permalink) ? new URL(hit.permalink) : null;
  const pathname = currentUrl ? currentUrl.pathname : '';
  const search = currentUrl ? currentUrl.search : '';

  const newUrl =
    currentUrl && pathname
      ? `https://${newHostname}${pathname}${search}`
      : `https://${newHostname}`;

  return (
    <article className="hitArticle">
      <a href={newUrl} target="_blank">
        <Highlight attribute="post_title" hit={hit} />
        {/* <p dangerouslySetInnerHTML={{ __html: hit.post_excerpt }} /> */}
      </a>
    </article>
  );
}

const DCXNav = () => {
  const MainNavItems = [
    { text: 'Buy / Sell', icon: 'buy-sell', path: '/buy-sell' },
    // { text: 'Assets', icon: 'buy-sell', path: '/digital-assets' },
    // { text: 'dcx+', icon: 'trading', path: '/exchange' },
    // { text: 'Balances', icon: 'balance', path: '/balances' },
    // { text: 'Streaming', icon: 'activity', path: '/eotc' },
    // { text: 'Lending', icon: 'sell', path: '/iba' },
    { text: 'Dashboard', icon: 'dashboard', path: '/dashboard' },
    { text: 'Wallets', icon: 'wallet', path: '/wallets' },
    // { text: 'Activity', icon: 'activity', path: '/asset-activity-details' }
    { text: 'Transfer funds', icon: 'star', path: '/transfer' }
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); // Add this state

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const modalRef = useRef(null); // Define the modalRef here

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const loadScript = () => {
    // Create a new script element
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=30291170-2a00-4019-8a93-b4e898fccd6d';

    // Add an onload event handler to track when the script is loaded
    script.onload = () => {
      setScriptLoaded(true);
    };

    // Append the script element to the document's head
    document.head.appendChild(script);
  };

  useEffect(() => {
    // Check if the script has loaded
    if (scriptLoaded) {
      // Execute the code to activate the widget
      if (window.zE) {
        window.zE(function () {
          zE.activate();
        });
      }
    }
  }, [scriptLoaded]);

  useEffect(() => {
    // Function to handle clicks outside the modal
    const handleOutsideClick = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Click occurred outside the modal, so close it
        handleCloseModal();
      }
    };

    // Add the click event listener to the document when the modal is open
    if (isModalOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    // Remove the event listener when the component unmounts or modal is closed
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isModalOpen]);

  useEffect(() => {
    // Function to handle clicks outside the dropdown
    const handleOutsideClick = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Click occurred outside the dropdown, so close it
        setIsOpen(false);
      }
    };

    // Add the click event listener to the document when the dropdown is open
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    // Remove the event listener when the component unmounts or dropdown is closed
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <VerificationButton />

      <div className="dcx-navbar">
        <div className="nav-container">
          <a href="/">
            <svg
              className="dcxLogo"
              width="81"
              height="31"
              viewBox="0 0 81 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_3070_109272)">
                <path
                  d="M80.1819 30.8963C77.135 27.8374 74.7776 21.0861 69.217 19.8633L67.3809 22.6656C71.859 28.3144 77.2352 31.6019 80.1859 30.8963H80.1819Z"
                  fill="#FF7300"
                />
                <path
                  d="M53.4692 8.82956C56.5161 11.8885 58.4766 17.7538 64.0372 19.5017C65.2319 19.8786 68.2868 19.8626 68.2868 19.8626C63.5321 12.5299 56.877 8.0117 53.4733 8.82956H53.4692Z"
                  fill="#FF7300"
                />
                <path
                  d="M53.4692 30.8964C56.5161 27.8375 58.4766 21.9722 64.0372 20.2242C65.2319 19.8473 68.2868 19.8634 68.2868 19.8634C63.5321 27.196 56.877 31.7142 53.4733 30.8964H53.4692Z"
                  fill="#FF7300"
                />
                <path
                  d="M80.1819 8.82905C77.135 11.888 74.7776 18.6393 69.217 19.8621L67.3809 17.0597C71.859 11.4109 77.2352 8.12345 80.1859 8.82905H80.1819Z"
                  fill="#FF7300"
                />
                <path
                  d="M38.9084 8.83008C37.6495 8.83008 36.3706 9.04657 35.1198 9.4996C30.9343 11.015 28.0197 15.0602 27.8794 19.5103C27.6829 25.7564 32.7063 30.9001 38.9084 30.9001C39.4015 30.9001 39.8986 30.868 40.4037 30.7999C43.1419 30.4391 45.6156 28.9637 47.4317 26.879L48.7587 25.3515L47.0067 23.8281C46.5657 23.4432 46.0245 23.2548 45.4833 23.2548C44.8338 23.2548 44.1883 23.5234 43.7313 24.0526C42.5165 25.4477 40.7565 26.2496 38.9084 26.2496C38.0624 26.2496 37.1925 26.0812 36.3506 25.7244C34.4703 24.9306 33.0671 23.2027 32.6582 21.2021C31.8203 17.0968 34.9514 13.4766 38.9084 13.4766C39.3333 13.4766 39.7663 13.5167 40.2073 13.6049C41.5984 13.8815 42.8373 14.6713 43.7794 15.7297C44.2044 16.2068 44.7977 16.4473 45.391 16.4473C45.9042 16.4473 46.4174 16.2669 46.8303 15.8981L48.6825 14.2423L47.131 12.5104C45.0382 10.1731 42.0394 8.83409 38.9084 8.83409"
                  fill="#FF7300"
                />
                <path
                  d="M19.9292 0.992188C17.3633 0.992188 15.2826 3.0729 15.2826 5.63872V9.68389C13.9476 9.12663 12.5043 8.82995 11.033 8.82995C9.34919 8.82995 7.62528 9.21482 5.98155 10.0407C2.97875 11.5521 0.809829 14.4547 0.196439 17.7622C-1.10251 24.7661 4.26566 30.9 11.037 30.9C14.2403 30.9 17.2872 29.5048 19.3839 27.0793C19.7568 26.6463 19.9292 26.0811 19.9292 25.5118V0.992188ZM11.033 26.2534C7.50901 26.2534 4.64252 23.3869 4.64252 19.863C4.64252 16.339 7.50901 13.4765 11.033 13.4765C12.6046 13.4765 14.12 14.0578 15.2826 15.0962V24.6338C14.12 25.6721 12.6086 26.2534 11.033 26.2534Z"
                  fill="#FF7300"
                />
              </g>
              <defs>
                <clipPath id="clip0_3070_109272">
                  <rect
                    width="80.1817"
                    height="30"
                    fill="white"
                    transform="translate(0 0.992188)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>

          {/* <div className="mobileNav">
            <div className="monileNav-menu">
              <PageHeaderNavComponent navItems={MainNavItems} />
            </div>
          </div> */}

          <div className="custom-header-items">
            <div
              className="header-search custom-header--item"
              onClick={handleOpenModal}
            >
              {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#5F6368"
            >
              <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            </svg> */}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                data-element="Search"
                data-source-file="AppHeaderSearch.tsx"
              >
                <circle cx="11" cy="11" r="8" />
                <line x1="21" x2="16.65" y1="21" y2="16.65" />
              </svg>
            </div>

            <div className="custom-header--item" onClick={toggleDropdown}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                <line x1="12" x2="12.01" y1="17" y2="17" />
              </svg>
            </div>

            {isOpen && (
              <div className="dropdown-content" ref={dropdownRef}>
                <a onClick={loadScript}>Live chat</a>
                <a
                  href="https://dcxmarkets.com/help/crypto-coach"
                  target="_blank"
                >
                  Crypto coach
                </a>
                <a href="https://dcxmarkets.com/quiz/">Crypto quiz</a>
              </div>
            )}

            <UserSummaryContainer
              customClass="page-header-user-summary"
              settingsRoute="/settings/user"
            />
          </div>

          {isModalOpen && (
            <div className="modal">
              <div className="modal-content" ref={modalRef}>
                <span className="close" onClick={handleCloseModal}>
                  &times;
                </span>
                {/* <input
                type="search"
                placeholder="Search"
                className="modal-search"
              /> */}

                <InstantSearch
                  searchClient={searchClient}
                  indexName="wp_posts_currency-guide"
                >
                  <SearchBox
                    className="modal-search"
                    onChange={setSearchQuery}
                  />

                  {/* Only show results if searchQuery is not empty */}
                  <>
                    <Hits hitComponent={Hit} />
                    {/* <Pagination /> */}
                  </>
                </InstantSearch>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DCXNav;
