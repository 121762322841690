import React from 'react';
import PropTypes from 'prop-types';

import TrustpayDepositWorkflowComponent from '../PaymentProcessors/TrustpayDepositWorkflowComponent';
import RazorPayDepositWorkflowComponent from '../PaymentProcessors/RazorPayDepositWorkflowComponent';
import PsigateDepositWorkflowComponent from '../PaymentProcessors/PsigateDepositWorkflowComponent';
import InterswitchDepositWorkflowComponent from '../PaymentProcessors/InterswitchDepositWorkflowComponent';
import FennasDepositWorkflowComponent from '../PaymentProcessors/FennasDepositWorkflowComponent';
import HyphenDepositWorkflowContainer from '../PaymentProcessors/Hyphen/HyphenDepositWorkflowContainer';

const GenericPaymentWorkflowComponent = (
  { depositInfo, templateType },
  context
) => {
  const { PaymentAPI, URL, RedirectURL, SerializedPaymentTransactionObject } =
    depositInfo[0] ? JSON.parse(depositInfo[0]) : {};

  if (templateType === 'Hyphen') {
    return <HyphenDepositWorkflowContainer />;
  }

  if (RedirectURL) {
    window.location = RedirectURL;
    return <p>Redirecting...</p>;
  }

  if (PaymentAPI === 'TrustPay') {
    return <TrustpayDepositWorkflowComponent url={URL} />;
  }

  if (SerializedPaymentTransactionObject) {
    const options = JSON.parse(SerializedPaymentTransactionObject);

    if (PaymentAPI === 'Razorpay') {
      return <RazorPayDepositWorkflowComponent options={options} />;
    }
    if (PaymentAPI === 'Psigate') {
      return (
        <PsigateDepositWorkflowComponent options={{ ...options, Email: '' }} />
      );
    }
    if (PaymentAPI === 'Interswitch') {
      return <InterswitchDepositWorkflowComponent options={options} />;
    }
    if (PaymentAPI === 'Fennas') {
      return <FennasDepositWorkflowComponent options={options} />;
    }
  }

  if (PaymentAPI === null) {
    return <p>{context.t('Server provided an unexpected response.')}</p>;
  }

  return null;
};

GenericPaymentWorkflowComponent.defaultProps = {
  depositInfo: []
};

GenericPaymentWorkflowComponent.propTypes = {
  depositInfo: PropTypes.array.isRequired
};

GenericPaymentWorkflowComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default GenericPaymentWorkflowComponent;
