import { connect } from 'react-redux';
import VerificationRequiredComponent from './VerificationRequiredComponent';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import config from '../../../config';

const mapStateToProps = (state, ownProps) => {
  const { disableTrading, isUnderReview } =
    kycVerificationLevelsSelector(state);

  return {
    verificationRequired: true,
    verificationLevelPageUrl: config.KYC.verificationRequiredUrl,
    enableVerificationLinkOnClick: false,
    disabled: disableTrading,
    isUnderReview,
    ...ownProps
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationRequiredComponent);
