import React, { useState, useEffect } from 'react';
import Dropdown from '../../../common/Dropdown/Dropdown';
import { getBEMClasses } from '../../../../helpers/cssClassesHelper';
import {
  getBanxaBuyCryptos,
  getBanxaFundingOptions,
  getBanxaPrices,
  createBanxaOrder
} from '../../../../dcxApi';
import Input from '../../../common/Input/Input';
import { debounce } from './utils/debounce';

import './Banxa.css';

const baseClasses = getBEMClasses('banxa-form');

const Banxa = ({ user }) => {
  const [banxaBuyCryptos, setBanxaBuyCryptos] = useState(null);
  const [selectedCrypto, setSelectedCrypto] = useState(null);

  const handleSelectedCrypto = depositType => {
    setSelectedCrypto(depositType);
  };

  useEffect(() => {
    getBanxaBuyCryptos().then(data => {
      const modifiedCoins = data?.data?.coins?.map(coin => ({
        ...coin,
        value: coin.coin_code,
        title: coin.coin_code
      }));
      setBanxaBuyCryptos(modifiedCoins);
    });
  }, [setBanxaBuyCryptos]);

  const [fundingOptions, setFundingOptions] = useState(null);
  const [selectedFundingOption, setSelectedFundingOption] = useState(null);

  const [banxaMinAmount, setBanxaMinAmount] = useState(null);

  // if a crypto is selected we should get funding options for that crypto
  useEffect(() => {
    if (selectedCrypto) {
      getBanxaFundingOptions(selectedCrypto).then(data => {
        const modifiedFundingOptions = data?.data?.payment_methods?.map(
          method => ({
            ...method,
            value: method.id,
            title: method.name
          })
        );
        setFundingOptions(modifiedFundingOptions);

        const highestMin =
          data?.data?.payment_methods?.reduce((max, item) => {
            const currentMin = parseFloat(item?.transaction_limits[0]?.min);
            return currentMin > max ? currentMin : max;
          }, 0) || 0;

        setBanxaMinAmount(highestMin);
      });
    }
  }, [selectedCrypto]);

  const handleFundingOption = fundingOption => {
    setSelectedFundingOption(fundingOption);
  };

  // lets handle the AUD amount input and update the state variable dollarAmount after a user stops typing for 1 second
  const [dollarAmount, setDollarAmount] = useState(null);

  const debouncedSetDollarAmount = debounce(value => {
    console.log(typeof Number(value), Number(value));
    setDollarAmount(Number(value));
  }, 500);

  const handleAmountChange = e => {
    debouncedSetDollarAmount(e.target.value);
  };

  // when a funding option is selected we get the price for that option

  const [priceData, setPriceData] = useState(null);

  useEffect(() => {
    if (selectedFundingOption && dollarAmount >= banxaMinAmount) {
      getBanxaPrices(dollarAmount, 'AUD', selectedCrypto).then(data => {
        const priceInfo = data.data.prices.find(
          price => price.payment_method_id === selectedFundingOption
        );

        setPriceData(priceInfo);
      });
    }
  }, [selectedFundingOption, dollarAmount]);

  const [isChecked, setIsChecked] = useState(false);

  const submitOrder = () => {
    const payload = {
      userId: user.userInfo.UserId,
      accountId: user.userInfo.AccountId,
      email: user.userInfo.Email,
      paymentMethodId: selectedFundingOption,
      source: 'AUD',
      sourceAmount: dollarAmount,
      target: selectedCrypto.toString()
    };

    createBanxaOrder(
      user.userInfo.UserId,
      user.userInfo.AccountId,
      user.userInfo.Email,
      selectedFundingOption,
      'AUD',
      dollarAmount,
      selectedCrypto.toString()
    )
      .then(response => {
        if (!response.data) {
          throw new Error('Network response was not ok');
        }
        return response.data; // Directly return the parsed data without calling .json()
      })
      .then(result => {
        if (result && result.order.checkout_url) {
          window.location.href = result.order.checkout_url; // Use the checkout URL for redirection
        }
      })
      .catch(error => {
        if (error instanceof SyntaxError) {
          console.error('Error parsing JSON:', error);
        } else if (error.message === 'Network response was not ok') {
          console.error('Network error:', error);
        } else {
          console.error('Error during order creation:', error);
        }
      });
  };

  return (
    <>
      <Dropdown
        options={banxaBuyCryptos}
        defaultValue="Select crypto"
        selectedOption={selectedCrypto}
        setSelectedOption={handleSelectedCrypto}
        customClass={baseClasses('dropdown')}
        label="Step 1: Select a crypto"
      />

      {selectedCrypto && fundingOptions && (
        <Dropdown
          options={fundingOptions}
          defaultValue="Select funding option"
          selectedOption={selectedFundingOption}
          setSelectedOption={handleFundingOption}
          customClass={baseClasses('dropdown')}
          label="Step 2: Select a funding option"
        />
      )}

      {selectedFundingOption && (
        <Input
          type="number"
          label="Step 3: How much AUD you like to spend?"
          customClass={baseClasses('input')}
          variant="dollarSign"
          onChange={handleAmountChange}
        />
      )}

      {dollarAmount && dollarAmount < banxaMinAmount && (
        <p style={{ color: 'red' }}>
          The minimum amount you can spend is {banxaMinAmount} AUD.
        </p>
      )}

      {priceData && dollarAmount >= banxaMinAmount && (
        <div className={baseClasses('price-info')}>
          You will receive: {priceData.coin_amount} {priceData.coin_code}
        </div>
      )}

      {dollarAmount && dollarAmount >= banxaMinAmount && (
        <>
          <div className="banxa-disclaimer">
            <p className="banxa-form-label" style={{ fontSize: '12px' }}>
              You are now leaving the dcx platform for Banxa (
              <a href="https://banxa.com" target="_blank">
                https://banxa.com
              </a>
              ), which handles our Apple Pay, Google Pay, and credit card
              payments. By proceeding and using Banxa's services, you agree to
              their{' '}
              <a
                href="https://banxa.com/wp-content/uploads/2024/01/Customer-Terms-and-Conditions-22-January-2024-BANXA.pdf"
                target="_blank"
              >
                Customer Terms and Conditions
              </a>
              ,
              <a
                href="https://banxa.com/wp-content/uploads/2024/03/Privacy-and-Cookies-Policy-3-March-2024-Banxa.pdf"
                target="_blank"
              >
                Privacy and Cookies Policy
              </a>
              , and
              <a
                href="https://banxa.com/wp-content/uploads/2023/12/Biometric-Privacy-Disclosure-and-Consent.pdf"
                target="_blank"
              >
                Biometric Privacy Disclosure and Consent
              </a>
              . If you have any questions about Banxa's services, please visit{' '}
              <a
                href="https://support.banxa.com/en/support/home"
                target="_blank"
              >
                support.banxa.com
              </a>
              .
            </p>
          </div>
          <div>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              style={{ marginRight: '12px' }}
            />
            I have read and agree to the disclaimer.
          </div>
        </>
      )}

      {isChecked && (
        <button className={baseClasses('submit-btn')} onClick={submitOrder}>
          Submit
        </button>
      )}
    </>
  );
};

export default Banxa;
