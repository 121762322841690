import axios from 'axios';
// apiUtils.js
const baseUrl = 'https://beta-dev.dcxmarkets.com:443/monoova/api/';
const username = 'hM5OANHKZtFA28XaU.Y9';

const getHeaders = () => {
  const auth = Buffer.from(username.concat(':')).toString('base64');
  return {
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Basic ${auth}`
  };
};

const createUserAcct = async (method, payload) => {
  const headers = getHeaders();
  const response = await fetch(`${baseUrl}${method}`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: headers
  });

  return await response.json();
};

const getMonoovaAcctDetails = async acctId => {
  try {
    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_DCX_MONOOVA}/getAccountDetails`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic aE01T0FOSEtadEZBMjhYYVUuWTk6'
      },
      data: {
        accountId: acctId
      }
    };

    console.log('requesting Monoova account details from ' + config.url);

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    if (error.response) {
      console.log('Server Error Response:', error.response.data);
    } else if (error.request) {
      console.log('Request made but no response received:', error.request);
    } else {
      console.log('Error setting up the request:', error.message);
    }
  }
};

export { createUserAcct, getMonoovaAcctDetails };
