import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import APTable from 'apex-web/lib/components/common/APTable';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import MobileOrderHistoryList from '../MobileOrderHistoryList/MobileOrderHistoryList';

const baseClass = getBEMClasses('order-history-table');

const DepositsStatusComponent = (props, context) => {
  useEffect(() => {
    props.getDepositTickets(props.config.maxLines);
  }, [props.selectedAccountId]);

  const { data, decimalPlaces, config, isMobile } = props;
  const { maxLines, usePagination } = config;

  const columns = [
    {
      header: context.t('Product'),
      dataTest: 'Product',
      cell: row => row.AssetName
    },
    {
      header: context.t('Amount'),
      dataTest: 'Amount',
      cell: row => formatNumberToLocale(row.Amount, decimalPlaces[row.AssetId])
    },
    {
      header: context.t('Status'),
      dataTest: 'Status',
      cell: row => context.t(row.Status)
    },
    {
      header: context.t('Transaction Id'),
      dataTest: 'Transaction Id',
      cell: row => row.TicketNumber
    },
    {
      header: context.t('To Address'),
      dataTest: 'To Address',
      cell: row => {
        let addr = '-';
        try {
          const data = JSON.parse(row.DepositInfo);
          if (data.ToAddress) {
            addr = data.ToAddress;
          }
        } catch (e) {
          //
        }
        return <span className="address">{addr}</span>;
      }
    },
    {
      header: context.t('Created'),
      dataTest: 'Created',
      cell: row => formatDateTime(row.CreatedTimestamp)
    },
    {
      header: context.t('Fee'),
      dataTest: 'Fee',
      cell: row => row.FeeAmt
    }
  ];

  const mobileRows = [
    {
      cell: row => row.AssetName
    },
    {
      cell: row => formatNumberToLocale(row.Amount, decimalPlaces[row.AssetId])
    }
  ];

  return isMobile ? (
    <MobileOrderHistoryList
      rows={data}
      columns={columns}
      rowsContent={mobileRows}
      pageSize={maxLines}
      empty={context.t('No data is available')}
      onRequestPrev={() =>
        props.getDepositTickets(
          props.config.maxLines,
          props.depositStartIndex - props.config.maxLines
        )
      }
      onRequestNext={() =>
        props.getDepositTickets(
          props.config.maxLines,
          props.depositStartIndex + props.config.maxLines
        )
      }
      componentName={'DepositStatusComponent'}
    />
  ) : (
    <APTable
      {...{
        columns,
        rows: data,
        totalPages: data.length,
        baseClass,
        pageSize: maxLines,
        usePagination,
        alwaysShowPagination: true,
        useSimplePagination: true,
        empty: context.t('No data is available'),
        componentName: 'DepositStatusComponent',
        onRequestPrev: () => {
          props.getDepositTickets(
            props.config.maxLines,
            props.depositStartIndex - props.config.maxLines
          );
        },
        onRequestNext: () => {
          props.getDepositTickets(
            props.config.maxLines,
            props.depositStartIndex + props.config.maxLines
          );
        }
      }}
    />
  );
};

/*
  context.t('Deposit Status');
 */
DepositsStatusComponent.title = 'Deposit Status';
DepositsStatusComponent.componentName = 'DepositsStatusComponent';

DepositsStatusComponent.propTypes = {
  data: PropTypes.array.isRequired,
  decimalPlaces: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired
};

DepositsStatusComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DepositsStatusComponent;
