import React from 'react';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import WalletRowListContainer from '../../dcxComponents/WalletRowList/WalletRowListContainer';
import PageLayout from '../../layouts/PageLayout/PageLayout';

const WalletsPage = () => {
  return (
    <PageLayout
      title="Wallets"
      subTitle="Here’s where you can keep an eye on all your crypto."
    >
      <WalletRowListContainer />
    </PageLayout>
  );
};

export default withAuthentication(WalletsPage);
