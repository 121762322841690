import React from 'react';
import PropTypes from 'prop-types';

import GenericPaymentWorkflowContainer from './GenericPaymentWorkflow/GenericPaymentWorkflowContainer';
import CryptoWalletDepositWorkflowContainer from './CryptoWalletDepositWorkflow/CryptoWalletDepositWorkflowContainer';
import ManualDepositWorkflowContainer from './ManualDepositWorkflow/ManualDepositWorkflowContainer';

const DepositWorkflowsComponent = props => {
  const {
    templateInfo: { DepositWorkflow },
    submitting = false,
    isLoading,
    onSubmit = () => {}
  } = props;
  let WorkflowComponent = null;

  if (isLoading) {
    return null;
  }

  switch (DepositWorkflow) {
    case 'CryptoWallet': {
      WorkflowComponent = <CryptoWalletDepositWorkflowContainer />;
      break;
    }
    case 'ManualDeposit': {
      WorkflowComponent = (
        <ManualDepositWorkflowContainer
          submitting={submitting}
          onSubmit={onSubmit}
        />
      );
      break;
    }
    case 'GenericPayment': {
      WorkflowComponent = <GenericPaymentWorkflowContainer />;
      break;
    }
    default: {
      break;
    }
  }
  return WorkflowComponent;
};

export default DepositWorkflowsComponent;
