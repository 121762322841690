import React from 'react';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import SearchIcon from '../../../images/icons/icon-search.svg';
import './SearchInput.css';

const baseClasses = getBEMClasses('search-input');

const SearchInput = ({ placeholder = 'Search for coin', value, setValue }) => {
  return (
    <div className={baseClasses()}>
      <img src={SearchIcon} />
      <input
        className={baseClasses('input')}
        placeholder={placeholder}
        value={value}
        onChange={e => setValue(e.target.value)}
        onClick={e => e.stopPropagation()}
      />
    </div>
  );
};

export default SearchInput;
