import { connect } from 'react-redux';
import InstrumentOverviewComponent from './InstrumentOverviewComponent';
import get from 'lodash/get';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

const mapStateToProps = (state, ownProps) => {
  const selectedInstrument =
    ownProps?.selectedInstrument || selectedInstrumentSelector(state);
  const { level1 } = state.apexCore ? state.apexCore : {};
  const selectedInstrumentId = get(selectedInstrument, 'InstrumentId', '');
  const instrumentLevel1Data = level1[selectedInstrumentId];

  const lastPrice = formatNumberToLocale(
    ownProps.price || instrumentLevel1Data?.LastTradedPx,
    convertIncrementToIntDecimalPlaces(
      get(selectedInstrument, 'PriceIncrement', 0)
    )
  );

  return {
    selectedInstrument,
    lastPrice
  };
};

export default connect(mapStateToProps)(InstrumentOverviewComponent);
