import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import APVerticalScroller from 'apex-web/lib/components/common/APVerticalScroller/APVerticalScroller';
import APInlineButton from 'apex-web/lib/components/common/APInlineButton';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { isFirefox } from 'apex-web/lib/helpers/browserHelper';
import Level2DataTableComponent from './Level2DataTableComponent';
import './OrderBookComponent.css';

const orderBookClasses = getBEMClasses('dcx-orderbook');

const OrderBookComponent = (props, context) => {
  const {
    spread,
    selectedInstrument,
    cancelOrders,
    level2: { buy, sell },
    buyPriceClicked,
    buyQuantityClicked,
    sellPriceClicked,
    sellQuantityClicked,
    fetching,
    selectedInstrumentId,
    convertedQuantityIncrement,
    convertedPriceIncrement
  } = props;

  const [showCenterButton, setShowCenterButton] = useState(false); //  will change to true when user scrolls away from rest position
  const buyScroller = useRef(null);
  const sellScroller = useRef(null);

  useEffect(() => {
    onClickCenter(true);
  }, [selectedInstrumentId]);

  useEffect(() => {
    window.addEventListener('resize', resizeEventListener);
    onClickCenter();
    return () => {
      window.removeEventListener('resize', resizeEventListener);
    };
  }, []);

  const onClickCenter = (overrideUserScrolled = false) => {
    if (sellScroller.current && buyScroller.current) {
      const scrollToRest = () => {
        sellScroller.current.scrollToRest();
        buyScroller.current.scrollToRest();
      };

      if (overrideUserScrolled)
        return sellScroller.current.resetUserScrolled(() =>
          buyScroller.current.resetUserScrolled(scrollToRest)
        );
      scrollToRest();
    }
  };

  const resizeEventListener = () => onClickCenter(true);

  const onScrollRestChange = value => {
    // show "center" button when user scrolls away from rest position
    setTimeout(handleScrollRestChange, 230);
  };

  const handleScrollRestChange = () => {
    if (!!sellScroller.current) {
      const sellIsAtRest = sellScroller.current.getIsAtRest();
      const buyIsAtRest = buyScroller.current.getIsAtRest();

      setShowCenterButton(!sellIsAtRest || !buyIsAtRest);
    }
  };

  const renderCenterButton = () => (
    <div className={orderBookClasses('spread-row')}>
      <span>
        {context.t('Spread')} {spread}
      </span>
      {showCenterButton && (
        <APInlineButton
          icon="center"
          iconModifier="small"
          text={context.t('Center')}
          onClick={() => onClickCenter(true)}
          customClass={orderBookClasses('center-button')}
        />
      )}
    </div>
  );

  return (
    <div className={orderBookClasses()}>
      <div className={orderBookClasses('body')}>
        <div className={orderBookClasses('column')}>
          <div className={orderBookClasses('column-header')}>
            <div className={orderBookClasses('column-header-row', 'buyers')}>
              Buyers
            </div>
            <div className={orderBookClasses('column-header-row')}>
              <div className={orderBookClasses('column-header-title', 'qty')}>
                {context.t('Volume')}{' '}
                {`(${selectedInstrument?.Product1Symbol})`}
              </div>
              <div className={orderBookClasses('column-header-title', 'price')}>
                {context.t('Price')} {`(${selectedInstrument?.Product2Symbol})`}
              </div>
            </div>
          </div>
          <APVerticalScroller
            ref={buyScroller}
            restPosition="bottom"
            onScrollRestChange={onScrollRestChange}
            customClass={orderBookClasses(
              'vertical-scroller-body',
              isFirefox() ? 'firefox' : ''
            )}
          >
            <div className="dcx-orderbook__table-filler">
              <Level2DataTableComponent
                quantityDecimalPlaces={convertedQuantityIncrement}
                priceDecimalPlaces={convertedPriceIncrement}
                selectedInstrument={selectedInstrument}
                cancelOrders={cancelOrders}
                onPriceClick={buyPriceClicked}
                onQuantityClick={buyQuantityClicked}
                level2Data={buy}
                classModifier={'buy'}
                fetching={fetching}
              />
            </div>
          </APVerticalScroller>
        </div>
        <div className={orderBookClasses('column')}>
          <div className={orderBookClasses('column-header')}>
            <div className={orderBookClasses('column-header-row', 'sellers')}>
              Sellers
            </div>
            <div className={orderBookClasses('column-header-row')}>
              <div className={orderBookClasses('column-header-title', 'qty')}>
                {context.t('Price')} {`(${selectedInstrument?.Product2Symbol})`}
              </div>
              <div className={orderBookClasses('column-header-title', 'price')}>
                {context.t('Volume')}{' '}
                {`(${selectedInstrument?.Product1Symbol})`}
              </div>
            </div>
          </div>
          <APVerticalScroller
            ref={sellScroller}
            restPosition="bottom"
            onScrollRestChange={onScrollRestChange}
            customClass={orderBookClasses(
              'vertical-scroller-body',
              isFirefox() ? 'firefox' : ''
            )}
          >
            <div className="dcx-orderbook__table-filler">
              <Level2DataTableComponent
                quantityDecimalPlaces={convertedQuantityIncrement}
                priceDecimalPlaces={convertedPriceIncrement}
                selectedInstrument={selectedInstrument}
                cancelOrders={cancelOrders}
                onPriceClick={sellPriceClicked}
                onQuantityClick={sellQuantityClicked}
                level2Data={sell}
                classModifier={'sell'}
                fetching={fetching}
              />
            </div>
          </APVerticalScroller>
        </div>
      </div>
    </div>
  );
};

OrderBookComponent.defaultProps = {
  level2: {
    buy: [],
    sell: [],
    short: [],
    unknown: []
  }
};

OrderBookComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default OrderBookComponent;
