import React from 'react';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import APLogo from 'apex-web/lib/components/common/APLogo/APLogo';
import PageHeaderNavComponent from '../../components/PageHeader/PageHeaderNavComponent';
import UserSummaryContainer from '../../components/UserSummary/UserSummaryContainer';
import './PageHeaderLayout.css';
import './UserSummaryOverrides.css';
import DCXNav from './DCXNav';

import { Link } from 'react-router-dom';

const pageHeaderClasses = getBEMClasses('page-header');

const MainNavItems = [
  { text: 'Buy / Sell', icon: 'buy-sell', path: '/buy-sell' },
  // { text: 'Dashboard', icon: 'dashboard', path: '/dashboard' },
  // { text: 'Assets', icon: 'buy-sell', path: '/digital-assets' },
  // { text: 'dcx+', icon: 'trading', path: '/exchange' },
  // { text: 'Balances', icon: 'balance', path: '/balances' },
  // { text: 'Streaming', icon: 'activity', path: '/eotc' },
  // { text: 'Lending', icon: 'sell', path: '/iba' },
  { text: 'Portfolio', icon: 'reports', path: '/portfolio' },
  { text: 'Wallets', icon: 'wallet', path: '/wallets' },
  // { text: "Deposit / Withdraw", icon: "balance", path: "/transfer" },
  // { text: 'Activity', icon: 'activity', path: '/asset-activity-details' }
  { text: 'My dcx', icon: 'settings', path: '/settings/user' }
];

const PageHeaderComponent = () => {
  return (
    <React.Fragment>
      <DCXNav />

      <div className={pageHeaderClasses('container')}>
        <div className={pageHeaderClasses('content-wrapper')}>
          {/* <div className={pageHeaderClasses('header-item', 'logo')} /> */}
          <div className="desktopNav">
            <div className="page-header-nav-custom-container">
              <PageHeaderNavComponent navItems={MainNavItems} />
              <Link to={'/transfer'} className="customDepositButtonContainer">
                <div className="customDepositButton">Transfer funds</div>
              </Link>
            </div>
          </div>
          {/* <div className={pageHeaderClasses('right-panel')}>
            <UserSummaryContainer
              customClass="page-header-user-summary"
              settingsRoute="/settings/user"
            />
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageHeaderComponent;
