import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux'; // Step 1
import PageFooterLayout from '../layouts/PageFooterLayout/PageFooterLayout';
import SettingsMenuComponent from '../components/SettingsMenu/SettingsMenuComponent';
import UserSettingsPage from './UserSettingsPage';
import TradeReportPage from './TradeReportPage';
import TradeSettingsPage from 'apex-web/lib/components/Settings/TradeSettings';
import APIKeysPage from './APIKeysPage';
import UserContactsPage from './UserContactsPage';
import AffiliateProgramPage from './AffiliateProgramPage';
import LoyaltyTokenPage from './LoyaltyTokenPage';
import DemoSettingsContainer from 'apex-web/lib/components/Settings/DemoSettings/DemoSettingsContainer';
import InterestBearingAccountsSettingsContainer from 'apex-web/lib/components/Settings/InterestBearingAccounts/';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import KYCSettings from 'apex-web/lib/components/KYCSettings';
import './SettingsPage.css';

const settingsPageClasses = getBEMClasses('settings-page');

const SettingsPage = ({ user }) => {
  const [name, setName] = useState({});

  // find the firstName key in user.userConfig and console.log it
  useEffect(() => {
    setTimeout(() => {
      setName(user.userConfig);
    }, 100);
  }, [user]);

  return (
    <React.Fragment>
      <div className={settingsPageClasses('container')}>
        <div className={settingsPageClasses('menu')}>
          <div className="settings-welcome-back">
            <h1 className={settingsPageClasses('title')}>Welcome back</h1>
            <span className={settingsPageClasses('username')}>
              {name && name[1] && name[1].Value !== 'fail'
                ? name[1].Value
                : null}
            </span>
          </div>
          <SettingsMenuComponent />
        </div>
        <div className={settingsPageClasses('content')}>
          <Route path={'/settings/user'} component={UserSettingsPage} />
          {/* <Route path="/settings/trade" component={TradeSettingsPage} />
        <Route
          path="/settings/iba"
          component={InterestBearingAccountsSettingsContainer}
        /> */}
          <Route path={'/settings/trade-reports'} component={TradeReportPage} />
          <Route path={'/settings/api-keys'} component={APIKeysPage} />
          <Route path={'/settings/contacts'} component={UserContactsPage} />
          {/* <Route path={'/settings/affiliate'} component={AffiliateProgramPage} />
        <Route path={'/settings/loyalty-token'} component={LoyaltyTokenPage} /> */}
          <Route
            path={'/settings/verification-level'}
            component={KYCSettings}
          />
          {/* <Route
          path={'/settings/demo-settings'}
          component={DemoSettingsContainer}
        /> */}
        </div>
      </div>
      <div className={settingsPageClasses('footer')}>
        <PageFooterLayout />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(SettingsPage); // Step 3
