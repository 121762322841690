import React from 'react';
// import ApexChartContainer from './ApexChart/ApexChartContainer';
import TVChartContainer from './TVChart/TVChartContainer';
// import LightWeightChartContainer from './LightWeightCharts/LightWeightChartContainer';
// import config from 'apex-web/lib/config';

// const chartOptions = {
//   APEX: <ApexChartContainer />,
//   TradingView: <TVChartContainer />,
//   LightWeight: <LightWeightChartContainer />
// };

const PriceChartContainer = props => {
  // let chart = config.TradingLayout.chart;
  // if (props.chart) chart = props.chart;
  return <TVChartContainer />;
};

export default PriceChartContainer;
