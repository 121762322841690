import React from 'react';
import PropTypes from 'prop-types';
import RemoveApiKeyModalContainer from 'apex-web/lib/components/ApiKeysSidePaneComponents/RemoveApiKeyModal/RemoveApiKeyModalContainer';
import APTable from 'apex-web/lib/components/common/APTable';
import APIcon from 'apex-web/lib/components/common/APIcon';
import CancelAPButton from 'apex-web/lib/components/common/CancelAPButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './ApiKeyListComponent.css';

const ApiKeyListComponent = (props, context) => {
  const baseClasses = getBEMClasses('api-key-list', props.customClass);
  const formatPermissionValue = (permissions, permissionName) =>
    permissions.indexOf(permissionName) !== -1 ? (
      <p className={baseClasses('check')}>&#10003;</p>
    ) : (
      ''
    );

  const columns = [
    {
      header: context.t('Key'),
      cell: row => (
        <div className={baseClasses('text-item')}>
          {props.customClass && (
            <APIcon name="bigKey" customClass={baseClasses('icon')} />
          )}
          {row.APIKey}
        </div>
      )
    },
    {
      header: context.t('Deposits'),
      cell: row => formatPermissionValue(row.Permissions, 'Deposit')
    },
    {
      header: context.t('Withdraws'),
      cell: row => formatPermissionValue(row.Permissions, 'Withdraw')
    },
    {
      header: context.t('Trading'),
      cell: row => formatPermissionValue(row.Permissions, 'Trading')
    },
    {
      header: context.t('Read-only'),
      cell: row => formatPermissionValue(row.Permissions, 'AccountReadOnly')
    },
    {
      header: '',
      cell: row => (
        <CancelAPButton
          customClass={baseClasses()}
          text={context.t('Delete')}
          onClick={() => props.removeApiKey(row.APIKey)}
        />
      )
    }
  ];

  return (
    <div className={baseClasses()}>
      <APTable
        {...{
          componentName: 'ApiKeysComponent',
          columns,
          rows: props.apiKeys,
          baseClass: baseClasses,
          headerOutside: true,
          empty: context.t('No data is available')
        }}
      />
      <RemoveApiKeyModalContainer />
    </div>
  );
};

ApiKeyListComponent.propTypes = {
  apiKeys: PropTypes.array.isRequired,
  removeApiKey: PropTypes.func.isRequired,
  customClass: PropTypes.string
};

ApiKeyListComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ApiKeyListComponent;
