import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import FiatDepositFormComponent from './FiatDepositFormComponent';
import {
  createDepositTicket,
  fetchProductDepositTemplate,
  fetchAllProductDepositTemplates
} from 'apex-web/lib/redux/actions/depositActions';
import {
  openModal,
  MODAL_TYPES
} from 'apex-web/lib/redux/actions/modalActions';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { closeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import { selectPosition } from 'apex-web/lib/redux/actions/positionActions';
import { validateDepositTicketPayload } from 'apex-web/lib/helpers/createFiatDepositHelper';
import config from 'apex-web/lib/config';

const mapStateToProps = state => {
  const { disableDeposit } = kycVerificationLevelsSelector(state);
  return {
    disableDeposit,
    depositInfo: state.deposit,
    products: state.apexCore.product,
    allowTransfer: config.global.allowTransfer
  };
};

const mapDispatchToProps = dispatch => ({
  selectPosition: productId => dispatch(selectPosition(productId)),
  confirmDeposit: createDepositTicket,
  verificationOnClick: () => dispatch(closeSidePane()),
  selectDepositProduct: productId =>
    dispatch(fetchAllProductDepositTemplates(productId)),
  selectDepositTemplate: (ProductId, AccountProviderId) =>
    dispatch(fetchProductDepositTemplate(ProductId, AccountProviderId))
});

const FiatDepositForm = reduxForm({
  form: 'createFiatDeposit',
  onSubmit: (
    payload,
    dispatch,
    { depositInfo: { template, templateInfo }, registeredFields }
  ) =>
    dispatch(
      openModal(MODAL_TYPES.CONFIRM_FIAT_DEPOSIT, {
        payload: validateDepositTicketPayload(payload, registeredFields),
        template,
        templateInfo
      })
    )
})(FiatDepositFormComponent);

export default connect(mapStateToProps, mapDispatchToProps)(FiatDepositForm);
