import React from 'react';
import PropTypes from 'prop-types';
// import APIcon from "apex-web/lib/components/components/common/APIcon";
import { getBEMClasses } from '../helpers/cssClassesHelper';

import CustomProblemLoggingInFormContainer from '../components/CustomProblemLoggingInFormContainer';

import '../styles/components/common/StandaloneForm.css';
import './LoginPage.css';

export const ProblemLoggingIn = (props, context) => {
  return (
    <div className="dcx-login-form">
      <CustomProblemLoggingInFormContainer />;
    </div>
  );
};

ProblemLoggingIn.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ProblemLoggingIn;
