import { connect } from 'react-redux';
import ApiKeyListComponent from './ApiKeyListComponent';
import {
  MODAL_TYPES,
  openModal
} from 'apex-web/lib/redux/actions/modalActions';

const mapStateToProps = state => {
  const { apiKeys } = state.apiKey;

  return {
    apiKeys
  };
};

const mapDispatchToProps = dispatch => ({
  removeApiKey: apiKey =>
    dispatch(openModal(MODAL_TYPES.REMOVE_API_KEY, { apiKey }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiKeyListComponent);
