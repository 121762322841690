import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { templateFormRenderer } from 'apex-web/lib/helpers/formTemplateHelper';
import { required, biggerThanZero } from 'apex-web/lib/helpers/formValidations';
import { showTemplateSelect } from 'apex-web/lib/helpers/withdrawHelper';
import WithdrawWorkflowsContainer from './WithdrawComponents/WithdrawWorkflows/WithdrawWorkflowsContainer';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import APButton from 'apex-web/lib/components/common/APButton';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import APSelect from 'apex-web/lib/components/common/APSelect';
import classnames from 'classnames';
import config from 'apex-web/lib/config';

import './FiatWithdrawFormComponent.css';
import SendFiatTransfer from './SendFiatTransfer/SendFiatTransfer';

const baseClasses = getBEMClasses('dcx-withdraw-form');
const slidePaneClasses = getBEMClasses('fiat-slide-pane');

const FiatWithdrawFormComponent = (props, context) => {
  const {
    product,
    selectWithdrawProduct,
    selectPosition,
    initialize,
    handleSubmit,
    disableWithdraw,
    withdrawInfo,
    withdrawInfo: {
      withdrawWorkflowInfo,
      template,
      templateTypes,
      isLoading,
      error
    },
    product: { ProductSymbol, ProductId },
    balance,
    fee,
    total,
    showDetails,
    verificationOnClick,
    selectWithdrawTemplate,
    allowTransfer,
    products: { DecimalPlaces },
    onChange
  } = props;
  const [isTransfer, setIsTransfer] = useState(false);

  useEffect(() => {
    selectPosition(product.ProductId);

    selectWithdrawProduct(product.ProductId);
  }, [product.ProductId]);

  useEffect(() => {
    initialize({ ...product });
  }, []);

  const toggleTransfer = () => {
    if (config.global.allowTransfer) {
      setIsTransfer(!isTransfer);
    }
  };

  const showTemplateTypeSelect = showTemplateSelect(withdrawInfo);
  const useExternalWithdrawWorkflow =
    withdrawWorkflowInfo && Object.values(withdrawWorkflowInfo).length;
  const templateTypeOptions = [
    { value: '', label: 'Select an option' },
    ...templateTypes.map(template => ({
      value: template.AccountProviderId,
      label: template[config.Withdraw.AccountProviderDisplayKey]
    }))
  ];

  return (
    <React.Fragment>
      {/* We don't need this section for now */}
      {/* <section className={baseClasses('send-to')}>
        <header className={baseClasses('source')}>
          <div className={baseClasses('source-item-with-border')}>
            <div
              className={baseClasses('source-item', {
                selected: !isTransfer
              })}
              onClick={toggleTransfer}
            >
              {context.t('External Account')}
            </div>
          </div>
          {allowTransfer && (
            <div
              className={baseClasses('source-item', {
                selected: isTransfer
              })}
              onClick={toggleTransfer}
            >
              {context.t('Send Transfer')}
            </div>
          )}
        </header>
      </section> */}
      {isTransfer ? (
        <SendFiatTransfer onChange={onChange} />
      ) : (
        <React.Fragment>
          <WithdrawWorkflowsContainer />
          <form
            className={classnames(
              slidePaneClasses('body'),
              baseClasses('body')
            )}
            onSubmit={handleSubmit}
          >
            <div className={baseClasses('form-body')}>
              <div className={baseClasses('frame-container')}>
                <div className={baseClasses('frame')}>
                  {disableWithdraw ? (
                    <VerificationRequiredContainer
                      disabled={disableWithdraw}
                      onClick={verificationOnClick}
                    />
                  ) : (
                    <React.Fragment>
                      {showTemplateTypeSelect && (
                        <APSelect
                          name="TemplateType"
                          customClass={baseClasses()}
                          label={context.t(
                            'Select an option to continue the withdraw process'
                          )}
                          onSelect={value =>
                            selectWithdrawTemplate(ProductId, value)
                          }
                          options={templateTypeOptions}
                        />
                      )}

                      {!showTemplateTypeSelect &&
                        !template.hasOwnProperty('Amount') &&
                        !isLoading && (
                          <APNumberInput
                            type="text"
                            name="Amount"
                            labelInInput={ProductSymbol}
                            label={context.t(
                              `Amount of ${ProductSymbol} to Withdraw`
                            )}
                            decimalPlaces={DecimalPlaces}
                            customClass={baseClasses()}
                            validate={[required, biggerThanZero]}
                          />
                        )}

                      {!useExternalWithdrawWorkflow &&
                        templateFormRenderer(
                          template,
                          baseClasses(''),
                          context,
                          product
                        )}

                      {!showDetails ? (
                        <div className={baseClasses('info-items')}>
                          <APLabelWithText
                            label={context.t('Current Balance:')}
                            text={`${balance} ${ProductSymbol}`}
                            customClass={baseClasses()}
                          />
                          <APLabelWithText
                            label={context.t('Transaction Fee:')}
                            text={fee !== undefined ? fee : '-'}
                            customClass={baseClasses()}
                          />
                          <APLabelWithText
                            label={context.t('Final Balance:')}
                            text={total !== undefined ? total : '-'}
                            customClass={baseClasses()}
                          />
                        </div>
                      ) : null}

                      {error && (
                        <p className={baseClasses('error')}>
                          {context.t(error)}
                        </p>
                      )}

                      {isLoading && <Spinner customClass={baseClasses} />}
                    </React.Fragment>
                  )}
                </div>
              </div>
              {!showTemplateTypeSelect &&
                !disableWithdraw &&
                !useExternalWithdrawWorkflow && (
                  <div className={baseClasses('footer')}>
                    <APButton
                      customClass={baseClasses()}
                      disabled={disableWithdraw}
                      type="submit"
                      styleName="additive"
                    >
                      {context.t('Withdraw')} {ProductSymbol}
                    </APButton>
                  </div>
                )}
            </div>
          </form>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

FiatWithdrawFormComponent.defaultProps = {
  withdrawInfo: {
    template: {},
    isLoading: false,
    error: '',
    withdrawWorkflowInfo: [],
    withdrawStatus: {}
  }
};

FiatWithdrawFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  showSnack: PropTypes.func,
  withdrawInfo: PropTypes.shape({
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    product: PropTypes.number,
    withdrawWorkflowInfo: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]).isRequired,
    withdrawStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  }),
  disableWithdraw: PropTypes.bool,
  verificationOnClick: PropTypes.func
};

FiatWithdrawFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatWithdrawFormComponent;
