import { createContext, useState, useEffect, useContext } from 'react';
import { getDcxAssets, getDcxInstruments } from '../dcxApi';

export const DcxContext = createContext({
  assets: [],
  instruments: [],
  isDataLoading: true
});

export const DcxProvider = ({ children }) => {
  const [assets, setAssets] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    // Fetch data from getDcxAssets & getDcxInstruments and set it to data state
    setIsDataLoading(true);
    getDcxAssets().then(assets => {
      setAssets(assets);
      getDcxInstruments().then(instruments => {
        const instrumentsWithFullNames = instruments.map(item => {
          const assetByInstrumentId = assets.find(
            asset => asset.instrumentId === item.InstrumentId
          );

          return {
            ...item,
            Product1FullName: assetByInstrumentId.productFullName1,
            Product2FullName: assetByInstrumentId.productFullName2
          };
        });
        setInstruments(instrumentsWithFullNames);
        setIsDataLoading(false);
      });
    });
  }, []);

  return (
    <DcxContext.Provider value={{ assets, instruments, isDataLoading }}>
      {children}
    </DcxContext.Provider>
  );
};

export const useDcxData = () => useContext(DcxContext);
