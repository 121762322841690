import { connect } from 'react-redux';
import SendAddressComponent from './SendAddressComponent';
import {
  fetchProductWithdrawTemplates,
  fetchProductWithdrawTemplate
} from 'apex-web/lib/redux/actions/withdrawActions';
import config from 'apex-web/lib/config';

const mapStateToProps = state => {
  return {
    withdrawInfo: state.withdraw,
    WalletsDisabledMessage: config.global.WalletsDisabledMessage
  };
};

const mapDispatchToProps = dispatch => ({
  selectWithdrawProduct: productId =>
    dispatch(fetchProductWithdrawTemplates(productId)),
  selectWithdrawTemplate: (ProductId, AccountProviderId) =>
    dispatch(fetchProductWithdrawTemplate(ProductId, AccountProviderId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendAddressComponent);
