import React from 'react';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import InfoIcon from '../../../images/icons/icon-info-sky.svg';
import { Tooltip } from 'react-mdl';
import './Section.css';

const baseClasses = getBEMClasses('page-section');

const Section = ({ children, title, isNotFullHeight, infoText }) => {
  return (
    <div className={baseClasses('', isNotFullHeight && 'not-full-height')}>
      {!!title && (
        <div className={baseClasses('header-wrapper')}>
          <p className={baseClasses('header-title')}>{title}</p>
          {!!infoText && (
            <Tooltip label={infoText} position="top">
              <img src={InfoIcon} />
            </Tooltip>
          )}
        </div>
      )}
      <div className={baseClasses(title ? 'content-with-header' : 'content')}>
        {children}
      </div>
    </div>
  );
};

export { Section };
