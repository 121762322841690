import {
  REQUEST_ACCOUNT_TRANSACTION,
  RECEIVE_ACCOUNT_TRANSACTION
} from '../actions/accountTransactions';

const initialState = {
  transactions: [],
  transactionsPeriod: 'day',
  fetching: true
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case RECEIVE_ACCOUNT_TRANSACTION:
      return {
        ...state,
        fetching: false,
        transactionsPeriod: payload.period,
        transactions: [...payload.transaction]
      };
    case REQUEST_ACCOUNT_TRANSACTION:
      return {
        ...state,
        fetching: true
      };
    default:
      return state;
  }
}
