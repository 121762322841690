import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { getOrderPriceToDisplay } from 'apex-web/lib/helpers/orderHistoryHelper';
import APTable from 'apex-web/lib/components/common/APTable';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import MobileOrderHistoryList from '../MobileOrderHistoryList/MobileOrderHistoryList';

const baseClass = getBEMClasses('order-history-table');

const FilledOrdersComponent = (props, context) => {
  const {
    data,
    instruments,
    selectedAccountId,
    getAccountTrades,
    OMSId,
    config,
    fetching,
    accountTradesStartIndex,
    isMobile
  } = props;
  const { maxLines, usePagination } = config;

  useEffect(() => {
    if (selectedAccountId) {
      getAccountTrades(selectedAccountId, OMSId, maxLines, 0);
    }
  }, [selectedAccountId]);

  const rowProps = row => ({
    selectedInstrument:
      instruments.find(item => item.InstrumentId === row.InstrumentId) || {}
  });

  const columns = useMemo(
    () => [
      {
        header: context.t('ID'),
        dataTest: 'ID',
        cell: row => row.TradeId
      },
      {
        header: context.t('Pair'),
        dataTest: 'Pair',
        cell: (row, { selectedInstrument }) =>
          selectedInstrument ? selectedInstrument.Symbol : ''
      },
      {
        header: context.t('Side'),
        dataTest: 'Side',
        cell: row => (
          <div className={baseClass(row.Side === 'Buy' ? 'buy' : 'sell')}>
            {context.t(row.Side)}
          </div>
        )
      },
      {
        header: context.t('Size'),
        dataTest: 'Size',
        cell: (row, { selectedInstrument }) => (
          <div className={baseClass('size')}>
            {row.Quantity &&
              formatNumberToLocale(
                row.Quantity,
                convertIncrementToIntDecimalPlaces(
                  selectedInstrument.QuantityIncrement
                )
              )}
          </div>
        )
      },
      {
        header: context.t('Price'),
        dataTest: 'Price',
        cell: (row, { selectedInstrument }) => (
          <div className={baseClass('price')}>
            {getOrderPriceToDisplay(
              row,
              convertIncrementToIntDecimalPlaces(
                selectedInstrument.PriceIncrement
              )
            )}
          </div>
        )
      },
      {
        header: context.t('Total'),
        dataTest: 'Total',
        cell: (row, { selectedInstrument }) =>
          formatNumberToLocale(
            row.Value,
            convertIncrementToIntDecimalPlaces(
              selectedInstrument.PriceIncrement
            )
          )
      },
      {
        header: context.t('Fee'),
        dataTest: 'Fee',
        cell: row =>
          `${formatNumberToLocale(row.Fee, row.Fee.symbol)} ${row.Fee.symbol}`
      },
      {
        header: context.t('Execution ID'),
        dataTest: 'Execution ID',
        cell: row => row.ExecutionId
      },
      {
        header: context.t('Date/Time'),
        dataTest: 'Date/Time',
        cell: row => formatDateTime(new Date(row.TradeTimeMS))
      }
    ],
    [instruments?.length]
  );

  const mobileRows = useMemo(
    () => [
      {
        cell: (row, { selectedInstrument }) =>
          selectedInstrument ? selectedInstrument.Symbol : ''
      },
      {
        cell: (row, { selectedInstrument }) => {
          return getOrderPriceToDisplay(
            row,
            convertIncrementToIntDecimalPlaces(
              selectedInstrument.PriceIncrement
            )
          );
        }
      }
    ],
    [instruments?.length]
  );

  return isMobile ? (
    <MobileOrderHistoryList
      rows={data}
      rowProps={rowProps}
      columns={columns}
      rowsContent={mobileRows}
      fetching={fetching}
      pageSize={maxLines}
      empty={context.t('No data is available')}
      onRequestPrev={() =>
        getAccountTrades(
          selectedAccountId,
          OMSId,
          maxLines,
          accountTradesStartIndex - maxLines
        )
      }
      onRequestNext={() =>
        getAccountTrades(
          selectedAccountId,
          OMSId,
          maxLines,
          accountTradesStartIndex + maxLines
        )
      }
      componentName={'FilledOrdersComponent'}
    />
  ) : (
    <APTable
      {...{
        columns,
        rowProps,
        rows: data,
        totalPages: data.length,
        baseClass,
        fetching,
        pageSize: maxLines,
        usePagination,
        alwaysShowPagination: true,
        useSimplePagination: true,
        empty: context.t('No data is available'),
        componentName: 'FilledOrdersComponent',
        onRequestPrev: () => {
          getAccountTrades(
            selectedAccountId,
            OMSId,
            maxLines,
            accountTradesStartIndex - maxLines
          );
        },
        onRequestNext: () => {
          getAccountTrades(
            selectedAccountId,
            OMSId,
            maxLines,
            accountTradesStartIndex + maxLines
          );
        }
      }}
    />
  );
};

/*
 this.context.t('Filled Orders');
 */
FilledOrdersComponent.title = 'Filled Orders';

FilledOrdersComponent.propTypes = {
  data: PropTypes.array.isRequired,
  instruments: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired
};

FilledOrdersComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FilledOrdersComponent;
