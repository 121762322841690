import React from 'react';
import PropTypes from 'prop-types';
import APTable from 'apex-web/lib/components/common/APTable';
import PaddedDecimal from 'apex-web/lib/components/common/PaddedDecimal/PaddedDecimal';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { formatTime } from 'apex-web/lib/helpers/dateHelper';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

import './RecentTradesComponent.css';

const baseClass = getBEMClasses('dcx-recent-trades');

const RecentTradesComponent = (props, context) => {
  const { selectedInstrument, recentTrades, fetching } = props;

  const columns = [
    {
      header: context.t('Time'),
      cell: row => (
        <div className={baseClass('table-time')}>
          {formatTime(row.Timestamp)}
        </div>
      )
    },
    {
      header: context.t('Price'),
      cell: row => {
        return (
          <div
            className={baseClass(
              'table-price',
              row.TakerSide === 0 ? 'buy' : 'sell'
            )}
          >
            {selectedInstrument.Product2Symbol}{' '}
            <PaddedDecimal
              value={row.Price}
              bemMod={row.TakerSide === 0 ? 'buy' : 'sell'}
              decimals={convertIncrementToIntDecimalPlaces(
                selectedInstrument.PriceIncrement
              )}
            />
          </div>
        );
      }
    },
    {
      header: `${context.t('Volume')} (${selectedInstrument.Product1Symbol})`,
      cell: row => (
        <div className={baseClass('table-qty')}>
          <PaddedDecimal
            value={row.Quantity}
            decimals={convertIncrementToIntDecimalPlaces(
              selectedInstrument.QuantityIncrement
            )}
          />
        </div>
      )
    }
  ];

  return (
    <APTable
      {...{
        columns,
        rows: recentTrades,
        baseClass,
        empty: context.t('No recent trades'),
        fetching
      }}
    />
  );
};

RecentTradesComponent.propTypes = {
  recentTrades: PropTypes.arrayOf(
    PropTypes.shape({
      TradeId: PropTypes.number.isRequired,
      TakerSide: PropTypes.number.isRequired,
      Price: PropTypes.object.isRequired,
      Quantity: PropTypes.object.isRequired,
      Timestamp: PropTypes.number.isRequired
    })
  )
};

RecentTradesComponent.defaultProps = {
  recentTrades: []
};

RecentTradesComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default RecentTradesComponent;
