import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { showTemplateSelect } from 'apex-web/lib/helpers/withdrawHelper';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import DepositTemplateFormContainer from './DepositComponents/DepositTemplateFormContainer';
import DepositWorkflowsContainer from './DepositComponents/DepositWorkflows/DepositWorkflowsContainer';
import classnames from 'classnames';
import APSelect from 'apex-web/lib/components/common/APSelect';
import config from 'apex-web/lib/config';
import RequestTransfer from './RequestFiatTransfer';
import './FiatDepositFormComponent.css';

const baseClasses = getBEMClasses('dcx-deposit-form');
const slidePaneClasses = getBEMClasses('fiat-slide-pane');

const FiatDepositFormComponent = (props, context) => {
  const {
    product,
    product: { ProductId },
    selectDepositProduct,
    selectPosition,
    initialize,
    disableDeposit,
    depositInfo: deposit,
    depositInfo: { templateTypes, isLoading, error, depositInfo },
    handleSubmit,
    submitting,
    selectDepositTemplate,
    verificationOnClick,
    allowTransfer
  } = props;
  const [isTransfer, setIsTransfer] = useState(false);

  useEffect(() => {
    selectPosition(ProductId);
    selectDepositProduct(ProductId);
  }, [ProductId]);

  useEffect(() => {
    initialize({ ...product });
  }, []);

  const toggleTransfer = () => {
    if (config.global.allowTransfer) {
      setIsTransfer(!isTransfer);
    }
  };

  const showTemplateTypeSelect = showTemplateSelect(deposit);
  const templateTypeOptions = [
    { value: '', label: 'Select an option' },
    ...templateTypes.map(template => ({
      value: template.AccountProviderId,
      label: template[config.Deposit.AccountProviderDisplayKey]
    }))
  ];
  const useTemplateDepositWorkflow = depositInfo && !depositInfo.length;

  return (
    <React.Fragment>
      <section className={baseClasses('send-to')}>
        <header className={baseClasses('source')}>
          <div className={baseClasses('source-item-with-border')}>
            <div
              className={baseClasses('source-item', {
                selected: !isTransfer
              })}
              onClick={toggleTransfer}
            >
              {context.t('External Account')}
            </div>
          </div>
          {allowTransfer && (
            <div
              className={baseClasses('source-item', {
                selected: isTransfer
              })}
              onClick={toggleTransfer}
            >
              {context.t('Request Transfer')}
            </div>
          )}
        </header>
      </section>
      {isLoading ? (
        <Spinner customClass={baseClasses} />
      ) : (
        <React.Fragment>
          {isTransfer ? (
            <RequestTransfer />
          ) : (
            <React.Fragment>
              <DepositWorkflowsContainer
                onSubmit={handleSubmit}
                submitting={submitting}
              />
              {error && (
                <p className={baseClasses('error')}>{context.t(error)}</p>
              )}
              {useTemplateDepositWorkflow && (
                <form
                  className={classnames(
                    slidePaneClasses('body'),
                    baseClasses('body')
                  )}
                  onSubmit={handleSubmit}
                >
                  <div className={baseClasses('form-body')}>
                    <div className={baseClasses('frame')}>
                      {disableDeposit ? (
                        <VerificationRequiredContainer
                          disabled={disableDeposit}
                          onClick={verificationOnClick}
                        />
                      ) : (
                        <React.Fragment>
                          {showTemplateTypeSelect && (
                            <APSelect
                              name="TemplateType"
                              customClass={baseClasses()}
                              label={context.t(
                                'Select an option to continue the deposit process'
                              )}
                              onSelect={value =>
                                selectDepositTemplate(product.ProductId, value)
                              }
                              options={templateTypeOptions}
                            />
                          )}
                          <DepositTemplateFormContainer
                            submitting={submitting}
                            baseClasses={baseClasses}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </form>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

FiatDepositFormComponent.defaultProps = {
  deposit: {
    templateInfo: {},
    template: {},
    isLoading: false,
    error: '',
    depositInfo: {},
    depositStatus: {}
  }
};

FiatDepositFormComponent.propTypes = {
  disableDeposit: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  showSnack: PropTypes.func,
  deposit: PropTypes.shape({
    templateInfo: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    product: PropTypes.number,
    depositInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    depositStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  }),
  verificationOnClick: PropTypes.func
};

FiatDepositFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatDepositFormComponent;
