import axios from 'axios';

export const getDcxInstruments = async () => {
  const response = await axios.post(
    `${process.env.REACT_APP_DCX_ASSETS}/getInstruments`
  );
  if (response.data?.data) {
    return formatInstruments(response.data.data);
  }
  return [];
};

const formatInstruments = instruments => {
  return instruments.map(item => ({
    OMSId: 1,
    CreatedAt: item.createdAt,
    IsDisable: !item.enable,
    InstrumentId: item.instrumentId,
    InstrumentType: item.instrumentType,
    MinimumPrice: item.minimumPrice,
    MinimumQuantity: item.minimumQuantity,
    PriceIncrement: item.priceIncrement,
    Product1: item.productId1,
    Product2: item.productId2,
    Product1Symbol: item.productSymbol1,
    Product2Symbol: item.productSymbol2,
    QuantityIncrement: item.quantityIncrement,
    Symbol: item.symbol,
    UpdatedAt: item.updatedAt,
    VenueId: item.venueId,
    VenueInstrumentId: item.venueInstrumentId,
    VenueSymbol: item.venueSymbol
  }));
};
