import { connect } from 'react-redux';
import ReceiveComponent from './ReceiveComponent';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { closeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import { productDepositLimitsSelector } from 'apex-web/lib/redux/selectors/depositWithdrawalSelectors';
import { positionByProductIdSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import { isSuperUser } from 'apex-web/lib/redux/selectors/userSelectors';
import { get } from 'lodash';

const mapStateToProps = (state, ownProps) => {
  const hasSuperUserPermission = isSuperUser(state);
  const { disableDeposit } = kycVerificationLevelsSelector(state);
  const {
    DailyDepositLimit,
    DailyDepositNotionalLimit,
    MonthlyDepositLimit,
    MonthlyDepositNotionalLimit,
    YearlyDepositLimit,
    YearlyDepositNotionalLimit
  } = productDepositLimitsSelector(state) || {};

  const productId = get(ownProps, 'product.ProductId', null);
  const {
    TotalDayDeposits,
    TotalDayDepositNotional,
    TotalMonthDeposits,
    TotalMonthDepositNotional,
    TotalYearDeposits,
    TotalYearDepositNotional
  } = positionByProductIdSelector(productId)(state);

  const dailyLimitDisabled =
    (TotalDayDeposits >= DailyDepositLimit && DailyDepositLimit !== -1) ||
    DailyDepositLimit === 0;
  const dailyNotionalLimitDisabled =
    (TotalDayDepositNotional >= DailyDepositNotionalLimit &&
      DailyDepositNotionalLimit !== -1) ||
    DailyDepositNotionalLimit === 0;
  const monthlyLimitDisabled =
    (TotalMonthDeposits >= MonthlyDepositLimit && MonthlyDepositLimit !== -1) ||
    MonthlyDepositLimit === 0;
  const monthlyNotionalLimitDisabled =
    (TotalMonthDepositNotional >= MonthlyDepositNotionalLimit &&
      MonthlyDepositNotionalLimit !== -1) ||
    MonthlyDepositNotionalLimit === 0;
  const yearlyLimitDisabled =
    (TotalYearDeposits >= YearlyDepositLimit && YearlyDepositLimit !== -1) ||
    YearlyDepositLimit === 0;
  const yearlyNotionalLimitDisabled =
    (TotalYearDepositNotional >= YearlyDepositNotionalLimit &&
      YearlyDepositNotionalLimit !== -1) ||
    YearlyDepositNotionalLimit === 0;

  const showDepositLimitWarningMessage =
    (dailyLimitDisabled ||
      dailyNotionalLimitDisabled ||
      monthlyLimitDisabled ||
      monthlyNotionalLimitDisabled ||
      yearlyLimitDisabled ||
      yearlyNotionalLimitDisabled) &&
    !hasSuperUserPermission;

  return { disableDeposit, showDepositLimitWarningMessage };
};

const mapDispatchToProps = dispatch => ({
  verificationOnClick: () => dispatch(closeSidePane())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveComponent);
