import React from 'react';
import PropTypes from 'prop-types';
import CancelAPButton from 'apex-web/lib/components/common/CancelAPButton';
import APTable from 'apex-web/lib/components/common/APTable';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './ScheduledReportsTableComponent.css';

const baseClass = getBEMClasses('schedule-report-list');

const ScheduledReportsTableComponent = (props, context) => {
  const { reports, isLoading, cancelReport } = props;
  const columns = [
    {
      header: context.t('Report Summary'),
      classModifier: 'type',
      cell: row => row.reportFlavor
    },
    {
      header: context.t('Frequency'),
      classModifier: 'frequency',
      cell: row => row.ReportFrequency
    },
    {
      header: context.t('Created'),
      classModifier: 'created',
      cell: row => formatDateTime(row.createTime)
    },
    {
      header: context.t('Actions'),
      classModifier: 'actions',
      cell: row => (
        <CancelAPButton
          customClass={baseClass('action-btn')}
          styleName="subtractive"
          text={context.t('Cancel Report')}
          onClick={() => cancelReport(row.RequestId)}
        />
      )
    }
  ];

  return (
    <div className={baseClass('container')}>
      <APTable
        {...{
          columns,
          baseClass,
          rows: reports,
          empty: context.t('No Report Is Available'),
          fetching: isLoading,
          headerOutside: true
        }}
      />
    </div>
  );
};

ScheduledReportsTableComponent.propTypes = {
  reports: PropTypes.array.isRequired,
  cancelReport: PropTypes.func.isRequired
};

ScheduledReportsTableComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ScheduledReportsTableComponent;
