import React from 'react';
import PropTypes from 'prop-types';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';

export const HyphenDepositWorkflowComponent = (
  { product: { Product }, bankInfo },
  context
) => (
  <React.Fragment>
    <APLabelWithText label={context.t('Bank Name:')} text={bankInfo.bankName} />
    <APLabelWithText
      label={context.t('Wire Number:')}
      text={bankInfo.bankWireNumber}
    />
    <APLabelWithText
      label={context.t('Account Number:')}
      text={bankInfo.bankAccountNumber}
    />
  </React.Fragment>
);

HyphenDepositWorkflowComponent.propTypes = {
  product: PropTypes.shape({
    Product: PropTypes.string.isRequired
  }).isRequired,
  bankInfo: PropTypes.object.isRequired
};

HyphenDepositWorkflowComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default HyphenDepositWorkflowComponent;
