import { connect } from 'react-redux';
import CryptoWalletDepositWorkflowComponent from './CryptoWalletDepositWorkflowComponent';
import { depositProductSelector } from 'apex-web/lib/redux/selectors/depositWithdrawalSelectors';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';

const mapStateToProps = state => ({
  addressList: state.deposit.depositInfo,
  product: depositProductSelector(state)
});

const mapDispatchToProps = dispatch => ({
  showSnack: text =>
    dispatch(showSnack({ id: 'depositCopyAddress', text, type: 'success' }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CryptoWalletDepositWorkflowComponent);
