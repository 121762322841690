import React from 'react';
import PropTypes from 'prop-types';
import ProfileSettingsCardComponent from 'apex-web/lib/components/Settings/ProfileAndSecurity/ProfileSettingsCard';
import RegionSettingsCardComponent from 'apex-web/lib/components/Settings/ProfileAndSecurity/RegionSettingsCard';
import ThemeSettingsCardComponent from '../dcxComponents/Settings/ProfileAndSecurity/ThemeSettingsCard';
import EmailSettingsCardComponent from 'apex-web/lib/components/Settings/ProfileAndSecurity/EmailSettingsCard/EmailSettingsCard';
import PasswordSettingsCardComponent from 'apex-web/lib/components/Settings/ProfileAndSecurity/PasswordSettingsCard';
import TwoFactorAuthSettingsCardComponent from 'apex-web/lib/components/Settings/ProfileAndSecurity/TwoFactorAuthSettingsCard';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './UserSettingsPage.css';

const userSettingsPageClasses = getBEMClasses('user-settings-page');

const UserSettingsPage = (props, context) => (
  <React.Fragment>
    <div className="dcx-page">
      <div className={userSettingsPageClasses('container')}>
        <div className={userSettingsPageClasses('page-header')}>
          <h1 className={userSettingsPageClasses('title')}>
            {context.t('My profile')}
          </h1>
          <h2 className={userSettingsPageClasses('sub-title')}>
            {context.t('Please keep your personal settings private.')}
          </h2>
        </div>
        <div className={userSettingsPageClasses('row')}>
          <ProfileSettingsCardComponent />
          <RegionSettingsCardComponent />
        </div>

        <div className={userSettingsPageClasses('row', 'sm')}>
          <ThemeSettingsCardComponent
            themeOptions={[
              {
                value: 'Light',
                label: context.t('Light')
              }
            ]}
          />
          <EmailSettingsCardComponent />
        </div>

        <div className="two-factor-settings">
          <div className={userSettingsPageClasses('row')}>
            <TwoFactorAuthSettingsCardComponent />
          </div>
        </div>

        <div className={userSettingsPageClasses('row', 'sm')}>
          <PasswordSettingsCardComponent />
        </div>
      </div>
    </div>
  </React.Fragment>
);

UserSettingsPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default UserSettingsPage;
