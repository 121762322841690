import React from 'react';
import { connect } from 'react-redux';
import ReceiveForm from '../../SendReceive/ReceiveForm/ReceiveFormContainer';
import SendReceiveConfirmModal from '../../SendReceive/SendReceiveConfirmModal/SendReceiveConfirmModalComponent';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { requestTransferFunds } from 'apex-web/lib/redux/actions/transferActions';
import { getTransferRequestsRequested } from 'apex-web/lib/redux/actions/transferRequestActions';
import config from 'apex-web/lib/config';

const baseClasses = getBEMClasses('fiat-deposit-form');

class RequestTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      data: {}
    };
  }

  onDataUpdate = data => {
    this.setState({ data });
  };

  onSubmit = data => {
    this.setState({ isOpen: true, data });
  };

  submitData = () => {
    this.props.receiveFunds({
      ProductId: this.props.product.ProductId,
      Notes: '',
      ...this.state.data
    });
  };

  render() {
    const { product } = this.props;
    const { data } = this.state;

    return (
      <div className={baseClasses('send-to-friend')}>
        <ReceiveForm
          product={product}
          onChange={() => {}}
          onSubmit={this.onSubmit}
        />
        <SendReceiveConfirmModal
          product={product}
          values={data}
          total={data.Amount}
          isSend={false}
          isOpen={this.state.isOpen}
          close={() => this.setState({ isOpen: false })}
          onConfirm={this.submitData}
          hideFees={config.global.hideFees}
          useExternalAddress={false}
          TicketAmount={data.Amount}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  product: state.apexCore.product.products.find(
    i => i.ProductId === state.apexCore.position.selectedProductId
  ),
  selectedAccountId: state.user.selectedAccountId
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  receiveFunds: ({ selectedAccountId, ...rest }) =>
    dispatch(requestTransferFunds(rest, ownProps.name)).then(() => {
      console.log(ownProps);
      return dispatch(getTransferRequestsRequested(ownProps.selectedAccountId));
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestTransfer);
