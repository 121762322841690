import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  sendResetPasswordEmail,
  resendVerificationEmail
} from 'apex-web/lib/redux/actions/userActions';
import CustomForgotPasswordFormComponent from '../CustomProblemLoggingInFormComponent';

const CustomProblemLoggingInForm = reduxForm({
  form: 'forgotPassword',
  onSubmit: (payload, dispatch) => {
    const { resetPassword, Email } = payload;
    if (resetPassword) return dispatch(sendResetPasswordEmail(Email));

    return dispatch(resendVerificationEmail({ Email }));
  },
  enableReinitialize: true
})(CustomForgotPasswordFormComponent);

const mapDispatchToProps = dispatch => ({
  sendResetPasswordEmail,
  resendVerificationEmail
});

const CustomProblemLoggingInFormContainer = connect(
  null,
  mapDispatchToProps()
)(CustomProblemLoggingInForm);

export default CustomProblemLoggingInFormContainer;
