import React, { useState } from 'react';
import { connect } from 'react-redux';
import SendForm from '../../../SendReceive/SendForm/SendFormContainer';
import SendReceiveConfirmModal from '../../../SendReceive/SendReceiveConfirmModal/SendReceiveConfirmModalComponent';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import {
  transferFunds,
  getTransfers
} from 'apex-web/lib/redux/actions/transferActions';
import config from 'apex-web/lib/config';

const baseClasses = getBEMClasses('dcx-withdraw-form');

const SendFiatTransfer = props => {
  const { sendFunds, selectedAccountId, product } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});

  const onSubmit = data => {
    setIsOpen(true);
    setData(data);
  };

  const submitData = () => {
    sendFunds({
      selectedAccountId: selectedAccountId,
      ProductId: product.ProductId,
      Notes: '',
      ...data
    });
  };

  const onChange = amount => {
    props.onChange(amount);
  };

  return (
    <div className={baseClasses('send-to-friend')}>
      <SendForm
        product={product}
        onChange={amount => onChange(amount)}
        onSubmit={onSubmit}
        toggleUseExternalAddress={() => {}}
      />
      <SendReceiveConfirmModal
        product={product}
        values={data}
        total={data.Amount}
        isSend={true}
        fee={props.fee}
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        onConfirm={submitData}
        hideFees={config.global.hideFees}
        useExternalAddress={false}
        TicketAmount={data.Amount}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  product: state.apexCore.product.products.find(
    i => i.ProductId === state.apexCore.position.selectedProductId
  ),
  fee: state.withdraw.withdrawFee,
  selectedAccountId: state.user.selectedAccountId
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  sendFunds: ({ selectedAccountId, ...rest }) =>
    dispatch(transferFunds(rest, ownProps.name)).then(() =>
      dispatch(getTransfers(selectedAccountId))
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(SendFiatTransfer);
