import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import './styles.css';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

const baseClasses = getBEMClasses('trading-tabs');

const TABS = [
  { title: 'Easy trading', path: '/buy-sell' },
  { title: 'Detailed trading', path: '/buy-sell/detailed' },
  { title: 'Markets', path: '/buy-sell/markets' }
];

const TradingTabs = ({ history }) => {
  const [activeTabPath, setActiveTabPath] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (location) setActiveTabPath(location.pathname);
  }, [location]);

  return (
    <div className="trading-tabs">
      {TABS.map(item => (
        <div
          key={item.path}
          onClick={() => history.push(item.path)}
          className={baseClasses(
            'single-tab',
            activeTabPath === item.path && 'active'
          )}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default withRouter(TradingTabs);
