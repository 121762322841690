import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import APQRCode from 'apex-web/lib/components/common/APQRCode/APQRCode';
import APIcon from 'apex-web/lib/components/common/APIcon';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import copyToClipboard from 'apex-web/lib/helpers/clipboardHelper';
import destinationTagHelper from 'apex-web/lib/helpers/destinationTagHelper';
import './ReceiveAddress.css';

const bemClasses = getBEMClasses('dcx-receive-address');

const ReceiveAddressComponent = (props, context) => {
  const {
    deposit: { depositInfo: addressList },
    deposit: { isLoading, error },
    product,
    showSnack,
    WalletsDisabledMessage,
    selectDepositProduct
  } = props;

  useEffect(() => {
    selectDepositProduct(product.ProductId);
  }, [product?.ProductId]);

  let selectedAddress;

  if (!isLoading && addressList.length) {
    selectedAddress = addressList[addressList.length - 1];
  }

  const { address, dt, memo } = destinationTagHelper(selectedAddress);

  return (
    <section className={bemClasses()}>
      {selectedAddress && (
        <React.Fragment>
          <p className={bemClasses('description')}>
            {context.t('Scan this QR code to receive funds:')}
          </p>
          <div className={bemClasses('qr-code')}>
            <APQRCode value={`${selectedAddress}`} />
          </div>
          <p className={bemClasses('copy-address-text')}>
            {context.t('Or copy this address:')}
          </p>
          <div
            className={bemClasses('copy-address-payload')}
            onClick={() => {
              copyToClipboard(address);
              showSnack({
                id: 'RECEIVE_ADDRESS_COPY',
                text: context.t('The address has been copied to the clipboard.')
              });
            }}
          >
            <span className={bemClasses('address')}>{address}</span>
            <APIcon name="copy" customClass={bemClasses('copy-icon')} />
          </div>
          {dt && (
            <div
              onClick={() => {
                copyToClipboard(dt);
                showSnack({
                  id: 'RECEIVE_ADDRESS_COPY',
                  text: context.t(
                    'The destination tag has been copied to the clipboard.'
                  )
                });
              }}
            >
              <p className={bemClasses('destination-tag-text')}>
                {context.t('Be sure to include this destination tag:')}
              </p>
              <span className={bemClasses('address')}>{dt}</span>
              <APIcon name="copy" customClass={bemClasses('copy-icon')} />
            </div>
          )}
          {memo && (
            <div
              onClick={() => {
                copyToClipboard(memo);
                showSnack({
                  id: 'RECEIVE_ADDRESS_COPY',
                  text: context.t(
                    'The memo tag has been copied to the clipboard.'
                  )
                });
              }}
            >
              <p className={bemClasses('memo-tag-text')}>
                {context.t('Be sure to include this memo tag:')}
              </p>
              <span className={bemClasses('address')}>{memo}</span>
              <APIcon name="copy" customClass={bemClasses('copy-icon')} />
            </div>
          )}
        </React.Fragment>
      )}
      {isLoading && (
        <Spinner text={context.t('Loading url...')} customClass={bemClasses} />
      )}
      {WalletsDisabledMessage ? (
        <span className={bemClasses('error')}>
          {context.t(WalletsDisabledMessage)}
        </span>
      ) : (
        error && <span className={bemClasses('error')}>{context.t(error)}</span>
      )}
    </section>
  );
};

ReceiveAddressComponent.defaultProps = {
  deposit: {
    templateInfo: {},
    template: {},
    isLoading: false,
    error: '',
    depositInfo: {},
    depositStatus: {}
  },
  product: {}
};

ReceiveAddressComponent.propTypes = {
  product: PropTypes.object,
  deposit: PropTypes.shape({
    templateInfo: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    product: PropTypes.number,
    depositInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    depositStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  })
};

ReceiveAddressComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReceiveAddressComponent;
