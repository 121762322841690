import { connect } from 'react-redux';
import { reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import get from 'lodash/get';
import { preprocessErrorTextForReduxForm } from 'apex-web/lib/helpers/errorTextFormatter';
import {
  signup,
  clearSignupError
} from 'apex-web/lib/redux/actions/signupActions';
import config from '../../config';
import CustomSignupFormComponent from '../CustomSignUpFormComponent';

const CustomSignupFormForm = reduxForm({
  form: 'signup',
  onSubmit: (payload, dispatch) =>
    dispatch(signup(payload)).then(response => {
      if (response && response.errormsg) {
        /**
         * To leverage `redux-form` field-specific error functionality,
         *  we must throw a `SubmissionError` from decorated component's `handleSubmit` prop.
         */
        throw new SubmissionError(
          preprocessErrorTextForReduxForm(response.errormsg, payload)
        );
      }
    })
})(CustomSignupFormComponent);

const selector = formValueSelector('signup');

const mapStateToProps = state => {
  return {
    passwordValue: selector(state, 'password'),
    errorMsg: get(state, 'form.signup.error', false),
    done: !!state.signup.userId,
    isAuthenticated: state.auth.isAuthenticated,
    siteName: config.global.siteName,
    ...config.SignupForm
  };
};

const mapDispatchToProps = {
  clearSignupError
};

const CustomSignupFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomSignupFormForm);

export default CustomSignupFormContainer;
