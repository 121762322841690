import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import APSelect from 'apex-web/lib/components/common/APSelect/APSelect';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

const baseClasses = getBEMClasses('retail-withdraw-form');

const BankSelect = (props, context) => {
  const {
    fetchAccountWithdrawInfos,
    isLoading,
    options,
    resetBankField,
    onSelect
  } = props;

  useEffect(() => {
    fetchAccountWithdrawInfos();
  }, []);

  useEffect(() => {
    if (options.length === 2) {
      const AccountWithdrawInfoId = options[1].value;

      resetBankField(AccountWithdrawInfoId);
      onSelect(AccountWithdrawInfoId);
    }
  }, [isLoading]);

  return (
    <APSelect
      name="Bank"
      customClass={baseClasses()}
      label={context.t('Bank')}
      onSelect={bank => onSelect(bank)}
      options={options}
    />
  );
};

BankSelect.contextTypes = {
  t: PropTypes.func.isRequired
};

export default BankSelect;
