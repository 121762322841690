import { createSelector } from 'reselect';
import { selectBaseCurrencyDecimalPlaces } from './decimalPlacesSelector';
import { selectInstrumentsWithPricesInBaseCurrency } from './level1Selectors';
import BigNumber from 'bignumber.js';

export const selectAccountTransactionsInBaseCurrency = createSelector(
  [
    selectInstrumentsWithPricesInBaseCurrency,
    selectBaseCurrencyDecimalPlaces,
    state => state.accountTransactions.transactions
  ],
  (instrumentsWithPrices, baseCurrencyDecimalPlace, transactions) => {
    const transactionsInBaseCurrency = transactions.map(item => {
      const instrumentByPosition = instrumentsWithPrices.find(
        instrument => instrument.Product1 === item.ProductId
      ) || {
        BestOffer: 1
      };
      const baseCurrencyMultiplier = instrumentByPosition.BestOffer;
      const amountInBaseCurrency = new BigNumber(item.Balance).times(
        baseCurrencyMultiplier
      );

      return {
        ...item,
        amountInBaseCurrency
      };
    });

    return { transactionsInBaseCurrency, baseCurrencyDecimalPlace };
  }
);
