import { loadStartupData } from 'apex-web/lib/redux/actions/appActions';
import {
  CONNECTION_OPENED,
  CONNECTION_CLOSED,
  connectionOpened,
  connectionClosed,
  connectionClosedCount
} from 'apex-web/lib/redux/actions/wsConnectionActions';
import { onInit } from 'apex-web/lib/redux/actions/lifeCycleActions';
import delay from 'lodash/delay';
import { bindActionCreators } from 'redux';
import { initApex } from '../../apex';

// delays[0] will never be used,
// but it gets the rest of the value's indexes to align with wsCloseCount
const delays = [
  0, 10000, 10000, 10000, 30000, 30000, 30000, 60000, 60000, 60000, 120000
];

export default store => next => action => {
  switch (action.type) {
    case CONNECTION_OPENED:
      store.dispatch(connectionClosedCount(0));
      store.dispatch(loadStartupData());
      store.dispatch(onInit());
      break;
    case CONNECTION_CLOSED:
      const wsCloseCount = store.getState().wsConnection.tries + 1;
      let delayTimer =
        wsCloseCount >= delays.length
          ? delays[delays.length - 1]
          : delays[wsCloseCount];

      store.dispatch(connectionClosedCount(wsCloseCount));

      const open = bindActionCreators(connectionOpened, store.dispatch);
      const close = bindActionCreators(connectionClosed, store.dispatch);
      delay(initApex, delayTimer, open, close);
      break;
    default:
      break;
  }

  return next(action);
};
