import { connect } from 'react-redux';
import BankSelect from './BankSelectComponent';
import {
  fetchAccountWithdrawInfos,
  selectWithdrawBank
} from 'apex-web/lib/redux/actions/eotcWithdrawActions';
import { change } from 'redux-form';

const mapStateToProps = state => {
  const options = [
    {
      value: 'default',
      label: 'Not selected'
    },
    ...state.eotcWithdraw.withdrawInfos.map(opt => ({
      value: opt.AccountWithdrawInfoId,
      label: `${opt.Name} - *${opt.AccountIdentifier}`
    }))
  ];

  return {
    isLoading: state.eotcWithdraw.isLoading,
    options
  };
};

const mapDispatchToProps = dispatch => ({
  resetBankField: bank => dispatch(change('createFiatWithdraw', 'Bank', bank)),
  onSelect: bank => dispatch(selectWithdrawBank(bank)),
  fetchAccountWithdrawInfos: () => dispatch(fetchAccountWithdrawInfos())
});

export default connect(mapStateToProps, mapDispatchToProps)(BankSelect);
