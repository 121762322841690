import React, { useState } from 'react';
import resize from 'apex-web/lib/hocs/resize';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import WalletRowContainer from './WalletRow/WalletRowContainer';
import { DepositReferenceIdModalContainer } from 'apex-web/lib/components/Retail/RetailSidePanes/FiatDeposit';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import RefreshIcon from '../../images/icons/icon-refresh-blue.svg';

import './WalletRowList.css';
import TransfersFiltersContainer from '../Transfers/TransfersFilters/TransfersFiltersContainer';
import TransfersContainer from '../Transfers/TransfersContainer';

const walletRowListClasses = getBEMClasses('dcx-wallet-row-list');

const WalletRowListComponent = (props, context) => {
  const { selectPositionAndSave, getTransfers, OMSId, AccountId, width } =
    props;
  const [selectedProduct, setSelectedProduct] = useState(null);
  const isMobile = width <= 900;

  if (props.fetching) {
    return <Spinner customClass={walletRowListClasses} />;
  }

  const items = props.products.map(item => {
    return (
      <WalletRowContainer
        key={item.ProductId}
        productId={item.ProductId}
        onRowClick={() => {
          selectPositionAndSave(item.ProductId);
          setSelectedProduct(item);
        }}
        isMobile={isMobile}
      />
    );
  });

  return (
    <div className={walletRowListClasses()}>
      {selectedProduct ? (
        <div>
          <WalletRowContainer
            key={selectedProduct.ProductId}
            productId={selectedProduct.ProductId}
            onRowClick={() => {
              setSelectedProduct(null);
            }}
            isOpened
            isMobile={isMobile}
          />
          <div className={walletRowListClasses('transfers-content')}>
            <div className={walletRowListClasses('transfers-title-wrapper')}>
              <p className={walletRowListClasses('transfers-title')}>
                {context.t('Transfers')}
              </p>
              <img
                className={walletRowListClasses('refresh')}
                src={RefreshIcon}
                onClick={() => getTransfers(OMSId, AccountId)}
              />
            </div>
            <p className={walletRowListClasses('transfers-sub-title')}>
              All your transfers in one spot
            </p>
            <TransfersFiltersContainer />

            <TransfersContainer />
          </div>
        </div>
      ) : (
        items
      )}
      <DepositReferenceIdModalContainer />
    </div>
  );
};

WalletRowListComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default resize(WalletRowListComponent);
