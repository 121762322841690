import React from 'react';
import TrustpayWithdrawWorkflowContainer from '../PaymentProcessors/TrustpayWithdrawWorkflowContainer';
import HyphenWithdrawWorkflowContainer from '../PaymentProcessors/HyphenWithdrawWorkflowContainer';
import EOTCWithdrawWorkflowContainer from '../../BankSelect/BankSelectContainer';

const WithdrawWorkflowsComponent = props => {
  const { templateType, loading } = props;
  let WorkflowComponent = null;

  switch (templateType) {
    case 'TrustPay': {
      WorkflowComponent = <TrustpayWithdrawWorkflowContainer />;
      break;
    }
    case 'Hyphen': {
      WorkflowComponent = <HyphenWithdrawWorkflowContainer />;
      break;
    }
    case 'EOTC': {
      WorkflowComponent = <EOTCWithdrawWorkflowContainer />;
      break;
    }
    default: {
      break;
    }
  }
  return !loading ? WorkflowComponent : null;
};

export default WithdrawWorkflowsComponent;
