import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import {
  required,
  email,
  lessThanValue,
  validateReceiverEmail
} from 'apex-web/lib/helpers/formValidations';
import SendAddressContainer from 'apex-web/lib/components/SendReceiveSidePane/SendAddress/SendAddressContainer';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import APInput from 'apex-web/lib/components/common/APInput';
import APButton from 'apex-web/lib/components/common/APButton';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import { product as ProductPropTypes } from 'apex-web/lib/propTypes/sendReceiveComponent';
import 'apex-web/lib/components/SendReceiveSidePane/SendForm/SendForm.css';

const bemClasses = getBEMClasses('send-form');

class SendForm extends React.Component {
  lessThanBalance = value => {
    const { balance } = this.props;
    if (balance === 0) return this.context.t('0 balance available');
    return lessThanValue(balance, value);
  };

  checkUserEmail = value => {
    const { userEmail } = this.props;
    return validateReceiverEmail(value, userEmail);
  };

  render() {
    const {
      product: { ProductFullName: name, ProductSymbol: symbol, decimalPlaces },
      handleSubmit,
      invalid,
      submitting,
      onSubmit,
      useExternalAddress,
      toggleUseExternalAddress,
      balance,
      product,
      fee,
      total,
      showDetails,
      disableWithdraw,
      verificationOnClick,
      allowTransfer,
      withdrawError,
      verificationLevelConfig
    } = this.props;

    return (
      <form onSubmit={handleSubmit(onSubmit)} className={bemClasses()}>
        <section className={bemClasses('send-to')}>
          <header className={bemClasses('source')}>
            <div className={bemClasses('source-item-with-border')}>
              <div
                className={bemClasses('source-item', {
                  selected: useExternalAddress
                })}
                onClick={() => {
                  !useExternalAddress && toggleUseExternalAddress();
                }}>
                {this.context.t('External Wallet')}
              </div>
            </div>
            {/* {allowTransfer && (
              <div
                className={bemClasses('source-item', {
                  selected: !useExternalAddress
                })}
                onClick={() => {
                  useExternalAddress && toggleUseExternalAddress();
                }}>
                {this.context.t('To Email Address')}
              </div>
            )} */}
          </header>
        </section>

        {disableWithdraw ? (
          <VerificationRequiredContainer
            disabled={disableWithdraw}
            onClick={verificationOnClick}
          />
        ) : (
          <React.Fragment>
            {useExternalAddress ? (
              <SendAddressContainer
                product={product}
                balance={balance}
                verificationLevelConfig={verificationLevelConfig}
              />
            ) : (
              <React.Fragment>
                <p className={bemClasses('label-text')}>
                  {this.context.t('Recipient’s Email Address')}
                </p>

                <APInput
                  name="ReceiverUsername"
                  customClass={bemClasses()}
                  validate={[required, email, this.checkUserEmail]}
                />
              </React.Fragment>
            )}

            {(!withdrawError || !useExternalAddress) && (
              <React.Fragment>
                <p className={bemClasses('label-text')}>
                  {this.context.t('Amount of {name} to Send', { name })}
                </p>

                <section className={bemClasses('amounts')}>
                  <APNumberInput
                    name="Amount"
                    customClass={bemClasses()}
                    labelInInput={symbol}
                    decimalPlaces={decimalPlaces}
                    validate={[required, this.lessThanBalance]}
                  />
                </section>
              </React.Fragment>
            )}

            {!showDetails && (
              <section className={bemClasses('info-items')}>
                <APLabelWithText
                  label={this.context.t('Current Balance:')}
                  text={balance}
                  customClass={bemClasses()}
                />
                <APLabelWithText
                  label={this.context.t('Transaction Fee:')}
                  text={fee !== undefined ? fee : '-'}
                  customClass={bemClasses()}
                />
                <APLabelWithText
                  label={this.context.t('Total Amount:')}
                  text={total !== undefined ? `${total} ${symbol}` : '-'}
                  customClass={bemClasses()}
                />
              </section>
            )}

            {!useExternalAddress && (
              <section className={bemClasses('note')}>
                <p className={bemClasses('label-text')}>
                  {this.context.t('Add a Note ')}
                  <em>{this.context.t('(Optional)')}</em>
                </p>

                <APInput name="Notes" rows={3} customClass={bemClasses()} />
              </section>
            )}

            {(!withdrawError || !useExternalAddress) && (
              <APButton
                type="submit"
                customClass={bemClasses()}
                styleName="additive"
                disabled={invalid || submitting}>
                {this.context.t('Send')} {name}
              </APButton>
            )}
          </React.Fragment>
        )}
      </form>
    );
  }
}

SendForm.propTypes = {
  product: ProductPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  useExternalAddress: PropTypes.bool.isRequired,
  balance: PropTypes.number.isRequired,
  toggleUseExternalAddress: PropTypes.func.isRequired,
  allowTransfer: PropTypes.bool.isRequired,
  fee: PropTypes.number,
  total: PropTypes.number,
  disableWithdraw: PropTypes.bool,
  verificationOnClick: PropTypes.func
};

SendForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendForm;
