import React from 'react';
import PropTypes from 'prop-types';
import { formatDate, formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import APIconButton from 'apex-web/lib/components/common/APIconButton/APIconButton';
import DownloadAPButton from 'apex-web/lib/components/common/DownloadAPButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import subDays from 'date-fns/sub_days';
import APTable from 'apex-web/lib/components/common/APTable';

import './DownloadReportComponent.css';

const baseClass = getBEMClasses('download-report');

const DownloadReportComponent = (props, context) => {
  const {
    reportWriterResults,
    isLoading,
    getUserReportWriterResults,
    downloadDocument
  } = props;
  const formatType = report => {
    const summary = report.reportDescriptiveHeader.split('|');
    const startTime = formatDate(subDays(summary[2], 1));
    const endTime = formatDate(subDays(summary[3], 1));
    return `${summary[0]} ${summary[1]} from ${startTime} to ${endTime}`;
  };

  const isMobile = navigator.userAgent.match(
    /Android|BlackBerry|iPhone|iPad|iPod/i
  );

  const columns = [
    {
      header: context.t('Report Summary'),
      classModifier: 'type',
      cell: row => formatType(row)
    },
    {
      header: context.t('Created'),
      classModifier: 'created',
      cell: row => formatDateTime(row.reportExecutionStartTime)
    },
    !isMobile && {
      header: context.t('Actions'),
      classModifier: 'actions',
      cell: row => (
        <DownloadAPButton
          onClick={() => downloadDocument(row.descriptorId)}
          customClass={baseClass('action-btn')}
          styleName="additive"
          text={context.t('Download Report')}
        />
      )
    }
  ];

  return (
    <div className={baseClass('container')}>
      <APIconButton
        customClass={baseClass('refresh-reports')}
        iconName="refresh"
        action={getUserReportWriterResults}
        showLabel={false}
      />
      <APTable
        {...{
          columns,
          baseClass,
          rows: reportWriterResults,
          empty: context.t('No Report Is Available'),
          fetching: isLoading,
          headerOutside: true
        }}
      />
    </div>
  );
};

DownloadReportComponent.propTypes = {
  reports: PropTypes.array.isRequired,
  reportWriterResults: PropTypes.array.isRequired
};

DownloadReportComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DownloadReportComponent;
