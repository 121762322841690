import React from 'react';
import PropTypes from 'prop-types';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
import StandAloneLayout from '../layouts/StandAloneLayout/StandAloneLayout';
import SignupFormContainer from 'apex-web/lib/components/Signup/SignupFormContainer';
import CustomSignupFormContainer from '../components/CustomSignUpFormContainer';

import '../styles/components/common/StandaloneForm.css';
import './SignupPage.css';

export const SignupFormComponent = (props, context) => {
  return (
    <StandAloneLayout>
      <div className="dcx-login-form">
        <CustomSignupFormContainer />
      </div>
    </StandAloneLayout>
  );
};

SignupFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  SignupFormComponent,
  process.env.REACT_APP_DEFAULT_PATH
);
