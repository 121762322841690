/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import ScriptLoader from 'apex-web/lib/helpers/scriptLoader';

const baseClasses = getBEMClasses('withdraw-form');

const TrustpayWithdrawWorkflowComponent = ({ url }) => {
  if (url) {
    return (
      <div className={baseClasses('trustpay-form')}>
        <form
          action={location.href}
          className="paymentWidgets"
          data-brands="VISA MASTER AMEX"
        />
        <ScriptLoader url={url} />
      </div>
    );
  }
  return null;
};

TrustpayWithdrawWorkflowComponent.defaultProps = {
  url: ''
};

TrustpayWithdrawWorkflowComponent.propTypes = {
  url: PropTypes.string
};

export default TrustpayWithdrawWorkflowComponent;
