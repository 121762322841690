import { connect } from 'react-redux';
import WalletRowComponent from './WalletRowComponent';
import {
  getProductByIdSelector,
  productPositionAssetsSelector
} from 'apex-web/lib/redux/selectors/productPositionSelectors';
import { baseCurrencyPriceSelector } from 'apex-web/lib/redux/selectors/baseCurrenciesSelectors';

const mapStateToProps = (state, ownProps) => {
  const {
    iconFileName,
    ProductSymbol,
    ProductFullName,
    ProductType,
    ProductId
  } = getProductByIdSelector(state, ownProps.productId);
  const isCrypto = ProductType === 'CryptoCurrency';
  const positionDetails = productPositionAssetsSelector(state).find(
    i => i.ProductId === ownProps.productId
  );
  const baseCurrency = baseCurrencyPriceSelector(state, ownProps.productId);

  return {
    iconFileName,
    ProductSymbol,
    ProductFullName,
    isCrypto,
    ProductId,
    positionDetails,
    baseCurrency,
    isWithdrawDisabled:
      positionDetails?.Amount === '-' || positionDetails?.Amount < 0
  };
};

export default connect(mapStateToProps)(WalletRowComponent);
