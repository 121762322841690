import React, { useEffect, useState } from 'react';
import './LineGraphSvg.css';

const LineGraphSvg = ({ priceEveryHour }) => {
  const [svgString, setSvgString] = useState('');

  useEffect(() => {
    const svgWidth = 140;
    const svgHeight = 30;
    const padding = 5;
    const hoursLength =
      priceEveryHour.length > 1 ? priceEveryHour.length - 1 : 1;
    const xScale = (svgWidth - 2 * padding) / hoursLength;
    const maxPrice = Math.max(...priceEveryHour);
    const minPrice = Math.min(...priceEveryHour);
    const yScale =
      maxPrice === minPrice
        ? 1
        : (svgHeight - 2 * padding) / (maxPrice - minPrice);

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('width', svgWidth);
    svg.setAttribute('height', svgHeight);

    const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
    const linearGradient = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'linearGradient'
    );
    linearGradient.setAttribute('id', 'gradient');
    linearGradient.setAttribute('x1', '0');
    linearGradient.setAttribute('y1', '0');
    linearGradient.setAttribute('x2', '0');
    linearGradient.setAttribute('y2', '1');

    const stop1 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'stop'
    );
    stop1.setAttribute('offset', '0%');
    stop1.setAttribute('stop-color', '#FF7300');
    stop1.setAttribute('stop-opacity', '1');

    const stop2 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'stop'
    );
    stop2.setAttribute('offset', '100%');
    stop2.setAttribute('stop-color', '#FF7300');
    stop2.setAttribute('stop-opacity', '0');

    linearGradient.appendChild(stop1);
    linearGradient.appendChild(stop2);
    defs.appendChild(linearGradient);
    svg.appendChild(defs);

    const points = priceEveryHour
      .map((price, index) => {
        const x = padding + index * xScale;
        const y = svgHeight - padding - (price - minPrice) * yScale;
        return `${x},${y}`;
      })
      .join(' ');

    const fillPoints = [
      `${padding},${svgHeight - padding}`,
      ...priceEveryHour.map((price, index) => {
        const x = padding + index * xScale;
        const y = svgHeight - padding - (price - minPrice) * yScale;
        return `${x},${y}`;
      }),
      `${svgWidth - padding},${svgHeight - padding}`
    ].join(' ');

    const polygon = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'polygon'
    );
    polygon.setAttribute('fill', 'url(#gradient)');
    polygon.setAttribute('stroke', 'none');
    polygon.setAttribute('points', fillPoints);
    svg.appendChild(polygon);

    const polyline = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'polyline'
    );
    polyline.setAttribute('fill', 'none');
    polyline.setAttribute('stroke', '#FF7300');
    polyline.setAttribute('stroke-width', '2');
    polyline.setAttribute('points', points);
    svg.appendChild(polyline);

    const svgString = new XMLSerializer().serializeToString(svg);
    setSvgString(svgString);
  }, [priceEveryHour]);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: svgString }}
      className="line-graph"
    />
  );
};

export default LineGraphSvg;
