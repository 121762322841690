import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import ProductIconContainer from 'apex-web/lib/components/common/ProductIcon/ProductIconContainer';
import './SendReceiveHeader.css';

const bemClasses = getBEMClasses('dcx-send-receive-header');

const SendReceiveHeader = props => {
  const {
    product: { iconFileName, ProductFullName, ProductSymbol }
  } = props;
  return (
    <header className={bemClasses()}>
      <ProductIconContainer
        iconFileName={iconFileName}
        customClass={bemClasses('logo')}
      />
      <div>
        <div className={bemClasses('title')}>{ProductFullName}</div>
        <div className={bemClasses('sub-title')}>{ProductSymbol}</div>
      </div>
    </header>
  );
};

SendReceiveHeader.propTypes = {
  name: PropTypes.string
};

export default SendReceiveHeader;
