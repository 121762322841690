import React from 'react';
import { Route } from 'react-router-dom';
import PageHeaderLayout from '../layouts/PageHeaderLayout/PageHeaderLayout';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';

import DashboardPage from './DashboardPage';
import MarketDataPage from './MarketDataPage';
import BuySellPage from './BuySellPage';
import TransactPage from '../dcxPages/TransactPage/TransactPage';
import MarketsPage from '../dcxPages/MarketsPage/MarketsPage';
import SendReceivePage from '../dcxPages/SendReceivePage/SendReceivePage';
import WalletsPage from '../dcxPages/WalletsPage/WalletsPage';
import ProductDetailsPage from './ProductDetailsPage';
import ComponentExhibitPage from './ComponentExhibitPage/ComponentExhibitPage';
import SettingsPage from './SettingsPage';

import MarginBalancesLayout from 'apex-web/lib/layouts/MarginBalancesLayout/MarginBalancesLayoutContainer';
import AssetActivityDetailsLayout from 'apex-web/lib/layouts/AssetActivityDetailsLayout/AssetActivityDetailsLayout';

import FixedLeftFluidLayout from 'apex-web/lib/layouts/FixedLeftFluidLayout/FixedLeftFluidLayout';
import WalletDetailsBackButtonComponent from 'apex-web/lib/components/WalletDetails/WalletDetailsBackButtonComponent';
import BalancesMenuContainer from 'apex-web/lib/components/BalancesMenu/BalanceMenuContainer';
import WalletDetailsLayout from 'apex-web/lib/layouts/WalletDetailsLayout/WalletDetailsLayout';

import DigitalAssetsPage from 'apex-web/lib/components/DigitalAssets/DigitalAssetsPageContainer';
import DigitalAssetsOfferingProfilePage from 'apex-web/lib/components/DigitalAssets/DigitalAssetsOfferingProfilePageContainer';
import IBALayout from 'apex-web/lib/components/IBA/IbaLayout';
import ExchangePage from './Exchange/ExchangePage';
import StreamingPage from './StreamingPage';
import Custom from './Custom';
import CustomExchangePage from './Exchange/CustomExchangePage';
import DigitalAssetsCustom from './DigitalAssetsCustom';
import PortfolioPage from '../dcxPages/PortfolioPage/PortfolioPage';

import TransferFundsPage from '../dcxPages/TransferFundsPage/TransferFundsPage';
import BanxaSuccess from './Banxa';
import AssetsPage from '../dcxPages/AssetsPage/AssetsPage';
import ReportingPage from '../dcxPages/ReportingPage/ReportingPage';

const InteriorPage = () => (
  <React.Fragment>
    <PageHeaderLayout />
    <Route exact path={'/dashboard'} component={DashboardPage} />
    <Route path={'/dashboard/market-data'} component={MarketDataPage} />
    <Route exact path={'/wallets'} component={WalletsPage} />
    <Route exact path={'/send-receive'} component={SendReceivePage} />
    <Route
      path="/wallets/wallet-detail"
      component={() => (
        <FixedLeftFluidLayout
          left={[
            <WalletDetailsBackButtonComponent key="1" path="/wallets" />,
            <BalancesMenuContainer key="2" />
          ]}
        >
          <WalletDetailsLayout />
        </FixedLeftFluidLayout>
      )}
    />
    <Route path={'/exchange'} component={ExchangePage} />
    <Route exact path={'/buy-sell'} component={AssetsPage} />
    <Route path={'/buy-sell/detailed'} component={TransactPage} />
    <Route path={'/buy-sell/markets'} component={MarketsPage} />
    <Route path={'/reporting'} component={ReportingPage} />
    <Route path={'/wallets/product-details'} component={ProductDetailsPage} />
    <Route path={'/settings'} component={SettingsPage} />
    <Route path={'/component'} component={ComponentExhibitPage} />

    <Route path={'/jmtest'} component={BuySellPage} />

    <Route
      path={'/balances'}
      component={() => (
        // <MarginBalancesLayout detailsLink="/wallets/wallet-detail" />
        <Custom />
      )}
    />

    <Route path={'/custom'} component={() => <Custom />} />
    <Route path={'/portfolio'} component={() => <PortfolioPage />} />

    <Route path={'/banxa/success'} component={() => <BanxaSuccess />} />

    <Route
      path={'/asset-activity-details'}
      component={AssetActivityDetailsLayout}
    />
    <Route path={'/iba'} component={IBALayout} />
    <Route path={'/transfer'} component={TransferFundsPage} />

    {/* <Route path="/eotc" component={StreamingPage} /> */}

    {/* Investor portal Routes */}
    <Route exact path={'/digital-assets'} component={DigitalAssetsCustom} />
    <Route
      path={'/digital-assets/:id'}
      component={DigitalAssetsOfferingProfilePage}
    />
  </React.Fragment>
);

export default withAuthentication(InteriorPage);
