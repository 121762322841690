import { connect } from 'react-redux';
import ApiKeysModalButtonComponent from './ApiKeysModalButtonComponent';
import { openModal } from 'apex-web/lib/redux/actions/modalActions';

const mapDispatchToProps = dispatch => ({
  openApiKeysModal: () => {
    dispatch(openModal('API_KEYS_MODAL'));
  }
});

export default connect(null, mapDispatchToProps)(ApiKeysModalButtonComponent);
