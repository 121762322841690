import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import OrderEntryComponent from './OrderEntryWithPercentButtons';
import VerificationRequiredContainer from './VerificationRequired/VerificationRequiredContainer';
import { marketPriceOfSelectedInstrumentSelector } from 'apex-web/lib/redux/selectors/buySellSelectors';
import {
  buyValue,
  orderTypes
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import config from 'apex-web/lib/config';
import { getOrderFee, getOrderNet } from 'apex-web/lib/helpers/orderHelper.js';
import { orderTotalSelector } from 'apex-web/lib/redux/selectors/orderTotalSelector';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { isMarginActiveSelector } from 'apex-web/lib/redux/selectors/marginSelectors';
import { marginProductSelector } from 'apex-web/lib/redux/selectors/marginProductSelectors';
import { positionSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import {
  MODAL_TYPES,
  openModal
} from 'apex-web/lib/redux/actions/modalActions';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

const OrderEntryComponentForm = reduxForm({
  form: 'orderEntry',
  enableReinitialize: false,
  destroyOnUnmount: false,
  onSubmit: (payload, dispatch) => {
    dispatch(openModal(MODAL_TYPES.CONFIRM_ORDER_REPORT));
  }
})(OrderEntryComponent);

const mapStateToProps = (state, ownProps) => {
  const { form } = state;

  const isMarginActive = isMarginActiveSelector(state);
  const marginProducts = marginProductSelector(state);
  let instrument = {};
  if (isMarginActive && !state.margin.instrument.fetching) {
    instrument = state.margin.instrument;
  } else {
    instrument = state.apexCore.instrument;
  }

  const { fetching } = instrument;
  const selectedInstrument = ownProps?.selectedInstrument
    ? ownProps.selectedInstrument
    : selectedInstrumentSelector(state);
  const selectedInstrumentId = selectedInstrument
    ? selectedInstrument.InstrumentId
    : 1;

  const level1 = state.apexCore.level1[selectedInstrumentId] || {};
  const orderEntryForm = form.orderEntry || { values: {} };

  const { disableTrading } = kycVerificationLevelsSelector(state);

  const orderTotal = form.orderEntry
    ? orderTotalSelector(state, {
        form: form.orderEntry.values
      })
    : 0;

  const positions = positionSelector(state);
  const marketPrice = marketPriceOfSelectedInstrumentSelector(state, {
    side: orderEntryForm.values.side
  });

  return {
    lastTradePrice: level1?.LastTradedPx,
    orderEntryForm,
    marketPrice,
    orderTotal: `${selectedInstrument.Product2Symbol} ${formatNumberToLocale(
      orderTotal,
      convertIncrementToIntDecimalPlaces(selectedInstrument.PriceIncrement)
    )}`,
    orderNet: getOrderNet(
      orderEntryForm,
      selectedInstrument,
      orderTotal,
      true,
      level1
    ),
    orderFee: getOrderFee(orderEntryForm, positions),
    initialValues: {
      side: ownProps?.side || orderEntryForm.values.side || buyValue,
      orderType:
        orderEntryForm.values.orderType || orderTypes.limit.displayName,
      quantity: '',
      limitPrice: '',
      stopPrice: '',
      fee: '0',
      totalAmount: '0',
      selectedInstrumentId,
      timeInForce: '1',
      orderQuantity: selectedInstrument && selectedInstrument.Product1Symbol
    },
    selectedInstrument,
    fetching,
    disableTrading,
    VerificationRequiredComponent: <VerificationRequiredContainer />,
    hideFees: !!config.global.hideFees,
    isMarginActive,
    marginProducts,
    positions,
    level1: state.apexCore.level1[selectedInstrumentId]
  };
};

const mapDispatchToProps = {
  reduxFormChange: (key, val) => change('orderEntry', key, val)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderEntryComponentForm);
