import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import TransfersComponent from './TransfersComponent';
import {
  approveTransferRequest,
  rejectTransferRequest
} from 'apex-web/lib/redux/actions/transferRequestActions';
import { transfersByProductIdSelector } from 'apex-web/lib/redux/selectors/transfersTableSelectors';
import config from '../../config';

const mapStateToProps = state => {
  const { receivedRequests, sentRequests, sentTransfers, receivedTransfers } =
    transfersByProductIdSelector(state);
  const formSelector = formValueSelector('transfers-filters-form');
  const selectedFilter = formSelector(state, 'transfersFilters');

  const { products } = state.apexCore.product;
  const { usePagination } = config.OrderHistoryData;

  const addProduct = item =>
    (item.product = products.find(p => item.ProductId === p.ProductId));

  receivedRequests.forEach(addProduct);
  sentRequests.forEach(addProduct);
  sentTransfers.forEach(addProduct);
  receivedTransfers.forEach(addProduct);

  return {
    receivedRequests,
    sentRequests,
    sentTransfers,
    receivedTransfers,
    usePagination,
    selectedFilter: selectedFilter ? selectedFilter : 'sentTransfers'
  };
};

const mapDispatchToProps = dispatch => ({
  approveTransferRequest: requestCode =>
    dispatch(approveTransferRequest(requestCode)),
  rejectTransferRequest: requestCode =>
    dispatch(rejectTransferRequest(requestCode))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransfersComponent);
