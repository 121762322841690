import { connect } from 'react-redux';
import DepositReferenceIdModal from './DepositReferenceIdModal';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import config from 'apex-web/lib/config';

const mapStateToProps = state => {
  const { requestcode, referenceid } = state.deposit.depositStatus;

  const referenceCode = config.Deposit.useShortDepositId
    ? referenceid
    : requestcode;

  return {
    referenceCode
  };
};

const container = connect(mapStateToProps, null)(DepositReferenceIdModal);

export default modalDecorator({
  name: MODAL_TYPES.DEPOSIT_REFERENCE_ID
})(container);
