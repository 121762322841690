import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import ThemeSettingsComponent from './ThemeSettingsComponent';
import config from 'apex-web/lib/config';
// import { chartEnums } from './ChartEnums';

const ThemeForm = reduxForm({
  form: 'themeForm',
  enableReinitialize: true,
  onSubmit: () => {}
})(ThemeSettingsComponent);

const mapStateToProps = (state, { themeOptions }) => {
  const { theme } = config.global;
  // const { chart } = config.TradingLayout;
  const localConfig = JSON.parse(localStorage.getItem('apexTemplate')) || {};

  const currentTheme = localConfig.theme || theme;
  // const currentChart = localConfig.chart || chart;

  return {
    initialValues: {
      theme: currentTheme.charAt(0).toUpperCase() + currentTheme.slice(1),
      chart: 'TradingView'
    },
    formValues: getFormValues('themeForm')(state) || {},
    themeOptions
  };
};

const ThemeSettingsContainer = connect(mapStateToProps)(ThemeForm);

export default ThemeSettingsContainer;
