import React from 'react';
import { getBEMClasses } from '../../../../helpers/cssClassesHelper';
import { productIcons } from '../../../../utils/Icons';
import './AssetsTable.css';

const baseClasses = getBEMClasses('portfolio-page-assets');

const AssetsTable = ({ assets }) => {
  const getIconFileName = productId => {
    const productData = productIcons[productId];
    return productData ? productData.iconFileName : null;
  };

  return (
    <>
      <div className={baseClasses('section-header')}>
        <p className={baseClasses('section-title')}>My assets</p>
        <p className={baseClasses('section-sub-title')}>
          Here are all your orders waiting to be filled.
        </p>
      </div>
      <div className={baseClasses('table')}>
        <div className={baseClasses('table-header')}>
          <h2>My assets</h2>
        </div>

        <div className={baseClasses('table-header-names')}>
          <div className={baseClasses('table-header-name')}>
            <p>Name</p>
          </div>
          <div className={baseClasses('table-header-name')}>
            <p>Balance</p>
          </div>
          <div className={baseClasses('table-header-name')}>
            <p>Pending widthdraw</p>
          </div>
          <div className={baseClasses('table-header-name')}>
            <p>Total change %</p>
          </div>
        </div>
        {assets.assetsWithPriceAndPercent.map(asset => {
          const iconFileName = getIconFileName(asset.ProductSymbol);
          return (
            <div className={baseClasses('table-row')} key={asset.ProductId}>
              <div className={baseClasses('table-row-cell')}>
                <div className={baseClasses('table-row-icon')}>
                  {iconFileName && (
                    <img
                      src={iconFileName}
                      alt={asset.ProductSymbol}
                      className={baseClasses('table-row-icon-image')}
                    />
                  )}
                </div>
                <p>{asset.ProductSymbol}</p>
              </div>
              <div className={baseClasses('table-row-cell')}>
                <p>${asset.AvailableBalanceInBaseCurrency}</p>
              </div>
              <div className={baseClasses('table-row-cell')}>
                <p>{asset.PendingWithdraws}</p>
              </div>
              <div className={baseClasses('table-row-cell')}>
                <p>{asset.Rolling24HrPxChangePercent}%</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AssetsTable;
