import { connect } from 'react-redux';
import { get } from 'lodash';
import FiatSidePaneComponent from './DepositWithdrawComponent';
import {
  createWithdrawTicket,
  getWithdrawFee
} from 'apex-web/lib/redux/actions/withdrawActions';

import { positionByProductIdSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import config from 'apex-web/lib/config';

const mapStateToProps = (state, ownProps) => {
  const productSymbol = get(ownProps, 'product.ProductSymbol', null);
  const productConfig = config.Deposit[productSymbol] || {};
  const productId = get(ownProps, 'product.ProductId', null);
  const position = positionByProductIdSelector(productId)(state);
  const {
    withdrawFee: fee,
    AccountProviderId,
    template: { TemplateType }
  } = state.withdraw;
  const TicketAmount = get(state, ['withdraw', 'TicketAmount'], null);
  const withdrawDetailsInfo = config.Withdraw.WithdrawDetails;
  const { isDepositDisabled, isWithdrawDisabled } = ownProps;

  return {
    TicketAmount,
    position,
    fee,
    AccountProviderId,
    TemplateType,
    productConfig,
    withdrawDetailsInfo,
    isDepositDisabled,
    isWithdrawDisabled
  };
};

const mapDispatchToProps = dispatch => ({
  sendToAddress: payload => dispatch(createWithdrawTicket(payload)),
  getWithdrawFee: (productId, amount, AccountProviderId) =>
    dispatch(getWithdrawFee(productId, amount, AccountProviderId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiatSidePaneComponent);
