import React from 'react';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import './InstrumentOverviewComponent.css';

const baseClasses = getBEMClasses('dcx-instrument-overview');

const InstrumentOverviewComponent = ({ selectedInstrument, lastPrice }) => {
  return (
    <h1 className={baseClasses('')}>
      {selectedInstrument?.Product1FullName} Price -{' '}
      {selectedInstrument?.Product1Symbol}/{selectedInstrument?.Product2Symbol}:{' '}
      {lastPrice} {selectedInstrument?.Product2Symbol}
    </h1>
  );
};

export default InstrumentOverviewComponent;
