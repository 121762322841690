import { connect } from 'react-redux';
import { closeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';

import CustomSidePaneComponent from './CustomSidePaneComponent';

const mapStateToProps = state => {
  const { isOpen, name, data } = state.sidePane;
  return {
    isOpen,
    name,
    data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    close: name => dispatch(closeSidePane(name))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomSidePaneComponent);
