import React from 'react';
import PropTypes from 'prop-types';
import APButton from 'apex-web/lib/components/common/APButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './ApiKeysModalButtonComponent.css';

const baseClasses = getBEMClasses('api-keys-modal-button');

const ApiKeysModalButtonComponent = ({ openApiKeysModal }, context) => (
  <APButton
    type="submit"
    customClass={baseClasses()}
    onClick={openApiKeysModal}
  >
    {context.t('API Key')}
  </APButton>
);

ApiKeysModalButtonComponent.propTypes = {
  openApiKeysModal: PropTypes.func.isRequired
};

ApiKeysModalButtonComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ApiKeysModalButtonComponent;
