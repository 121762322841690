import React from 'react';
import './PayIdForm.css';

const CopyIcon = ({ onClick }) => {
  return (
    <div className="copy-to-clipboard" onClick={onClick}>
      <svg
        width="97"
        height="98"
        viewBox="0 0 97 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="deposit-icon"
      >
        <path
          d="M33.6271 0.530273H82.5881C86.4154 0.530273 89.893 2.10162 92.413 4.63604C94.9331 7.17047 96.4955 10.668 96.4955 14.5171V63.3872C96.4955 67.2363 94.9331 70.7339 92.413 73.2683C89.893 75.8027 86.4154 77.374 82.5881 77.374H77.2803V83.0828C77.2803 86.932 75.7147 90.4295 73.1978 92.9639C70.6778 95.4984 67.2002 97.0697 63.3729 97.0697H14.4119C10.5846 97.0697 7.10701 95.4984 4.58699 92.9639C2.06696 90.4295 0.504547 86.932 0.504547 83.0828V34.2128C0.504547 30.3636 2.06696 26.8661 4.58699 24.3317C7.10701 21.7973 10.5846 20.2259 14.4119 20.2259H19.7197V14.5171C19.7197 10.668 21.2853 7.17047 23.8022 4.63604C26.3222 2.10162 29.7998 0.530273 33.6271 0.530273ZM72.5584 77.374H33.6271C29.7998 77.374 26.3222 75.8027 23.8022 73.2683C21.2822 70.7339 19.7197 67.2363 19.7197 63.3872V24.9748H14.4119C11.8856 24.9748 9.59239 26.0139 7.92602 27.6898C6.25966 29.3657 5.22645 31.6752 5.22645 34.2128V83.0828C5.22645 85.6236 6.25966 87.9299 7.92602 89.6058C9.59239 91.2817 11.8888 92.3208 14.4119 92.3208H63.3729C65.8961 92.3208 68.1924 91.2817 69.8588 89.6058C71.5252 87.9299 72.5584 85.6204 72.5584 83.0828V77.374ZM82.5849 5.27915H33.624C31.1008 5.27915 28.8044 6.31827 27.1381 7.99416C25.4717 9.67004 24.4385 11.9795 24.4385 14.5171V22.5988V63.384C24.4385 65.9216 25.4717 68.2311 27.1381 69.907C28.8044 71.5829 31.1008 72.622 33.624 72.622H74.9177H82.5849C85.1113 72.622 87.4045 71.5829 89.0708 69.907C90.7372 68.2311 91.7704 65.9216 91.7704 63.384V14.514C91.7704 11.9732 90.7372 9.66688 89.0708 7.99099C87.4045 6.3151 85.1081 5.27598 82.5849 5.27598V5.27915Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

const PayIdForm = ({
  depositInfo,
  depositData,
  copyMessage,
  copyToClipboard
}) => {
  return (
    <>
      <p className="deposit-instructions">
        To deposit via PayID select ‘Pay Anyone’ from your internet banking
        website or application. You will need to enter your unique details shown
        below.
      </p>
      <div className="deposit-table-container">
        <table className="deposit-table">
          <tbody>
            <tr className="deposit-row">
              <td className="deposit-cell">
                <strong>Deposit Time:</strong>
              </td>
              <td className="deposit-cell">{depositInfo?.time || 'N/A'}</td>
            </tr>
            <tr className="deposit-row">
              <td className="deposit-cell">
                <strong>Deposit Fee:</strong>
              </td>
              <td className="deposit-cell">{depositInfo?.fee || 'N/A'}</td>
            </tr>
            <tr className="deposit-row">
              <td className="deposit-cell">
                <strong> Deposit Limit:</strong>
              </td>
              <td className="deposit-cell">
                {depositInfo?.dailyLimit || 'N/A'}
              </td>
            </tr>
            <tr className="deposit-row">
              <td className="deposit-cell">
                <strong>PayId:</strong>
              </td>
              <td className="deposit-cell">
                {depositData?.payId?.payId ? (
                  <>
                    {depositData.payId.payId.toString()}
                    <CopyIcon
                      onClick={() =>
                        copyToClipboard(
                          depositData?.depositAccs?.[0].bsbNumber
                            .replace(/[^0-9]/g, '')
                            .toString()
                        )
                      }
                    />
                  </>
                ) : (
                  <p>
                    Please check back again soon for account details, it may
                    take up to 5 minutes for your accounts to appear after
                    verification.
                  </p>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PayIdForm;
