import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  formatPercentageValue,
  formatNumberToLocale
} from 'apex-web/lib/helpers/numberFormatter';
import * as level1DataProp from 'apex-web/lib/propTypes/level1Data';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { instrumentPropType } from 'apex-web/lib/propTypes/instrumentPropTypes';
import InstrumentTableItem from './InstrumentTableItem';
import INSTRUMENT_TABLE_ITEMS from './InstrumentTableItems';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import {
  buyValue,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import './InstrumentTableComponent.css';
import LineGraphSvg from '../LineGraphSvg';
import path from '../../helpers/path';
import history from '../../helpers/history';
import ArrowDownIcon from '../../images/icons/icon-arrow-down.svg';

const getProductSymbol = instrument => get(instrument, 'Product1Symbol', '');

const InstrumentTableComponent = (props, context) => {
  const { t } = context;
  const {
    selectedInstrument,
    items,
    customClass,
    priceData,
    withoutChart,
    isSelected,
    isFromAssetsPage,
    selectInstrument,
    openBuySellModal,
    onInstrumentClick,
    title,
    symbol,
    price,
    change24hr,
    marketCap,
    price24hr
  } = props;

  const onMoreClick = () => {
    selectInstrument(selectedInstrument.InstrumentId);
    history.push(path('/buy-sell/detailed'));
  };

  const priceEveryHour = price24hr ? price24hr : [];

  const baseClasses = getBEMClasses(['dcx-instrument-table', customClass]);

  const productSymbol = get(selectedInstrument, 'Product1Symbol', '');
  const priceSymbol = get(selectedInstrument, 'Product2Symbol', '');

  const instrumentTableItems = items.map(item =>
    level1ItemsData[item.field](
      t,
      { title, symbol, price, change24hr, marketCap },
      selectedInstrument
    )
  );

  return (
    <>
      <div
        className={baseClasses('', isSelected && 'selected')}
        onClick={onInstrumentClick ? onInstrumentClick : () => {}}
      >
        {customClass === 'market-overview-instrument' && (
          <div className={baseClasses('row')}>
            <span className={baseClasses('title')}>
              {t(`${productSymbol}${priceSymbol} Price Data:`)}
            </span>
          </div>
        )}

        {instrumentTableItems.map(
          ({ key, value, logo, label, classModifiers }, index) => (
            <InstrumentTableItem
              index={index}
              key={key}
              value={value}
              label={label}
              logo={logo}
              baseClasses={baseClasses}
              classModifiers={classModifiers}
              isFromAssetsPage={isFromAssetsPage}
              onClick={onMoreClick}
            />
          )
        )}

        {!withoutChart && (
          <div className={baseClasses('row')}>
            <div className={baseClasses('chart-with-arrow')}>
              <LineGraphSvg priceEveryHour={priceEveryHour} />
              <img src={ArrowDownIcon} />
            </div>
          </div>
        )}
        {isFromAssetsPage && (
          <div className={baseClasses('row buttons')}>
            <div className={baseClasses('buttons')}>
              <div
                onClick={e => {
                  selectInstrument(selectedInstrument.InstrumentId);
                  openBuySellModal(buyValue);
                }}
                className={baseClasses('button', 'buy')}
              >
                Buy
              </div>
              <div
                onClick={e => {
                  selectInstrument(selectedInstrument.InstrumentId);
                  openBuySellModal(sellValue);
                }}
                className={baseClasses('button', 'sell')}
              >
                Sell
              </div>
              {/* <div
              onClick={onMoreClick}
              className={baseClasses('button', 'more')}
            >
              More
            </div> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

InstrumentTableComponent.propTypes = {
  level1: level1DataProp.type.isRequired,
  selectedInstrument: instrumentPropType,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired
    })
  )
};

InstrumentTableComponent.defaultProps = {
  level1: level1DataProp.defaultProps,
  items: [
    { field: INSTRUMENT_TABLE_ITEMS.symbol },
    { field: INSTRUMENT_TABLE_ITEMS.lastPrice },
    { field: INSTRUMENT_TABLE_ITEMS.dailyPercentageChange },
    // { field: INSTRUMENT_TABLE_ITEMS.bid },
    // { field: INSTRUMENT_TABLE_ITEMS.ask },
    { field: INSTRUMENT_TABLE_ITEMS.dailyVolume }
    // { field: INSTRUMENT_TABLE_ITEMS.dailyMin },
    // { field: INSTRUMENT_TABLE_ITEMS.dailyMax }
  ]
};

InstrumentTableComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const level1ItemsData = {
  [INSTRUMENT_TABLE_ITEMS.symbol]: (t, { title, symbol }) => {
    return {
      key: INSTRUMENT_TABLE_ITEMS.symbol,
      value: symbol,
      label: title,
      logo: symbol,
      classModifiers: ['symbol']
    };
  },

  [INSTRUMENT_TABLE_ITEMS.lastPrice]: (t, { price }, instrument) => {
    return {
      key: INSTRUMENT_TABLE_ITEMS.lastPrice,
      value: `$${formatNumberToLocale(price)}`,
      label: t(`${instrument.Product2Symbol} price`),
      classModifiers: ['last-price']
    };
  },

  [INSTRUMENT_TABLE_ITEMS.dailyPercentageChange]: (t, { change24hr }) => ({
    key: INSTRUMENT_TABLE_ITEMS.change24hr,
    value: formatPercentageValue(change24hr),
    label: t('24h change'),
    classModifiers: [change24hr >= 0 ? '24h-positive' : '24h-negative']
  }),

  [INSTRUMENT_TABLE_ITEMS.bid]: (t, { BestBid }, instrument) => ({
    key: INSTRUMENT_TABLE_ITEMS.bid,
    value: formatNumberToLocale(
      BestBid,
      convertIncrementToIntDecimalPlaces(instrument.PriceIncrement)
    ),
    label: t('Bid'),
    classModifiers: ['hideable', 'bid']
  }),

  [INSTRUMENT_TABLE_ITEMS.ask]: (t, { BestOffer }, instrument) => ({
    key: INSTRUMENT_TABLE_ITEMS.ask,
    value: formatNumberToLocale(
      BestOffer,
      convertIncrementToIntDecimalPlaces(instrument.PriceIncrement)
    ),
    label: t('Ask'),
    classModifiers: ['hideable', 'ask']
  }),

  [INSTRUMENT_TABLE_ITEMS.dailyVolume]: (t, { marketCap }, instrument) => ({
    key: INSTRUMENT_TABLE_ITEMS.marketCap,
    value: `$${formatNumberToLocale(
      marketCap,
      convertIncrementToIntDecimalPlaces(marketCap)
    )} ${getProductSymbol(instrument)}`,
    label: t('24h volume'),
    classModifiers: ['24h-volume']
  }),

  [INSTRUMENT_TABLE_ITEMS.dailyMin]: (t, { SessionLow }, instrument) => ({
    key: INSTRUMENT_TABLE_ITEMS.dailyMin,
    value: formatNumberToLocale(
      SessionLow,
      convertIncrementToIntDecimalPlaces(instrument.PriceIncrement)
    ),
    label: t('24 Hour Low'),
    classModifiers: ['hideable', '24h-low']
  }),

  [INSTRUMENT_TABLE_ITEMS.dailyMax]: (t, { SessionHigh }, instrument) => ({
    key: INSTRUMENT_TABLE_ITEMS.dailyMax,
    value: formatNumberToLocale(
      SessionHigh,
      convertIncrementToIntDecimalPlaces(instrument.PriceIncrement)
    ),
    label: t('24 Hour High'),
    classModifiers: ['hideable', '24h-high']
  })
};

export default InstrumentTableComponent;
