import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import APIcon from 'apex-web/lib/components/common/APIcon';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { templateConfirmRenderer } from 'apex-web/lib/helpers/formTemplateHelper';

import './WithdrawConfirmationModalComponent.css';

const withdrawConfirmationModalClasses = getBEMClasses(
  'withdraw-confirmation-modal'
);

const WithdrawConfirmationModalComponent = (props, context) => {
  const { template, payload, TicketAmount, fee } = props;
  const { ProductSymbol, ProductFullName, Product } = payload;

  const productSymbol = ProductSymbol || Product;

  return (
    <Modal
      isOpen={props.isOpen}
      title={
        <div className={withdrawConfirmationModalClasses('header-content')}>
          <APIcon
            name={'withdrawWithBorder'}
            customClass={withdrawConfirmationModalClasses('icon')}
          />
          <div className={withdrawConfirmationModalClasses('header-title')}>
            {`${context.t('Withdraw')} ${productSymbol} ${ProductFullName}`}
          </div>
        </div>
      }
      close={props.close}
      footer={{
        buttonText: context.t('Confirm'),
        buttonStyle: 'additive',
        onClick: () => props.submitWithdraw(props.payload)
      }}
      customClass={withdrawConfirmationModalClasses()}
    >
      <div className={withdrawConfirmationModalClasses('title')}>
        {context.t('Confirmation')}
      </div>
      <div className={withdrawConfirmationModalClasses()}>
        <APLabelWithText
          label={context.t('Amount to Withdraw')}
          text={`${TicketAmount} ${productSymbol}`}
          customClass={withdrawConfirmationModalClasses()}
        />

        {templateConfirmRenderer(
          template,
          payload,
          withdrawConfirmationModalClasses,
          context
        )}

        <APLabelWithText
          label={context.t('Fee')}
          text={fee}
          customClass={withdrawConfirmationModalClasses()}
        />

        <APLabelWithText
          label={context.t('Time')}
          text={formatDateTime(new Date())}
          customClass={withdrawConfirmationModalClasses()}
        />
      </div>
    </Modal>
  );
};

WithdrawConfirmationModalComponent.propTypes = {
  payload: PropTypes.object,
  close: PropTypes.func.isRequired
};

WithdrawConfirmationModalComponent.defaultProps = {
  payload: {
    FullName: '',
    Amount: '',
    BankAccountName: '',
    BankAccountNumber: '',
    BankAddress: '',
    Comment: ''
  }
};

WithdrawConfirmationModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default WithdrawConfirmationModalComponent;
