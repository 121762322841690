import axios from 'axios';

export const getBanxaBuyCryptos = async () => {
  const config = {
    // headers: {
    //   Authorization: `Basic aE01T0FOSEtadEZBMjhYYVUuWTk6`
    // }
  };

  const response = await axios.post(
    `${process.env.REACT_APP_DCX_BANXA}/getBuyCryptoCurrencies`,
    config
  );
  return response.data;
};

export const getBanxaFundingOptions = async option => {
  const config = {
    headers: {
      Authorization: 'Basic aE01T0FOSEtadEZBMjhYYVUuWTk6'
    }
  };

  const body = {
    source: 'AUD',
    target: option
  };

  const response = await axios.post(
    `${process.env.REACT_APP_DCX_BANXA}/getPaymentMethods`,
    body,
    config
  );
  return response.data;
};

export const getBanxaPrices = async (sourceAmount, source, target) => {
  const config = {
    headers: {
      Authorization: `Basic aE01T0FOSEtadEZBMjhYYVUuWTk6`
    }
  };

  const body = {
    sourceAmount: sourceAmount,
    source: source,
    target: target
  };

  const response = await axios.post(
    `${process.env.REACT_APP_DCX_BANXA}/getPrices`,
    body,
    config
  );
  return response.data;
};

export const createBanxaOrder = async (
  userId,
  accountId,
  email,
  paymentMethodId,
  source,
  sourceAmount,
  target
) => {
  const config = {
    // headers: {
    //   Authorization: `Basic aE01T0FOSEtadEZBMjhYYVUuWTk6`
    // }
  };

  const body = {
    userId,
    accountId,
    email,
    paymentMethodId,
    source,
    sourceAmount,
    target,
    returnUrlOnSuccess: `${process.env.REACT_APP_BASE_URL}/callback/success`,
    returnUrlOnCancelled: `${process.env.REACT_APP_BASE_URL}/callback/cancelled`,
    returnUrlOnFailure: `${process.env.REACT_APP_BASE_URL}/callback/failure`
  };

  const response = await axios.post(
    `${process.env.REACT_APP_DCX_BANXA}/createBuyOrder`,
    body,
    config
  );
  return response.data;
};
