import { connect } from 'react-redux';
import DownloadReportComponent from './DownloadReportComponent';
import { downloadDocument } from 'apex-web/lib/helpers/downloadHelper';
import { getUserReportWriterResults } from 'apex-web/lib/redux/actions/reportActions';

const mapStateToProps = state => {
  const { reports, reportWriterResults, isLoading } = state.report;
  return {
    reports,
    reportWriterResults,
    isLoading,
    downloadDocument
  };
};

const mapDispatchToProps = {
  getUserReportWriterResults
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadReportComponent);
