import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import DepositWithdrawTransfer from '../../dcxComponents/DepositWithdrawTransfer';
import PageLayout from '../../layouts/PageLayout/PageLayout';
import './TransferFundsPage.css';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

const baseClasses = getBEMClasses('transferfunds-page');

const TransferFundsPage = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (user && user.verificationLevelConfigs) {
        setIsLoading(false);
      }
    }, 200); // Adjust the timeout duration as needed

    return () => clearTimeout(timer);
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user || !user.verificationLevelConfigs) {
    return <div>Loading...</div>;
  }

  return (
    <PageLayout
      title="Transfer funds"
      subTitle="Easily move funds securely between your wallets or to other users."
    >
      {user.verificationLevelConfigs.Level >= 1 ? (
        <DepositWithdrawTransfer />
      ) : (
        <div className={baseClasses('no-ver')}>
          <p>Please verify your account to deposit funds</p>
          <a
            className={baseClasses('button')}
            href="/settings/verification-level"
          >
            Verify now
          </a>
        </div>
      )}
    </PageLayout>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(withAuthentication(TransferFundsPage));
