import React, { useEffect } from 'react';
import './Banxa.css';
import PageFooterLayout from '../layouts/PageFooterLayout/PageFooterLayout';

const BanxaSuccess = () => {
  const parseQueryParams = search => {
    return search
      .substring(1)
      .split('&')
      .reduce((acc, curr) => {
        const [key, value] = curr.split('=');
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});
  };

  const queryParams = parseQueryParams(window.location.search);
  const { fiatAmount, coin, coinAmount, orderRef } = queryParams;

  useEffect(
    () => {
      if (!fiatAmount || !coin) {
        window.location.href = '/';
      }
    },
    [coin, fiatAmount]
  );

  return (
    <>
      <div className="banxa-order-status">
        {fiatAmount && (
          <>
            <p className="banxa-order-status--text">
              Details of your purchase using Banxa
            </p>
            <p className="banxa-order-status--text">
              <strong>Purchased:</strong> {coinAmount} {coin}
            </p>
            <p className="banxa-order-status--text">
              <strong>AUD spent:</strong> ${fiatAmount}
            </p>
            <p className="banxa-order-status--text">
              <strong>Order reference:</strong> {orderRef}
            </p>

            <p className="banxa-order-status--text banxa-order-status--text_small">
              We'll send you an email with the transaction details, and another
              when your crypto appears in your wallet. This usually takes a day
              but might take a bit longer, so please feel free to keep trading.
              If you need any help, please contact our dcx support team.
            </p>
          </>
        )}

        <a href="/buy-sell" className="banxa-continue">
          Continue trading
        </a>
      </div>
      <PageFooterLayout />
    </>
  );
};

export default BanxaSuccess;
