import format from 'date-fns/format';
import subDays from 'date-fns/sub_days';
import apex from 'apex-web/lib/apex';

export const RECEIVE_ALL_TICKERS = 'RECEIVE_ALL_TICKERS';
export const REQUEST_ALL_TICKERS = 'REQUEST_ALL_TICKERS';

export const receiveAllTickers = payload => ({
  type: RECEIVE_ALL_TICKERS,
  payload
});

export const requestAllTickers = () => ({
  type: REQUEST_ALL_TICKERS
});

export const getAllTickersData = () => async (dispatch, getState) => {
  const {
    instrument: { instruments }
  } = getState().apexCore;
  dispatch(requestAllTickers());
  const toDate = format(new Date(), 'YYYY-MM-DD HH:mm:ss');
  const fromDate = format(subDays(new Date(), 1), 'YYYY-MM-DD HH:mm:ss');

  const promises = instruments.map(async instrument => {
    const res = await apex.connection.RPCPromise('GetTickerHistory', {
      InstrumentId: instrument.InstrumentId,
      Interval: 3600,
      OMSId: instrument.OMSId,
      FromDate: fromDate,
      ToDate: toDate
    });
    return {
      instrumentId: instrument.InstrumentId,
      tickersData: JSON.parse(res.o)
    };
  });

  Promise.all(promises)
    .then(res => {
      dispatch(receiveAllTickers(res));
    })
    .catch(e => dispatch(receiveAllTickers([])));
};
