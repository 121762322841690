import { connect } from 'react-redux';
import { destroy, initialize } from 'redux-form';
import { WithdrawConfirmationModalComponent } from '.';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import { createWithdrawTicket } from '../../../../redux/actions/withdrawActions';

const mapStateToProps = state => {
  return {
    products: state.apexCore.product
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitWithdraw: values => {
    dispatch(createWithdrawTicket(values));
    dispatch(destroy('createFiatWithdraw'));
    dispatch(
      initialize('createFiatWithdraw', { ...ownProps.product, Amount: 0 })
    );
  }
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithdrawConfirmationModalComponent);

export default modalDecorator({
  name: MODAL_TYPES.CONFIRM_FIAT_WITHDRAW
})(container);
