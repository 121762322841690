import {
  RECEIVE_ALL_TICKERS,
  REQUEST_ALL_TICKERS
} from '../actions/tickersActions';

const initialState = {
  tickers: [],
  fetching: true
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case RECEIVE_ALL_TICKERS:
      return {
        ...state,
        fetching: false,
        tickers: [...payload]
      };
    case REQUEST_ALL_TICKERS:
      return {
        ...state,
        fetching: true
      };
    default:
      return state;
  }
}
