import React from 'react';
import PropTypes from 'prop-types';
import APQRCode from 'apex-web/lib/components/common/APQRCode/APQRCode';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import copyToClipboard from 'apex-web/lib/helpers/clipboardHelper';
import destinationTagHelper from 'apex-web/lib/helpers/destinationTagHelper';
import APIcon from 'apex-web/lib/components/common/APIcon';

import './CryptoWalletDepositWorkflowComponent.css';

const baseClasses = getBEMClasses('dcx-cryptowallet-deposit');

const CryptoWalletDepositWorkflowComponent = (props, context) => {
  const {
    addressList = [],
    product = [
      { Product: '', ProductId: 0, ProductFullName: '', ProductType: '' }
    ],
    showSnack
  } = props;
  // Protocol prefix for the QR Code, lowercase name with no spaces.
  const depositType = product.ProductFullName.toLowerCase().replace(/\W/g, '');
  const selectedAddress = addressList.length
    ? addressList[addressList.length - 1]
    : '';

  const { address, dt, memo } = destinationTagHelper(selectedAddress);

  return (
    <div className={baseClasses('wrapper')}>
      <div className={baseClasses('title')}>
        {context.t('Please read the instructions below')}
      </div>

      <div className={baseClasses('description')}>
        {context.t(
          'Depositing cryptocurrency into the exchange is safe and easy. The address below can always be used to deposit cryptocurrency into your account.'
        )}
      </div>

      <div className={baseClasses('frame')}>
        <div className={baseClasses('qr-code')}>
          <APQRCode
            value={`${depositType}:${selectedAddress}`}
            className={baseClasses('qrcode')}
          />
        </div>
        <div>
          <div className={baseClasses('title')}>{context.t('Address')}:</div>
          <div
            data-test="Address"
            onClick={() => {
              copyToClipboard(address);
              showSnack(
                context.t('The address has been copied to the clipboard.')
              );
            }}
            className={baseClasses('address')}
          >
            {address}
            <APIcon name="copy" customClass={baseClasses('copy-icon')} />
          </div>
          {dt && (
            <React.Fragment>
              <div className={baseClasses('title')}>
                {context.t('Be sure to include this destination tag:')}
              </div>
              <div
                data-test="Destination tag"
                className={baseClasses('address')}
                onClick={() => {
                  copyToClipboard(dt);
                  showSnack(
                    context.t(
                      'The destination tag has been copied to the clipboard.'
                    )
                  );
                }}
              >
                {dt}
                <APIcon name="copy" customClass={baseClasses('copy-icon')} />
              </div>
            </React.Fragment>
          )}
          {memo && (
            <React.Fragment>
              <div className={baseClasses('title')}>
                {context.t('Be sure to include this memo tag:')}
              </div>
              <div
                data-test="Memo tag"
                className={baseClasses('address')}
                onClick={() => {
                  copyToClipboard(memo);
                  showSnack(
                    context.t('The memo tag has been copied to the clipboard.')
                  );
                }}
              >
                {memo}
                <APIcon name="copy" customClass={baseClasses('copy-icon')} />
              </div>
            </React.Fragment>
          )}
          <div className={baseClasses('description')}>
            {context.t(
              'Your account will automatically update after the cryptocurrency network confirms your transaction.'
            )}
          </div>
          <div className={baseClasses('description')}>
            {context.t('The confirmation may take up to 1 hour.')}
          </div>
          <div className={baseClasses('description')}>
            <div className={baseClasses('description-item')}>
              {context.t('1) Send cryptocurrency to this address')}
            </div>
            <div className={baseClasses('description-item')}>
              {context.t('2) Your deposit will automatically be processed')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CryptoWalletDepositWorkflowComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CryptoWalletDepositWorkflowComponent;
