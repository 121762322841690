import { connect } from 'react-redux';
import DepositWorkflowsComponent from './DepositWorkflowsComponent';

const mapStateToProps = (state, { submitting, onSubmit }) => {
  return {
    templateInfo: state.deposit.templateInfo,
    submitting,
    isLoading: state.deposit.isLoading,
    onSubmit
  };
};

export default connect(mapStateToProps)(DepositWorkflowsComponent);
