import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import SlidingPane from 'react-sliding-pane';
import ReactModal from 'react-modal';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import history from 'apex-web/lib/helpers/history';
import APIcon from 'apex-web/lib/components/common/APIcon';

import ChildrenComponents from './SidePaneChildren';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './SidePane.css';

export default class CustomSidePane extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    hideCloseLink: PropTypes.bool
  };

  static defaultProps = {
    customClass: 'custom-sidepane',
    options: {}
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.unlisten = null;

    this.state = {
      title: '',
      customClass: '',
      hideCloseLink: false,
      classModifiers: '',
      hideHeader: false,
      scrollPosition: 0
    };
  }

  componentDidMount() {
    ReactModal.setAppElement(this.modalRef);
    this.unlisten = history.listen((location, action) => {
      if (action === 'POP' && this.props.isOpen) {
        this.props.close();
      }
    });
  }

  componentDidUpdate() {
    const bodyPosition = document.body.style.position;
    if (!this.props.isOpen && bodyPosition === 'fixed') {
      this.returnScrollPosition();
    }
  }

  componentWillUnmount() {
    this.unlisten();
    this.unlisten = null;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isOpen) {
      const header = document.getElementsByClassName('slide-pane__header')[0];
      if (header && state.hideHeader) {
        header.classList.add('slide-pane__hide-header');
      }
    }
    return state;
  }

  getClasses() {
    return getBEMClasses(['ap-sidepane', this.state.customClass]);
  }

  getPropsFromChild = ({
    title,
    customClass,
    hideCloseLink,
    classModifiers,
    hideHeader
  }) => {
    this.setState({
      title,
      customClass,
      hideCloseLink,
      classModifiers,
      hideHeader
    });
  };

  returnScrollPosition = () => {
    document.body.style.position = 'static';
    window.scroll(0, this.state.scrollPosition);
  };

  renderCloseIcon = () => {
    const classes = this.getClasses();

    return (
      <div onClick={this.props.close} className={classes('close-button')}>
        <APIcon name="close" classModifiers="big" />
      </div>
    );
  };

  closePane = () => this.props.close(this.props.name);

  render() {
    const { isOpen, name, data, options } = this.props;
    const { hideCloseLink, classModifiers, ...otherProps } = this.state;
    const classes = this.getClasses();
    const Child = ChildrenComponents[name];
    const isMobile = navigator.userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod/i
    );

    if (name && !Child) {
      console.error(
        this.context.t(`SidePane Component named ${name} not found`)
      );
      return null;
    }

    const sidePaneOptions = get(options, name, {});

    return (
      <div
        className={classes(null, classModifiers)}
        ref={ref => (this.modalRef = ref)}>
        <SlidingPane
          className={classes('container', classModifiers)}
          overlayClassName={classes('overlay', classModifiers)}
          onAfterOpen={() => {
            if (isMobile) {
              // set position after opening sidepane
              setTimeout(() => {
                this.setState({ scrollPosition: window.pageYOffset });
                document.body.style.position = 'fixed';
              }, 500);
            }
          }}
          onRequestClose={this.closePane}
          isOpen={isOpen}
          {...otherProps}>
          {!hideCloseLink && this.renderCloseIcon()}
          {Child && (
            <Child
              name={name}
              closeSidePane={this.closePane}
              {...data}
              {...sidePaneOptions}
              onSidePaneOpen={this.getPropsFromChild}
            />
          )}
        </SlidingPane>
      </div>
    );
  }
}
