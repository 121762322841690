import React from 'react';

const MarketSwitcher = ({ view, setView }) => (
  <div className="flex flex-col sm:flex-row items-center justify-center w-full py-6 bg-wash">
    <div className="lg:flex w-full lg:w-auto lg:bg-[#efefef] rounded-full text-[#037C99] gap-3 px-4 py-2 marketSwitcher ">
      <label className="cursor-pointer">
        <input
          id="top40"
          type="radio"
          name="view"
          value="top40"
          checked={view === 'top40'}
          onChange={e => setView(e.target.value)}
          className="hidden mb-0"
        />
        <span
          className={`block sm:inline-block px-4 py-2 rounded-full mb-2 lg:mb-0 ${
            view === 'top40'
              ? 'bg-[#037C99] text-white font-bold '
              : 'bg-white border-[0.2px] border-[#037C99]'
          }`}>
          dcx 40
        </span>
      </label>
      <label className="cursor-pointer">
        <input
          id="marketCap"
          type="radio"
          name="view"
          value="marketCap"
          checked={view === 'marketCap'}
          onChange={e => setView(e.target.value)}
          className="hidden mb-0"
        />
        <span
          className={`block sm:inline-block px-4 py-2 rounded-full mb-2 lg:mb-0 ${
            view === 'marketCap'
              ? 'bg-[#037C99] text-white font-bold'
              : 'bg-white border-[0.2px] border-[#037C99]'
          }`}>
          Market cap
        </span>
      </label>
      <label className="cursor-pointer">
        <input
          id="gainers"
          type="radio"
          name="view"
          value="gainers"
          checked={view === 'gainers'}
          onChange={e => setView(e.target.value)}
          className="hidden mb-0"
        />
        <span
          className={`block sm:inline-block px-4 py-2 rounded-full mb-2 lg:mb-0 ${
            view === 'gainers'
              ? 'bg-[#037C99] text-white font-bold'
              : 'bg-white border-[0.2px] border-[#037C99]'
          }`}>
          Gainers
        </span>
      </label>
      <label className="cursor-pointer">
        <input
          id="losers"
          type="radio"
          name="view"
          value="losers"
          checked={view === 'losers'}
          onChange={e => setView(e.target.value)}
          className="hidden mb-0"
        />
        <span
          className={`block sm:inline-block px-4 py-2 rounded-full mb-2 lg:mb-0 ${
            view === 'losers'
              ? 'bg-[#037C99] text-white font-bold'
              : 'bg-white border-[0.2px] border-[#037C99]'
          }`}>
          Losers
        </span>
      </label>
    </div>
  </div>
);

export default MarketSwitcher;
