import { connect } from 'react-redux';
import FiatDepositModalComponent from './FiatDepositModalComponent';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import {
  MODAL_TYPES,
  openModal
} from 'apex-web/lib/redux/actions/modalActions';
import { createDepositTicket } from 'apex-web/lib/redux/actions/depositActions';
import config from 'apex-web/lib/config';

const mapStateToProps = (state, ownProps) => {
  let bankInfo = {};
  if (ownProps.payload && config.Deposit[ownProps.payload.ProductSymbol]) {
    const depositBankInfo = config.Deposit[ownProps.payload.ProductSymbol];
    bankInfo = {
      name: depositBankInfo.bankName,
      wireNumber: depositBankInfo.bankWireNumber,
      accountNumber: depositBankInfo.bankAccountNumber
    };
  }
  return {
    bankInfo,
    showDepositIdModal: config.Deposit.showDepositIdModal
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitDeposit: payload => dispatch(createDepositTicket(payload)),
  openReferenceModal: () =>
    dispatch(
      openModal(MODAL_TYPES.DEPOSIT_REFERENCE_ID, {
        ProductSymbol: ownProps.payload.ProductSymbol
      })
    )
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiatDepositModalComponent);

export default modalDecorator({
  name: MODAL_TYPES.CONFIRM_FIAT_DEPOSIT
})(container);
