import { connect } from 'react-redux';
import ReceiveComponent from './ReceiveComponent';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { closeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';

const mapStateToProps = state => {
  const { disableDeposit } = kycVerificationLevelsSelector(state);
  return { disableDeposit };
};

const mapDispatchToProps = dispatch => ({
  verificationOnClick: () => dispatch(closeSidePane())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiveComponent);
