import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import APIcon from 'apex-web/lib/components/common/APIcon';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './DepositReferenceIdModal.css';

const depositReferenceIdModalClasses = getBEMClasses(
  'deposit-reference-id-modal'
);

const DepositReferenceIdModal = (props, context) => {
  return (
    <Modal
      isOpen={!!(props.referenceCode && props.isOpen)}
      title={
        <div className={depositReferenceIdModalClasses('header-content')}>
          <APIcon
            name={'depositWithBorder'}
            size={44}
            customClass={depositReferenceIdModalClasses('icon')}
          />
          <div className={depositReferenceIdModalClasses('header-title')}>
            {`${context.t('Deposit')} ${props.ProductSymbol}`}
          </div>
        </div>
      }
      close={props.close}
      footer={{
        buttonText: context.t('OK'),
        buttonStyle: 'additive',
        onClick: () => props.close
      }}
      customClass={depositReferenceIdModalClasses()}
    >
      <p className={depositReferenceIdModalClasses('title')}>
        {context.t('Your deposit ticket has been created successfully.')}
      </p>
      <div
        className={depositReferenceIdModalClasses('reference-code-container')}
      >
        <p className={depositReferenceIdModalClasses('reference-code-title')}>
          {context.t(
            'Please note the following deposit ticket ID for your reference:'
          )}
        </p>
        <p className={depositReferenceIdModalClasses('reference-code')}>
          {props.referenceCode}
        </p>
      </div>
    </Modal>
  );
};

DepositReferenceIdModal.propTypes = {
  close: PropTypes.func.isRequired
};

DepositReferenceIdModal.defaultProps = {
  referenceCode: ''
};

DepositReferenceIdModal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DepositReferenceIdModal;
