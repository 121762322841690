import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import {
  required,
  email,
  lessThanValue,
  validateReceiverEmail
} from 'apex-web/lib/helpers/formValidations';
import SendAddressContainer from '../SendAddress/SendAddressContainer';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import APInput from 'apex-web/lib/components/common/APInput';
import APButton from 'apex-web/lib/components/common/APButton';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import { product as ProductPropTypes } from 'apex-web/lib/propTypes/sendReceiveComponent';
import './SendForm.css';

const bemClasses = getBEMClasses('dcx-send-form');

const SendForm = (props, context) => {
  const {
    product: {
      ProductFullName: name,
      ProductSymbol: symbol,
      DecimalPlaces: decimalPlaces
    },
    handleSubmit,
    invalid,
    submitting,
    onSubmit,
    useExternalAddress,
    toggleUseExternalAddress,
    userEmail,
    product,
    fee,
    total,
    showDetails,
    disableWithdraw,
    verificationOnClick,
    allowTransfer,
    withdrawError,
    verificationLevelConfig,
    balance
  } = props;

  const lessThanBalance = useCallback(
    value => {
      if (balance === 0) return context.t('0 balance available');
      return lessThanValue(balance, value);
    },
    [balance]
  );

  const checkUserEmail = useCallback(
    value => {
      return validateReceiverEmail(value, userEmail);
    },
    [userEmail]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={bemClasses()}>
      <section className={bemClasses('send-to')}>
        <header className={bemClasses('source')}>
          <div className={bemClasses('source-item-with-border')}>
            <div
              className={bemClasses('source-item', {
                selected: useExternalAddress
              })}
              onClick={() => {
                !useExternalAddress && toggleUseExternalAddress();
              }}
            >
              {context.t('External Wallet')}
            </div>
          </div>
          {allowTransfer && (
            <div
              className={bemClasses('source-item', {
                selected: !useExternalAddress
              })}
              onClick={() => {
                useExternalAddress && toggleUseExternalAddress();
              }}
            >
              {context.t('To Email Address')}
            </div>
          )}
        </header>
      </section>

      {disableWithdraw ? (
        <VerificationRequiredContainer
          disabled={disableWithdraw}
          onClick={verificationOnClick}
        />
      ) : (
        <React.Fragment>
          {useExternalAddress ? (
            <SendAddressContainer
              product={product}
              balance={balance}
              verificationLevelConfig={verificationLevelConfig}
            />
          ) : (
            <React.Fragment>
              <p className={bemClasses('label-text')}>
                {context.t('Recipient’s Email Address')}
              </p>

              <APInput
                name="ReceiverUsername"
                customClass={bemClasses()}
                validate={[required, email, checkUserEmail]}
              />
            </React.Fragment>
          )}

          {(!withdrawError || !useExternalAddress) && (
            <React.Fragment>
              <label className={bemClasses('label-text')}>
                {context.t('Amount of {name} to Send', { name })}
              </label>

              <section className={bemClasses('amounts')}>
                <APNumberInput
                  name="Amount"
                  customClass={bemClasses()}
                  labelInInput={symbol}
                  decimalPlaces={decimalPlaces}
                  validate={[required, lessThanBalance]}
                />
              </section>
            </React.Fragment>
          )}

          {!showDetails && (
            <section className={bemClasses('info-items')}>
              <APLabelWithText
                label={context.t('Current Balance:')}
                text={balance}
                customClass={bemClasses()}
              />
              <APLabelWithText
                label={context.t('Transaction Fee:')}
                text={fee !== undefined ? fee : '-'}
                customClass={bemClasses()}
              />
              <APLabelWithText
                label={context.t('Total Amount:')}
                text={total !== undefined ? `${total} ${symbol}` : '-'}
                customClass={bemClasses()}
              />
            </section>
          )}

          {!useExternalAddress && (
            <section className={bemClasses('note')}>
              <p className={bemClasses('label-text')}>
                {context.t('Add a Note ')}
                <em>{context.t('(Optional)')}</em>
              </p>

              <APInput name="Notes" rows={3} customClass={bemClasses()} />
            </section>
          )}

          {(!withdrawError || !useExternalAddress) && (
            <APButton
              type="submit"
              customClass={bemClasses()}
              styleName="additive"
              disabled={invalid || submitting}
            >
              {context.t('Send')}
            </APButton>
          )}
        </React.Fragment>
      )}
    </form>
  );
};

SendForm.propTypes = {
  product: ProductPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  useExternalAddress: PropTypes.bool.isRequired,
  balance: PropTypes.number.isRequired,
  toggleUseExternalAddress: PropTypes.func.isRequired,
  allowTransfer: PropTypes.bool.isRequired,
  fee: PropTypes.number,
  total: PropTypes.number,
  disableWithdraw: PropTypes.bool,
  verificationOnClick: PropTypes.func
};

SendForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendForm;
