import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import resize from 'apex-web/lib/hocs/resize';

import APTable from 'apex-web/lib/components/common/APTable';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { getOrderPriceToDisplay } from 'apex-web/lib/helpers/orderHistoryHelper';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import MobileOrderHistoryList from '../MobileOrderHistoryList/MobileOrderHistoryList';

const baseClass = getBEMClasses('order-history-table');

const InactiveOrdersComponent = (props, context) => {
  const {
    data,
    instruments,
    selectedAccountId,
    getInactiveOrders,
    OMSId,
    config,
    fetching,
    inactiveOrdersStartIndex,
    isMobile
  } = props;
  const { maxLines, usePagination } = config;

  useEffect(() => {
    if (selectedAccountId) {
      getInactiveOrders(selectedAccountId, OMSId, maxLines, 0);
    }
  }, [selectedAccountId]);

  const rowProps = row => ({
    selectedInstrument:
      instruments.find(item => item.InstrumentId === row.Instrument) || {}
  });

  const columns = useMemo(
    () => [
      {
        header: context.t('Pair'),
        dataTest: 'Pair',
        cell: (row, { selectedInstrument }) =>
          selectedInstrument ? selectedInstrument.Symbol : ''
      },
      {
        header: context.t('Side'),
        dataTest: 'Side',
        cell: row => (
          <div className={baseClass(row.Side === 'Buy' ? 'buy' : 'sell')}>
            {context.t(row.Side)}
          </div>
        )
      },
      {
        header: context.t('Type'),
        dataTest: 'Type',
        cell: row =>
          row.IsQuote ? context.t('Quote') : context.t(row.OrderType)
      },
      {
        header: context.t('Size'),
        dataTest: 'Size',
        cell: (row, { selectedInstrument }) => (
          <div className={baseClass('size')}>
            {row.DisplayQuantity &&
              formatNumberToLocale(
                row.DisplayQuantity,
                convertIncrementToIntDecimalPlaces(
                  selectedInstrument.QuantityIncrement
                )
              )}
          </div>
        )
      },
      {
        header: context.t('Price'),
        dataTest: 'Price',
        cell: (row, { selectedInstrument }) => (
          <div className={baseClass('price')}>
            {getOrderPriceToDisplay(
              row,
              convertIncrementToIntDecimalPlaces(
                selectedInstrument.PriceIncrement
              )
            )}
          </div>
        )
      },
      {
        header: context.t('Date/Time'),
        dataTest: 'Date/Time',
        cell: row => formatDateTime(row.ReceiveTime)
      },
      {
        header: context.t('Status'),
        dataTest: 'Status',
        cell: row => context.t(row.OrderState)
      }
    ],
    [instruments?.length]
  );

  const mobileRows = useMemo(
    () => [
      {
        cell: (row, { selectedInstrument }) =>
          selectedInstrument ? selectedInstrument.Symbol : ''
      },
      {
        cell: (row, { selectedInstrument }) => {
          return getOrderPriceToDisplay(
            row,
            convertIncrementToIntDecimalPlaces(
              selectedInstrument.PriceIncrement
            )
          );
        }
      }
    ],
    [instruments?.length]
  );

  return isMobile ? (
    <MobileOrderHistoryList
      rows={data}
      rowProps={rowProps}
      columns={columns}
      rowsContent={mobileRows}
      fetching={fetching}
      pageSize={maxLines}
      empty={context.t('No data is available')}
      onRequestPrev={() =>
        getInactiveOrders(
          selectedAccountId,
          OMSId,
          maxLines,
          inactiveOrdersStartIndex - maxLines
        )
      }
      onRequestNext={() =>
        getInactiveOrders(
          selectedAccountId,
          OMSId,
          maxLines,
          inactiveOrdersStartIndex + maxLines
        )
      }
      componentName={'InactiveOrdersComponent'}
    />
  ) : (
    <APTable
      {...{
        columns,
        rowProps,
        rows: data,
        totalPages: data.length,
        baseClass,
        fetching,
        pageSize: maxLines,
        usePagination,
        alwaysShowPagination: true,
        useSimplePagination: true,
        empty: context.t('No data is available'),
        componentName: 'InactiveOrdersComponent',
        onRequestPrev: () => {
          getInactiveOrders(
            selectedAccountId,
            OMSId,
            maxLines,
            inactiveOrdersStartIndex - maxLines
          );
        },
        onRequestNext: () => {
          getInactiveOrders(
            selectedAccountId,
            OMSId,
            maxLines,
            inactiveOrdersStartIndex + maxLines
          );
        }
      }}
    />
  );
};

InactiveOrdersComponent.title = 'Inactive Orders';

InactiveOrdersComponent.propTypes = {
  data: PropTypes.array.isRequired,
  instruments: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired
};

InactiveOrdersComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default resize(InactiveOrdersComponent);
