import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import APIcon from 'apex-web/lib/components/common/APIcon';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { templateConfirmRenderer } from 'apex-web/lib/helpers/formTemplateHelper';

import './FiatDepositModalComponent.css';

const baseClasses = getBEMClasses('dcx-deposit-modal');

const shouldRenderBankInfo = bankInfo => {
  return bankInfo.name && bankInfo.wireNumber && bankInfo.accountNumber;
};

const FiatDepositModalComponent = (props, context) => {
  const {
    payload: { ProductSymbol, fullname, amount, comments, GivenName, Amount },
    template,
    templateInfo = {},
    payload
  } = props;

  const templateFields = templateConfirmRenderer(
    template,
    payload,
    baseClasses,
    context
  );

  return (
    <Modal
      isOpen={props.isOpen}
      title={
        <div className={baseClasses('header-content')}>
          <APIcon
            name={'depositWithBorder'}
            size={44}
            customClass={baseClasses('icon')}
          />
          <div className={baseClasses('header-title')}>
            {`${context.t('Deposit')} ${ProductSymbol}`}
          </div>
        </div>
      }
      close={props.close}
      footer={{
        buttonText: context.t('Confirm'),
        buttonStyle: 'additive',
        onClick: () => {
          props.submitDeposit(payload);

          if (props.showDepositIdModal) {
            props.openReferenceModal();
          }
        }
      }}
      customClass={baseClasses()}
    >
      <div className={baseClasses('title')}>{context.t('Confirmation')}</div>
      <div className={baseClasses()}>
        {templateFields.length > 0 ? (
          templateFields
        ) : (
          <React.Fragment>
            <APLabelWithText
              label={context.t('Full Name')}
              text={fullname || GivenName}
              customClass={baseClasses()}
            />
            <APLabelWithText
              label={context.t('Amount to Deposit')}
              text={amount || Amount}
              customClass={baseClasses()}
            />
            <APLabelWithText
              label={context.t('Note')}
              text={comments || '-'}
              customClass={baseClasses()}
            />
            <APLabelWithText
              label={context.t('Time')}
              text={formatDateTime(new Date())}
              customClass={baseClasses()}
            />
          </React.Fragment>
        )}

        {templateInfo.DepositWorkflow === 'ManualDeposit' &&
          shouldRenderBankInfo(props.bankInfo) && (
            <React.Fragment>
              <div className={baseClasses('bank-info')}>
                <APLabelWithText
                  label={context.t('Step 2: Wire the funds to this account')}
                  customClass={baseClasses()}
                  classModifiers="bank-info"
                />
                <APLabelWithText
                  label={context.t('Bank Name:')}
                  text={props.bankInfo.name}
                  customClass={baseClasses()}
                  classModifiers="bank-info"
                />
                <APLabelWithText
                  label={context.t('Wire Number:')}
                  text={props.bankInfo.wireNumber}
                  customClass={baseClasses()}
                  classModifiers="bank-info"
                />
                <APLabelWithText
                  label={context.t('Account Number:')}
                  text={props.bankInfo.accountNumber}
                  customClass={baseClasses()}
                  classModifiers="bank-info"
                />
              </div>
              <div className={baseClasses('information')}>
                <div className={baseClasses('information-text')}>
                  {context.t(
                    'Make sure the wire transfer matches the Name and Amount stated in this Deposit Ticket.'
                  )}
                </div>
                <div className={baseClasses('information-text')}>
                  {context.t(
                    'A copy of this Deposit Ticket will be emailed to you'
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
      </div>
    </Modal>
  );
};

FiatDepositModalComponent.propTypes = {
  payload: PropTypes.object,
  close: PropTypes.func.isRequired
};

FiatDepositModalComponent.defaultProps = {
  payload: {
    fullname: '',
    amount: '',
    comments: ''
  }
};

FiatDepositModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatDepositModalComponent;
