import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import {
  required,
  email,
  validateReceiverEmail
} from 'apex-web/lib/helpers/formValidations';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import APInput from 'apex-web/lib/components/common/APInput';
import APButton from 'apex-web/lib/components/common/APButton';
import { product } from 'apex-web/lib/propTypes/sendReceiveComponent';
import './ReceiveForm.css';

const bemClasses = getBEMClasses('dcx-receive-form');

const ReceiveForm = (props, context) => {
  const {
    product: {
      ProductFullName: name,
      ProductSymbol: symbol,
      DecimalPlaces: decimalPlaces
    },
    handleSubmit,
    invalid,
    submitting,
    onChange,
    onSubmit,
    userEmail
  } = props;

  const checkUserEmail = useCallback(
    value => {
      return validateReceiverEmail(value, userEmail);
    },
    [userEmail]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={bemClasses()}>
      <section className={bemClasses('receive-from')}>
        <p className={bemClasses('label-text')}>
          {context.t('Email Address to Request From')}
        </p>

        <APInput
          name="ReceiverUsername"
          customClass={bemClasses()}
          onChange={handleSubmit(onChange)}
          validate={[required, email, checkUserEmail]}
        />
      </section>

      <p className={bemClasses('label-text')}>
        {context.t('Amount of {name} to Request', { name })}
      </p>

      <section className={bemClasses('amounts')}>
        <APNumberInput
          name="Amount"
          customClass={bemClasses()}
          labelInInput={symbol}
          decimalPlaces={decimalPlaces}
          onChange={handleSubmit(onChange)}
          validate={[required]}
        />
      </section>

      <section className={bemClasses('note')}>
        <p className={bemClasses('label-text')}>
          {context.t('Add a Note ')}
          <em>{context.t('(Optional)')}</em>
        </p>

        <APInput name="Notes" rows={3} customClass={bemClasses()} />
      </section>

      <APButton
        type="submit"
        customClass={bemClasses()}
        styleName="additive"
        disabled={invalid || submitting}
      >
        {context.t('Request')}
      </APButton>
    </form>
  );
};

ReceiveForm.propTypes = {
  product: product.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

ReceiveForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReceiveForm;
