import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import './DepositWithdrawTabsComponent.css';

const bemClasses = getBEMClasses('dcx-deposit-withdraw-tabs');

const DepositWithdrawTabsComponent = (props, context) => {
  const { isSend, toggleTab, isDepositDisabled, isWithdrawDisabled, balance } =
    props;

  const onWithdrawClick = () => {
    if (isSend && !isNegativeBalance() && !isWithdrawDisabled) {
      toggleTab();
    }
  };

  const isNegativeBalance = () => {
    return balance < 0;
  };

  return (
    <header className={bemClasses()}>
      <div
        className={bemClasses('tab', {
          selected: isSend,
          disabled: isDepositDisabled
        })}
        onClick={() => !isSend && !isDepositDisabled && toggleTab()}
      >
        {context.t('Deposit')}
      </div>
      <div
        className={bemClasses('tab', {
          selected: !isSend,
          disabled: isNegativeBalance() || isWithdrawDisabled
        })}
        onClick={() => onWithdrawClick()}
      >
        {context.t('Withdraw')}
      </div>
    </header>
  );
};

DepositWithdrawTabsComponent.propTypes = {
  isSend: PropTypes.bool.isRequired,
  toggleTab: PropTypes.func.isRequired,
  balance: PropTypes.number.isRequired
};

DepositWithdrawTabsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DepositWithdrawTabsComponent;
