import React from 'react';
import PropTypes from 'prop-types';
import APSegmentedButton from 'apex-web/lib/components/common/APSegmentedButton';
import APInlineButton from 'apex-web/lib/components/common/APInlineButton';
import { styleNames } from 'apex-web/lib/propTypes/commonComponent';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import './TransfersFiltersComponent.css';

const baseClasses = getBEMClasses('transfers-filters');

const CustomTransfersFiltersComponent = (props, context) => {
  const { resetActivePage, getTransfers, OMSId, AccountId } = props;
  const tabs = {
    sentTransfers: 'sentTransfers',
    receivedTransfers: 'receivedTransfers',
    sentRequests: 'sentRequests',
    receivedRequests: 'receivedRequests'
  };

  return (
    <div className={baseClasses()}>
      <div className={baseClasses('select-buttons')}>
        <APSegmentedButton
          name="transfersFilters"
          defaultValue={tabs.sentTransfers}
          items={[
            {
              value: tabs.sentTransfers,
              text: context.t('Sent Transfers')
            },
            {
              value: tabs.receivedTransfers,
              text: context.t('Received Transfers')
            }
            // {
            //   value: tabs.sentRequests,
            //   text: context.t('Sent Requests')
            // },
            // {
            //   value: tabs.receivedRequests,
            //   text: context.t('Received Requests')
            // }
          ]}
          styleName={styleNames.additive}
          customClass={baseClasses()}
          onClick={resetActivePage}
        />
      </div>
      <APInlineButton
        icon="refresh"
        customClass={baseClasses('refresh-transfers')}
        text={context.t('Refresh Transfers')}
        onClick={() => {
          getTransfers(OMSId, AccountId);
        }}
      />
    </div>
  );
};

CustomTransfersFiltersComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CustomTransfersFiltersComponent;
