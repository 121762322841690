import React, { useState, useMemo } from 'react';
import get from 'lodash/get';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import './TransactPage.css';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import PriceChart from '../../dcxComponents/PriceChart/PriceChartContainer';
import InstrumentTable from '../../dcxComponents/InstrumentTable/InstrumentTableContainer';
import OrderBook from '../../dcxComponents/OrderBookComponents/OrderBookContainer';
import BuySell from '../../dcxComponents/OrderEntryComponents/OrderEntryWithPercentButtonsContainer';
import DepthChart from '../../dcxComponents/DepthChart/DepthChartContainer';
import RecentTrades from '../../dcxComponents/RecentTrades/RecentTradesContainer';
import { Section } from '../../dcxComponents/common/Section/Section';
import InstrumentOverviewContainer from '../../dcxComponents/InstrumentOverview/InstrumentOverviewContainer';
import SearchInput from '../../dcxComponents/common/SearchInput/SearchInput';
import { connect } from 'react-redux';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import TradingTabs from '../../dcxComponents/TradingTabs';
import { selectInstrument } from 'apex-web/lib/redux/actions/instrumentActions';
import { useDcxData } from '../../dcxContext';

const baseClasses = getBEMClasses('transact-page');

const TransactPage = ({ selectedInstrumentId, selectInstrument }) => {
  const [isShowAssets, setIsShowAssets] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { assets, instruments } = useDcxData();

  const selectedAsset = assets.find(
    item => item.instrumentId === selectedInstrumentId
  );
  const selectedInstrument = instruments.find(
    item => item.InstrumentId === selectedAsset.instrumentId
  );

  const filteredAssets = useMemo(() => {
    const searchValueInLowerCase = searchValue.toLowerCase();
    return searchValue
      ? assets.filter(
          item =>
            (item.productFullName1
              .toLowerCase()
              .includes(searchValueInLowerCase) ||
              item.productFullName2
                .toLowerCase()
                .includes(searchValueInLowerCase) ||
              item.productSymbol1
                .toLowerCase()
                .includes(searchValueInLowerCase) ||
              item.productSymbol2
                .toLowerCase()
                .includes(searchValueInLowerCase)) &&
            item.instrumentId !== selectedInstrumentId
        )
      : assets.filter(item => item.instrumentId !== selectedInstrumentId);
  }, [assets.length, searchValue, selectedInstrumentId]);

  return (
    <>
      <div className={baseClasses()}>
        <TradingTabs />

        <div className={baseClasses('container')}>
          <div className={baseClasses('instrument-overview-row')}>
            <InstrumentOverviewContainer
              selectedInstrument={selectedInstrument}
              price={selectedAsset.price}
            />
          </div>
          <div
            className={baseClasses('instrument-row', isShowAssets && 'open')}
            onClick={() => setIsShowAssets(!isShowAssets)}
          >
            <InstrumentTable
              price={selectedAsset.price}
              change24hr={selectedAsset.change24hr}
              marketCap={selectedAsset.volume24hr}
              title={selectedAsset.productFullName1}
              symbol={selectedAsset.productSymbol1}
              price24hr={selectedAsset.price24hr}
            />
            {isShowAssets && (
              <>
                <div className={baseClasses('search')}>
                  <SearchInput value={searchValue} setValue={setSearchValue} />
                </div>
                {filteredAssets.map(item => (
                  <InstrumentTable
                    selectedInstrument={instruments.find(
                      instrument =>
                        instrument.InstrumentId === item.instrumentId
                    )}
                    key={item.instrumentId}
                    onInstrumentClick={() => {
                      selectInstrument(item.instrumentId);
                    }}
                    price={item.price}
                    change24hr={item.change24hr}
                    marketCap={item.volume24hr}
                    title={item.productFullName1}
                    symbol={item.productSymbol1}
                    price24hr={item.price24hr}
                  />
                ))}
              </>
            )}
          </div>
          <div className={baseClasses('price-chart')}>
            <PriceChart />
          </div>
          <div className={baseClasses('order-book-with-buy-sell')}>
            <div className={baseClasses('order-book')}>
              <Section title="Order book" infoText={'Order book info'}>
                <OrderBook />
              </Section>
            </div>

            <div className={baseClasses('buy-sell')}>
              <Section isNotFullHeight>
                <BuySell />
              </Section>
            </div>
          </div>
          <div className={baseClasses('depth-chart-with-recent-trades')}>
            <div className={baseClasses('depth-chart')}>
              <Section
                title="Cumulative market depth"
                infoText={'Cumulative market depth info'}
              >
                <DepthChart smallScreen />
              </Section>
            </div>
            <div className={baseClasses('recent-trades')}>
              <Section title="Recent trades" infoText={'Recent trades'}>
                <RecentTrades />
              </Section>
            </div>
          </div>
        </div>
      </div>
      <div className={baseClasses('footer')}>
        <PageFooterLayout />
      </div>
    </>
  );
};

const mapStateFromProps = state => {
  const selectedInstrument = selectedInstrumentSelector(state);
  const selectedInstrumentId = get(selectedInstrument, 'InstrumentId', '');
  const user = state;

  return { selectedInstrumentId };
};

const mapDispatchToProps = {
  selectInstrument
};

export default connect(mapStateFromProps, mapDispatchToProps)(TransactPage);
