import { createSelector } from 'reselect';
import { selectBaseCurrencyDecimalPlaces } from './decimalPlacesSelector';
import { positionSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import get from 'lodash/get';
import BigNumber from 'bignumber.js';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

export const selectInstrumentsWithPricesInBaseCurrency = createSelector(
  [state => state.instrument.instruments, state => state.level1],
  (instruments, level1) => {
    return instruments.map(item => {
      return {
        ...item,
        BestBid: get(level1[item.InstrumentId], 'BestBid', 0),
        BestOffer: get(level1[item.InstrumentId], 'BestOffer', 0),
        LastTradedPx: get(level1[item.InstrumentId], 'LastTradedPx', 0),
        Rolling24HrPxChangePercent: get(
          level1[item.InstrumentId],
          'Rolling24HrPxChangePercent',
          0
        ),
        Rolling24NumTrades: get(
          level1[item.InstrumentId],
          'Rolling24NumTrades',
          0
        ),
        CurrentDayVolume: get(level1[item.InstrumentId], 'CurrentDayVolume', 0)
      };
    });
  }
);

export const selectPositionsWithPredicate = createSelector(
  [
    positionSelector,
    state => state.product.products,
    (state, options) => options.predicate
  ],
  (positions, products, predicate = () => true) => {
    return Object.values(positions).filter(item => {
      const product = products.find(
        product => product.ProductId === item.ProductId
      );
      return !!product && predicate(item);
    });
  }
);

export const selectAssetsBalanceInBaseCurrencyWithPredicate = createSelector(
  [
    selectPositionsWithPredicate,
    selectInstrumentsWithPricesInBaseCurrency,
    selectBaseCurrencyDecimalPlaces,
    state => state.product.products
  ],
  (positions, instrumentsWithPrices, baseCurrencyDecimalPlace, products) => {
    let totalHoldInBaseCurrency = new BigNumber(0);
    let totalAmountInBaseCurrency = new BigNumber(0);
    let totalPendingDeposits = new BigNumber(0);
    let totalPendingWithdraws = new BigNumber(0);
    const assetsWithPrice = positions.map(item => {
      const product = products.find(
        product => product.ProductId === item.ProductId
      ) || { ProductFullName: item.ProductSymbol };
      const instrumentByPosition = instrumentsWithPrices.find(
        instrument => instrument.Product1 === item.ProductId
      ) || {
        BestOffer: 1,
        BestBid: 0,
        LastTradedPx: 0,
        Rolling24HrPxChangePercent: 0,
        SessionStatus: 'Running'
      };
      const baseCurrencyMultiplier = instrumentByPosition.BestOffer;
      const holdInBaseCurrency = new BigNumber(item.Hold).times(
        baseCurrencyMultiplier
      );
      totalHoldInBaseCurrency =
        totalHoldInBaseCurrency.plus(holdInBaseCurrency);
      const amountInBaseCurrency = new BigNumber(item.Amount).times(
        baseCurrencyMultiplier
      );
      totalAmountInBaseCurrency =
        totalAmountInBaseCurrency.plus(amountInBaseCurrency);
      const pendingDeposits = new BigNumber(item.PendingDeposits).times(
        baseCurrencyMultiplier
      );
      totalPendingDeposits = totalPendingDeposits.plus(pendingDeposits);
      const pendingWithdraws = new BigNumber(item.PendingWithdraws).times(
        baseCurrencyMultiplier
      );
      totalPendingWithdraws = totalPendingWithdraws.plus(pendingWithdraws);
      return {
        ...item,
        HoldInBaseCurrency: holdInBaseCurrency,
        AmountInBaseCurrency: amountInBaseCurrency.minus(pendingWithdraws),
        PendingDepositsInBaseCurrency: pendingDeposits,
        AvailableBalanceInBaseCurrency:
          amountInBaseCurrency.minus(pendingWithdraws),
        BestBid: instrumentByPosition.BestBid,
        BestOffer: instrumentByPosition.BestOffer,
        LastTradedPx: instrumentByPosition.LastTradedPx,
        Rolling24HrPxChangePercent:
          instrumentByPosition.Rolling24HrPxChangePercent,
        Rolling24NumTrades: instrumentByPosition.Rolling24NumTrades,
        ProductFullName: product.ProductFullName,
        MarketValue: instrumentByPosition.CurrentDayVolume,
        isMarketRunning: instrumentByPosition.SessionStatus === 'Running'
      };
    });

    const totalAvailableBalance = totalAmountInBaseCurrency.minus(
      totalPendingWithdraws
    );
    const assetsWithPriceAndPercent = assetsWithPrice.map(item => {
      const percentOfTotalAmount = item.AmountInBaseCurrency.times(100).div(
        totalAvailableBalance
      );
      return {
        ...item,
        rawData: {
          AmountInBaseCurrency: item.AmountInBaseCurrency,
          HoldInBaseCurrency: item.HoldInBaseCurrency,
          PendingDepositsInBaseCurrency: item.PendingDepositsInBaseCurrency,
          AvailableBalanceInBaseCurrency: item.AvailableBalanceInBaseCurrency,
          PercentOfTotalAmount: item.PercentOfTotalAmount,
          BestBid: item.BestBid,
          BestOffer: item.BestOffer
        },
        AmountInBaseCurrency: formatNumberToLocale(
          item.AmountInBaseCurrency,
          baseCurrencyDecimalPlace
        ),
        HoldInBaseCurrency: formatNumberToLocale(
          item.HoldInBaseCurrency,
          baseCurrencyDecimalPlace
        ),
        PendingDepositsInBaseCurrency: formatNumberToLocale(
          item.PendingDepositsInBaseCurrency,
          baseCurrencyDecimalPlace
        ),
        AvailableBalanceInBaseCurrency: formatNumberToLocale(
          item.AvailableBalanceInBaseCurrency,
          baseCurrencyDecimalPlace
        ),
        PercentOfTotalAmount: formatNumberToLocale(
          percentOfTotalAmount.dp(2, BigNumber.ROUND_HALF_UP)
        ),
        BestBid: formatNumberToLocale(item.BestBid, baseCurrencyDecimalPlace),
        BestOffer: formatNumberToLocale(
          item.BestOffer,
          baseCurrencyDecimalPlace
        )
      };
    });
    return {
      assetsWithPriceAndPercent,
      rawData: {
        totalHold: totalHoldInBaseCurrency,
        totalAmount: totalAmountInBaseCurrency,
        totalPendingDeposits,
        totalAvailableBalance
      },
      totalHold: formatNumberToLocale(
        totalHoldInBaseCurrency,
        baseCurrencyDecimalPlace
      ),
      totalAmount: formatNumberToLocale(
        totalAmountInBaseCurrency,
        baseCurrencyDecimalPlace
      ),
      totalPendingDeposits: formatNumberToLocale(
        totalPendingDeposits,
        baseCurrencyDecimalPlace
      ),
      totalAvailableBalance: formatNumberToLocale(
        totalAvailableBalance,
        baseCurrencyDecimalPlace
      )
    };
  }
);
